import styled from "styled-components";

export const FlexDef = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: center;
  padding: 0 7rem 1.2rem 0;
  width: 100%;
  /* padding: 0 5rem 0 0; */
  @media (max-width: 1250px) {
    padding: 0 0 1.2rem 0;
  }
`;

export const NoneDisplay1000 = styled.div`
  display: block;
  @media (max-width: 1000px) {
    display: none;
  }
`;
