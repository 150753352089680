import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

interface ChatUserType {
  ISYOU: boolean;
  col?: boolean;
  ros?: boolean;
}

export const Container = styled.div<ChatUserType>`
  display: grid;
  column-gap: 0.8rem;
  width: 40rem;
  align-items: start;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  width: 100%;
`;

export const Nickname = styled(Link)<ChatUserType>`
  width: 100%;
  text-align: left;
  ${(props) =>
    props.ISYOU
      ? props.theme.fonts.GothamBold20
      : props.theme.fonts.GothamRegular20};

  /* props.ISYOU */
  /* ? props.theme.colors.blue.accent */
  /* : props.col */
  /* ? props.theme.colors.red.accent */
  /* : props.theme.colors.white.white70}; */
  color: ${(props) =>
    props.ros
      ? "#ff66cc"
      : props.col
      ? props.theme.colors.green.selected
      : props.ISYOU
      ? props.theme.colors.blue.accent
      : props.theme.colors.white.white70};
`;

export const MessageWrapper = styled.div<{ isMenu: boolean; ISYOU: boolean }>`
  display: flex;
  align-items: center;
  position: relative;
  justify-content: space-between;
  /* flex-direction: ${(props) => (props.ISYOU ? "row" : "row-reverse")}; */
  width: 100%;
  svg {
    transition: 0.3s all;
    cursor: pointer;
    circle {
      transition: 0.3s all;
      fill-opacity: ${(props) => props.isMenu && 1};
    }
  }
`;

export const Message = styled.div<ChatUserType>`
  width: calc(100% - 1.8rem);
  height: fit-content;
  padding: 1.5rem 1rem 1rem 1.5rem;
  border-radius: 1.3rem;
  background: ${(props) => props.theme.colors.white.white10};
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  /* ${(props) =>
    props.ISYOU
      ? "border-top-right-radius: 0;"
      : "border-top-left-radius: 0;"} */
`;

export const Text = styled.p`
  word-break: break-word;
  ${(props) => props.theme.fonts.GothamRegular16};
  color: ${(props) => props.theme.colors.white.white100};
`;

export const Timestamp = styled.span`
  text-align: right;
  width: 100%;
  color: ${(props) => props.theme.colors.white.white70};
  ${(props) => props.theme.fonts.GothamChatTimeLight12};
`;

export const Menu = styled.div<{ isLast: boolean }>`
  padding: 1.5rem;
  position: absolute;
  border-radius: 2rem;
  opacity: 0;
  pointer-events: none;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  right: 1rem;
  transition: 0.3s all;
  top: -2rem;
  z-index: -4;
  background: ${(props) => props.theme.colors.white.white100};
  ${(props) => props.theme.mixins.defaultShadow};
  width: 17rem;
  height: fit-content;
  &.active {
    opacity: 1;
    pointer-events: all;
    z-index: 5;
  }
  ${(props) =>
    props.isLast &&
    css`
      top: -11rem;
    `}
`;

export const MenuItem = styled.span`
  ${(props) => props.theme.fonts.GothamRegular16};
  color: ${(props) => props.theme.colors.black.black100};
  transition: 0.15s all;
  cursor: pointer;
  &:hover {
    ${(props) => props.theme.fonts.GothamBold16};
  }
`;
