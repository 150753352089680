import { CaseServise as CaseService } from "API/CaseService";
import { Icons } from "assets/icons";
import { TinyIcons } from "assets/tiny-icons";
import Image from "components/Image";
import Layout from "components/Layout";
import ArrowBack from "components/UI/ArrowBack";
import Button from "components/UI/Button";
import { useActions } from "hooks/useActions";
import { useAppSelector } from "hooks/useAppSelector";
import {
  CaseCategory,
  CaseItems,
  ItemGetCase,
  SkinInCase,
} from "interfaces/Case";
import { useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate, useParams } from "react-router-dom";
import rarityConvector from "utils/rarityConvector";
import { CasesAnimation } from "../CasesAnimation/CasesAnimation";
import {
  BlockDiv,
  ButNot,
  ButtonWrap,
  Container,
  ContainerAnimationCase,
  ItemKilCase,
  NameSkin,
  NameSkinResult,
  SkinName,
  TextSold,
  Title,
  TitleCaseWrapper,
  TitleSkinCase,
  WeaponName,
  WrapperItem,
  WrapperItemCase,
  WrapperResultItemCase,
  WrapperSkin,
  WrappKolCase,
  WrapResultCases,
} from "./Styled";

export default function CaseItem() {
  const { id } = useParams();
  const [kolCaseOpen, setKolCaseOpen] = useState(1);
  const [fullCase, setFullCase] = useState<CaseItems>();
  const [skinInCase, setSkinInCase] = useState<Array<SkinInCase>>([]);
  const [isOpenCase, setIsOpenCase] = useState<boolean>(false);
  const [ItemCase, setItemCase] = useState<[ItemGetCase]>([{}]);
  const [priceResultCase, setPriceItemCase] = useState<number>(0);
  const [sellItem, setSellItem] = useState<Array<number>>([0]);
  const [time, setTime] = useState<any>(-1);
  const [isAutoScrolling1, setIsAutoScrolling1] = useState(false);
  const [isAutoScrolling2, setIsAutoScrolling2] = useState(false);
  const [isAutoScrolling3, setIsAutoScrolling3] = useState(false);
  const [isAutoScrolling4, setIsAutoScrolling4] = useState(false);
  const [isAutoScrolling5, setIsAutoScrolling5] = useState(false);
  const [nonAnimation, setNonAnimation] = useState<boolean>(false);
  const { setBalance } = useActions();

  const [tik, setTik] = useState<number>(1);
  const { setUser } = useActions();

  useEffect(() => {
    async function getCase() {
      const caseCategory = await CaseService.getCases();
      const skinInCase = await CaseService.getItemCase(Number(id));
      setSkinInCase(skinInCase);

      // console.log(fullCase);
      caseCategory.forEach((items: CaseCategory) => {
        items.cases.forEach((item) => {
          if (String(item.id) == id) {
            setFullCase(item);
            // console.log(item);
          }
        });
      });
    }
    getCase();
  }, []);

  useMemo(() => {
    if (priceResultCase == 0) {
      setIsOpenCase(false);
    }
  }, [priceResultCase]);
  const windowInnerWidth = document.documentElement.clientWidth;

  async function OpenCase(resp?: [ItemGetCase]) {
    let response: [ItemGetCase];
    if (resp) {
      response = resp;
      // console.log(response);
    } else {
      response = await CaseService.OpenCase(Number(id), kolCaseOpen);
    }
    // console.log(response);
    if (response) {
      setIsOpenCase(true);
      setItemCase(response);
    }
    let FullPrice = 0;
    response.forEach((item) => {
      FullPrice = FullPrice + (item.price || 0);
    });

    setPriceItemCase(FullPrice);
  }
  const { isAuth, user } = useAppSelector((state) => state.user);
  const navigate = useNavigate();
  useEffect(() => {
    if (!isAuth) {
      navigate(`/chest`);
    }
  });

  // useEffect(() => {
  //   console.log(time);
  // }, [time]);

  return (
    <Layout leftElement={<ArrowBack />} headerElement={<div />} nonChat>
      <Helmet>
        <title>{`FREEZ-BLOOD - ${fullCase?.name}`}</title>
        <meta name="description" content={`FREEZ-BLOOD - ${fullCase?.name}`} />
      </Helmet>
      <WrapperItem>
        <WrapperItemCase>
          <Title>
            <Icons.ArrowLeftCase />
            {fullCase?.name}
            <Icons.ArrowRightCase />
          </Title>

          <hr />
          {isOpenCase == true &&
          ItemCase !== undefined &&
          isAutoScrolling1 == false &&
          isAutoScrolling2 == false &&
          isAutoScrolling3 == false &&
          isAutoScrolling4 == false &&
          isAutoScrolling5 == false ? (
            <>
              <WrapResultCases>
                {ItemCase &&
                  ItemCase.map((item) => {
                    // setPriceItemCase(priceResultCase + (item.price || 0));
                    return (
                      <WrapperResultItemCase
                        rarity={rarityConvector(item.rarity || 2)}
                      >
                        <BlockDiv rarity={rarityConvector(item.rarity || 2)}>
                          &nbsp;
                        </BlockDiv>
                        <Image
                          width="280px"
                          height="210px"
                          src={item.image || ""}
                          alt={"skin"}
                        />
                        <NameSkinResult>
                          <WeaponName>{item.weapon}&nbsp;|&nbsp;</WeaponName>
                          <SkinName rarity={rarityConvector(item.rarity || 2)}>
                            {item.skin}
                          </SkinName>
                        </NameSkinResult>
                        {sellItem.includes(item.inventory_id || -10) ? (
                          <ButNot>
                            <Icons.Sold />
                            <TextSold>Продано</TextSold>
                          </ButNot>
                        ) : (
                          <Button
                            variant="picker"
                            height={"52"}
                            color="blue"
                            params={{ width: "250px" }}
                            onClick={async () => {
                              const result: { success: boolean } =
                                await CaseService.SoldItem([
                                  item.inventory_id || 0,
                                ]);
                              if (result) {
                                setPriceItemCase(
                                  priceResultCase - (item.price || 0)
                                );
                                setSellItem([
                                  ...sellItem,
                                  item.inventory_id || 0,
                                ]);

                                setTik(tik + 1);
                                setBalance({
                                  real: user.balance.real,
                                  virtual:
                                    user.balance.virtual + (item.price || 0),
                                });
                              }
                            }}
                          >
                            Продать за {item.price}&nbsp; <TinyIcons.Credit />
                          </Button>
                        )}
                      </WrapperResultItemCase>
                    );
                  })}
              </WrapResultCases>
              <ButtonWrap>
                {kolCaseOpen !== 1 ? (
                  <Button
                    variant="picker"
                    height={"52"}
                    color="blue"
                    params={{ width: "300px" }}
                    onClick={async () => {
                      const result: { success: boolean } =
                        await CaseService.SoldItem(
                          ItemCase &&
                            ItemCase.map((item) => {
                              return item?.inventory_id
                                ? item?.inventory_id
                                : 0;
                            })
                        );
                      if (result) {
                        setPriceItemCase(0);
                        setTik(tik + 1);

                        setBalance({
                          real: user.balance.real,
                          virtual: user.balance.virtual + priceResultCase,
                        });
                      }
                    }}
                  >
                    Продать все {priceResultCase}&nbsp;
                    <TinyIcons.Credit />
                  </Button>
                ) : (
                  <></>
                )}

                <Button
                  variant="picker"
                  height="52"
                  onClick={() => setPriceItemCase(0)}
                >
                  Повторить
                </Button>
              </ButtonWrap>
            </>
          ) : (
            <>
              {isAutoScrolling1 == true &&
              ItemCase !== undefined &&
              time < 0 ? (
                <ContainerAnimationCase>
                  {ItemCase.map((item, index) => {
                    const isAuto =
                      index == 0
                        ? isAutoScrolling1
                        : index == 1
                        ? isAutoScrolling2
                        : index == 2
                        ? isAutoScrolling3
                        : index == 3
                        ? isAutoScrolling4
                        : isAutoScrolling5;
                    return (
                      <CasesAnimation
                        isAutoScrolling={isAuto}
                        setIsAutoScrolling={
                          index == 0
                            ? setIsAutoScrolling1
                            : index == 1
                            ? setIsAutoScrolling2
                            : index == 2
                            ? setIsAutoScrolling3
                            : index == 3
                            ? setIsAutoScrolling4
                            : setIsAutoScrolling5
                        }
                        arrayItem={skinInCase}
                        vinItem={item}
                      />
                    );
                  })}
                </ContainerAnimationCase>
              ) : (
                <>
                  <Image
                    src={fullCase?.image || "/static/images/defaultCase.png"}
                    alt={"ImageCase"}
                    width="335"
                    height="228"
                  />
                  {fullCase?.price !== 0 ? (
                    <WrappKolCase>
                      <ItemKilCase
                        active={kolCaseOpen == 1 ? true : false}
                        onClick={() => {
                          setKolCaseOpen(1);
                        }}
                      >
                        1
                      </ItemKilCase>
                      <ItemKilCase
                        active={kolCaseOpen == 2 ? true : false}
                        onClick={() => {
                          setKolCaseOpen(2);
                        }}
                      >
                        2
                      </ItemKilCase>
                      <ItemKilCase
                        active={kolCaseOpen == 3 ? true : false}
                        onClick={() => {
                          setKolCaseOpen(3);
                        }}
                      >
                        3
                      </ItemKilCase>
                      <ItemKilCase
                        active={kolCaseOpen == 4 ? true : false}
                        onClick={() => {
                          setKolCaseOpen(4);
                        }}
                      >
                        4
                      </ItemKilCase>
                      <ItemKilCase
                        active={kolCaseOpen == 5 ? true : false}
                        onClick={() => {
                          setKolCaseOpen(5);
                        }}
                      >
                        5
                      </ItemKilCase>
                    </WrappKolCase>
                  ) : (
                    <></>
                  )}

                  <Button
                    variant="default"
                    height={"52px"}
                    color="blue"
                    disabled={
                      time > 0 ||
                      time == undefined ||
                      fullCase?.price == null ||
                      fullCase?.id == undefined
                    }
                    params={{ width: "260px" }}
                    onClick={async () => {
                      if (
                        user?.balance?.virtual -
                          kolCaseOpen * (fullCase?.price || 0) <
                        0
                      ) {
                        navigate(`/store/credits`);
                      } else {
                        setBalance({
                          real: user.balance.real,
                          virtual:
                            user.balance.virtual -
                            kolCaseOpen * (fullCase?.price || 0),
                        });
                      }
                      if (fullCase?.price == 0) {
                        const d: [ItemGetCase] = await CaseService.OpenCase(
                          Number(id),
                          1
                        );
                        if (Array.isArray(d)) {
                          OpenCase(d);
                        } else {
                          setTime(d);
                        }
                      } else {
                        setTik(tik + 1);
                        OpenCase();
                      }

                      if (nonAnimation == false) {
                        if (
                          kolCaseOpen == 1 &&
                          user?.balance?.virtual -
                            kolCaseOpen * (fullCase?.price || 0) >=
                            0
                        ) {
                          setIsAutoScrolling1(true);
                        } else if (kolCaseOpen == 2) {
                          setIsAutoScrolling1(true);
                          setIsAutoScrolling2(true);
                        } else if (kolCaseOpen == 3) {
                          setIsAutoScrolling2(true);
                          setIsAutoScrolling1(true);
                          setIsAutoScrolling3(true);
                        } else if (kolCaseOpen == 4) {
                          setIsAutoScrolling4(true);
                          setIsAutoScrolling3(true);
                          setIsAutoScrolling2(true);
                          setIsAutoScrolling1(true);
                        } else if (kolCaseOpen == 5) {
                          setIsAutoScrolling5(true);
                          setIsAutoScrolling4(true);
                          setIsAutoScrolling3(true);
                          setIsAutoScrolling2(true);
                          setIsAutoScrolling1(true);
                        }
                      }

                      // if (priceResultCase == 0) {
                      //   setIsOpenCase(false);
                      // }
                    }}
                  >
                    {fullCase?.price == 0 ? (
                      <>
                        {time < 0
                          ? "Бесплатно"
                          : time == undefined
                          ? "Ошибка"
                          : `${Math.round(time / 1000 / 60 / 60)}ч`}
                      </>
                    ) : (
                      <>
                        {user?.balance?.virtual -
                          kolCaseOpen * (fullCase?.price || 0) >=
                        0 ? (
                          <>
                            Открыть за {kolCaseOpen * (fullCase?.price || 0)}
                            <TinyIcons.Credit />
                          </>
                        ) : (
                          "Купить кредиты"
                        )}
                      </>
                    )}
                  </Button>

                  {/* <CheckBoxWrapper>
                    <Checkbox
                      onClick={() => {
                        setNonAnimation(!nonAnimation);
                        // console.log(nonAnimation);
                      }}
                      checked={nonAnimation}
                    />
                    <Text>Открыть без анимации</Text>
                  </CheckBoxWrapper> */}
                </>
              )}
            </>
          )}
        </WrapperItemCase>
        <WrapperItemCase>
          <TitleCaseWrapper>
            <Icons.ArrowLeftCase />
            <TitleSkinCase>Скины в кейсе</TitleSkinCase>
            <Icons.ArrowRightCase />
          </TitleCaseWrapper>
          <hr />
          <WrapperSkin>
            {skinInCase.map((skin) => {
              return (
                <Container rarity={rarityConvector(skin.rarity)}>
                  <BlockDiv rarity={rarityConvector(skin.rarity)}>
                    &nbsp;
                  </BlockDiv>
                  <Image
                    src={skin.image}
                    alt={"skin"}
                    width={windowInnerWidth > 768 ? "187" : "127"}
                    height={windowInnerWidth > 768 ? "136" : "77"}
                  />
                  <NameSkin>
                    {skin.weapon == skin.skin ? null : (
                      <WeaponName>{skin.weapon}</WeaponName>
                    )}

                    <SkinName rarity={rarityConvector(skin.rarity)}>
                      {skin.skin}
                    </SkinName>
                  </NameSkin>
                </Container>
              );
            })}
          </WrapperSkin>
        </WrapperItemCase>
      </WrapperItem>
    </Layout>
  );
}
