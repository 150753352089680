import styled, { GradientKeys } from "styled-components";

interface Props {
  rarity: GradientKeys;
}

export const Container = styled.div<Props>`
  position: relative;
  cursor: pointer;
  /* padding: 0 0px 2rem 0; */
  background: ${(props) => props.theme.gradients[props.rarity]};
  border-radius: 1.5rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  /* align-items: center; */
  text-align: center;
  align-items: center;
  gap: 1.6rem;
  width: 15.7rem;
  height: 24rem;
`;
export const BlockDiv = styled.div<{ rarity: GradientKeys }>`
  /* position: relative; */
  /* top: 0; */
  font-size: 3px;
  border-radius: 0 0 5rem 5rem;
  height: 1rem;
  width: 5rem;
  background: ${(props) => props.theme.colors.weapon[props.rarity]};
  color: ${(props) => props.theme.colors.weapon[props.rarity]};
  align-self: center;
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  gap: 1.5rem;
  height: 100%;
  /* padding-left: 1.5rem; */
  /* padding-bottom: 2rem; */
  /* align-items: center; */
`;

export const Name = styled.span`
  ${(props) => props.theme.fonts.GothamChatRegular12};
  color: ${(props) => props.theme.colors.white.white100};
  font-weight: 400;
  height: 3rem;
  display: flex;
  flex-direction: column;
`;

export const SubName = styled.span<Props>`
  /* font-weight: bold; */
  ${(props) => props.theme.fonts.GothamBold14};
  color: ${(props) => props.theme.colors.weapon[props.rarity]};
`;

export const Price = styled.div`
  ${(props) => props.theme.fonts.GothamBold16};
  color: ${(props) => props.theme.colors.white.white100};
  align-items: center;
  text-align: center;
  padding: 1rem 0 1rem 0;
`;
export const Sdf = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  align-items: center;
  svg {
    width: 15px;
    height: 15px;
  }
`;
