import { TinyIcons } from "assets/tiny-icons";
import FriendCard from "components/Cards/FriendCard";
import Column from "components/Column";
import Layout from "components/Layout";
import LayoutIcon from "components/LayoutIcon";
import ProfileLayout from "components/ProfileLayout";
import SubHeader from "components/SubHeader";
import { useActions } from "hooks/useActions";
import { useAppSelector } from "hooks/useAppSelector";
import { FriendRenderMode } from "models/Friend";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Grid } from "./Styles";

export default function Friends() {
  const { friends, friendRequests, friendSent } = useAppSelector(
    (state) => state.user
  );
  const { fetchFriends, fetchFriendRequests, fetchFriendSent } = useActions();
  const { steamid64 } = useParams();
  const [mode, setMode] = useState(0);
  const [actualFriendList, setActualFriendList] = useState<any>();

  useEffect(() => {
    fetchFriends(steamid64 || "");
    fetchFriendRequests(steamid64 || "");
    fetchFriendSent(steamid64 || "");
  }, [steamid64]);

  useEffect(() => {
    if (mode == FriendRenderMode.default) {
      setActualFriendList(friends);
      //console.log(friends)
    }

    if (mode == FriendRenderMode.request) {
      setActualFriendList(friendRequests);
    }

    if (mode == FriendRenderMode.decline) {
      setActualFriendList(friendSent);
      //console.log('ОТПРАВЛЕННЫЕ ЗАЯВКИ ;', friendSent)
    }
  }, [friends, mode, steamid64]);

  return (
    <Layout
      headerElement={<SubHeader>Друзья</SubHeader>}
      leftElement={
        <Column style={{ marginTop: "29rem" }}>
          <LayoutIcon
            className={mode === FriendRenderMode.default ? "active" : ""}
            onClick={() => setMode(0)}
          >
            <TinyIcons.LayoutSecond />
          </LayoutIcon>
          <LayoutIcon
            className={mode === FriendRenderMode.request ? "active" : ""}
            onClick={() => setMode(1)}
          >
            <TinyIcons.Plus />
          </LayoutIcon>
          <LayoutIcon
            className={mode === FriendRenderMode.decline ? "active" : ""}
            onClick={() => setMode(2)}
          >
            <TinyIcons.Reload />
          </LayoutIcon>
        </Column>
      }
    >
      <ProfileLayout>
        <Grid>
          {actualFriendList?.length ? (
            actualFriendList.map((item: any, index: number) => (
              <FriendCard
                id={item.id}
                last_online={item.last_online}
                steamid64={item.steamid64}
                renderMode={mode}
                name={item.name}
                avatar={item.avatar}
                key={index}
              />
            ))
          ) : (
            <></>
          )}
        </Grid>
      </ProfileLayout>
    </Layout>
  );
}
