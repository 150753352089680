import { useEffect, useMemo, useRef, useState } from "react";
import "./style.css";

import rarityConvector from "utils/rarityConvector";
import { BlockDiv, Item, NameSkin, SkinName, WeaponName } from "./styled";

// const items = Array.from({ length: 100 }, (_, index) => index + 1);
const itemWidth = 190;
const itemsToShow = 5; // Количество элементов, видимых одновременно

export const CasesAnimation = (props) => {
  const scrollContentRef = useRef(null);
  const [newArray, setNewArray] = useState([]);
  // console.log("vinItem", props.vinItem);

  const generateNewArray = (arrayItem, vinItem) => {
    const tempArray = [];
    for (let i = 0; i < 80; i++) {
      if (i !== 75) {
        const randomElement =
          arrayItem[Math.floor(Math.random() * arrayItem.length)];
        tempArray.push(randomElement);
      } else {
        tempArray.push(vinItem);
      }
    }
    return tempArray;
  };

  useMemo(() => {
    // Генерируем новый массив, только если изменились props.arrayItem или props.vinItem
    setNewArray(generateNewArray(props.arrayItem, props.vinItem));
  }, [props.vinItem]); // Добавили props.arrayItem в зависимости

  const windowInnerWidth = document.documentElement.clientWidth;

  // console.log("newArray", newArray);
  useMemo(() => {
    if (props.isAutoScrolling) {
      const scrollContainer = scrollContentRef.current;
      // const itemsToScroll = 77; // Количество элементов для прокрутки
      const itemsToScroll =
        windowInnerWidth > 1650
          ? 77
          : windowInnerWidth > 800 && windowInnerWidth < 1650
          ? 78.5
          : 79.8; // Количество элементов для прокрутки

      const targetScrollPosition = itemsToScroll * itemWidth;
      const animationDuration = 20000; // Время анимации в миллисекундах
      const startTime = performance.now();

      const animateScroll = (currentTime) => {
        const elapsedTime = currentTime - startTime;
        const progress = elapsedTime / animationDuration;

        // Используем кубическую функцию замедления после 50% прогресса
        const slowdownFactor =
          progress < 0.1
            ? 2 * Math.pow(progress, 3)
            : 1 - Math.pow(1 - progress, 3);

        // Останавливаем анимацию при достижении 75%
        const currentPosition = progress
          ? targetScrollPosition * slowdownFactor
          : targetScrollPosition;

        setScrollPosition(currentPosition);

        if (progress <= 1 && progress < 0.757) {
          requestAnimationFrame(animateScroll);
        } else {
          setTimeout(() => {
            props.setIsAutoScrolling(false);
          }, 2500);
        }
      };

      requestAnimationFrame(animateScroll);
    }
  }, [props.isAutoScrolling]);

  const setScrollPosition = (position) => {
    const scrollContainer = scrollContentRef.current;
    scrollContainer.style.transform = `translateX(-${position}px)`;
  };

  return windowInnerWidth >= 1650 ? (
    <div className="container">
      {/* <div className="linies"></div> */}
      <div
        ref={scrollContentRef}
        className={`scroll-content ${props.isAutoScrolling ? "scrolling" : ""}`}
      >
        {newArray.map((item, index) => (
          <Item className="item" rarity={rarityConvector(item.rarity)}>
            <BlockDiv rarity={rarityConvector(item.rarity)}>&nbsp;</BlockDiv>
            <img src={item.image} alt="" width={"100px"} height="80px" />
            {/* <Image src={item.image} width="100px" height="100px" /> */}
            <NameSkin>
              <WeaponName>{item.weapon}</WeaponName>
              <SkinName rarity={rarityConvector(item.rarity)}>
                {item.skin}
              </SkinName>
            </NameSkin>
          </Item>
        ))}
      </div>
    </div>
  ) : windowInnerWidth > 800 && windowInnerWidth < 1650 ? (
    <div className="container2">
      {/* <div className="linies"></div> */}
      <div
        ref={scrollContentRef}
        className={`scroll-content ${props.isAutoScrolling ? "scrolling" : ""}`}
      >
        {newArray.map((item, index) => (
          <Item className="item" rarity={rarityConvector(item.rarity)}>
            <BlockDiv rarity={rarityConvector(item.rarity)}>&nbsp;</BlockDiv>
            <img src={item.image} alt="" width={"100px"} height="80px" />
            {/* <Image src={item.image} width="100px" height="100px" /> */}
            <NameSkin>
              <WeaponName>{item.weapon}</WeaponName>
              <SkinName rarity={rarityConvector(item.rarity)}>
                {item.skin}
              </SkinName>
            </NameSkin>
          </Item>
        ))}
      </div>
    </div>
  ) : (
    <div className="container1">
      {/* <div className="linies"></div> */}
      <div
        ref={scrollContentRef}
        className={`scroll-content ${props.isAutoScrolling ? "scrolling" : ""}`}
      >
        {newArray.map((item) => (
          <Item className="item" rarity={rarityConvector(item.rarity)}>
            <img src={item.image} alt="" width={"100px"} height="80px" />
            {/* <Image src={item.image} width="100px" height="100px" /> */}
            <NameSkin>
              <WeaponName>{item.weapon}&nbsp;|&nbsp;</WeaponName>
              <SkinName rarity={rarityConvector(item.rarity)}>
                {item.skin}
              </SkinName>
            </NameSkin>
          </Item>
        ))}
      </div>
    </div>
  );
};
