export default function Cross() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_2621_28343)">
        <path
          d="M1.875 7.5C1.875 8.23869 2.02049 8.97014 2.30318 9.65259C2.58586 10.3351 3.00019 10.9551 3.52252 11.4775C4.04485 11.9998 4.66495 12.4141 5.34741 12.6968C6.02986 12.9795 6.76131 13.125 7.5 13.125C8.23869 13.125 8.97014 12.9795 9.65259 12.6968C10.3351 12.4141 10.9551 11.9998 11.4775 11.4775C11.9998 10.9551 12.4141 10.3351 12.6968 9.65259C12.9795 8.97014 13.125 8.23869 13.125 7.5C13.125 6.76131 12.9795 6.02986 12.6968 5.34741C12.4141 4.66495 11.9998 4.04485 11.4775 3.52252C10.9551 3.00019 10.3351 2.58586 9.65259 2.30318C8.97014 2.02049 8.23869 1.875 7.5 1.875C6.76131 1.875 6.02986 2.02049 5.34741 2.30318C4.66495 2.58586 4.04485 3.00019 3.52252 3.52252C3.00019 4.04485 2.58586 4.66495 2.30318 5.34741C2.02049 6.02986 1.875 6.76131 1.875 7.5Z"
          stroke="#E41E1E"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M6.25 6.25L8.75 8.75M8.75 6.25L6.25 8.75"
          stroke="#E41E1E"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2621_28343">
          <rect width="15" height="15" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
