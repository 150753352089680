import styled, { css } from "styled-components";

export const Box = styled.div`
  display: flex;
  gap: 1rem;
`

export const Container = styled.div<{onClick: any}>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2.3rem;
  width: 7rem;
  height: 7rem;
  background: ${props => props.theme.colors.white.white10};
  border-radius: 1.5rem;
  cursor: pointer;
  &:hover{
    background: ${props => props.theme.colors.white.white20};
  }
  &.active {
    border: .2rem solid rgba(255, 255, 255, 0.30);
  }
`

export const Sticker = styled.img`
  width: 4.6rem;
`