import styled from "styled-components";

export const WrapperModal = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 2rem;
  gap: 2rem;
`;

export const WrapperModalPrices = styled.div`
  display: flex;
  /* flex-direction: column; */
  justify-content: center;
  align-items: center;
  padding: 1rem;
  gap: 0.5rem;
  background: ${(props) => props.theme.gradients.shadow};
  border-radius: 2rem;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const BlockTime = styled.div<{ active: Boolean }>`
  cursor: pointer;
  padding: 0.5rem 2rem;
  background: ${(props) =>
    props.active ? props.theme.colors.blue.accent : null};
  border-radius: 1rem;
  ${(props) => props.theme.fonts.GothamMedium16}
`;

export const NameVip = styled.div<{ Color: Boolean }>`
  display: flex;
  /* font-stretch: extra-expanded; */
  justify-content: center;
  width: 100%;
  ${(props) => props.theme.fonts.GothamBold44};
  border-radius: 2rem;
  background: ${(props) =>
    props.Color == true
      ? "rgba(255, 186, 53, 0.05);"
      : "rgba(154, 187, 251, 0.05);"};
  text-shadow: ${(props) =>
    props.Color == true ? "0px 0px 150px #ffba35;" : "0px 0px 150px #9ABBFB;"};
  padding: 2rem 4rem;
  color: ${(props) =>
    props.Color == true ? "#FFBA35" : props.theme.colors.blue.accent};
`;

export const SmallPriceVip = styled.span`
  ${(props) => props.theme.fonts.GothamMedium15};
  color: ${(props) => props.theme.colors.white.white70};
`;

export const BlockFlex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const PriceVip = styled.span`
  ${(props) => props.theme.fonts.GothamBold24}
  display: flex;
  align-items: center;
  gap: 1rem;
`;
