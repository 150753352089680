export interface NewBet {
  sum: number;
  startDate?: Date;
  endDate?: Date;
  chances: chances[];
}

export interface FullInfoRoulette {
  currDate: Date;
  nextDate?: Date;
  startDate?: Date;
  endDate?: Date;
  winner?: WinnerRoulette;
  sum: number;
  roulete?: chances[];
  chances: [
    {
      id: number;
      items: [
        {
          id: number;
          image: string;
          rarity: number;
          tickets: number;
        }
      ];
      user: {
        steamid64: string;
        name: string;
        avatar: string;
      };
      tickets: number;
      chance: string;
      range: {
        rangeStart: number;
        rangeEnd: number;
      };
    }
  ];
}

export interface chances {
  id: number;
  items: [
    {
      id: number;
      image: string;
      rarity: number;
      tickets: number;
    }
  ];
  user: {
    steamid64: string;
    name: string;
    avatar: string;
  };
  tickets: number;
  chance: string;
  range: {
    rangeStart: number;
    rangeEnd: number;
  };
}

export enum Exceptions {
  ACTIVE_TRADE = "ACTIVE_TRADE",
  ITEMS_ON_MARKET = "ITEMS_ON_MARKET",
  INVENTORY_MISS = "INVENTORY_MISS",
  BET_ON = "BET_ON",
  MAX_PLAYERS_LIMIT = "MAX_PLAYERS_LIMIT",
  NOT_ENOUGH_BALANCE = "NOT_ENOUGH_BALANCE",
  UNKNOWN_ERROR = "UNKNOWN",
  TIME_OUT = "TIME_OUT",
  UNATHORIZED = "UNATHORIZED",
  TradeEnd = "Trade ended",
  ItemLocked = "Item locked",
  UnknownError = "Error occured",
  ActiveTrade = "Trade active",
  ItemNotFound = "Item not found",
  Forbidden = "Access denied",
  TradeSend = "Trade has been sent",
  ItemLimit = "Too many items",
  ErrorOccured = "Error occured",
  ImATeapot = "ImATeapot",
  BanProfile = "Blocked",
}

export interface WinnerRoulette {
  nextDate: Date;
  endDate?: Date;
  currDate: Date;
  id: number;
  items: [
    {
      id: number;
      image: string;
      rarity: number;
      tickets: number;
    }
  ];
  user: {
    steamid64: string;
    name: string;
    avatar: string;
  };
  tickets: number;
  chance: string;
  range: {
    rangeStart: number;
    rangeEnd: number;
  };
  winerTicket: {
    hash: string;
    winTicket: number;
    sum: number;
  };
}
export interface Items {
  id: number;
  image: string;
  rarity: number;
  tickets: number;
}

export const ColorRulette = [
  "#fea47f",
  "#d6a2e8",
  "#706fd3",
  "#f97f51",
  "#b33771",
  "#58b19f",
  "#fd5b03",
  "#008891",
  "#92f22a",
  "#fd7272",
];
