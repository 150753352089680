import { DefaultTheme } from 'styled-components';
import { colors } from './colors';
import { stroke } from './stroke';
import { gradients } from './gradients';
import { fonts } from './fonts';
import { consts } from './consts';
import { mixins } from './mixins';
import { shadows } from './shadows';
import { motion } from './motion';
import { mediaQueries } from './adaptive';

export const Theme: DefaultTheme = {
  colors,
  stroke,
  gradients,
  fonts,
  consts,
  mixins,
  shadows,
  motion,
  mediaQueries
}