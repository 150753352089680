import { TinyIcons } from "assets/tiny-icons";
import ReplenishmentCard from "components/Cards/ReplenishmentCard";
import Layout from "components/Layout";
import SubHeader from "components/SubHeader";
import UIKIT from "components/UI";
import { IReplenishment } from "models/Replenishment";

import { endpoints, url } from "API";
import { ShopService } from "API/ShopService";
import { useAppSelector } from "hooks/useAppSelector";
import { useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  BlueText,
  Flex,
  Flex2,
  Offer,
  TextGreen,
  Textt,
  Textttt,
  TextWhj,
  Title,
  WrapBlock,
  WrapBlockRep,
  WrapBonuse,
  WrapBuy,
  WrapperBlockRep,
  WrapperReplenishment,
} from "./styled";

enum PaymentType {
  Card = "card",
  CardForeign = "cardForeign",
  USDT = "usdt",
}

export enum FreeKassaType {
  Default = "Default",
  FirstType = "First",
  Second = "Second",
}

export default function AccountReplenishment2() {
  const items: IReplenishment[] = [
    {
      text: "МИР/VISA/MASTERCARD",
      condition: "От 10",
      banner: "/static/images/payment.png", ///+
      id: 1, ///first
      percent: "0",
      wd: true,
    },
    {
      text: "СБП",
      condition: "От 10",
      banner: "/static/images/payment3.png", ///second +
      id: 2,
      percent: "0",
      wd: true,
    },
    {
      text: "VISA/MASTERCARD НЕ РФ",
      condition: "От 20",
      banner: "/static/images/payment2.png", //// unit
      id: 3,
      percent: "0",
      wd: false,
    },
    {
      text: "СКИНЫ",
      condition: "",
      banner: "/static/images/skinsBack.png", ////default +
      id: 6,
      percent: "0",
      wd: false,
    },
    {
      text: "USDT",
      condition: "От 100",
      banner: "/static/images/tether.png", ////default +
      id: 5,
      percent: "1",
      wd: false,
    },
    {
      text: "МЕГАФОН/TELE2/БИЛАЙН",
      condition: "От 100",
      banner: "/static/images/skinspay.png", ////default +
      id: 4,
      percent: "0",
      wd: true,
    },
  ];

  const navigate = useNavigate();
  const [useParams, SetUseParams] = useState<boolean>(false);
  const [valueMoney, setValueMoney] = useState<number>(0);
  const [mail, setMail] = useState<string>("");
  const [useCassa, setUseCassa] = useState<number>(1);
  const [fillMon, setFullMon] = useState<number | undefined>(0);
  const { isAuth, user } = useAppSelector((state) => state.user);

  useMemo(() => {
    // console.log("lox");
    SetUseParams(false);
  }, [useCassa]);

  function loginHandler() {
    window.location.href = url + endpoints.auth.login;
  }

  function CheckObj() {
    if (useCassa == 5) {
      if (
        valueMoney >= 100 &&
        valueMoney <= 8000 &&
        useParams &&
        mail !== "" &&
        mail.includes("@") &&
        mail.includes(".") &&
        !/[а-яА-Я]/.test(mail)
      ) {
        toast.success("Идет составление платежа, ожидайте");
        ShopService.BuyUnitpay(mail, valueMoney, PaymentType.USDT);
        return;
      }
      if (valueMoney < 100 || valueMoney > 8000) {
        toast.error("Минимально 100руб");
        return;
      }
      if (!useParams) {
        toast.error(
          "Для совершения оплаты, примите соглашение с правилами проекта"
        );
      }
      if (mail == "") {
        toast.error("Укажите почту");
      }
      if (
        !mail.includes("@") ||
        !mail.includes(".") ||
        !/[а-яА-Я]/.test(mail)
      ) {
        toast.error("Неверный формат почты");
      }
      if (!useParams) {
        toast.error(
          "Для совершения оплаты, примите соглашение с правилами проекта"
        );
        return;
      }
    } else if (useCassa == 6) {
      if (useParams) {
        toast.success("Идет составление платежа, ожидайте");
        ShopService.getSkins();
      }
      if (!useParams) {
        toast.error(
          "Для совершения оплаты, примите соглашение с правилами проекта"
        );
        return;
      }

      return;
    } else if (useCassa == 2) {
      if (
        valueMoney >= 10 &&
        valueMoney <= 8000 &&
        useParams &&
        mail !== "" &&
        mail.includes("@") &&
        mail.includes(".") &&
        !/[а-яА-Я]/.test(mail)
      ) {
        toast.success("Идет составление платежа, ожидайте");
        ShopService.BuyMoney(valueMoney, mail, FreeKassaType.Second);
        return;
      }
      if (valueMoney < 10 || valueMoney > 8000) {
        toast.error("Минимально 10руб");
        return;
      }
      if (!useParams) {
        toast.error(
          "Для совершения оплаты, примите соглашение с правилами проекта"
        );
      }
      if (mail == "") {
        toast.error("Укажите почту");
      }
      if (
        !mail.includes("@") ||
        !mail.includes(".") ||
        !/[а-яА-Я]/.test(mail)
      ) {
        toast.error("Неверный формат почты");
      }
      if (!useParams) {
        toast.error(
          "Для совершения оплаты, примите соглашение с правилами проекта"
        );
        return;
      }
    }
    if (useCassa == 1) {
      if (
        valueMoney >= 10 &&
        valueMoney <= 8000 &&
        useParams &&
        mail !== "" &&
        mail.includes("@") &&
        mail.includes(".") &&
        !/[а-яА-Я]/.test(mail)
      ) {
        ShopService.BuyMoney(valueMoney, mail, FreeKassaType.FirstType);
        toast.success("Идет составление платежа, ожидайте");
        return;
      }

      if (valueMoney < 10 || valueMoney > 8000) {
        toast.error(`Минимально 10 руб`);
        return;
      }
      if (mail == "") {
        toast.error("Укажите почту");
      }
      if (!mail.includes("@") || !mail.includes(".") || /[а-яА-Я]/.test(mail)) {
        toast.error("Неверный формат почты");
      }
      if (!useParams) {
        toast.error(
          "Для совершения оплаты, примите соглашение с правилами проекта"
        );
        return;
      }
    } else if (useCassa == 3) {
      if (
        valueMoney >= 20 &&
        valueMoney <= 8000 &&
        useParams &&
        mail !== "" &&
        mail.includes("@") &&
        mail.includes(".") &&
        !/[а-яА-Я]/.test(mail)
      ) {
        ShopService.BuyUnitpay(mail, valueMoney, PaymentType.CardForeign);
        toast.success("Идет составление платежа, ожидайте");
        return;
      }

      if (valueMoney < 20 || valueMoney > 8000) {
        toast.error(`Минимально 20 руб`);
        return;
      }
      if (mail == "") {
        toast.error("Укажите почту");
      }
      if (
        !mail.includes("@") ||
        !mail.includes(".") ||
        !/[а-яА-Я]/.test(mail)
      ) {
        toast.error("Неверный формат почты");
      }
      if (!useParams) {
        toast.error(
          "Для совершения оплаты, примите соглашение с правилами проекта"
        );
        return;
      }
    } else if (useCassa == 4) {
      if (
        valueMoney >= 100 &&
        valueMoney <= 8000 &&
        useParams &&
        mail !== "" &&
        mail.includes("@") &&
        mail.includes(".") &&
        !/[а-яА-Я]/.test(mail)
      ) {
        toast.success("Идет составление платежа, ожидайте");
        ShopService.BuyMoney(valueMoney, mail, FreeKassaType.Default);
        return;
      }
      if (valueMoney < 100 || valueMoney > 8000) {
        toast.error("Минимально 100руб");
        return;
      }
      if (!useParams) {
        toast.error(
          "Для совершения оплаты, примите соглашение с правилами проекта"
        );
      }
      if (mail == "") {
        toast.error("Укажите почту");
      }
      if (
        !mail.includes("@") ||
        !mail.includes(".") ||
        !/[а-яА-Я]/.test(mail)
      ) {
        toast.error("Неверный формат почты");
      }
      if (!useParams) {
        toast.error(
          "Для совершения оплаты, примите соглашение с правилами проекта"
        );
        return;
      }
    }
  }

  useEffect(() => {
    function functionValueItog(Money: number) {
      if (Money < 0) {
        return 0;
      }
      // if (valueMoney > 0 && valueMoney < 100) {
      //   toast("Мин 100");
      // }
      if (Money < 100) {
        return Money;
      }
      if (Money >= 100 && Money <= 999) {
        return Math.round(Money + (Money / 100) * 5);
      }
      if (Money >= 1000) {
        return Math.round(Money + (Money / 100) * 10);
      }
    }
    setFullMon(functionValueItog(valueMoney));
  }, [valueMoney]);

  return (
    <Layout
      leftElement={<div />}
      headerElement={<SubHeader>Пополнение баланса</SubHeader>}
    >
      <Helmet>
        <title>FREEZ-BLOOD - Пополнение баланса</title>
        <meta name="description" content={`FREEZ-BLOOD - Пополнение баланса`} />
        <meta name="robots" content="noindex, nofollow"></meta>
      </Helmet>
      <WrapperReplenishment>
        <WrapperBlockRep>
          <Title>Выберите способ оплаты</Title>
          <Flex>
            {items.map(
              ({ banner, text, condition, id, wd, percent }, index) => (
                // <div>
                <div onClick={() => setUseCassa(id)}>
                  <ReplenishmentCard
                    percent={percent}
                    key={index}
                    banner={banner}
                    text={text}
                    id={id}
                    condition={condition}
                    active={id == useCassa ? true : false}
                    wd={wd}
                  />
                </div>
              )
            )}
          </Flex>
        </WrapperBlockRep>
        <WrapBlockRep>
          <WrapBonuse>
            <Title>Бонус за пополнение</Title>
            <Flex2>
              <WrapBlock>
                <Textt>От 100-999руб</Textt>
                <TextGreen>+5%</TextGreen>
              </WrapBlock>
              <WrapBlock>
                <Textt>От 1000-8000руб</Textt>
                <TextGreen>+10%</TextGreen>
              </WrapBlock>
            </Flex2>
          </WrapBonuse>

          <WrapBuy>
            {/* <UIKIT.Input
              label="Введите промокод"
              placeholder="Введите промокод"
              type="text"
              params={{ width: "100%" }}
              // onChange={(e) => setSteamid(e.target.value)}
              // value={steamId}
            /> */}
            {useCassa !== 6 ? (
              <>
                <UIKIT.Input
                  label="Введите почту"
                  placeholder="name@name.com"
                  type="text"
                  params={{ width: "100%" }}
                  value={mail}
                  inputMode="email"
                  onChange={(e) => setMail(e.target.value)}
                  // onChange={(e) => setSteamid(e.target.value)}
                  // value={steamId}
                />
                <UIKIT.Input
                  label={`Сумма пополнения (от ${
                    useCassa == 1
                      ? 10
                      : useCassa == 2
                      ? 10
                      : useCassa == 3
                      ? 20
                      : useCassa == 4
                      ? 100
                      : useCassa == 5
                      ? 100
                      : 100
                  }руб)`}
                  placeholder="Введите сумму"
                  // type="text"
                  params={{ width: "100%" }}
                  type={"number"}
                  maxLength={5}
                  // placeholder="Введите сумму"
                  // params={{ width: "100%", height: "5rem" }}
                  value={valueMoney <= 0 && valueMoney >= 0 ? "" : valueMoney}
                  inputMode="numeric"
                  onChange={(e) =>
                    Number(e.target.value) <= 8000
                      ? setValueMoney(Number(e.target.value))
                      : (toast.error("Максимально 8000"), setValueMoney(0))
                  }
                />
                <TextWhj>Получите на баланс</TextWhj>
                <Flex act={true}>
                  <Title>{fillMon}</Title>
                  <TinyIcons.Rub />
                </Flex>
              </>
            ) : null}

            {isAuth && localStorage.token !== undefined ? (
              <UIKIT.Button
                variant="default"
                color="blue"
                height="5rem"
                // disabled={}
                onClick={() => CheckObj()}
              >
                Перейти к пополнению
              </UIKIT.Button>
            ) : (
              <UIKIT.Button
                height="5rem"
                variant="default"
                color="blue"
                onClick={() => {
                  loginHandler();
                }}
              >
                Авторизоваться
              </UIKIT.Button>
            )}
            <Offer onClick={() => SetUseParams(!useParams)}>
              <input
                type="checkbox"
                checked={useParams ? true : false}
                // height="0.5rem"
                // params={{ width: "1.5rem" }}
                style={{ maxWidth: "max-content" }}
              />
              <Textttt active={false}>
                Я прочитал и согласен с{" "}
                <BlueText
                  color="RoyalBlue"
                  onClick={() => navigate("/rules/offer")}
                >
                  офертой и правилами проекта
                </BlueText>
              </Textttt>
            </Offer>
          </WrapBuy>
        </WrapBlockRep>
      </WrapperReplenishment>
    </Layout>
  );
}
