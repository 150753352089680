import styled from "styled-components";

export const Card = styled.div`
  width: 100%;
  height: fit-content;
  cursor: pointer;
  padding: 1rem 3rem;
  border: 0.3rem solid ${(props) => props.theme.stroke.stroke5};
  border-radius: 1.5rem;
  display: grid;
  grid-template-columns: 0.8fr 0.3fr 0.3fr 0.4fr 0.1fr;
  grid-template-rows: minmax(5rem, 1fr);
  align-items: center;
`;

export const Name = styled.span`
  transition: 0.5s all;
  ${(props) => props.theme.fonts.GothamRegular20};
  color: ${(props) => props.theme.colors.white.white100};
  position: relative;
  z-index: 20;
  cursor: default;
`;

export const Map = styled.span`
  ${(props) => props.theme.fonts.GothamRegular20};
  color: ${(props) => props.theme.colors.white.white100};
  position: relative;
  z-index: 20;
  cursor: default;
`;

export const Ip = styled.span`
  ${(props) => props.theme.fonts.GothamRegular20};
  color: ${(props) => props.theme.colors.white.white100};
  position: relative;
  z-index: 20;
  cursor: default;
`;
