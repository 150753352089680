import React from "react";
import { Container } from "./Styles";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  children: any;
  justifyContent?: string;
  alignItems?: string;
  gap?: string;
  fullWidth?: boolean;
  margin?: string;
}

export default function Row({
  children,
  gap,
  justifyContent,
  alignItems,
  margin,
  ...props
}: Props) {
  return (
    <Container
      gap={gap}
      justifyContent={justifyContent}
      alignItems={alignItems}
      margin={margin}
      {...props}
    >
      {children}
    </Container>
  );
}
