import styled from "styled-components";

export const BigText = styled.h2`
  ${(props) => props.theme.fonts.GothamBold24}
`;
export const MediumText = styled.h3<{ al?: boolean }>`
  ${(props) => props.theme.fonts.GothamBold16}
  overflow-wrap: break-word;
  align-items: center;
  text-align: ${(props) => (props.al ? "left" : "center")};
`;

export const ArrowStyle = styled.div<{ right: boolean }>`
  width: 10px;
  height: 10px;
  border-top: 2px solid white !important;
  border-right: 2px solid white !important;
  transform: rotate(${(props) => (props.right ? "45deg" : "-135deg")});
  opacity: 60%;
`;

export const BlockClan = styled.div`
  background: ${(props) => props.theme.gradients.shadow};
  border-radius: 1.5rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 1.5rem 2rem;
  align-items: center;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;
  }
`;

export const StandartFlexG1 = styled.div<{
  aling?: boolean;
  flexDir?: boolean;
  Member?: boolean;
  Task?: boolean;
}>`
  display: flex;
  gap: 1rem;
  align-items: ${(props) => (props.aling ? "start" : "center")};
  @media (max-width: 1250px) {
    flex-direction: ${(props) => (props.Member ? "column" : "")};
    align-items: ${(props) => (props.aling ? "start" : "center")};
    gap: ${(props) => (props.Task ? "0.5rem" : "2rem")};
  }

  @media (max-width: 768px) {
    flex-direction: ${(props) => (props.flexDir ? "" : "column")};
    align-items: center;
    gap: ${(props) => (props.Task ? "0.5rem" : "2rem")};
  }
`;

export const BlockExpAdd = styled.div`
  display: flex;
  gap: 1rem;
  min-width: 10rem;
  align-items: center;
  justify-content: center;
  background: ${(props) => props.theme.gradients.shadow};
  border-radius: 0 1.5rem 1.5rem 0;
  height: 6rem;
  padding: 0 1rem;
  @media (max-width: 768px) {
    align-items: center;
    gap: 2rem;
    border-radius: 1.5rem;
  }
`;

export const BlockExpGife = styled.div`
  display: flex;
  gap: 2rem;
  align-items: center;
  background: ${(props) => props.theme.gradients.shadow};
  border-radius: 1.5rem;
  height: 5rem;
  padding: 1.5rem 0.5rem 1.5rem 1.5rem;
`;

export const BlockAddBut = styled.div`
  display: flex;
  justify-content: center;
  /* gap: 1rem; */
  align-items: center;
  align-content: center;
  background: ${(props) => props.theme.gradients.shadow};
  border-radius: 1.5rem;
  height: 4rem;
  width: 4rem;
  cursor: pointer;
  /* padding: 0 1rem; */
`;

export const FullBlock = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 1rem;
  margin-left: 0.1rem;
`;

export const BlockMembers = styled.div`
  display: flex;
  width: max-content;
  gap: 1rem;
  background: ${(props) => props.theme.gradients.shadow};
  border-radius: 1.5rem;
  align-items: center;
  padding: 1rem;
`;
export const BlockFullMembers = styled.div`
  display: flex;
  flex-direction: column;
  background: ${(props) => props.theme.gradients.shadow};
  border-radius: 1.5rem;
  gap: 1rem;
  /* align-items: center; */
  padding: 2rem;
  width: 100%;
  @media (max-width: 950px) {
    flex-direction: column;
    align-items: center;
    gap: 2rem;
  }
`;

export const BlockArrUser = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
`;
export const OneMember = styled.div<{ isYou: boolean }>`
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-radius: 1.5rem;
  background: ${(props) =>
    props.isYou
      ? props.theme.colors.white.white20
      : (props) => props.theme.gradients.shadow};
  padding: 1rem 2rem;
  @media (max-width: 1250px) {
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    padding: 1rem;
  }
`;

export const DivBg = styled.div`
  background-image: url("/static/images/bgId.png");
  background-repeat: no-repeat;
  width: 3.6rem;
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const WrapBlockTaskMember = styled.div`
  display: flex;
  gap: 2rem;
  @media (max-width: 1250px) {
    flex-direction: column;
  }
`;

export const BlockTask = styled.div`
  background: ${(props) => props.theme.gradients.shadow};
  border-radius: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 2rem;
  min-width: 50rem;
  @media (max-width: 768px) {
    align-items: center;
    width: 100%;
    min-width: 30rem;
  }
`;

export const BlockTime = styled.span`
  ${(props) => props.theme.fonts.GothamBold16};
  color: ${(props) => props.theme.colors.white.white100};
  padding: 1rem;
  background: ${(props) => props.theme.gradients.shadow};
  border-radius: 1rem;
  max-width: 5rem;
  min-width: 5rem;
  text-align: center;
`;

export const WrapOneTask = styled.div`
  width: 100%;
  height: 6rem;
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 0 0 0 1.5rem;
  background: ${(props) => props.theme.gradients.shadow};
  border-radius: 1.5rem;
  @media (max-width: 768px) {
    flex-direction: column;
    height: max-content;
    width: 100%;
    gap: 2rem;
    padding: 2rem 0;
  }
`;

export const WrapOneMarket = styled.div`
  width: 100%;
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 1.5rem;
  background: ${(props) => props.theme.gradients.shadow};
  border-radius: 1.5rem;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;
export const BlockModes = styled.div`
  width: 100%;
  background: ${(props) => props.theme.gradients.shadow};
  border-radius: 1rem;
  display: flex;
  gap: 1rem;
  height: 4.5rem;
  @media (max-width: 768px) {
    flex-direction: column;
    height: max-content;
    align-items: center;
  }
`;

export const OneMode = styled.span<{ Active: boolean }>`
  ${(props) => props.theme.fonts.GothamBold16}
  /* padding: 2rem; */
  border-radius: 1rem;
  height: 100%;
  background-color: ${(props) =>
    props.Active ? (props) => props.theme.colors.blue.accent : null};
  align-content: center;
  padding: 0 1.5rem;
  cursor: pointer;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  @media (max-width: 768px) {
    height: 4rem;
    width: 100%;
    align-items: center;
    text-align: center;
  }
`;
