import styled from "styled-components";

export const Header = styled.div`
	${props => props.theme.fonts.GothamMedium32};
	color: ${props => props.theme.colors.white.white100};
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	padding-left: 20rem;
	transition: .3s all;
	position: relative;
	svg.back {
		cursor: pointer;
		position: absolute;
		left: 0;
	}
`