export interface IFriend {
	id: number;
	name: string;
	steamid64: string;
	renderMode?: FriendRenderMode;
	avatar: string;
	last_online: Date
}

export enum FriendRenderMode {
	default = 0,
	request = 1,
	decline = 2,
}