import styled from "styled-components";

interface Props {
  alignItems?: string;
  justifyContent?: string;
  gap?: string;
  direction?: string;
  width?: string;
  a?: boolean;
}

export const Container = styled.div<Props>`
  display: flex;
  flex-direction: ${(props) => (props.direction ? props.direction : "column")};
  align-items: ${(props) => (props.alignItems ? props.alignItems : "center")};
  justify-content: ${(props) =>
    props.justifyContent ? props.justifyContent : "center"};
  gap: ${(props) => (props.gap ? props.gap : "1rem")};
  width: ${({ width }) => width && "100%"};
  max-width: ${({ width }) => width && width};
`;
