export enum TicketTypes {
  BLOCK = "BLOCKS",
  HELP = "HELP",
}

export interface MyBans {
  id: number;
  reason: string;
  expiration: string;
  date: string;
  server: {
    id: number;
    name: string;
    ip: string;
  };
  admin: {
    name: string;
    avatar: string;
    steamid64: string;
  };
  banned: {
    name: string;
    avatar: string;
    steamid64: string;
    ip: string;
    type: string;
    state: string;
  };
}
