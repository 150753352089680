import { AdminServise } from "API/AdminServise";
import ModalWrapper from "components/Templates/ModalWrapper";
import UIKIT from "components/UI";
import Button from "components/UI/Button";
import { AdminList } from "interfaces/AdminPanel";
import { useEffect, useMemo, useState } from "react";
import {
  SpanDefal,
  SpanDefault,
  WrapAdminChage,
  WrapButtosn,
  WrapInformationOfAdmin,
  WrapInfoW,
} from "./styled";

interface Props {
  isActive: boolean;
  admin?: AdminList;
  setActive: React.SetStateAction<any>;
  setAdmin: React.SetStateAction<any>;
  fullAdmins?: AdminList[];
}

export default function AdminUserChange({
  isActive,
  admin,
  setActive,
  setAdmin,
  fullAdmins,
}: Props) {
  const [steamId, setSteamid] = useState<string>();
  const [paused, setPaused] = useState<boolean>(admin?.paused || false);

  useMemo(() => {
    setSteamid(admin?.user.steamid64);
    setPaused(admin?.paused || false);
  }, [admin?.user.steamid64, 1]);

  return (
    <ModalWrapper
      setActive={setActive}
      isActive={isActive}
      headContent="Подробная информация"
      params={{ width: "160rem", height: "fit-content" }}
      display="flex"
    >
      <WrapAdminChage>
        <UIKIT.Input
          label="Идентификатор Steam"
          placeholder="Идентификатор Steam"
          type="search"
          params={{ width: "100%" }}
          onChange={(e) => setSteamid(e.target.value)}
          value={steamId}
        />
        <WrapButtosn>
          <Button
            variant="default"
            color="green"
            height="5rem"
            params={{ width: "100%" }}
            onClick={() => {
              AdminServise.ChangeParamsAdmin(
                admin?.user.steamid64 || "",
                steamId || "",
                admin?.server.server_id || 0
              );
              setActive(!isActive);
            }}
          >
            Изменить
          </Button>
          <Button
            variant="default"
            color={paused ? "green" : "red"}
            height="5rem"
            params={{ width: "100%" }}
            onClick={() => {
              setPaused(!paused);
              setAdmin(
                fullAdmins?.map((item) => {
                  if (item !== admin) {
                    return item;
                  } else {
                    let newAdmin = admin;
                    newAdmin.paused = !item.paused;
                    return newAdmin;
                  }
                })
              );
              AdminServise.PauseAdmin(
                admin?.user.steamid64 || "",
                admin?.server.server_id || 0,
                !paused
              );
            }}
          >
            {paused ? "Возобновить" : "Приостановить"}
          </Button>
          <Button
            variant="default"
            color="red"
            height="5rem"
            params={{ width: "100%" }}
            onClick={() => {
              setActive(!isActive);
              setAdmin(
                fullAdmins?.filter((item) => {
                  return item !== admin;
                })
              );
              AdminServise.DeleteAdmin(
                admin?.user.steamid64 || "",
                admin?.server.server_id || 0
              );
            }}
          >
            Удалить права
          </Button>
        </WrapButtosn>
        <WrapInformationOfAdmin>
          <WrapInfoW>
            <SpanDefal>№ </SpanDefal>
            <SpanDefal>Услуга</SpanDefal>
            <SpanDefal>Флаги/Группа</SpanDefal>
            <SpanDefal>Иммунитет</SpanDefal>
            <SpanDefal>Дата получения</SpanDefal>
            <SpanDefal>Дата окончания</SpanDefal>
          </WrapInfoW>
          <WrapInfoW top={true}>
            <SpanDefault>1 </SpanDefault>
            <SpanDefault>{admin?.user.group.name}</SpanDefault>
            <SpanDefault>{admin?.user.group.flags}</SpanDefault>
            <SpanDefault>{admin?.user.group.immunity}</SpanDefault>
            <SpanDefault>{admin?.created_at?.slice(0, 10)}</SpanDefault>
            <SpanDefault>
              {admin?.user.group.expiration.slice(0, 10)}
            </SpanDefault>
          </WrapInfoW>
        </WrapInformationOfAdmin>
      </WrapAdminChage>
    </ModalWrapper>
  );
}
