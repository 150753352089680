import styled from "styled-components";

export const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 1.5rem;
`;

export const Active = styled.div`
  border-radius: 1.5rem;
  outline: 0.2rem solid ${(props) => props.theme.colors.white.white10};
  height: 100%;
`;

export const InActiveGradient = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(1rem);
  height: 100%;
  border-radius: 1.5rem;
  transition: 0.3s;
`;

export const InActiveGradientSkin = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(106, 0, 0, 0.2);
  backdrop-filter: blur(0.1rem);
  height: 100%;
  border-radius: 1.5rem;
  transition: 0.3s;
`;

export const ActiveGradient = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: ${(props) => props.theme.colors.gradient.activeSC};
  height: 100%;
  border-radius: 1.5rem;
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 0.6rem;
`;

export const Text = styled.span`
  text-align: center;
  ${(props) => props.theme.fonts.GothamRegular16};
  color: ${(props) => props.theme.colors.white.white100};
`;

export const Team = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.4rem;
  margin-top: 0.8rem;
`;

export const CT = styled.div`
  width: 2.4rem;
  height: 0.4rem;
  background: ${(props) => props.theme.colors.ct};
`;

export const T = styled.div`
  width: 2.4rem;
  height: 0.4rem;
  background: ${(props) => props.theme.colors.t};
`;
