import { GradientKeys, WeaponKeys } from "styled-components";

function rarityConvector(rarity: number): WeaponKeys {
  // console.log(rarity);
  switch (rarity) {
    case 1:
      return "common_grade";
    case 2:
      return "industrial_grade";
    case 3:
      return "mil_spec";
    case 4:
      return "restricted";
    case 5:
      return "classified";
    case 6:
      return "covert";
    case 7:
      return "covert";
    case 8:
      return "immortal";
    case 99:
      return "exceedingly_rare";

    case 999:
      return "shadow";
    default:
      return "common_grade";
  }
}

function priceRarityConvector(rarity: number): number {
  // console.log(rarity);
  switch (rarity) {
    case 1:
      return 100;
    case 2:
      return 100;
    case 3:
      return 200;
    case 4:
      return 400;
    case 5:
      return 500;
    case 6:
      return 2000;
    case 7:
      return 2000;
    case 8:
      return 3500;
    case 99:
      return 3500;

    case 999:
      return 100;
    default:
      return 100;
  }
}

function rarityConvectorToInt(rarity: string): number {
  // console.log(r)
  // console.log(rarity);
  switch (rarity) {
    case "default":
      return 2;
    case "common":
      return 2;
    case "uncommon":
      return 3;
    case "rare":
      return 4;
    case "mythical":
      return 5;
    case "legendary":
      return 6;
    case "ancient":
      return 7;
    case "immortal":
      return 8;
    case "unusual":
      return 99;

    case "shadow":
      return 999;
    default:
      return 1;
  }
}

function rarityConvectorString(rarity: number) {
  switch (rarity) {
    case 2:
      return "Ширпотреб";
    case 3:
      return "Промышленное качество";
    case 4:
      return "Армейское качество";
    case 5:
      return "Запрещенное оружие";
    case 6:
      return "Засекреченное";
    case 7:
      return "Тайное";
    case 8:
      return "★";
    case 99:
      return "★";
    default:
      return "Ширпотреб";
  }
}

export default rarityConvector;
export { rarityConvectorString, rarityConvectorToInt, priceRarityConvector };
