import { Icons } from "assets/icons";
import { NanoIcons } from "assets/nano-icons";
import Image from "components/Image";
import Input from "components/UI/Input";
import { useAnimation } from "framer-motion";
import { useActions } from "hooks/useActions";
import { useAppSelector } from "hooks/useAppSelector";
import { IChat } from "models/Chat";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useTheme } from "styled-components";
import ChatItem from "./ChatItem";
import ChatState from "./ChatState";
import {
  AttachedFiles,
  AttachedFilesWrapper,
  Buttons,
  ChatButton,
  Container,
  DefSpan,
  EmptyMessages,
  ImageWrapper,
  InputBlock,
  Messages,
  OnlineDiv,
  Title,
} from "./Styles";

export default function Chat({
  isActive,
  setActive,
  items,
  isPrivate,
  params,
  source,

  isFileInput = false,
}: IChat) {
  const Theme = useTheme();
  const control = useAnimation();
  const [message, setMessage] = useState("");
  const { user } = useAppSelector((state) => state.user?.user);
  const chatRef = useRef<HTMLDivElement>(null);
  const { fetchPrivateUser } = useActions();
  const { user: privateUser } = useAppSelector((state) => state.privateChat);
  const { steamid64 } = useParams();
  const [files, setFiles] = useState<File[]>();
  const [previews, setPreviews] = useState<string[]>();
  const [idPar, setIdPar] = useState<number>();
  // const [on, setOn] = useState<number>(ChatState.onlineChat);

  useEffect(() => {
    isPrivate && fetchPrivateUser(steamid64 || "");
  }, []);

  // useEffect(() => {
  //   setOn(ChatState.onlineChat);
  // }, [ChatState.onlineChat]);

  // useEffect(() => {
  //   console.log(onlineChat);
  // }, [onlineChat]);

  useEffect(() => {
    control.start(isActive ? "show" : "hide");
  }, [isActive]);

  useEffect(() => {
    if (chatRef.current) {
      chatRef.current.scrollTo(0, 999999);
    }
  }, [items, isActive]);

  useEffect(() => {
    if (!files?.length) {
      setPreviews([]);
      return;
    }

    try {
      setPreviews(files.map((file) => URL.createObjectURL(file)));
    } catch (error) {
      //console.log(error);
    }
  }, [files]);

  function sendMessage(message: {
    nickname: string;
    text: string;
    steamid64?: string;
  }) {
    if (isPrivate) {
      source.emit("private message", {
        content: message.text,
        from: user?.steamid64,
        to: steamid64,
      });
    } else {
      source.emit("sendMessage", {
        nickname: message.nickname,
        text: message.text,
      });
      return;
    }
  }

  function submitHandler() {
    // event.preventDefault();
    if (message) {
      sendMessage({
        nickname: user.name,
        text: message,
        steamid64: steamid64 || "",
      });

      setMessage("");
    }
    return;
  }

  function onMessageChange(event: React.ChangeEvent<HTMLInputElement>) {
    setMessage(event.target.value);
  }

  const itemsMemo = useMemo(() => {
    return (
      items?.length &&
      items.map(
        (
          { nickname, id, text, imagePath, createdAt, steamid64, avatar },
          index
        ) => {
          return (
            <ChatItem
              isLast={index + 1 === items.length}
              steamid64={steamid64}
              avatar={avatar}
              key={id}
              nickname={nickname}
              text={text}
              imagePath={imagePath}
              createdAt={createdAt}
              isPrivate={isPrivate}
              id={id}
            />
          );
        }
      )
    );
  }, [items]);

  return (
    <Container
      variants={Theme.motion.openChat}
      initial="start"
      animate={control}
      transition={Theme.motion.chat}
      className={isActive ? "active" : "none"}
      params={params}
      activ={isActive}
    >
      {isPrivate && !items?.length && (
        <EmptyMessages>
          Это начало вашего общения с <span>{privateUser?.user?.name}</span>
        </EmptyMessages>
      )}
      <DefSpan>
        <OnlineDiv>
          <Icons.Ellipse />
          {ChatState.onlineChat} онлайн
        </OnlineDiv>
        {setActive ? <Icons.Close onClick={() => setActive(false)} /> : null}
      </DefSpan>
      <Messages className="customScroll" ref={chatRef}>
        {itemsMemo}
      </Messages>
      <InputBlock isFileInput={isFileInput}>
        <Input
          chat={true}
          onChange={onMessageChange}
          value={message}
          placeholder="Сообщение..."
          params={{
            // width: "38.5rem",
            font: Theme.fonts.GothamRegular16,
            height: "8rem",
          }}
        />

        <Buttons>
          <ChatButton
            variant={"blue"}
            disabled={!message}
            onClick={() => submitHandler()}
          >
            <NanoIcons.ArrowLeft />
          </ChatButton>
        </Buttons>
        {files?.length && (
          <AttachedFilesWrapper>
            <Title>Вложенные файлы:</Title>
            <AttachedFiles>
              {previews?.map((src, index) => (
                <ImageWrapper key={src}>
                  <Icons.RemoveFile />
                  <Image src={src} alt="attached-file" />
                </ImageWrapper>
              ))}
            </AttachedFiles>
          </AttachedFilesWrapper>
        )}
      </InputBlock>
    </Container>
  );
}
