export default function Lock() {
  return (
    <svg width="3.3rem" height="3.2rem" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_207_25561)">
        <path d="M7.16406 17.3332C7.16406 16.6259 7.44501 15.9476 7.94511 15.4476C8.44521 14.9475 9.12349 14.6665 9.83073 14.6665H23.1641C23.8713 14.6665 24.5496 14.9475 25.0497 15.4476C25.5498 15.9476 25.8307 16.6259 25.8307 17.3332V25.3332C25.8307 26.0404 25.5498 26.7187 25.0497 27.2188C24.5496 27.7189 23.8713 27.9998 23.1641 27.9998H9.83073C9.12349 27.9998 8.44521 27.7189 7.94511 27.2188C7.44501 26.7187 7.16406 26.0404 7.16406 25.3332V17.3332Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M15.1641 21.3333C15.1641 21.687 15.3045 22.0261 15.5546 22.2761C15.8046 22.5262 16.1438 22.6667 16.4974 22.6667C16.851 22.6667 17.1902 22.5262 17.4402 22.2761C17.6903 22.0261 17.8307 21.687 17.8307 21.3333C17.8307 20.9797 17.6903 20.6406 17.4402 20.3905C17.1902 20.1405 16.851 20 16.4974 20C16.1438 20 15.8046 20.1405 15.5546 20.3905C15.3045 20.6406 15.1641 20.9797 15.1641 21.3333Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M11.1641 14.6667V9.33333C11.1641 7.91885 11.726 6.56229 12.7262 5.5621C13.7264 4.5619 15.0829 4 16.4974 4C17.9119 4 19.2684 4.5619 20.2686 5.5621C21.2688 6.56229 21.8307 7.91885 21.8307 9.33333V14.6667" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_207_25561">
          <rect width="3.2rem" height="3.2rem" fill="white" transform="translate(0.5)" />
        </clipPath>
      </defs>
    </svg>
  )
}

