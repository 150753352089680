import { NanoIcons } from "assets/nano-icons";
import Input from "components/UI/Input";
import { useAnimation } from "framer-motion";
import { useActions } from "hooks/useActions";
import { useAppSelector } from "hooks/useAppSelector";
import { IChat } from "models/Chat";
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useTheme } from "styled-components";
import ChatItem from "./ChatItem";
import {
  Buttons,
  ChatButton,
  Container,
  InputBlock,
  Messages,
  NonMessage,
  Title,
} from "./Styles";

export default function ChatUser({
  isActive,
  isPrivate,
  params,
  source,
  id,
  nonChat,
  isFileInput = false,
  itemMessage,
}: IChat) {
  const Theme = useTheme();
  const control = useAnimation();
  const [message, setMessage] = useState("");
  // const { user } = useAppSelector((state) => state.user);
  const [showEmoji, setShowEmoji] = useState<boolean>(false);
  const chatRef = useRef<HTMLDivElement>(null);
  const { fetchPrivateUser } = useActions();
  const { user: privateUser } = useAppSelector((state) => state.privateChat);
  const { steamid64 } = useParams();
  const [files, setFiles] = useState<File[]>();
  const [previews, setPreviews] = useState<string[]>();
  const [idPar, setIdPar] = useState<number>();
  const { isAuth, user } = useAppSelector((state) => state.user);

  // useEffect(() => {
  //   isPrivate && fetchPrivateUser(steamid64 || "");
  // }, []);

  useEffect(() => {
    control.start(isActive ? "show" : "hide");
  }, [isActive]);

  useEffect(() => {
    if (chatRef.current) {
      chatRef.current.scrollTo(0, 999999);
    }
  }, [itemMessage]);

  function sendMessage(message: { id: number; text: string }) {
    source.emit("ticketChat", {
      id: id,
      text: message.text,
    });
  }
  useEffect(() => {
    if (message.length == 500) {
      toast("Максмальное кол-во символов 500");
    }
  }, [message]);

  function submitHandler() {
    // event.preventDefault();

    if (message) {
      sendMessage({
        id: id || -1,
        text: message,
      });

      setMessage("");
    }
    return;
  }

  function onMessageChange(event: React.ChangeEvent<HTMLInputElement>) {
    setMessage(event.target.value);
  }

  return (
    <Container
      variants={Theme.motion.openChat}
      nonChat={nonChat}
      initial="start"
      animate={control}
      transition={Theme.motion.chat}
      className={isActive ? "active" : "none"}
      params={params}
      activ={isActive}
    >
      <Messages className="customScroll" ref={chatRef}>
        {itemMessage?.length !== undefined ? (
          itemMessage.map((item, index) => {
            return (
              <ChatItem
                isLast={index + 1 === itemMessage.length}
                steamid64={item.sender?.steamid64}
                avatar={item.sender?.avatar}
                // key={id}
                nickname={item.sender?.name || ""}
                text={item.text || ""}
                // imagePath={imagePath}
                createdAt={item.created_at}
                isPrivate={isPrivate}
                // id={id}
              />
            );
          })
        ) : (
          <Title>-</Title>
        )}
      </Messages>

      {!itemMessage?.length && !nonChat && (
        <NonMessage>Начните общение </NonMessage>
      )}
      {nonChat && !itemMessage?.length && (
        <NonMessage>Нет сообщений</NonMessage>
      )}

      {!nonChat ? (
        <InputBlock isFileInput={isFileInput}>
          <Input
            maxLength={500}
            onChange={onMessageChange}
            value={message}
            placeholder="Сообщение..."
            params={{
              width: "100%",
              font: Theme.fonts.GothamRegular16,
              height: "8rem",
            }}
          />

          <Buttons>
            <ChatButton
              variant={"blue"}
              disabled={!message}
              onClick={() => submitHandler()}
            >
              <NanoIcons.ArrowLeft />
            </ChatButton>
          </Buttons>
        </InputBlock>
      ) : null}
    </Container>
  );
}
