import { Button } from "components/UI/Button/Styles";
import { Label } from "components/UI/Input/Styles";
import styled, { WeaponKeys } from "styled-components";

interface Props {
  rarity: WeaponKeys;
}

export const Container = styled.div`
  width: 43.4rem;
  height: fit-content;
  padding: 1.5rem 3rem 3rem;
  border-radius: 2rem;
  ${(props) => props.theme.shadows.defaultShadow};
  border: 0.3rem solid ${(props) => props.theme.stroke.stroke5};
  background: ${(props) => props.theme.gradients.bg};
  padding: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  @media (max-width: 1250px) {
    width: auto;
    padding: 1rem;
  }
`;

export const Bottom = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 2rem;
  ${Button}, ${Label} {
    width: 100%;
    max-width: 100%;
  }
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  align-items: center;
  justify-content: center;
`;

export const Name = styled.span`
  ${(props) => props.theme.fonts.GothamMedium32};
  color: white;
  font-weight: 400;
  text-wrap: wrap;
`;

export const SubName = styled.div<Props>`
  display: inline;
  font-weight: bold;
  ${(props) => props.theme.fonts.GothamBold32};
  color: ${(props) => props.theme.colors.weapon[props.rarity]};
`;

export const Price = styled.div`
  ${(props) => props.theme.fonts.GothamBold24};
  color: white;
`;

export const Text = styled.span`
  ${(props) => props.theme.fonts.GothamBold20};
  color: ${(props) => props.theme.colors.white.white100};
`;
export const Fsdf = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
`;

export const BuyMode = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

export const SellMode = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

export const OnSellMode = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;
