import Avatar from "components/Avatar";
import {
  ActvBan,
  BansState,
  BlockItemIcon,
  Container,
  GrounPlayer,
  MainFlex,
  Nickname,
  OnlineStatus,
  ProfileData,
  SiteOnline,
  VerticalLineE,
  Wrapper,
} from "./Styles";
import { memo, useRef, useState } from "react";
import { useAppSelector } from "hooks/useAppSelector";
import { Banss, Block } from "models/User";
import { Icons } from "assets/icons";
import { toast } from "react-toastify";

function UProfileCard() {
  const { userData: user } = useAppSelector((state) => state.user);
  // console.log(user);
  const [activeBan, setActiveBan] = useState<boolean>(false);
  const [ban, setBan] = useState<Block>();

  function formatBlockMessage(dateString: string): string {
    // Преобразуем строку в объект Date
    let date = new Date(dateString);
    // date.setHours(date.getHours());
    const offsetMinutes = new Date().getTimezoneOffset();
    date.setMinutes(date.getMinutes() + offsetMinutes);

    // Проверка на валидность даты
    if (isNaN(date.getTime())) {
      throw new Error("Invalid date string");
    }

    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Месяцы начинаются с 0
    const year = date.getFullYear();

    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");

    return `Блокировка до ${day}.${month}.${year} ${hours}:${minutes}`;
  }
  const [copied, setCopied] = useState(false);
  const timeoutRef = useRef<number | null>(null);
  const handleCopy = (text: string) => {
    navigator.clipboard.writeText(text);
    setCopied(true);
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = window.setTimeout(() => {
      setCopied(false);
    }, 1000);
  };

  return (
    <Container>
      <Avatar
        size="ava2"
        src={
          user?.user?.avatar !== null || user?.user?.avatar !== ""
            ? user?.user?.avatar
            : "/static/images/avatar.png"
        }
      />

      <Wrapper>
        <ProfileData>
          <MainFlex>
            <GrounPlayer>
              {user?.group?.name}
              {user?.user?.steamid64 == "76561198799170541" ||
              user?.user?.steamid64 == "76561198842913345"
                ? ` (     Разработчик         -_-        )`
                : null}
            </GrounPlayer>
            {ban && (
              <div>
                <ActvBan active={activeBan}>
                  {formatBlockMessage(ban.expiration)}
                </ActvBan>
                <VerticalLineE active={activeBan} bot={true}>
                  &nbsp;
                </VerticalLineE>
              </div>
            )}

            {user?.blocks &&
              user?.blocks.map((item) => {
                if (item.banned.type == Banss.ban) {
                  if (ban == undefined) {
                    setBan(item);
                  }
                  return (
                    <BansState
                      onMouseOver={() => setActiveBan(true)}
                      onMouseOut={() => {
                        setActiveBan(false);
                      }}
                    >
                      {/* <Icons.Cross /> */}
                      {item.reason}
                    </BansState>
                  );
                }
              })}
          </MainFlex>
          <MainFlex>
            <Nickname>{user?.user?.name}</Nickname>
            <BlockItemIcon
              onClick={() =>
                window.open(
                  `https://steamcommunity.com/profiles/${user?.user?.steamid64}`
                )
              }
            >
              <Icons.Steam style={{ width: "24px" }} />
            </BlockItemIcon>
            {user?.user?.discord !== null ? (
              <BlockItemIcon
                onClick={() => {
                  if (user?.user?.discord) {
                    handleCopy(user?.user?.discord);
                    toast.success("Успешно скопированно");
                  }
                }}
              >
                <Icons.Discord style={{ width: "24px" }} />
              </BlockItemIcon>
            ) : null}
            {/* https://steamcommunity.com/id/76561198799170541/tradeoffers/privacy */}
            {user?.user?.vk ? (
              <BlockItemIcon onClick={() => window.open(user?.user?.vk || "")}>
                <Icons.VK style={{ width: "24px" }} />
              </BlockItemIcon>
            ) : null}
          </MainFlex>
          <OnlineStatus>
            <SiteOnline online={user?.online}>
              {user?.online ? "Онлайн" : "Не в сети"}
            </SiteOnline>
          </OnlineStatus>
        </ProfileData>
        {/* <UIKIT.Progress
          exp={user?.level?.currentXP}
          nextExp={1500}
          level={user?.level?.currentLevel}
          nextLevel={user?.level?.currentLevel + 1 || 0}
        /> */}
      </Wrapper>
    </Container>
  );
}

export const ProfileCard = memo(UProfileCard);
