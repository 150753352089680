import { Suspense } from "react"
import { Provider } from "react-redux"
import { BrowserRouter as Router } from "react-router-dom"
import { store } from "redux/store"
import { ThemeProvider } from "styled-components"
import Theme from "../styles/theme"
import Fallback from "./Fallback"

export default function MainProvider({ children }: any) {
  return (
    <Suspense fallback={<Fallback />}>
      <Router>
        <ThemeProvider theme={Theme}>
          <Provider store={store}>
            {children}
          </Provider>
        </ThemeProvider>
      </Router>
    </Suspense>
  )
}