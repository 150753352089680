export default function Time() {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_2557_58307)">
        <path
          d="M2.70313 10C2.70312 10.9849 2.89712 11.9602 3.27403 12.8701C3.65094 13.7801 4.20338 14.6069 4.89982 15.3033C5.59626 15.9997 6.42306 16.5522 7.333 16.9291C8.24294 17.306 9.21821 17.5 10.2031 17.5C11.188 17.5 12.1633 17.306 13.0733 16.9291C13.9832 16.5522 14.81 15.9997 15.5064 15.3033C16.2029 14.6069 16.7553 13.7801 17.1322 12.8701C17.5091 11.9602 17.7031 10.9849 17.7031 10C17.7031 9.01509 17.5091 8.03982 17.1322 7.12987C16.7553 6.21993 16.2029 5.39314 15.5064 4.6967C14.81 4.00026 13.9832 3.44781 13.0733 3.0709C12.1633 2.69399 11.188 2.5 10.2031 2.5C9.21821 2.5 8.24294 2.69399 7.333 3.0709C6.42306 3.44781 5.59626 4.00026 4.89982 4.6967C4.20338 5.39314 3.65094 6.21993 3.27403 7.12987C2.89712 8.03982 2.70312 9.01509 2.70313 10Z"
          stroke="white"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M10.2031 10L12.7031 11.6667"
          stroke="white"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M10.2031 5.8335V10.0002"
          stroke="white"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2557_58307">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(0.203125)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
