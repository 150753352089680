import styled from "styled-components";

export const Link = styled.a<{ font?: string; }>`
	transition: .3s all;
	color: ${props => props.theme.colors.blue.accent};
	&:hover {
		color: ${props => props.theme.colors.blue.hover};
	}
	&:active {
		color: ${props => props.theme.colors.blue.active};
	}
	${props => props.font ? props.font : props.theme.fonts.GothamRegular20};
`