export default function NameTag() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.172 0C9.96758 0.000169912 10.7305 0.316352 11.293 0.879L19.003 8.589C19.6424 9.22848 20.0015 10.0957 20.0015 11C20.0015 11.9043 19.6424 12.7715 19.003 13.411L13.411 19.003C12.7715 19.6424 11.9043 20.0015 11 20.0015C10.0957 20.0015 9.22848 19.6424 8.589 19.003L0.879 11.293C0.316352 10.7305 0.000169912 9.96758 0 9.172V4C0 2.93913 0.421427 1.92172 1.17157 1.17157C1.92172 0.421427 2.93913 0 4 0H9.172ZM5.5 3.5C4.99542 3.49984 4.50943 3.69041 4.13945 4.0335C3.76947 4.37659 3.54284 4.84684 3.505 5.35L3.5 5.5C3.5 5.89556 3.6173 6.28224 3.83706 6.61114C4.05682 6.94004 4.36918 7.19638 4.73463 7.34776C5.10009 7.49913 5.50222 7.53874 5.89018 7.46157C6.27814 7.3844 6.63451 7.19392 6.91421 6.91421C7.19392 6.63451 7.3844 6.27814 7.46157 5.89018C7.53874 5.50222 7.49913 5.10009 7.34776 4.73463C7.19638 4.36918 6.94004 4.05682 6.61114 3.83706C6.28224 3.6173 5.89556 3.5 5.5 3.5Z"
        fill="white"
        fill-opacity="0.7"
      />
    </svg>
  );
}
