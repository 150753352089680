import ModalWrapper from "components/Templates/ModalWrapper";
import { SocialVerify, TastkStatsInfo } from "interfaces/BattlPass";
import { BlockTask, BlockTaskExp, Container, TextBlock } from "./styled";
import Button from "components/UI/Button";
import { Icons } from "assets/icons";
import { useAppSelector } from "hooks/useAppSelector";
import { useNavigate } from "react-router-dom";
import { BattlPassServise } from "API/BattlPassServise";
import { toast } from "react-toastify";
import { useState } from "react";
import { UserService } from "API/UserService";
import { endpoints, url } from "API";

interface Props {
  isActive: boolean;
  setActive: React.SetStateAction<any>;
  BattlPassMeSocial: TastkStatsInfo;
}
export default function SocialBattlPass({
  isActive,
  setActive,
  BattlPassMeSocial,
}: Props) {
  const { user } = useAppSelector((state) => state.user);
  const [verify, setVerify] = useState<SocialVerify>();
  const navigate = useNavigate();
  return (
    <ModalWrapper
      isActive={isActive}
      setActive={setActive}
      headContent={"Подписаться на соц.сети"}
      display="flex"
      params={{ width: "60rem", height: "max-content" }}
    >
      <Container>
        <BlockTask pad={true}>
          <TextBlock>Привязать соц.сети</TextBlock>
          {user?.user?.vk == null ||
          user?.user?.discord == null ||
          verify?.vk == false ||
          verify?.discord == false ? (
            <Button
              color="shadow"
              variant="default"
              height="5rem"
              params={{ width: "20rem" }}
              onClick={() =>
                navigate(`/profile/${user?.user?.steamid64}/settings`)
              }
            >
              Привязать
              <Icons.ArrowBack
                style={{
                  transform: "rotate(135deg)",
                  width: "20px",
                  height: "10px",
                }}
              />
            </Button>
          ) : (
            <TextBlock style={{ opacity: "70%" }}>
              Выполнено <Icons.EarnedBP />
            </TextBlock>
          )}
        </BlockTask>
        <BlockTask pad={true}>
          <TextBlock>
            <Icons.VK
              style={{ width: "34px", opacity: "70%", cursor: "default" }}
            />
            Подписаться на VK
          </TextBlock>
          {user?.user?.vkSubscribed ? (
            <TextBlock style={{ opacity: "70%" }}>
              Выполнено <Icons.EarnedBP />
            </TextBlock>
          ) : (
            <Button
              color="shadow"
              variant="default"
              height="5rem"
              params={{ width: "20rem" }}
              onClick={async (e) => {
                window.open("https://vk.com/freezblood", "_self");
              }}
            >
              Подписаться
              <Icons.ArrowBack
                style={{
                  transform: "rotate(135deg)",
                  width: "20px",
                  height: "10px",
                }}
              />
            </Button>
          )}
        </BlockTask>
        {/* <BlockTask pad={true}>
          <TextBlock>
            <Icons.Discord
              style={{ width: "34px", opacity: "70%", cursor: "default" }}
            />
            Discord
          </TextBlock>

          {user?.user?.discord ? (
            <TextBlock style={{ opacity: "70%" }}>
              Выполнено <Icons.EarnedBP />
            </TextBlock>
          ) : (
            <Button
              color="shadow"
              variant="default"
              height="5rem"
              params={{ width: "20rem" }}
              onClick={() =>
                navigate(`/profile/${user?.user?.steamid64}/settings`)
              }         
            >
              Подписаться
              <Icons.ArrowBack
                style={{
                  transform: "rotate(135deg)",
                  width: "20px",
                  height: "10px",
                }}
              />
            </Button>
          )}
        </BlockTask> */}

        <BlockTask pad={true}>
          <TextBlock>
            <Icons.Telegram
              style={{ width: "34px", opacity: "70%", cursor: "default" }}
            />
            Подписаться на Telegram
          </TextBlock>

          <Button
            color="shadow"
            variant="default"
            height="5rem"
            params={{ width: "20rem" }}
            onClick={() => window.open("https://t.me/freezblood")}
          >
            Подписаться
            <Icons.ArrowBack
              style={{
                transform: "rotate(135deg)",
                width: "20px",
                height: "10px",
              }}
            />
          </Button>
        </BlockTask>

        <Button
          height="5rem"
          color="blue"
          variant="default"
          onClick={async () => {
            const result: SocialVerify = await BattlPassServise.GetInfoSoc();

            setVerify(result);
            if (result.discord == false) {
              toast.error("Подпишитесь на discord");
            } else if (result.vk == false) {
              toast.error("Подключите Вконтакте");
            } else if (result.vkSubscribed == false) {
              toast.error("Подпишитесь на группу VK и Telegram канал");
            } else if (
              String(result) == "Socials already linked" ||
              Boolean(result) == true
            ) {
              window.location.reload();
            }
          }}
        >
          Проверить
        </Button>
      </Container>
    </ModalWrapper>
  );
}
