import styled, { css } from "styled-components";

interface ProgressProps {
	percent?: any
}

export const Container = styled.div`
	max-width: 12rem;
	width: 100%;
	height: 5rem;
	position: relative;
	border: .3rem solid ${props => props.theme.stroke.stroke5};
	border-radius: 2rem;
	${props => props.theme.mixins.dfcenter};
	${props => props.theme.fonts.GothamRegular20};
	color: ${props => props.theme.colors.white.white100};
	overflow: hidden;
`

export const Text = styled.span`
	position: relative;
	z-index: 10;
`

export const Progress = styled.div<ProgressProps>`
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	background: ${props => props.theme.colors.lightLime};
	${props => props.percent >= 30 && css`
	background: ${props => props.theme.colors.lightGreen};
	`}
	${props => props.percent >= 70 && css`
	background: ${props => props.theme.colors.orange};
	`}
	${props => props.percent >= 90 && css`
	background: ${props => props.theme.colors.red.accent};
	`}
	width: ${props => props.percent}%;
`