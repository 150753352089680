import ModalWrapper from "components/Templates/ModalWrapper";
import {
  Classes,
  SkinChangeItem,
  StickerRowProps,
} from "interfaces/SkinChanger";
import { observer } from "mobx-react-lite";
import SkinsChangersState from "pages/Profile/Tabs/SkinsChangers/SkinsChangersState";
import {
  BlockCommand,
  BlockModalSkin,
  BlockStikers,
  BlockStikersMobile,
  BlockUseCommand,
  DefFlexG1,
  DefFlexSB,
  Full,
  Gun,
  MediumText,
  ModalContainer,
  ModalContainerStic,
  ModalContainerSticMobile,
  NonePremium,
  SmallText,
  Sticker,
  StikerPick,
  TextPrem,
  Weapon,
} from "./styled";
import rarityConvector, { rarityConvectorToInt } from "utils/rarityConvector";
import UIKIT from "components/UI";
import { useEffect, useState } from "react";
import { TinyIcons } from "assets/tiny-icons";
import Image from "components/Image";
import { Icons } from "assets/icons";
import UI from "components/UI";
import { SkinChangerService } from "API/SkinChangerService";
import RangeSlider from "./RangeSlider/RangeSlider";
import RangeSliderPattern from "./RangeSliderPattern/RangeSliderPattern";
import { toast } from "react-toastify";
import { useAppSelector } from "hooks/useAppSelector";
import { useNavigate } from "react-router-dom";

interface Props {
  isActive: boolean;
  // indexItem: number;
  setActive: React.SetStateAction<any>;
  state: typeof SkinsChangersState;
  skin?: SkinChangeItem;
  // canBeUsedTT: boolean;
  // canBeUsedCT: boolean;
  // canPrefab: string;
}

function SkinChangerModal({ isActive, setActive, state, skin }: Props) {
  const [NameTag, SetNameTag] = useState<string>("");
  const [useCommand, setUseCommand] = useState<Classes>();
  const [useStickers, setUseStickers] = useState<number>();
  const [ValueSearch, setValueSearch] = useState<string>("");
  const [activeStikerPick, setActiveStikerPick] = useState<boolean>(false);
  const [activeIdSticker, setActiveIdSticker] = useState<{
    [key: string]: any;
  }>();
  const [imageSticker, setImageSticker] = useState<StickerRowProps[]>();
  const [FloatValue, setFloatValue] = useState<number>(0.001);
  const [PatternValue, setPatternValue] = useState<number>(0);
  const [activeIdBrelok, setActiveBrelok] = useState<number>();
  const [activeInventoryBrelok, setActiveInventoryBrelok] = useState<number>();
  const { isAuth, user, userData } = useAppSelector((state) => state.user);
  const [modalInModalStic, setModalInModalStic] = useState<boolean>(false);
  const navigate = useNavigate();
  useEffect(() => {
    SetNameTag("");
    setUseCommand(undefined);
    setValueSearch("");
    setUseStickers(undefined);
    setActiveStikerPick(false);
    async function StartModal() {
      setModalInModalStic(false);
      if (isActive == true && skin?.options) {
        if (skin.options.keychain && !SkinsChangersState.Breloks.length) {
          SkinsChangersState.getBrelok();
        }
        if (skin.used == true && skin.options.classes) {
          setUseCommand(skin.options.classes);
        }
        setActiveBrelok(skin?.options.keychain?.keychain_id);
        setActiveInventoryBrelok(skin?.options.keychain?.inventory_id);
        setActiveIdSticker(skin?.options?.stickers);
        setPatternValue(skin?.options.pattern);
        setFloatValue(skin.options.wear);
        const valuesArray = Object.values(skin.options.stickers).map((value) =>
          Number(value)
        );
        const result: StickerRowProps[] = await SkinChangerService.getStickerId(
          valuesArray
        );
        result && setImageSticker(result);
      } else {
        setActiveIdSticker(undefined);
        setPatternValue(0);
        setFloatValue(0.001);
        setActiveBrelok(undefined);
        setActiveInventoryBrelok(undefined);
      }
    }
    StartModal();
  }, [isActive]);

  useEffect(() => {
    console.log(activeIdSticker);
  }, [activeIdSticker]);

  useEffect(() => {
    if (useStickers !== 4 && useStickers !== undefined) {
      SkinsChangersState.getStickers(ValueSearch);
    } else if (useStickers == 4 && useStickers !== undefined) {
      if (!SkinsChangersState.Breloks.length) {
        SkinsChangersState.getBrelok();
      }
      setValueSearch("");
    }
    setActiveStikerPick(true);
  }, [useStickers]);

  useEffect(() => {
    if (ValueSearch !== undefined) {
      SkinsChangersState.getStickers(ValueSearch);
    }
  }, [ValueSearch]);

  return (
    <ModalWrapper
      SkinsChangers={true}
      isActive={isActive}
      setActive={() => {
        setActive();

        // SkinChangerState.resetSkins(indexItem);
        // setApplyOk(false);
      }}
      params={{ width: "max-content", height: "max-content" }}
      headContent={` ${skin?.paint_name}`}
      display="flex"
    >
      <Full>
        {skin?.prefab !== "hands_paintable" &&
        skin?.prefab !== "melee_unusual" &&
        activeStikerPick ? (
          <ModalContainerStic style={{ justifyContent: "start" }}>
            {useStickers !== 4 ? (
              <UI.Input
                placeholder="Поиск..."
                disabled={
                  !user?.group?.flags.includes("p") && useStickers !== 4
                }
                params={{
                  width: "100%",
                  InputPlaceholderColor: "#999999",
                  bradius: "2rem",
                }}
                value={ValueSearch}
                onChange={(e) => setValueSearch(e.target.value)}
              />
            ) : null}

            <BlockStikers
              notPrem={!user?.group?.flags.includes("p") && useStickers !== 4}
            >
              <NonePremium
                nonPrem={!user?.group?.flags.includes("p") && useStickers !== 4}
              >
                <DefFlexG1 style={{ flexDirection: "column", gap: "1.5rem" }}>
                  <TinyIcons.Crown
                    style={{
                      fill: "#FFC526",
                      stroke: "#FFC526",
                    }}
                  />
                  <TextPrem>Стикеры доступны только с</TextPrem>
                  <TextPrem
                    style={{
                      cursor: "pointer",
                      textDecoration: "underline ",
                    }}
                    onClick={() => navigate("/vip")}
                  >
                    Premium
                  </TextPrem>
                  <UI.Button
                    variant="default"
                    color="orangeVIP"
                    height="5rem"
                    onClick={() => navigate("/vip")}
                  >
                    Получить премиум
                  </UI.Button>
                </DefFlexG1>
              </NonePremium>
              {useStickers !== 4 &&
                useStickers !== undefined &&
                SkinsChangersState.stickers.map((item, index) => {
                  return (
                    <>
                      {index == 0 && (
                        <Sticker
                          style={{
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                          onClick={() =>
                            setActiveIdSticker((prevState) => {
                              const safeState = prevState ?? {}; // Гарантируем, что состояние не undefined
                              const { [useStickers]: _, ...rest } = safeState; // Удаляем ключ
                              return rest;
                            })
                          }
                        >
                          <Icons.Close />
                          <SmallText>Сбросить</SmallText>
                        </Sticker>
                      )}
                      <Sticker
                        onClick={() => {
                          setImageSticker([...(imageSticker || []), item]);
                          setActiveIdSticker((prevState) => ({
                            ...prevState,
                            [useStickers]: item.id,
                          }));
                        }}
                      >
                        <Image
                          src={item.image}
                          alt="stiker"
                          width="80px"
                          height="60px"
                        />
                        <SmallText>{item.item_name_ru}</SmallText>
                      </Sticker>
                    </>
                  );
                })}
              {useStickers == 4 && SkinsChangersState.Breloks.length ? (
                SkinsChangersState.Breloks.map((item, index) => {
                  return (
                    <>
                      {index == 0 && (
                        <Sticker
                          style={{
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                          onClick={() => {
                            setActiveBrelok(undefined);
                            setActiveInventoryBrelok(undefined);
                          }}
                        >
                          <Icons.Close />
                          <SmallText>Сбросить</SmallText>
                        </Sticker>
                      )}
                      <Sticker
                        style={
                          item.id == activeInventoryBrelok
                            ? { border: "1px solid green" }
                            : item.selected &&
                              item.id !== skin?.options?.keychain?.inventory_id
                            ? { border: "1px solid orange" }
                            : { border: "none" }
                        }
                        onClick={() => {
                          setActiveBrelok(item.keychain.id);
                          setActiveInventoryBrelok(item.id);
                        }}
                      >
                        <Image
                          src={item.image}
                          alt="stiker"
                          width="80px"
                          height="60px"
                        />
                        <SmallText>{item.keychain.nameRu}</SmallText>
                      </Sticker>
                    </>
                  );
                })
              ) : (
                <DefFlexG1
                  style={{
                    flexDirection: "column",
                    overflow: "hidden",
                    justifyContent: "center",
                    width: "100%",
                    alignItems: "center",
                    alignContent: "center",
                    height: "45rem",
                    display: useStickers !== 4 ? "none" : "flex",
                  }}
                >
                  <Icons.Brelok />
                  <TextPrem>У вас нет брелков.</TextPrem>
                </DefFlexG1>
              )}
            </BlockStikers>
          </ModalContainerStic>
        ) : null}
        <ModalContainer>
          <Weapon
            rarity={rarityConvector(rarityConvectorToInt(skin?.rarity || "bg"))}
          >
            <Gun src={skin?.image} />
          </Weapon>
          <DefFlexSB>
            <UIKIT.Input
              // max
              image={<TinyIcons.NameTag />}
              maxLength={16}
              placeholder={skin?.options?.nametag || "Именной ярлык..."}
              // height="2rem"
              params={{
                InputPlaceholderColor: "#acacac",
                width: "max-content",
              }}
              style={{
                height: "5rem",
                borderRadius: "1.5rem",
                width: "25rem",
              }}
              width={"50rem"}
              value={NameTag}
              onChange={(e) => SetNameTag(e.target.value)}
            />
            <BlockUseCommand>
              {SkinsChangersState.categories
                .find((item) => item.id == SkinsChangersState.usedCategoryId)
                ?.items.find(
                  (items) => items.id == SkinsChangersState.openSkinId
                )?.used_by_tt == true ? (
                <BlockCommand
                  onClick={() => setUseCommand(Classes.TT)}
                  active={useCommand == Classes.TT}
                >
                  <Image
                    src="/static/images/T.png"
                    alt="Team"
                    maxWidth="32px"
                  />
                </BlockCommand>
              ) : null}
              {SkinsChangersState.categories
                .find((item) => item.id == SkinsChangersState.usedCategoryId)
                ?.items.find(
                  (items) => items.id == SkinsChangersState.openSkinId
                )?.used_by_tt == true &&
              SkinsChangersState.categories
                .find((item) => item.id == SkinsChangersState.usedCategoryId)
                ?.items.find(
                  (items) => items.id == SkinsChangersState.openSkinId
                )?.used_by_ct == true ? (
                <BlockCommand
                  onClick={() => setUseCommand(Classes.BOTH)}
                  active={useCommand == Classes.BOTH}
                >
                  <Image
                    src="/static/images/BOTH.png"
                    alt="Team"
                    maxWidth="32px"
                  />
                </BlockCommand>
              ) : null}
              {SkinsChangersState.categories
                .find((item) => item.id == SkinsChangersState.usedCategoryId)
                ?.items.find(
                  (items) => items.id == SkinsChangersState.openSkinId
                )?.used_by_ct == true ? (
                <BlockCommand
                  onClick={() => setUseCommand(Classes.CT)}
                  active={useCommand == Classes.CT}
                >
                  <Image
                    src="/static/images/CT.png"
                    alt="Team"
                    maxWidth="32px"
                  />
                </BlockCommand>
              ) : null}
            </BlockUseCommand>
          </DefFlexSB>
          {skin?.prefab !== "hands_paintable" &&
          skin?.prefab !== "melee_unusual" ? (
            <DefFlexSB stic={true}>
              <DefFlexG1>
                <StikerPick
                  active={useStickers == 0}
                  onClick={() => {
                    setUseStickers(0);
                    setModalInModalStic(true);
                  }}
                >
                  {activeIdSticker && activeIdSticker[0] ? (
                    <Image
                      width="36px"
                      src={
                        imageSticker?.find(
                          (item) => item.id == Number(activeIdSticker[0])
                        )?.image || ""
                      }
                      alt="stiker"
                    />
                  ) : (
                    <Icons.Add />
                  )}
                </StikerPick>
                <StikerPick
                  active={useStickers == 1}
                  onClick={() => {
                    setUseStickers(1);
                    setModalInModalStic(true);
                  }}
                >
                  {activeIdSticker && activeIdSticker[1] ? (
                    <Image
                      width="36px"
                      src={
                        imageSticker?.find(
                          (item) => item.id == Number(activeIdSticker[1])
                        )?.image || ""
                      }
                      alt="stiker"
                    />
                  ) : (
                    <Icons.Add />
                  )}
                </StikerPick>
                <StikerPick
                  active={useStickers == 2}
                  onClick={() => {
                    setUseStickers(2);
                    setModalInModalStic(true);
                  }}
                >
                  {activeIdSticker && activeIdSticker[2] ? (
                    <Image
                      width="36px"
                      src={
                        imageSticker?.find(
                          (item) => item.id == Number(activeIdSticker[2])
                        )?.image || ""
                      }
                      alt="stiker"
                    />
                  ) : (
                    <Icons.Add />
                  )}
                </StikerPick>
                <StikerPick
                  active={useStickers == 3}
                  onClick={() => {
                    setUseStickers(3);
                    setModalInModalStic(true);
                  }}
                >
                  {activeIdSticker && activeIdSticker[3] ? (
                    <Image
                      width="36px"
                      src={
                        imageSticker?.find(
                          (item) => item.id == Number(activeIdSticker[3])
                        )?.image || ""
                      }
                      alt="stiker"
                    />
                  ) : (
                    <Icons.Add />
                  )}
                </StikerPick>
              </DefFlexG1>
              <StikerPick
                active={useStickers == 4}
                onClick={() => {
                  setUseStickers(4);
                  setModalInModalStic(true);
                }}
              >
                {activeIdBrelok && activeInventoryBrelok ? (
                  <Image
                    width="36px"
                    src={
                      SkinsChangersState.Breloks?.find(
                        (item) => item.keychain.id == activeIdBrelok
                      )?.image || ""
                    }
                    alt="stiker"
                  />
                ) : (
                  <Icons.Brelok />
                )}
              </StikerPick>
            </DefFlexSB>
          ) : null}
          <RangeSlider
            value={FloatValue}
            setValue={setFloatValue}
            isActive={isActive}
          />
          <RangeSliderPattern value={PatternValue} setValue={setPatternValue} />
          <UIKIT.Button
            color="blue"
            height="5rem"
            variant="default"
            params={{ width: "100%" }}
            onClick={async () => {
              if (useCommand !== undefined && skin?.item_id) {
                console.log(skin?.id);

                const result = await SkinsChangersState.ApplyItems(
                  SkinsChangersState.openSkinId,
                  useCommand,
                  false,
                  skin.paint_kit_id,
                  activeIdSticker,
                  FloatValue,
                  PatternValue,
                  NameTag,
                  false,
                  activeIdBrelok
                );
                if (result) {
                  SkinsChangersState.updateOpenSkinId(
                    SkinsChangersState.openSkinId,
                    SkinsChangersState.NameSkinId,
                    true
                  );
                  toast.success("Успешно");
                  setActive(false);
                  if (activeIdBrelok) {
                    SkinsChangersState.getBrelok();
                  }
                }
              } else {
                toast.error("Выберите команду");
              }
            }}
          >
            Применить
          </UIKIT.Button>
        </ModalContainer>
        <BlockModalSkin active={modalInModalStic}>
          <DefFlexSB
            style={{ justifyContent: "space-between", flexDirection: "row" }}
          >
            <div />
            <MediumText>
              Выберите {useStickers !== 4 ? "стикер" : "брелок"}
            </MediumText>
            <TinyIcons.Close
              style={{ fillOpacity: "0.6" }}
              onClick={() => setModalInModalStic(false)}
            />
          </DefFlexSB>
          <ModalContainerSticMobile style={{ justifyContent: "start" }}>
            {useStickers !== 4 ? (
              <UI.Input
                placeholder="Поиск..."
                disabled={
                  !user?.group?.flags.includes("p") && useStickers !== 4
                }
                params={{
                  width: "100%",
                  InputPlaceholderColor: "#999999",
                  bradius: "2rem",
                }}
                value={ValueSearch}
                onChange={(e) => setValueSearch(e.target.value)}
              />
            ) : null}

            <BlockStikersMobile
              notPrem={!user?.group?.flags.includes("p") && useStickers !== 4}
              style={{ maxHeight: "43rem" }}
            >
              <NonePremium
                nonPrem={!user?.group?.flags.includes("p") && useStickers !== 4}
              >
                <DefFlexG1 style={{ flexDirection: "column", gap: "1.5rem" }}>
                  <TinyIcons.Crown
                    style={{
                      fill: "#FFC526",
                      stroke: "#FFC526",
                    }}
                  />
                  <TextPrem>Стикеры доступны только с</TextPrem>
                  <TextPrem
                    style={{
                      cursor: "pointer",
                      textDecoration: "underline ",
                    }}
                    onClick={() => navigate("/vip")}
                  >
                    Premium
                  </TextPrem>
                  <UI.Button
                    variant="default"
                    color="orangeVIP"
                    height="5rem"
                    onClick={() => navigate("/vip")}
                  >
                    Получить премиум
                  </UI.Button>
                </DefFlexG1>
              </NonePremium>
              {useStickers !== 4 &&
                useStickers !== undefined &&
                SkinsChangersState.stickers.map((item, index) => {
                  return (
                    <>
                      {index == 0 && (
                        <Sticker
                          style={{
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                          onClick={() => {
                            setActiveIdSticker((prevState) => {
                              const safeState = prevState ?? {}; // Гарантируем, что состояние не undefined
                              const { [useStickers]: _, ...rest } = safeState; // Удаляем ключ
                              return rest;
                            });
                            setModalInModalStic(false);
                          }}
                        >
                          <Icons.Close />
                          <SmallText>Сбросить</SmallText>
                        </Sticker>
                      )}
                      <Sticker
                        onClick={() => {
                          setImageSticker([...(imageSticker || []), item]);
                          setActiveIdSticker((prevState) => ({
                            ...prevState,
                            [useStickers]: item.id,
                          }));
                          setModalInModalStic(false);
                        }}
                      >
                        <Image
                          src={item.image}
                          alt="stiker"
                          width="80px"
                          height="60px"
                        />
                        <SmallText>{item.item_name_ru}</SmallText>
                      </Sticker>
                    </>
                  );
                })}
              {useStickers == 4 && SkinsChangersState.Breloks.length ? (
                SkinsChangersState.Breloks.map((item, index) => {
                  return (
                    <>
                      {index == 0 && (
                        <Sticker
                          style={{
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                          onClick={() => {
                            setActiveBrelok(undefined);
                            setActiveInventoryBrelok(undefined);
                            setModalInModalStic(false);
                          }}
                        >
                          <Icons.Close />
                          <SmallText>Сбросить</SmallText>
                        </Sticker>
                      )}
                      <Sticker
                        style={
                          item.id == activeInventoryBrelok
                            ? { border: "1px solid green" }
                            : item.selected &&
                              item.id !== skin?.options?.keychain?.inventory_id
                            ? { border: "1px solid orange" }
                            : { border: "none" }
                        }
                        onClick={() => {
                          setActiveBrelok(item.keychain.id);
                          setActiveInventoryBrelok(item.id);
                          setModalInModalStic(false);
                        }}
                      >
                        <Image
                          src={item.image}
                          alt="stiker"
                          width="80px"
                          height="60px"
                        />
                        <SmallText>{item.keychain.nameRu}</SmallText>
                      </Sticker>
                    </>
                  );
                })
              ) : (
                <DefFlexG1
                  style={{
                    flexDirection: "column",
                    overflow: "hidden",
                    justifyContent: "center",
                    width: "100%",
                    alignItems: "center",
                    alignContent: "center",
                    height: "45rem",
                    display: useStickers !== 4 ? "none" : "flex",
                  }}
                >
                  <Icons.Brelok />
                  <TextPrem>У вас нет брелков.</TextPrem>
                </DefFlexG1>
              )}
            </BlockStikersMobile>
          </ModalContainerSticMobile>
        </BlockModalSkin>
      </Full>
    </ModalWrapper>
  );
}

export default observer(SkinChangerModal);
