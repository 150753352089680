import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import API from 'API'
import { IPrivilege } from 'interfaces/Privilege';

interface ShopState {
	shop: IPrivilege[];
	isLoading: boolean;
}

const initialState: ShopState = {
	shop: [],
	isLoading: false
}

export const fetchShop = createAsyncThunk(
	'shop/fetchShop',
	async () => {
		const response = await API.ShopService.getShop()
		return response.data
	}
)

export const shopSlice = createSlice({
	name: 'shop',
	initialState,
	reducers: {

	},
	extraReducers: (builder) => {
		builder.addCase(fetchShop.pending, (state) => {
			state.isLoading = true
		})
		builder.addCase(fetchShop.fulfilled, (state, action) => {
			state.isLoading = false
			state.shop = action.payload
		})
	}
})

export const { } = shopSlice.actions
export const shopActions = shopSlice.actions
export default shopSlice.reducer