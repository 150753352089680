import styled, { GradientKeys } from "styled-components";

export const BigText = styled.h2`
  ${(props) => props.theme.fonts.GothamBold24}
`;
export const MediumText = styled.h3`
  ${(props) => props.theme.fonts.GothamBold16}
`;

export const StandartFlexG1 = styled.div<{ NoAling?: boolean }>`
  display: flex;
  gap: 1rem;
  @media (max-width: 768px) {
    align-items: ${(props) => (props.NoAling ? "start" : "center")};
    justify-content: center;
  }
`;

export const FullWrapTrade = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3rem;
  width: 100%;
  margin-left: 0.1rem;
`;

export const ArrowStyle = styled.div<{ right: boolean }>`
  width: 10px;
  height: 10px;
  border-top: 2px solid white !important;
  border-right: 2px solid white !important;
  transform: rotate(${(props) => (props.right ? "45deg" : "-135deg")});
  opacity: 60%;
`;

export const BlockSkins = styled.div`
  display: flex;
  gap: 1rem;
  /* width: 100%; */
  margin-top: 3rem;
  @media (max-width: 1900px) {
    justify-content: center;
    flex-wrap: wrap;
    /* width: 100rem; */
  }
`;

export const ItemContr = styled.div<{ act?: boolean }>`
  display: flex;
  flex-direction: column;
  padding: 5rem 3rem;
  max-width: 120rem;
  height: 25rem;
  background: ${(props) => props.theme.gradients.shadow};
  color: ${(props) => props.theme.colors.white.white20};
  ${(props) => props.theme.fonts.GothamMedium24}
  align-items:center;
  gap: 3rem;
  border-radius: 3rem;
  cursor: ${(props) => (props.act ? "pointer" : "default")};
  @media (max-width: 768px) {
    padding: 3rem 1rem;
  }
`;

export const BlockDiv = styled.div<{ rarity: GradientKeys }>`
  /* position: relative; */
  /* top: 0; */
  font-size: 3px;
  border-radius: 0 0 5rem 5rem;
  height: 0.5rem;
  width: 5rem;
  background: ${(props) => props.theme.colors.weapon[props.rarity]};
  color: ${(props) => props.theme.colors.weapon[props.rarity]};
  align-self: center;
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

export const Name = styled.span`
  text-align: center;
  ${(props) => props.theme.fonts.GothamRegular14};
  color: ${(props) => props.theme.colors.white.white100};
  display: flex;
  align-items: center;
  flex-direction: column;
  font-weight: 400;
  height: 3rem;
`;

export const SubName = styled.div<{ rarity: GradientKeys }>`
  display: inline;
  font-weight: bold;
  ${(props) => props.theme.fonts.GothamBold16};
  color: ${(props) => props.theme.colors.weapon[props.rarity]};
`;
