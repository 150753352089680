import { ShopService } from "API/ShopService";
import KeyCard from "components/Cards/KeyCard";
import { IKeyCard } from "interfaces/KeyCard";

import { useEffect, useMemo, useState } from "react";
import { Grid, Wrapper } from "./Styles";

export default function Credits() {
  const [item, setItem] = useState<IKeyCard>();

  useEffect(() => {
    async function getCategories() {
      if (!item) {
        const items: IKeyCard = await ShopService.getCredits();
        setItem(items);
      }
    }
    getCategories();
  }, []);

  const ItemMemo = useMemo(() => {
    return item?.data.map(({ price, count, id }, index) => (
      <KeyCard id={id} price={price} count={count} />
    ));
  }, [item]);

  return (
    <Wrapper>
      <Grid>{ItemMemo}</Grid>
    </Wrapper>
  );
}
