import styled from "styled-components";

interface Props {
	width?: string;
	font?: string;
}

export const SelectBlock = styled.div`
	position: relative;
	width: 100%;
	/* z-index: 15; */
`

export const Label = styled.label<Props>`
	position: relative;
	display: flex;
	width: 100%;
	max-width: ${props => props.width ? props.width : '30rem'};
	flex-direction: column;
	gap: 1.5rem;
	${props => props.font ? props.font : props.theme.fonts.GothamBold24}
	color: ${props => props.theme.colors.white.white100};
	/* z-index: 2; */
`

export const Trigger = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	z-index: -2;
	pointer-events: none;
	&.active {
		z-index: 2;
		pointer-events: all;
	}
`

export const Text = styled.div<{ font?: string }>`
	padding-left: 2rem;
	${props => props.font ? props.font : props.theme.fonts.GothamBold24};
	color: ${props => props.theme.colors.white.white100};
`