import { BanServise } from "API/BanServise";
import Row from "components/Row";
import Detailed from "components/Templates/Detailed";
import ModalWrapper from "components/Templates/ModalWrapper";
import Button from "components/UI/Button";
import { useAppSelector } from "hooks/useAppSelector";
import { FullAdmins, OneBan } from "interfaces/Bans";
import { IModal } from "interfaces/Modal";
import {
  Violation,
  ViolationTitles,
  ViolationTitlesEnum,
} from "models/Violation";
import { useNavigate } from "react-router-dom";
import { Admin, Container, Item, Red, Title } from "./Styles";

interface Props extends IModal {
  violation: Violation;
  activeBan?: OneBan;
  fullBan?: FullAdmins;
  setFullBans?: React.SetStateAction<any>;
}

interface PatchData1 {
  id: string | number;
  state: number;
}

export default function BanModal({
  isActive,
  setActive,
  violation,
  activeBan,
  fullBan,
  setFullBans,
}: Props) {
  const navigate = useNavigate();

  const { user } = useAppSelector((state) => state.user);

  return (
    <ModalWrapper
      setActive={setActive}
      isActive={isActive}
      headContent="Подробная информация"
      params={{ width: "80rem", height: "fit-content" }}
      display="flex"
    >
      <Container>
        <Detailed>
          {activeBan?.banned.type !== "ban" ? (
            <Row gap="3rem" justifyContent="center" margin="3rem 0 0 0">
              <Title>{ViolationTitlesEnum.MUTE}</Title>
              <Item>{activeBan?.banned.type}</Item>
            </Row>
          ) : (
            <></>
          )}

          <Row
            gap="3rem"
            justifyContent="center"
            margin={activeBan?.banned.type !== "ban" ? "" : "3rem 0 0 0"}
            // fullWidth={true}
          >
            <Title>{ViolationTitles.PLAYER_IP}</Title>
            <Item>{activeBan?.banned.ip}</Item>
          </Row>

          <Row gap="3rem" justifyContent="center">
            <Title>{ViolationTitles.PLAYER_STEAMID64}</Title>
            <Item>{activeBan?.banned.steamid64}</Item>
          </Row>

          <Row gap="3rem" justifyContent="center">
            <Title>{ViolationTitles.user_name}</Title>
            <Admin
              onClick={() =>
                navigate(`/profile/${activeBan?.banned.steamid64}`)
              }
            >
              {activeBan?.banned.name}
            </Admin>
          </Row>
          <Row gap="3rem" justifyContent="center">
            <Title>{ViolationTitles.ADMIN_NICKNAME}</Title>
            <Admin
              onClick={() => navigate(`/profile/${activeBan?.admin.steamid64}`)}
            >
              {activeBan?.admin.name}
            </Admin>
          </Row>
          <Row gap="3rem" justifyContent="center">
            <Title>{ViolationTitles.REASON}</Title>
            <Red nonBan={activeBan?.banned.state == "banned" ? false : true}>
              {activeBan?.reason}
            </Red>
          </Row>
          <Row gap="3rem" justifyContent="center">
            <Title>{ViolationTitles.created_at}</Title>
            <Item>
              {activeBan?.date.slice(0, 10)},{activeBan?.date.slice(11, 16)}
            </Item>
          </Row>
          <Row gap="3rem" justifyContent="center">
            <Title>{ViolationTitles.END_TIMESTAMP}</Title>
            <Item>
              {activeBan?.expiration.slice(0, 10)},
              {activeBan?.expiration.slice(11, 16)}
            </Item>
          </Row>

          <Row gap="3rem" justifyContent="center">
            <Title>{ViolationTitles.SERVER_IP}</Title>
            <Red>{activeBan?.server.ip}</Red>
          </Row>
          {user?.group?.flags.includes("s") ? (
            <Row gap="3rem" justifyContent="center">
              <Button
                variant="default"
                color="green"
                height="5rem"
                params={{ width: "100%" }}
                onClick={() => {
                  BanServise.ChangeBan(activeBan?.id, "unbanned");
                  setActive(false);
                  const i = fullBan?.blocks.map((item) => {
                    if (item.id == activeBan?.id) {
                      let l = activeBan;
                      l.banned.state = "unbanned";
                      return l;
                    } else {
                      return item;
                    }
                  });
                }}
              >
                {activeBan?.banned.type === "ban" ? "Разбанить" : "Размутить"}
              </Button>
            </Row>
          ) : null}
        </Detailed>
      </Container>
    </ModalWrapper>
  );
}
