import styled from "styled-components"

export const Wrapper = styled.div`
	${props => props.theme.fonts.GothamRegular14};
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	right: .5rem;
	top: .5rem;
	width: 4.9rem;
	height: 3rem;
	color: ${props => props.theme.colors.white.white100};
	display: flex;
	font-weight: 400;
	background: ${props => props.theme.colors.white.white10};
	border-radius: .8rem;
`