import { TextareaParams } from './../../../interfaces/Textarea';
import styled from "styled-components";

export const Label = styled.label<TextareaParams>`
	display: flex;
	flex-direction: column;
	gap: 1.5rem;
	width: 100%;
	max-width: ${props => props.width ? props.width : '30rem'};
`

export const Text = styled.div<{ params?: TextareaParams }>`
	${props => props.params?.headFont ? props.params.headFont : props.theme.fonts.GothamMedium24};
	align-self: ${props => props.params?.labelPosition ? props.params?.labelPosition : 'flex-start'};
	color: ${props => props.params?.labelColor ? props.params.labelColor : props.theme.colors.white.white100};
`

export const TextareaBlock = styled.textarea<{ params?: TextareaParams }>`
	${props => props.params?.inputFont ? props.params?.inputFont : props.theme.fonts.GothamRegular20};
	color: ${props => props.params?.inputColor ? props.params.inputColor : props.theme.colors.white.white100};
	border-radius: 2rem;
	width: ${props => props.params?.width && props.params.width};
	border: .1rem solid ${props => props.theme.colors.black.black30};
	background: ${props => props.params?.background ? props.params?.background : props.theme.colors.white.white10};
	height: ${props => props.params?.height ? props.params.height : '5rem'};
	padding: 2rem;
	display: flex;
	align-items: center;
	justify-content: center;
	height: ${props => props?.params?.height ? props?.params?.height : '6rem'};
	resize: vertical;
	transition: .3s all;
	&:hover {
		border: .1rem solid ${props => props.theme.colors.blue.active};
	}
	&:focus {
		border: .1rem solid ${props => props.theme.colors.blue.hover};
	}
`