import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API from "API";
import { MarketPlaceState } from "models/Marketplace";
import { IWeaponCard } from "models/WeaponCard";
import { toast } from "react-toastify";

const name = "marketplace";

const initialState: MarketPlaceState = {
  data: [] as IWeaponCard[],
  selling: [],
  total: 0,
};

export const fetchMarketplace = createAsyncThunk(
  name + "/fetchMarketplace",
  async ({
    page,
    perPage,
    classId,
    weapon,
    priceSort,
    raritySort,
  }: {
    page: number;
    perPage: number;
    classId?: number;
    weapon?: number;
    priceSort?: string;
    raritySort?: string;
  }) => {
    const response = await API.MarketplaceService.getMarketplace(
      page,
      perPage,
      classId,
      weapon,
      priceSort,
      raritySort
    );
    return response;
  }
);

export const fetchPutItem = createAsyncThunk(
  name + "/fetchPutItem",
  async (item: any) => {
    const response = await API.MarketplaceService.putItem(item);
    return response;
  }
);

export const fetchSalesItems = createAsyncThunk(
  name + "/fetchSalesItems",
  async () => {
    const response = await API.MarketplaceService.sellingItems();
    return response;
  }
);

export const fetchBuyItems = createAsyncThunk(
  name + "/fetchBuyItems",
  async (id: number | string) => {
    const response = await API.MarketplaceService.buyItems(id);
    return response;
  }
);

export const fetchRemoveItem = createAsyncThunk(
  name + "/fetchRemoveItem",
  async (id: number | string) => {
    const response = await API.MarketplaceService.removeItem(id);
    return response;
  }
);

export const fetchUpdateItem = createAsyncThunk(
  name + "/fetchUpdateItem",
  async ({ id, price }: { id: string | number; price: string }) => {
    const response = await API.MarketplaceService.updateItem({ id, price });
    return response;
  }
);

export const notificationsSlice = createSlice({
  name,
  initialState,
  reducers: {
    // putItem: (item: SellItem) => {
    // }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchMarketplace.fulfilled, (state, { payload }) => {
      state.data = payload.data;
      state.total = payload.total;
    });
    builder.addCase(fetchPutItem.fulfilled, (state, { payload }) => {
      // state.data = payload.data
      if (payload.success) {
        toast.success("Предмет выставлен на продажу!!");
      } else {
        toast("Ошибка! " + payload.message, {
          type: "error",
        });
      }
    });
    builder.addCase(fetchSalesItems.fulfilled, (state, { payload }) => {
      state.selling = payload.data;
    });
    builder.addCase(fetchBuyItems.fulfilled, (state, { meta, payload }) => {
      //console.log(meta);
      if (payload.success) {
        // state.data.map((item) => //console.log("item: ", item))
        // state.data = state.data.filter((item) => item.id !== meta.arg)
        toast("Покупка успешна!");
      } else {
        toast("Ошибка! " + payload.message, {
          type: "error",
        });
      }
    });
    builder.addCase(fetchRemoveItem.fulfilled, (state, { payload }) => {
      if (payload.success) {
        toast("Предмет снят с продажи!");
      } else {
        toast("Ошибка! " + payload.message, {
          type: "error",
        });
      }
    });
    builder.addCase(fetchUpdateItem.fulfilled, (state, { payload }) => {
      if (payload.success) {
        toast("Цена обновлена!");
      } else {
        toast("Ошибка! " + payload.message, {
          type: "error",
        });
      }
    });
  },
});

export const {} = notificationsSlice.actions;
export const notificationsActions = notificationsSlice.actions;
export default notificationsSlice.reducer;
