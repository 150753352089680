import styled from "styled-components";

export const IconWrapper = styled.div`
  cursor: pointer;
  ${(props) => props.theme.mixins.dfcenter};
  ${(props) => props.theme.shadows.defaultShadow};
  border: 0.3rem solid ${(props) => props.theme.stroke.stroke5};
  border-radius: 0.6rem;
  width: 3.4rem;
  height: 3.4rem;

  svg > * {
    fill-opacity: 0.1;
    transition: all 0.3s;
  }
  &.active {
    svg > * {
      fill-opacity: 1;
    }
  }
`;
