import { Icons } from "assets/icons";
import { BlockEarn, LineBlue, Test, TextClip, WrappEarn } from "./styled";

interface Props {
  earn: boolean;
  xpLevel: number;
  xpMe: number;
  level: number;
  maxLevel: number;
}

export default function EarnProgressBar({
  earn,
  xpLevel,
  xpMe,
  level,
  maxLevel,
}: Props) {
  return (
    <WrappEarn>
      <BlockEarn>
        {/* //Сделал на похуй, кирчик заебал */}
        <Test>d</Test>
        {/* //Сделал на похуй, кирчик заебал */}
        <TextClip earn={xpLevel < xpMe}>
          {xpLevel <= xpMe ? (
            <Icons.EarnedBP style={{ stroke: "#50ADF0" }} />
          ) : (
            `${level}`
          )}
        </TextClip>
        {maxLevel !== level ? <LineBlue colorBlue={xpLevel <= xpMe} /> : null}
      </BlockEarn>
    </WrappEarn>
  );
}
