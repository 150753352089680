import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const AuthRedirect = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get("token");
    const refreshToken = searchParams.get("refreshToken");

    // console.log("token", token);
    // console.log("refreshToken", refreshToken);
    if (token && refreshToken) {
      // console.log(1);
      localStorage.setItem("token", token);
      localStorage.setItem("refreshToken", refreshToken);

      navigate("/");
      window.location.reload();
    }
  }, [location, navigate]);

  return <div>Авторизация... Пожалуйста, подождите.</div>;
};

export default AuthRedirect;
