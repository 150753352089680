import styled from "styled-components";

export const WrapperAdminServer = styled.div`
  display: flex;
  flex-direction: column;
  /* background: red; */
`;
export const WrapSearch = styled.div`
  display: flex;
  align-items: end;
  justify-content: space-between;
`;
export const Wr = styled.div`
  display: flex;
  align-items: end;
  gap: 5rem;
  width: 80rem;
`;
export const WrapServerAndAdmin = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 3rem;
  gap: 1.5rem;
`;
export const WrapItemServer = styled.div`
  /* display: flex; */
  border: 2px solid ${(props) => props.theme.colors.black.black70};
  width: 100%;
  background: ${(props) => props.theme.gradients.shadow};
  padding: 3rem;
  border-radius: 2.5rem;
  /* cursor: pointer; */
`;
export const SpanTitle = styled.span`
  color: ${(props) => props.theme.colors.white.white100};
  ${(props) => props.theme.fonts.GothamBold20}
`;
export const WrapperMenuProfile = styled.div<{ Active?: boolean }>`
  /* padding: 0 20px 0 20px; */
  display: flex;
  justify-content: space-between;
  transition: 0.3s;
  cursor: pointer;
  svg {
    width: 3rem;
    height: 2rem;
    transition: 0.3s;
    cursor: pointer;
    transform: rotate(${(props) => (props.Active ? "0" : "180deg")});
  }
`;
export const BlockWrapUser = styled.div<{ Active?: boolean }>`
  display: ${(props) => (props.Active ? "none" : "flex")};
  flex-direction: column;
  margin-top: 5rem;
  gap: 1rem;
`;
export const SpanDefal = styled.span<{ width?: boolean }>`
  color: ${(props) => props.theme.colors.white.white100};
  ${(props) => props.theme.fonts.GothamRegular20}
  width: ${(props) => (props.width ? "max-content" : "30rem")};
`;
export const WrapUser = styled.div<{ cursor?: boolean; nonActive?: boolean }>`
  display: flex;
  padding: 2rem;
  border: 2px solid ${(props) => props.theme.colors.black.black70};
  width: 100%;
  background: ${(props) =>
    props.nonActive
      ? props.theme.colors.red.active
      : props.theme.gradients.rating_second};
  width: 100%;
  border-radius: 2rem;
  justify-content: space-between;
  cursor: ${(props) => (props.cursor ? "pointer" : "auto")};
  align-items: center;
  margin-top: 2rem;
`;
export const DefaulFlexConteiner = styled.div`
  display: flex;
  width: 30rem;
  gap: 2rem;
  align-items: center;
`;
export const WrapInfoW = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;
export const WrapBlockAddAdmin = styled.div`
  width: 100%;
  padding: 3rem;
  border-radius: 2.5rem;
  background: ${(props) => props.theme.gradients.shadow};
  margin-top: 5rem;
  margin-bottom: 5rem;
`;
export const WrapBlockChange = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 3rem;
  width: 75rem;
`;
export const WrapInput = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 2rem;
`;
