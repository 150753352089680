import styled from "styled-components";

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  @media (max-width: 1800px) {
    display: flex;
    flex-direction: column;
  }
`;

export const Info = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  gap: 3rem;
`;

export const Content = styled.p`
  ${({ theme }) => theme.fonts.GothamRegular16};
  color: ${({ theme }) => theme.colors.white.white100};
  line-height: 125%;
  overflow-wrap: anywhere;
`;

export const FullInfo = styled.div`
  display: grid;
  gap: 3rem;
  grid-template-columns: 0.3fr 1fr;
`;

export const InfoItem = styled.span<{ width?: string; color?: string }>`
  ${({ theme }) => theme.fonts.GothamRegular20};
  color: ${({ theme }) => theme.colors.white.white100};
  display: block;
  width: ${({ width }) => width && "100%"};
  max-width: ${({ width }) => width && width};
  overflow-wrap: anywhere;
`;

export const WrapBlock = styled.div`
  display: flex;
  gap: 3rem;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const PlayerItem = styled(InfoItem)`
  color: ${({ theme }) => theme.colors.blue.accent};
  cursor: pointer;
`;
