import styled from "styled-components";

interface Props {
  color?: string;
  font?: string;
  minName?: boolean;
}

export const Nickname = styled.span<Props>`
  ${(props) => props.theme.fonts.GothamRegular24};
  color: ${(props) => props.color};
  ${(props) => props.font && props.font}
  max-width:25rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  /* @media (max-width: 768px) {
    max-width: 6rem;
  } */
`;
