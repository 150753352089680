import { Container as ColumnContainer } from "./../Column/Styles";
import styled from "styled-components";
import { NavLink } from "react-router-dom";

interface ContainerProps {
  isChatActive?: boolean;
  NoneGridConteiner?: boolean;
}

export const StyledLayout = styled.main`
  /* background: ${(props) => props.theme.gradients.bg}; */
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  /* height: 100%; */
  height: 100vh;
  margin-left: calc(${(props) => props.theme.consts.navBarWidth} + 4rem);
  padding-bottom: 5rem;
  /* margin-left: 5rem; */
  @media (max-width: 1250px) {
    /* margin-bottom: 10rem; */
    /* overflow-y: auto; */

    margin-left: 0.1rem;
    /* max-height: calc(100vh - 5rem); */
    overflow-x: hidden;

    /* scroll-padding-top: 5rem; */
  }
`;

export const Wrapper = styled.div`
  /* background: red; */
  background: ${(props) => props.theme.gradients.bg};
`;

export const Container = styled.div<ContainerProps>`
  transition: 0.5s;
  width: 100%;
  margin-bottom: 3rem;
  /* height: fit-content; */
  /* overflow-y: hidden; */
  position: relative;
  flex: 1;
  display: ${(props) => (props.NoneGridConteiner ? "" : "grid")};
  grid-template-columns: 2.4rem 0.95fr;
  gap: 2rem;
  /* padding-right: 10.6rem; */
  ${ColumnContainer} {
    align-self: flex-start;
  }
  svg.active {
    path {
      fill-opacity: 1;
    }
  }
  @media (max-width: 1250px) {
    grid-template-columns: 1fr;
    padding-right: 0.8rem;
    flex: none;
  }
`;

export const ChatWrapper = styled.div<ContainerProps>`
  display: flex;
  position: fixed;
  gap: ${(props) => (props.isChatActive ? "1.6rem" : "0rem")};
  right: 3rem;
  transition: 0.5s;
  top: -0rem;
  z-index: 1000;
  /* width: 10rem; */

  @media (max-width: 768px) {
    display: ${(props) => (props.isChatActive ? "flex" : "none")};
    width: 100%;
    right: 0;

    z-index: 1000;
    height: vh;
  }
`;
export const MediumSpan = styled.span`
  ${(props) => props.theme.fonts.GothamMedium16}
  color: white;
  align-items: center;
  /* align-self: center; */
`;

export const MediumSpanLink = styled(NavLink)`
  ${(props) => props.theme.fonts.GothamMedium16}
  color: white;
  align-items: center;
  /* align-self: center; */
`;

export const LeftItemFooter = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
  }
`;

export const BlockItemIcon = styled.div`
  cursor: pointer;
  opacity: 30%;
  :hover {
    opacity: 70%;
  }
`;

export const ItemIcons = styled.div`
  display: flex;
  align-items: center;
  gap: 2rem;
  /* margin-bottom: 5rem; */
`;

export const RightitemFooter = styled.div`
  /* padding: 0 5rem; */
  @media (max-width: 768px) {
    display: none;
  }
`;

export const ChatSpan = styled.span`
  color: ${(props) => props.theme.colors.white.white100};
  ${(props) => props.theme.fonts.GothamBold14}
`;
export const FooterMain = styled.div`
  padding: 1.5rem 10rem 4rem 10rem;
  border-top: 0.15rem solid ${(props) => props.theme.stroke.stroke5};
  /* background-color: ${(props) => props.theme.colors.white.white20}; */
  margin-left: -3.99rem;
  margin-top: 5rem;

  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 1250px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5rem;
    padding-bottom: 15rem;
    text-align: center;
  }
`;
export const AsideITEMBlock = styled.div`
  display: flex;
  width: 35rem;
  flex-wrap: wrap;
  gap: 3rem;
  justify-content: space-between;
  /* padding: 0 5rem; */
  @media (max-width: 400px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
  }
`;

export const ToggleChat = styled.div`
  z-index: 1000;
  ${(props) => props.theme.mixins.dfcenter};
  background-color: ${(props) => props.theme.colors.white.white20};
  border-radius: 2rem 0 0 2rem;
  flex-direction: column;
  gap: 0.4rem;
  cursor: pointer;
  width: 5rem;
  height: 7rem;
  bottom: 40rem;
  right: 0rem;
  padding: 1rem;
  border: 0.3rem solid ${(props) => props.theme.stroke.stroke5};
  /* ${(props) => props.theme.colors.white.white20}; */
  &:last-child {
    position: fixed;
    /* right: 0; */
  }
  button.dots {
    width: 60px;
    height: 30px;
    border: none;
    background: transparent;
    position: relative;
    cursor: pointer;
    &:focus {
      outline: none;
    }

    &.dots {
      &:after,
      &:before,
      span {
        width: 10%;
        height: 20%;
        border-radius: 100px;
        position: absolute;
        left: 0px;
        background: ${(props) => props.theme.colors.white.white100};
        transform: rotate(0deg);
        transition: all 0.5s;
        left: 1%;
        right: 1%;
        margin: 0 auto;
      }

      &:after,
      &:before {
        content: "";
      }

      &:after {
        top: -10%;
        margin-top: 0px;
      }
      &:before {
        bottom: -10%;
        margin-bottom: 0px;
      }

      span {
        top: 50%;
        margin-top: -3px;
      }

      &.on {
        span {
          opacity: 0;
        }

        &:after {
          transform: rotate(135deg) translate(11px, -11px);
          width: 30%;
          height: 7%;
        }

        &:before {
          transform: rotate(225deg);
          bottom: 50%;
          width: 31%;
          height: 7%;
        }

        span {
          transform: rotate(135deg);
        }
      }
    }
  }
  @media (max-width: 1250px) {
    display: none;
  }
`;

export const WrapSmallWidth = styled.div<{ active?: boolean }>`
  position: fixed;
  width: 100%;
  height: max-content;
  /* background: purple; */
  bottom: ${(props) => (props.active ? "0" : "-45rem")};
  /* left: 20%; */
  display: none;
  justify-content: center;
  flex-direction: column;
  transition: 1s;
  z-index: 10000;
  text-align-last: center;
  div {
    svg {
      width: 3rem;
      height: 3rem;
      background: blue;
      border-radius: 50%;
      padding: 1rem;
      align-self: center;
      cursor: pointer;
      margin-bottom: 1rem;
      transform: rotate(${(props) => (props.active ? "-180deg" : "0")});
      transition: 0.3s;
    }
  }
  @media (max-width: 1250px) and (min-width: 768px) {
    display: flex;
  }
`;

export const ItemModal = styled(NavLink)<{ Active: boolean }>`
  display: flex;
  gap: 2rem;
  border-radius: 1rem;
  padding: 1rem 2rem 1rem 2rem;
  align-items: center;
  opacity: ${(props) => (props.Active ? "100%" : "0%")};
  transition: 5s;
  ${(props) => props.theme.fonts.GothamMenuMedium14} /* ${(props) =>
    props.theme.mixins.dfcenter} */
     &.active {
    color: ${(props) => props.theme.colors.white.white100};
    background: ${(props) => props.theme.stroke.stroke5};
  }
  svg {
    transition: all 0.15s;
    fill-opacity: 1 !important;

    width: 22px;
    height: 22px;
  }
`;

export const Item = styled(NavLink)<{ NonAct?: boolean }>`
  cursor: pointer;
  width: 3rem;
  height: 100%;
  text-align: center;
  padding: 0 2.5rem;
  transition: all 0.15s;
  flex-direction: column;
  /* transition: 4s; */
  ${(props) => props.theme.fonts.GothamMenuMedium14}
  ${(props) => props.theme.mixins.dfcenter}
	color: ${(props) => props.theme.colors.white.white70};
  /* gap: 0.7rem; */
  position: relative;
  svg > * {
    fill-opacity: 1 !important;
    width: 26px;
    height: 26px;
  }
  &.active {
    color: ${(props) => props.theme.colors.white.white100};
    background: ${(props) => props.theme.stroke.stroke5};

    svg > * {
      transition: all 0.15s;
      fill-opacity: 1 !important;
      width: 26px;
      height: 26px;
    }
  }
`;
// export const Wrapper = styled.div``;

export const WrapMobile = styled.div`
  position: fixed;
  max-height: 5rem;
  overflow: hidden;
  display: none;
  background: ${(props) => props.theme.gradients.bg};
  width: 101%;

  z-index: 100;
  bottom: 0rem;
  padding: 0 1rem;
  display: none;
  left: -0.2rem;
  touch-action: none;

  @media (max-width: 1250px) {
    display: flex;
  }
`;

export const WrapItem = styled.div`
  /* left: 20%; */
  height: 5rem;

  /* padding: 0 1rem; */
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
  align-items: center;
  touch-action: none;
`;

export const WrapExtrItem = styled.div`
  /* left: 20%; */
  display: none;
  height: 5rem;
  padding: 0 1rem;
  cursor: pointer;

  flex-wrap: wrap;
  align-items: center;

  @media (max-width: 639px) {
    display: flex;
  }
`;
export const WrapChatItem = styled.div`
  /* left: 20%; */
  display: flex;
  height: 5rem;
  padding: 0 1rem;
  cursor: pointer;

  align-items: center;
`;

export const BlockFullAside = styled.div<{ Active: boolean }>`
  width: 100%;
  height: 100vh;
  /* height: max-content; */
  background: ${(props) => props.theme.gradients.bg};
  display: ${(props) => (props.Active ? "flex" : "none")};
  flex-direction: column;
  padding: 3rem 3rem 3rem 3rem;
  gap: 1rem;
  overflow-x: hidden;
  overflow-y: hidden;
  background-repeat: no-repeat; /* Убираем повторение фона */
  background-size: cover; /* Фон занимает всю доступную площадь */
`;

export const BlockAsideModal = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
// export const ScrollWrapper = styled.div`
//   display: flex;
//   flex-direction: column;
//   overflow-y: auto;
//   overflow-x: hidden;
//   width: 100%;
//   max-height: calc(
//     100vh - 5rem
//   ); /* Ограничиваем высоту за вычетом высоты меню */
//   padding-bottom: 1rem; /* Небольшой отступ для визуального удобства */
// `;
