import styled from "styled-components";

export const Container = styled.div`
	width: 100%;
	height: fit-content;
	transition: all .3s;
	background: transparent;
	padding: 3rem;
	${props => props.theme.shadows.defaultShadow};
	stroke: ${props => props.theme.stroke.stroke5};
	border-radius: 2.5rem;
	display: flex;
	flex-direction: column;
	gap: 3rem;
`

export const PrivilegeLabel = styled.h3`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	height: fit-content;
	${props => props.theme.fonts.GothamMedium32};
	color: ${props => props.theme.colors.weapon.exceedingly_rare};
`

export const Price = styled.h2`
	${props => props.theme.fonts.GothamBold32}
	font-size: 3.6rem;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	height: fit-content;
`

export const Feature = styled.p`
	${props => props.theme.fonts.GothamRegular20};
	color: ${props => props.theme.colors.white.white100};
`