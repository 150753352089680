import { Container } from "components/Row/Styles";
import { Button } from "components/UI/Button/Styles";
import styled from "styled-components";

export const Wrapper = styled.div`
  ${Container} {
    position: relative;
    ${Button} {
      padding: 1.25rem 4.6rem;
    }
  }
`;

export const Grid = styled.div`
  display: grid;
  transition: all 0.3s;
  gap: 1.5rem;
  grid-template-columns: repeat(auto-fill, minmax(19.7rem, 1fr));
  @media (max-width: 1250px) {
    display: flex;
    flex-wrap: wrap;
    gap: 3rem;
    align-items: center;
    justify-content: center;
  }
  @media (max-width: 768px) {
    gap: 1rem;
  }
`;
