import { ITextarea } from 'interfaces/Textarea'

import { Label, Text, TextareaBlock } from './Styles'

export default function Textarea({ label, params, placeholder, ...props }: ITextarea) {
	return (
		<Label width={params?.width} headFont={params?.headFont}>
			<Text params={params}>{label}</Text>
			<TextareaBlock
				className='hideScroll'
				placeholder={placeholder}
				params={params}
				{...props}
			/>
		</Label>
	)
}
