import { IInput } from "interfaces/Input";

import {
  InputBlock,
  Label,
  Text,
  ComareWrapper,
  ComareInput,
  ComareMax,
  IconWrapper,
} from "./Styles";
import { useEffect, useRef } from "react";
import ReactInputMask from "react-input-mask";
import Image from "components/Image";

export default function Input({
  mask,
  maskChar,
  chat,
  label,
  params,
  placeholder,
  compare,
  compareText,
  compareSet,
  image,
  compareGet,
  pad,
  maxLength,
  ...props
}: IInput) {
  const handleBlur = () => {
    // Сбрасываем прокрутку страницы к началу после закрытия клавиатуры
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 300); // Немного задерживаем, чтобы клавиатура успела закрыться
  };

  return (
    <Label width={params?.width} color={params?.labelColor} chat={chat}>
      {label && <Text params={params}>{label}</Text>}
      {compare ? (
        <ComareWrapper>
          <ComareInput
            onChange={(e) => compareSet(e.target.value)}
            value={compareGet}
          />
          <ComareMax>|</ComareMax>
          <ComareMax>{compareText}</ComareMax>
        </ComareWrapper>
      ) : (
        <>
          {image && <IconWrapper>{image}</IconWrapper>}
          <InputBlock
            image={image !== undefined}
            pad={pad}
            maxLength={maxLength}
            onBlur={handleBlur}
            mask={mask}
            maskChar={maskChar}
            placeholder={placeholder}
            params={params}
            {...props}
          />
        </>
      )}
    </Label>
  );
}
