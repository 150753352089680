import { IInventoryWeaponCard } from "models/InventoryWeaponCard";

export interface SkinChangeItem extends IInventoryWeaponCard {
  paint_name: string;
  image: string;
  can_be_used: boolean;
  used: boolean;
  item_id: number;
  id: string;
  options?: ModalSettings;
  isHover?: boolean;
  paint_kit_id: number;
  prefab: string;
  // options: ModalSettings;
}
export interface ModalSettings {
  quality: SkinQuality;
  float: number[]; //! wear
  pattern: number;
  patterns: number[];
  nametag: string;
  keychain?: {
    inventory_id: number;
    keychain_id: number;
  };
  is_stattrack: boolean;
  classes: Classes | null;
  wear: number;
  stickers: { [key: string]: string };
}

export enum SkinQuality {
  BS = 0,
  WW = 1,
  FT = 2,
  MW = 3,
  FN = 4,
}

export interface CategoriesItems {
  id: number;
  class: string;
  class_en: string;
  class_ru: string;
  image: string;
  max_stickers?: number;
  used_by_ct: boolean;
  used_by_tt: boolean;
  amount: number;
}

export interface Categories {
  id: number;
  name: string;
  single: boolean;
  items: CategoriesItems[];
}

export interface DataInterface {
  items: CategoriesItems[];
  rarities: Rarities[];
}

interface Rarities {
  id: number;
  value: number;
  name: string;
  loc_key: string;
  loc_key_en: string;
}

export enum SkinChangerStatus {
  selectedHover = 1,
  notSelected = 1,
  selected = 3,
  dontHave = 4,
  nonSkin = 5,
  agentSelect = 6,
}
export enum UsedCategoryEnum {
  Skins = "Skins",
  Agent = "Agent",
}

export interface AgentChangeItem extends IInventoryWeaponCard {
  id: string;
  class: string;
  class_en: string;
  class_ru: string;
  desc: string;
  desc_en: string;
  desc_ru: string;
  max_stickers: number;
  used_by_ct: boolean;
  used_by_tt: boolean;
  used: boolean;
  isHover?: boolean;
  can_be_used: boolean;
  image: string;
}

export enum Classes {
  CT = "CT",
  TT = "TT",
  BOTH = "BOTH",
}

export interface ApplyParams {
  item_id: number;
  paint_kit_id?: number;
  classes: Classes;
  is_stattrack?: boolean;
  stickers?: {
    [key: string]: any;
  };
  wear?: number;
  pattern?: number;
  nametag?: string;
  keychain_id?: number;
}

export interface StickerRowProps {
  id: number;
  sticker_kit: number;
  image: string;
  name?: string;
  item_name?: string;
  item_name_en?: string;
  item_name_ru: string;
}

export interface Brelok {
  id: number;
  selected: false;
  keychain: {
    id: number;
    itemId: number;
    nameEn: string;
    nameRu: string;
  };
  image: string;
  used: boolean;
  can_be_used: boolean;
}
