import { Icons } from "assets/icons";
import {
  Wrapper,
  Active,
  InActiveGradient,
  InActiveGradientSkin,
  ActiveGradient,
  Container,
  Text,
  Team,
  CT,
  T,
} from "./Styles";

interface Props {
  status: any; //! тут прописываем тот же тип как и в /Users/zephire/Desktop/work/freezing-blood-frontend/src/models/InventoryWeaponCard.ts
  skind: classes;
  cl: boolean;
}
type classes = "CT" | "TT" | "BOTH" | null;
export default function HoverPanel({ status, skind, cl }: Props) {
  const generateActiveHoverPanel = () => {
    return (
      <Active>
        <ActiveGradient>
          <Container>
            <Icons.Settings />
            <Text>Настроить скин</Text>
          </Container>
        </ActiveGradient>
      </Active>
    );
  };

  const generateInActiveHoverPanel = () => {
    return (
      <Active>
        <InActiveGradient>
          <Container>
            <Icons.Settings />
            <Text>Настроить скин</Text>
          </Container>
        </InActiveGradient>
      </Active>
    );
  };

  const generateActivePanel = () => {
    return (
      <Active>
        <ActiveGradient>
          <Container>
            <Icons.Used />
            <Text>Используется</Text>
          </Container>
          <Team>
            {/* {cl === false ? <>fds</> : null} */}
            {skind === "CT" ? <CT></CT> : undefined}
            {skind === "TT" ? <T></T> : undefined}
            {skind === "BOTH" ? (
              <>
                <CT></CT>
                <T></T>
              </>
            ) : undefined}
          </Team>
        </ActiveGradient>
      </Active>
    );
  };

  const generateLockPanel = () => {
    return (
      <Active>
        <InActiveGradient>
          <Container>
            <Icons.Lock />
            <Text>
              Отсутствует в <br /> вашем инвентаре
            </Text>
          </Container>
        </InActiveGradient>
      </Active>
    );
  };
  const nonSkin = () => {
    return (
      <Active>
        <InActiveGradientSkin>
          <Container>
            <Icons.Close />
            <Text>Сбросить примененные скины</Text>
          </Container>
        </InActiveGradientSkin>
      </Active>
    );
  };
  const agentSkin = () => {
    return (
      <Active>
        <ActiveGradient>
          <Container>
            <Icons.Add />
            <Text>Установить агента</Text>
          </Container>
        </ActiveGradient>
      </Active>
    );
  };

  const getHover = (status: any) => {
    if (status == 1) return generateActiveHoverPanel();
    if (status == 2) return generateInActiveHoverPanel();
    if (status == 3) return generateActivePanel();
    if (status == 4) return generateLockPanel();
    if (status == 5) return nonSkin();
    if (status == 6) return agentSkin();
  };

  return <Wrapper>{getHover(status)}</Wrapper>;
}
