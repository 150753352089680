import ModalWrapper from "components/Templates/ModalWrapper";
import Input from "components/UI/Input";
import { BigText, Container, MediumText, StandartFlex } from "./styled";
import Button from "components/UI/Button";
import { useEffect, useState } from "react";
import { ClansServise } from "API/ClansServise";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ClansState from "pages/Clans/ClansState";
import { TinyIcons } from "assets/tiny-icons";
import { useActions } from "hooks/useActions";
import { useAppSelector } from "hooks/useAppSelector";

interface Props {
  isActive: boolean;
  setActive: React.SetStateAction<any>;
}
export default function CreateClansModal({ isActive, setActive }: Props) {
  const [name, setName] = useState<string>("");
  const [urlAvatar, setUrlAvatar] = useState<string>("");
  const navigate = useNavigate();
  const { fetchServers, setShowModal, setHistoryShowModal, setBalance } =
    useActions();

  const { isAuth, user, userData } = useAppSelector((state) => state.user);

  useEffect(() => {
    setName("");
    setUrlAvatar("");
  }, [isActive]);

  return (
    <ModalWrapper
      isActive={isActive}
      setActive={setActive}
      display={"block"}
      headContent={"Создать клан"}
      params={{ width: "50rem", height: "max-content" }}
    >
      <Container>
        <Input
          type="url"
          label="Ссылка на аватарку"
          placeholder="Введите ссылку"
          value={urlAvatar}
          params={{ width: "100%" }}
          onChange={(e) => setUrlAvatar(e.target.value)}
        />
        <Input
          type="url"
          label="Название клана"
          value={name}
          placeholder="Введите название клана"
          params={{ width: "100%" }}
          onChange={(e) => setName(e.target.value)}
        />
        <StandartFlex
          style={{
            width: "100%",
            justifyContent: "start",
            alignItems: "center",
          }}
        >
          <StandartFlex
            style={{ flexDirection: "column", width: "max-content" }}
          >
            <MediumText style={{ opacity: "60%" }}>Стоимость</MediumText>
            <StandartFlex style={{ width: "max-content" }}>
              <BigText>250</BigText>
              <TinyIcons.Rub />
            </StandartFlex>
          </StandartFlex>
          <Button
            height="5rem"
            variant="default"
            color="blue"
            params={{ width: "100%" }}
            onClick={async () => {
              const result: { id: number } | any =
                await ClansServise.CreateClan(name, urlAvatar);

              if (result.id) {
                ClansState.SetClans(await ClansServise.getClans());
                setActive(false);
                setBalance({
                  real: user.balance.real - 250,
                  virtual: user.balance.virtual,
                });

                navigate(`/clans/${result.id}`);
              } else if (result == "You have not enough balance") {
                setActive(false);

                toast.error("Недостаточно баланса");
              } else if (result == "avatar must be an URL address") {
                setActive(false);
                toast.error("Неверная ссылка аватарки");
              } else if (result == "User active clan member") {
                setActive(false);
                toast.error(
                  "Создать клан можно спустя 12 часов после поданной заявки на вступление в клан"
                );
              }
            }}
          >
            Создать клан
          </Button>
        </StandartFlex>
      </Container>
    </ModalWrapper>
  );
}
