import { TaskEnum, TastkStatsInfo } from "./BattlPass";

export interface ClansOne {
  avatar: string;
  capacity: number;
  clanTag: string;
  closed: boolean;
  expired: Date;
  id: number;
  members: number;
  name: string;
  xp: number;
}
export interface ClansSeasonOne {
  clan: {
    avatar: string;
    capacity: number;
    clanTag: string;
    closed: boolean;
    created: Date;
    expired: Date;
    id: number;
    members: number;
    name: string;
    xp: number | null;
  };
  xp: number;
  expired: Date;
}

export interface ClansArray {
  clans: ClansOne[];
  myClan: ClanInfo;
}
export enum ClanShopEnum {
  Slots = "slots",
  Extend = "extend",
  Avatar = "avatar",
  ClanTag = "clanTag",
  Points = "points",
  Name = "name",
}
export enum ClanRolesEnum {
  Invited = "invited",
  Requested = "requested",
  Member = "member",
  Helper = "helper",
  Creator = "creator",
}

export interface ClanInfo {
  avatar: string;
  capacity: number;
  clanTag: string;
  closed: boolean;
  expired: Date;
  id: number;
  members: number;
  name: string;
  xp: number;
  place: number;
  role: ClanRolesEnum;
}

export interface SeparateClan {
  avatar: string;
  capacity: number;
  clanTag: string;
  closed: boolean;
  expired: Date;
  id: number;
  members: MemberInClan[];
  name: string;
  xp: number;
  created: Date;
  requested: MemberInClan[];
}

export interface MemberInClan {
  id: number;
  role: ClanRolesEnum;
  user: {
    id: number;
    avatar: string;
    name: string;
    steamid64: string;
    xp: number;
  };
  xp: number;
}

export interface TaskInClan {
  expired: Date;
  tasks: TastkStatsInfo[];
}

export interface Shop {
  id: number;
  type: ClanShopEnum;
  price: number;
  value: number;
}

export enum ClanMemberAction {
  Kick = "kick",
  Update = "update",
}
