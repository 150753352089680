import { SkinChangerService } from "API/SkinChangerService";
import { StickerRowProps } from "components/Modal/SkinChanger/StickerRow/StickerRow";
import { makeAutoObservable, toJS } from "mobx";
import { IInventoryWeaponCard } from "models/InventoryWeaponCard";
import rarityConvector, { rarityConvectorToInt } from "utils/rarityConvector";

export interface SkinChangeItem extends IInventoryWeaponCard {
  description_tag_ru: string;
  image: string;
  can_be_used: boolean;
  used: boolean;
  item_id: number;
  id: string;
  options: ModalSettings;
  isHover?: boolean;
  paint_kit_id: number;
  prefab: string;
  // options: ModalSettings;
}
// enum Classess {
//   CT = "CT",
//   TT = "TT",
//   BOTH = "BOTH",
//   null = "null",
// }

enum SkinQuality {
  BS = 0,
  WW = 1,
  FT = 2,
  MW = 3,
  FN = 4,
}

interface ModalSettings {
  quality: SkinQuality;
  float: number[]; //! wear
  pattern: number;
  patterns: number[];
  nametag: string;
  is_stattrack: boolean;
  classes: classes | null;
  wear: number;
  stickers: { [key: string]: string };
}

type classes = "CT" | "TT" | "BOTH";

interface CategoriesItems {
  id: number;
  class: string;
  class_en: string;
  class_ru: string;
  image: string;
  max_stickers?: number;
  used_by_ct: boolean;
  used_by_tt: boolean;
  amount: number;
}

interface Categories {
  id: number;
  name: string;
  single: boolean;
  items: CategoriesItems[];
}

interface Rarities {
  id: number;
  value: number;
  name: string;
  loc_key: string;
  loc_key_en: string;
}

interface DataInterface {
  items: CategoriesItems[];
  rarities: Rarities[];
}

export interface ApplyParams {
  item_id: number;
  paint_kit_id?: number;
  classes?: "CT" | "TT" | "BOTH" | undefined | null;
  is_stattrack?: boolean;
  stickers?: {
    [key: string]: any;
  };
  wear?: number;
  pattern?: number;
  nametag?: string;
}

export enum SkinChangerStatus {
  selectedHover = 1,
  notSelected = 1,
  selected = 3,
  dontHave = 4,
  nonSkin = 5,
  agentSelect = 6,
}

export interface AgentChangeItem extends IInventoryWeaponCard {
  id: string;
  class: string;
  class_en: string;
  class_ru: string;
  desc: string;
  desc_en: string;
  desc_ru: string;
  max_stickers: number;
  used_by_ct: boolean;
  used_by_tt: boolean;
  used: boolean;
  isHover?: boolean;
  can_be_used: boolean;
  image: string;
}

class SkinChangerState {
  public selectGroup: null | number = null;
  public skins: SkinChangeItem[] = [];
  public agent: AgentChangeItem[] = [];

  public data: DataInterface = { items: [], rarities: [] };
  // public options: ModalSettings;
  public stickers: StickerRowProps[] = [];
  public isStickerSelect: boolean = false;
  public stickersCount: number = 0;
  public selectedStickers: { [key: string]: string } = {};
  public categories: Categories[] = [];
  public isModalShow: boolean = false;
  public search: string = "";
  public stickerSelectGroup = -1;
  public categoryID = -1;
  public skinID = -1;
  public stickersLoadPage = 1;
  public mas = [];

  public modalSettings: ModalSettings = {
    float: [0.00001, 0.00001],
    patterns: [0, 0],
    quality: SkinQuality.BS,
    nametag: "",
    is_stattrack: false,
    classes: null,
    wear: 0.00001,
    pattern: 0,
    stickers: { "0": "0" },
  };
  // getNextStickersBundle: any;

  constructor() {
    makeAutoObservable(this);
    this.getData();
    this.getCategories();
    // if (!this.categories) {
    // }
    this.resetSkins(this.skinID);
  }

  public resetSkins(index: number) {
    // this.init();
    // console.log(this.categories);
    if (
      this.skins[index]?.options !== null &&
      this.skins[index]?.options !== undefined
    ) {
      this.modalSettings = {
        pattern: this.skins[index].options.pattern || 0,
        float: [0.00001, this.skins[index].options.wear] || [0.00001, 0.00001],
        patterns: [0, this.skins[index].options.pattern] || [0, 0],
        quality: this.skins[index].options.quality || SkinQuality.BS,
        nametag: this.skins[index].options.nametag || "",
        is_stattrack: this.skins[index].options.is_stattrack || false,
        classes: this.skins[index].options.classes || null,
        wear: this.skins[index].options.wear || 0.00001,
        stickers: this.skins[index].options.stickers || { "0": "0" },
      };
    } else {
      this.modalSettings = {
        pattern: 0,
        float: [0.00001, 0.00001, 0.00001],
        patterns: [0, 0],
        quality: SkinQuality.BS,
        nametag: "",
        is_stattrack: false,
        classes: null,
        wear: 0.00001,
        stickers: { "0": "0" },
      };
    }
    // ("dsfsdffsd", this.skins);
    // this.modalSettings = {
    //   pattern: 0,
    //   float: [0, 0, 0],
    //   patterns: [0, 0],
    //   quality: SkinQuality.BS,
    //   nametag: "",
    //   is_stattrack: false,
    //   classes: null,
    //   wear: 0,
    //   stickers: { "0": "0" },
    // };
    // (1);
  }

  // public async loadSkinChangerSettings(index: number) {
  //   const skin = this.skins[index];
  //   try {
  //     const settingsData = await SkinChangerService.getSkins(skin.item_id);
  //     this.updateLocalSettings(settingsData);
  //   } catch (error: any) {
  //     console.error("Error loading SkinChanger settings:", error.message);
  //     // Обработайте ошибку по вашему усмотрению
  //   }
  // }
  // private updateLocalSettings(data: any) {
  //   if (data && data.option) {
  //     this.modalSettings = data.option;
  //   }
  // }

  // public async getStkin() {
  //   return this.skins;
  // }

  public async init(filter?: string, index?: number) {
    this.stickersLoadPage = 1;

    if (!this.data.items[0]) {
      this.getData();
    }
    if (!this.categories[0]) {
      this.getCategories();
    }

    // if (this.categories == undefined || this.categories == null) {

    // }
    this.skins = await SkinChangerService.getSkins(this.categoryID);
    this.gitModalQualites(String(this.modalSettings.wear));

    // ("skins = ", this.skins);
    this.resetSkins(this.skinID);

    if (index && this.skins[index].options.stickers.length) {
      const valuesArray = Object.values(this.skins[index].options.stickers).map(
        (value) => Number(value)
      );
      const result = await SkinChangerService.getStickerId(valuesArray);
      // console.log(result);
      if (result) {
        this.stickers = [...this.stickers, ...result];
      }
    }

    // this.skins.map((i) => {
    //   i.options.classes;
    // });

    const a = await SkinChangerService.getStickers(
      this.stickersLoadPage,
      filter
    ).then((result) => {
      if (result?.total) {
        this.stickersCount = result?.total;
      }

      if (result?.data) {
        return result.data;
      }

      return [];
    });

    this.stickers = [...a, ...this.stickers];
  }

  public async getNextStickersBundle() {
    this.stickersLoadPage++;

    const newBundle = await SkinChangerService.getStickers(
      this.stickersLoadPage,
      this.search
    ).then((result) => {
      if (result?.total) {
        this.stickersCount = result?.total;
      }

      if (result?.data) {
        return result.data;
      }

      return [];
    });
    // console.log(newBundle);

    this.stickers = [...this.stickers, ...newBundle];
  }

  public getRarity(index: number, it: boolean) {
    if (it === false) {
      const skin = this.skins[index];
      const rarity = skin.rarity;
      // console.log(skin);
      if (!skin.can_be_used) {
        return rarityConvector(999);
      }
      return rarityConvector(rarityConvectorToInt(rarity));
    } else {
      const agent = this.agent[index];
      const rarity = agent.rarity;

      if (!agent.can_be_used) {
        return rarityConvector(999);
      }
      return rarityConvector(rarityConvectorToInt(rarity));
    }
  }

  public getStatus(index: number, it: boolean) {
    if (it === false) {
      const skin = this.skins[index];

      if (skin.paint_kit_id === 1) {
        return {
          hover: true,
          status: SkinChangerStatus.nonSkin,
        };
      }

      if (skin.can_be_used && skin.used && skin.isHover) {
        return {
          hover: false,
          status: SkinChangerStatus.selectedHover,
        };
      }

      if (skin.options !== undefined && skin.options !== null) {
        if (skin.can_be_used && skin.used) {
          return {
            hover: false,
            status: SkinChangerStatus.selected,
          };
        }
      }

      if (skin.can_be_used && !skin.used) {
        return {
          hover: true,
          status: SkinChangerStatus.notSelected,
        };
      }

      if (!skin.can_be_used && !skin.used) {
        return {
          hover: true,
          status: SkinChangerStatus.dontHave,
        };
      }

      if (skin.can_be_used) {
        return null;
      }
    } else {
      const agent = this.agent[index];
      if (agent.can_be_used && agent.used) {
        return {
          hover: false,
          status: SkinChangerStatus.selected,
        };
      }
      if (agent.can_be_used && !agent.used) {
        return {
          hover: true,
          status: SkinChangerStatus.agentSelect,
        };
      }
      if (!agent.can_be_used && !agent.used) {
        return {
          hover: true,
          status: SkinChangerStatus.dontHave,
        };
      }
      if (agent.can_be_used) {
        return null;
      }
    }
  }

  public async onClick(index: number) {
    this.isStickerSelect = false;
    this.search = "";
    const skin = this.skins[index];

    if (skin && skin.options && skin.options.stickers) {
      const stickerKeys = Object.keys(skin.options.stickers).filter(
        (key) => !isNaN(Number(key))
      );

      this.init(undefined, index);
      if (stickerKeys.length > 0) {
        const valuesArray = Object.values(skin.options.stickers).map((value) =>
          Number(value)
        );
        const result = await SkinChangerService.getStickerId(valuesArray);
        if (result) {
          this.stickers = [...this.stickers, ...result];
        }
      }
    } else {
      this.selectedStickers = {};
    }

    this.skinID = index;
    if (skin.options !== null && skin.options !== undefined) {
      if (
        skin &&
        skin.options !== undefined &&
        skin.options !== null &&
        skin.options.nametag
      ) {
        this.modalSettings.nametag = skin.options.nametag;
      }
      if (
        skin &&
        skin.options !== undefined &&
        skin.options !== null &&
        skin.options.is_stattrack
      ) {
        this.modalSettings.is_stattrack = skin.options.is_stattrack;
      }
      // this.selectedStickers = skin.options.stickers;
      // this.selectedStickers = { 0: 7205 };
      // (this.selectedStickers);

      if (
        skin &&
        skin.options !== undefined &&
        skin.options !== null &&
        skin.options.stickers
      ) {
        const updatedStickers: { [key: string]: string } =
          skin.options.stickers;
        this.stickers.forEach((i) => {
          for (const key in updatedStickers) {
            if (updatedStickers.hasOwnProperty(key)) {
              let value = updatedStickers[key].toString();
              if (value === i.sticker_kit.toString()) {
                updatedStickers[key] = i.id.toString();
              }
            }
          }
        });
        // (updatedStickers);
        this.selectedStickers = updatedStickers;
      } else {
        this.selectedStickers = { [""]: "" };
      }

      if (
        skin &&
        skin.options !== undefined &&
        skin.options !== null &&
        skin.options.classes
      ) {
        this.modalSettings.classes = skin.options.classes as classes;
      }
      // (this.modalSettings.classes);
      if (
        skin &&
        skin.options !== undefined &&
        skin.options !== null &&
        skin.options.wear
      ) {
        this.modalSettings.float = [0.00001, skin.options.wear];

        const patternItem: number = skin.options.pattern;
        this.modalSettings.patterns = [0, patternItem];
        if (skin.options.wear >= 0 && skin.options.wear < 0.07) {
          this.modalSettings.quality = SkinQuality.FN;
        } else if (skin.options.wear >= 0.07 && skin.options.wear < 0.15) {
          this.modalSettings.quality = SkinQuality.MW;
        } else if (skin.options.wear >= 0.15 && skin.options.wear < 0.38) {
          this.modalSettings.quality = SkinQuality.FT;
        } else if (skin.options.wear >= 0.38 && skin.options.wear < 0.45) {
          this.modalSettings.quality = SkinQuality.WW;
        } else if (skin.options.wear >= 0.45 && skin.options.wear <= 1) {
          this.modalSettings.quality = SkinQuality.BS;
        }
      }
      // (skin.options.stickers);?
    } else {
      this.modalSettings.nametag = "";
      this.modalSettings.is_stattrack = false;
      this.modalSettings.float = [0.00001, 0.00001];
      this.modalSettings.patterns = [0, 0];
    }

    // (skin.options);
    if (skin.can_be_used && skin.paint_kit_id !== 1) {
      this.setModalShow(true);
    }
  }

  public setHover(index: number | null, status: boolean, it: boolean) {
    if (it === false) {
      if (index) {
        this.skins[index].isHover = status;
      }
    } else {
      if (index) {
        this.agent[index].isHover = status;
      }
    }
  }

  public setRenderList(status: boolean) {
    this.isStickerSelect = status;
  }

  public setModalShow(status: boolean) {
    this.isModalShow = status;
  }

  public async setSearch(query: string) {
    this.search = query;

    this.stickersLoadPage = 1;

    this.init(query);
  }

  public updateClasses(side: classes) {
    if (this.modalSettings.classes === "CT") {
      this.modalSettings.classes = "CT";
    }
    if (this.modalSettings.classes === "TT") {
      this.modalSettings.classes = "TT";
    }
    if (this.modalSettings.classes === "CT" && side === "TT") {
      this.modalSettings.classes = "BOTH";
      return;
    }
    if (this.modalSettings.classes === "TT" && side === "CT") {
      this.modalSettings.classes = "BOTH";

      return;
    }

    this.modalSettings.classes = side;
  }

  public gitModalQualites(wear: string) {
    if (wear >= "0" && wear < "0.07") {
      this.updateModalSettigs("quality", SkinQuality.FN);
      this.modalSettings.quality = SkinQuality.FN;
    } else if (wear >= "0.07" && wear < "0.15") {
      this.updateModalSettigs("quality", SkinQuality.MW);
      this.modalSettings.quality = SkinQuality.MW;
    } else if (wear >= "0.15" && wear < "0.38") {
      this.updateModalSettigs("quality", SkinQuality.FT);
      this.modalSettings.quality = SkinQuality.FT;
    } else if (wear >= "0.38" && wear < "0.45") {
      this.updateModalSettigs("quality", SkinQuality.WW);
      this.modalSettings.quality = SkinQuality.WW;
    } else if (wear >= "0.45" && wear <= "1") {
      this.updateModalSettigs("quality", SkinQuality.BS);
      this.modalSettings.quality = SkinQuality.BS;
    }
  }

  public updateModalSettigs(key: keyof typeof this.modalSettings, value: any) {
    if (!key) return;

    switch (key) {
      case "float":
        this.modalSettings[key] = value.map(
          (wear: string | number, index: number) => {
            if (index !== 1) return wear;

            if (wear >= "0" && wear < "0.07") {
              this.updateModalSettigs("quality", SkinQuality.FN);
              this.modalSettings.quality = SkinQuality.FN;
            } else if (wear >= "0.07" && wear < "0.15") {
              this.updateModalSettigs("quality", SkinQuality.MW);
              this.modalSettings.quality = SkinQuality.MW;
            } else if (wear >= "0.15" && wear < "0.38") {
              this.updateModalSettigs("quality", SkinQuality.FT);
              this.modalSettings.quality = SkinQuality.FT;
            } else if (wear >= "0.38" && wear < "0.45") {
              this.updateModalSettigs("quality", SkinQuality.WW);
              this.modalSettings.quality = SkinQuality.WW;
            } else if (wear >= "0.45" && wear <= "1") {
              this.updateModalSettigs("quality", SkinQuality.BS);
              this.modalSettings.quality = SkinQuality.BS;
            }

            return wear;
          }
        );
        break;

      case "patterns":
        this.modalSettings[key] = value;
        break;

      case "quality":
        this.modalSettings[key] = value;
        break;

      case "nametag":
        this.modalSettings[key] = value + "";
        break;

      case "is_stattrack":
        this.modalSettings[key] = !!value;
        break;

      case "classes":
        if (value !== null) {
          this.modalSettings[key] = value;
        }
        break;
    }
  }

  public getQualitiesList() {
    return (Object.keys(SkinQuality) as Array<keyof typeof SkinQuality>)
      .filter((item) => {
        if (typeof +item == "number" && +item >= 0) return false;

        return true;
      })
      .map((item) => {
        return {
          name: item,
          minWare: this.getMinWare(item),
        };
      });
  }

  public getMinWare(name: keyof typeof SkinQuality) {
    switch (name) {
      case "BS":
        return 0.45;
      case "WW":
        return 0.38;
      case "FT":
        return 0.15;
      case "MW":
        return 0.07;
      case "FN":
        return 0.00001;

      default:
        return 0.00001;
    }
  }

  public getMaxWare(name: keyof typeof SkinQuality) {
    switch (name) {
      case "BS":
        return 1;
      case "WW":
        return 0.45;
      case "FT":
        return 0.38;
      case "MW":
        return 0.15;
      case "FN":
        return 0.07;

      default:
        return 1;
    }
  }

  public selectStickerGroup = (index: number = -1) => {
    // console.log("this.selectedStickers", this.selectedStickers);
    this.search = "";
    this.stickerSelectGroup = index;
    if (this.isStickerSelect) {
      this.stickerSelectGroup = -1;
      this.setRenderList(false);
    } else {
      this.setRenderList(true);
    }
  };

  public selectCategories = (index: number = -1) => {
    this.categoryID = index;

    this.init();
  };

  public get maxStickers() {
    const skin = toJS(this.data).items.filter(
      (skin) => skin.id === this.categoryID
    )[0];

    if (skin && skin.max_stickers) {
      // (skin);
      return skin.max_stickers;
    }

    return 0;
  }

  public get modalSkinName() {
    const gun =
      toJS(this.data).items.filter((skin) => skin.id === this.categoryID)[0]
        ?.class_en || "";
    const skin = this.skins[this.skinID]?.description_tag_ru || "";

    // ("SKINS ID:", this.skins[this.skinID]);
    // (skin);
    return `${gun} | ${skin}`;
  }

  public get modalSkinImage() {
    return this.skins[this.skinID]?.image;
  }

  public addSticker = (stickerId: string) => {
    // if (this.skins[index].options.stickers);

    this.selectedStickers[this.stickerSelectGroup] = stickerId;

    this.setRenderList(false);
    // this.selectedStickers = this.modalSettings.stickers;
  };

  public async apply(IT?: boolean, id?: number, usedAgent?: boolean) {
    if (IT === false) {
      let clas: classes = "CT";
      if (this.modalSettings.classes === null) {
        clas = "CT";
      } else {
        clas = this.modalSettings.classes;
      }
      const params: ApplyParams = {
        item_id: this.categoryID,
        paint_kit_id: this.skins[this.skinID].paint_kit_id || 0,
        is_stattrack: this.modalSettings["is_stattrack"],
        classes: clas,
      };

      if (this.modalSettings.nametag) {
        params["nametag"] = this.modalSettings.nametag;
      }

      if (this.modalSettings.classes) {
        params["classes"] = this.modalSettings.classes;
      }

      if (this.modalSettings.float) {
        params["wear"] = this.modalSettings.float[1];
      }

      if (this.modalSettings.patterns) {
        params["pattern"] = this.modalSettings.patterns[1];
      }

      if (this.stickers) {
        params["stickers"] = this.selectedStickers;
      }

      // {
      //   item_id: this.categoryID, //gun
      //   paint_kit_id: this.skins[this.skinID].id,
      //   classes: 'BOTH',
      //   is_stattrack: false,
      //   stickers: {"0": 1500},
      //   wear: 0,
      //   pattern: 0,
      //   nametag: '',
      // }

      await SkinChangerService.apply(params);
      this.init();

      this.onClick(this.categoryID);
      // this.resetSkins(this.skinID);
    } else {
      if (id !== undefined) {
        const params: ApplyParams = {
          item_id: id,
          paint_kit_id: 2,
          is_stattrack: false,
          classes: "TT",
        };
        if (!usedAgent) {
          await SkinChangerService.applyAgent(params);

          this.onClick(this.categoryID);
        }
      } else {
        const params: ApplyParams = {
          item_id: -1,
          paint_kit_id: 2,
          is_stattrack: false,
          classes: "TT",
        };
        if (!usedAgent) {
          await SkinChangerService.applyAgent(params);

          this.onClick(this.categoryID);
        }
      }
      // SkinChangerService.getAgents();

      // {
      //   item_id: this.categoryID, //gun
      //   paint_kit_id: this.skins[this.skinID].id,
      //   classes: 'BOTH',
      //   is_stattrack: false,
      //   stickers: {"0": 1500},
      //   wear: 0,
      //   pattern: 0,
      //   nametag: '',
      // }
    }
  }

  public async getAgents() {
    this.agent = await SkinChangerService.getAgents();
  }

  private async getData() {
    this.data = await SkinChangerService.getData();
  }

  private async getCategories() {
    this.categories = await SkinChangerService.getCategories();
  }
}

export default new SkinChangerState();

// export enum Classes {
//   CT = "CT",
//   TT = "TT",
//   BOTH = "BOTH",
// }
