export default function EarnedBP({
  ...props
}: React.HTMLAttributes<SVGElement>) {
  return (
    <svg
      {...props}
      width="15"
      height="12"
      viewBox="0 0 15 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        {...props}
        d="M1.16406 6.00016L5.33073 10.1668L13.6641 1.8335"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
