import { Wrapper } from "./styled";

interface Props {
  text?: string;
}

export default function Counter({ text }: Props) {
  if (!text) return <></>;

  return <Wrapper>{text}</Wrapper>;
}
