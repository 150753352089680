import FormController from "components/FormController"
import InputBlock from "components/UI/Input"
import SelectBlock from "components/UI/Select"
import CheckboxBlock from "components/UI/Checkbox"
import TextareaBlock from "components/UI/Textarea"

const Input = FormController(InputBlock)
const Select = FormController(SelectBlock)
const Checkbox = FormController(CheckboxBlock)
const Textarea = FormController(TextareaBlock)

export const Controllers = { Input, Select, Checkbox, Textarea }