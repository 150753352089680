import { TinyIcons } from "assets/tiny-icons";
import Image from "components/Image";
import LabelAvatar from "components/Labels/LabelAvatar";
import Row from "components/Row";
import ServerPlayers from "components/ServerPlayers";
import ActionButton from "components/UI/ActionButton";
import { CurrentServerContext } from "context/currentServer";
import { Player, PlayerAndBlock } from "interfaces/CategoryMap";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useTheme } from "styled-components";
import Modal from "../Modal";

import {
  Banner,
  Body,
  BodyItem,
  Container,
  Head,
  Item,
  ServerConnect,
  ServerInfoHead,
  ShortInfo,
  Stats,
  Team,
} from "./Styles";

interface Props {
  isActive: boolean;
  setActive: React.SetStateAction<any>;
  player?: Player[];
  play?: PlayerAndBlock;
  id?: number;
}

export default function DashboardServerModal({
  isActive,
  setActive,
  player,
  play,
}: Props) {
  const currentServer = useContext(CurrentServerContext);

  // const [playUser, setPlayUser] = useState<number>(0);

  // useEffect(() => {
  //   let i = 0;
  //   play?.servers.forEach((item) => {
  //     if (item.server.id == Number(currentServer.id)) {
  //       i++;
  //     }
  //   });
  //   setPlayUser(i);
  //   i = 0;
  // }, [play, currentServer]);

  function getTimeDifference(startTime: string) {
    let startDateInUTC = new Date(startTime);
    startDateInUTC.setHours(startDateInUTC.getHours() - 3);
    const currentTime = new Date();
    let a = currentTime.getTime() - startDateInUTC.getTime();
    let hours = Math.floor(a / (1000 * 60 * 60));
    let minutes = Math.floor((a % (1000 * 60 * 60)) / (1000 * 60));

    if (hours > 0) {
      return `${hours} час ${minutes} мин`;
    } else {
      return `${minutes} мин`;
    }
  }

  const Theme = useTheme();
  const navigate = useNavigate();

  const [copied, setCopied] = useState(false);
  const timeoutRef = useRef<number | null>(null);

  const handleCopy = (text: string) => {
    navigator.clipboard.writeText(text);
    setCopied(true);
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = window.setTimeout(() => {
      setCopied(false);
    }, 1000);
  };

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  return (
    <Modal isActive={isActive} setActive={setActive}>
      <Container>
        <Head>
          <Banner>
            <div className="bg"></div>
            <Image
              src={currentServer.image || ""}
              alt="server"
              className="map"
            />
            <Team style={{ marginLeft: "2rem" }}>
              <Image src="/static/images/CT.png" alt="Team" />
              Спецназ
            </Team>
            <Stats></Stats>
            <Team>
              <Image src="/static/images/T.png" alt="Team" />
              Террористы
            </Team>
          </Banner>
          <ShortInfo>
            <ServerPlayers
              players={currentServer.players}
              max_players={currentServer.max_players || 0}
            />
            <Row gap="5.5rem">
              <Row>
                <ActionButton
                  onClick={() =>
                    window.location.replace(
                      `steam://connect/${currentServer.ip}:${currentServer.port}`
                    )
                  }
                >
                  <TinyIcons.Play />
                </ActionButton>
                <ActionButton
                  onClick={() => {
                    handleCopy(
                      `connect ${currentServer.ip}:${currentServer.port}`
                    );
                    toast("Адрес сервера скопирован");
                  }}
                >
                  <TinyIcons.Copy />
                </ActionButton>
              </Row>
            </Row>
            <ServerConnect>
              IP: {currentServer.ip}:{currentServer.port}
            </ServerConnect>
          </ShortInfo>
          <ServerInfoHead>
            <Item>Игрок</Item>
            <Row gap="5rem">
              <Item>Время</Item>
            </Row>
          </ServerInfoHead>
        </Head>
        <Body className="customScroll">
          {player?.map((item, index) => (
            <BodyItem key={index}>
              <Item
                act={true}
                onClick={() => navigate(`/profile/${item.steamid64}`)}
              >
                <LabelAvatar
                  params={{
                    avatarSize: "small",
                    font: Theme.fonts.GothamRegular16,
                  }}
                  src={item.avatar || undefined}
                >
                  {item.name || "Игрок"}
                </LabelAvatar>
              </Item>
              <Row gap="5rem">
                <Item>{getTimeDifference(item.played_at)}</Item>
              </Row>
            </BodyItem>
          ))}
          {currentServer.teams?.terrorists.players.map((item, index) => (
            <BodyItem key={index}>
              <Item>
                <LabelAvatar
                  params={{
                    avatarSize: "small",
                    font: Theme.fonts.GothamRegular16,
                  }}
                >
                  {item.nickname}
                </LabelAvatar>
              </Item>
              <Row gap="5rem">
                <Item>{item.time_playing}</Item>
                <Item>{item.kill_deaths}</Item>
                <Item>{item.score}</Item>
              </Row>
            </BodyItem>
          ))}
        </Body>
      </Container>
    </Modal>
  );
}
