import { Container, Info } from "components/Cards/InventoryWeaponCard/Styles";
import styled from "styled-components";

const CardWidth = "17rem";
const CardHeight = "20rem";

export const Card = styled.div`
  width: ${CardWidth};
  height: ${CardHeight};
  background: rgba(47, 11, 26, 0.3);
  border: 3px solid rgba(255, 255, 255, 0.05);
  border-radius: 15px;
`;
export const WrapperItem = styled.div`
  display: flex;
  gap: 2rem;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const Column = styled.div`
  overflow-y: overlay !important;
  display: flex;
  flex-wrap: wrap;

  max-width: 72rem;
  /* max-height: 42vh; */
  transition: all 0.3s;
  gap: 1rem;
  /* grid-template-columns: repeat(auto-fill, minmax(18rem, 18.6rem)); */
  /* grid-auto-rows: 13.6rem; */
  /* grid-template-rows: repeat(auto-fill, minmax(13.6rem, 13.6rem)); */
  max-height: 41rem;
  justify-content: center;
  overflow-y: scroll;
  overflow-x: hidden;
  @media (max-height: 768px) {
    height: 20vh;
  }

  ${Container} {
    width: ${CardWidth};
    height: ${CardHeight};
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 80%;
    }

    ${Info} {
      display: none;
    }
  }
`;
export const Row = styled.div`
  overflow-y: overlay !important;
  display: grid;
  width: 100%;
  height: 13.6rem;
  transition: all 0.3s;
  gap: 0.8rem;
  grid-template-columns: repeat(auto-fill, minmax(18rem, 18.6rem));
  overflow-y: scroll;

  ${Container} {
    width: ${CardWidth};
    height: ${CardHeight};
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 80%;
    }

    ${Info} {
      display: none;
    }
  }
`;
export const Text = styled.span`
  display: block;
  ${(props) => props.theme.fonts.GothamBold24};
  color: ${(props) => props.theme.colors.white.white100};
  text-align: center;
`;

export const TextTwo = styled.span`
  display: block;
  ${(props) => props.theme.fonts.GothamRegular16};
  color: ${(props) => props.theme.colors.white.white70};
  text-align: center;
  margin-bottom: 2rem;
`;
