import { Container, Title } from "./Styles";

interface Props {
  title: string;
  children: any;
}

export default function WithTitle({ title, children }: Props) {
  return (
    <Container>
      <Title>{title}</Title>
      {children}
    </Container>
  );
}
