import Layout from "components/Layout";
import ProfileLayout from "components/ProfileLayout";
import SubHeader from "components/SubHeader";

export default function Notifications() {
  const notifications = {
    Сегодня: [
      {
        id: 123,
        isReaded: false,
        time: "11:10",
        name: "title",
        body: "hello world!",
      },
      {
        id: 1231,
        isReaded: false,
        time: "17:10",
        name: "titl4f1e",
        body: "hello123 wor123ld!",
      },
    ],
    Вчера: [
      {
        id: 1123,
        isReaded: true,
        time: "13:10",
        name: "dawdad",
        body: "hello123123!",
      },
    ],
  };

  return (
    <Layout
      leftElement={<div />}
      headerElement={<SubHeader>Уведомления</SubHeader>}
    >
      <ProfileLayout>
        {/* <ListInfo clearCallback={() => {}} list={notifications} type={ListType.natification} /> */}
      </ProfileLayout>
    </Layout>
  );
}
