export default function ExclamationMark() {
  return (
    <svg
      width="4"
      height="20"
      viewBox="0 0 4 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        y="20"
        width="4"
        height="4"
        rx="2"
        transform="rotate(-90 0 20)"
        fill="white"
      />
      <rect
        y="14"
        width="14"
        height="4"
        rx="2"
        transform="rotate(-90 0 14)"
        fill="white"
      />
    </svg>
  );
}
