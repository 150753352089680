

export default function Shop() {
	return (
		<svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M15.2748 19.3526C13.6637 19.3526 12.0837 19.3839 10.4726 19.3526C8.58278 19.3213 7.68432 18.5707 7.18862 16.7255C6.01132 12.5973 4.77207 8.46911 3.59478 4.34089C3.28496 3.24629 2.85122 2.43316 1.48804 2.68335C0.77547 2.80845 0.0319174 2.68335 0.000935987 1.77639C-0.0300454 0.869437 0.713508 0.806888 1.42608 0.775613C3.99753 0.68179 4.64814 1.15091 5.32973 3.71541C6.13525 6.68647 6.13525 6.68647 9.17142 6.68647C14.1594 6.68647 19.1784 6.68647 24.1664 6.68647C26.0253 6.68647 26.2731 7.03049 25.7774 8.81313C25.0339 11.5027 24.3213 14.2236 23.5778 16.9132C23.0821 18.6958 22.1836 19.3526 20.3247 19.3526C18.6827 19.3526 16.9787 19.3526 15.2748 19.3526Z" fill="white" fillOpacity="0.7" />
			<path d="M20.3867 25.2323C19.3333 25.2636 18.3729 24.3879 18.3729 23.3245C18.3419 22.2925 19.2713 21.323 20.3247 21.3543C21.471 21.3855 22.1216 22.0736 22.2765 23.1682C22.3695 24.2002 21.44 25.201 20.3867 25.2323Z" fill="white" fillOpacity="0.7" />
			<path d="M10.5966 21.3542C11.6809 21.3542 12.5484 22.2299 12.5484 23.3245C12.5484 24.3879 11.6189 25.2948 10.5656 25.2323C9.45025 25.1384 8.76866 24.513 8.7067 23.3558C8.64474 22.2612 9.5432 21.3542 10.5966 21.3542Z" fill="white" fillOpacity="0.7" />
		</svg>
	)
}
