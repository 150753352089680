import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3rem;
`;

export const Title = styled.span`
  ${({ theme }) => theme.fonts.GothamMedium20};
  color: ${({ theme }) => theme.colors.white.white100};
  width: 100%;
  display: block;
  text-align: right;
  white-space: nowrap;
`;

export const Item = styled.span`
  ${({ theme }) => theme.fonts.GothamRegular20};
  color: ${({ theme }) => theme.colors.white.white100};
  width: 100%;
  display: block;
  text-align: left;
  white-space: nowrap;
`;

export const Admin = styled(Item)`
  ${({ theme }) => theme.mixins.blueColor};
  cursor: pointer;
`;

export const Red = styled(Item)<{ nonBan?: boolean }>`
  color: ${(props) =>
    props.nonBan
      ? props.theme.colors.green.accent
      : props.theme.colors.red.accent};
  white-space: break-spaces;
`;
