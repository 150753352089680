import { token, url, urlSC } from "API";
import axios from "axios";

export class BanServise {
  static async getBans(
    page: number,
    type: string,
    server_id?: number,
    name?: string,
    steamid?: string
  ) {
    let link = url + `/blocks?page=${page}&perPage=15&type=${type}`;
    if (name) {
      link += `&name=${name}`;
    }
    if (steamid) {
      link += `&steamid64=${steamid}`;
    }
    if (server_id) {
      link += `&server_id=${server_id}`;
    }
    try {
      const headers = token ? { Authorization: "Bearer " + token } : {};
      const response = await axios.get(link, {
        withCredentials: true,
        headers: headers,
      });
      return response.data;
    } catch (error) {}
  }
  static async ChangeBan(id?: number, state?: string) {
    try {
      const headers = token ? { Authorization: "Bearer " + token } : {};
      const response = await axios.patch(
        urlSC + "/admin/block/change",
        {
          id: id,
          state: state,
        },
        {
          headers: headers,
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error) {
      // console.log(error);
    }
  }
}
