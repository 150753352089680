import { observer } from "mobx-react-lite";
import RangeSlider from "react-range-slider-input";
import "react-range-slider-input/dist/style.css";
import { Container } from "./Styles";

interface Props {
  min: number;
  max: number;
  step: number;
  isSingle: boolean;
  value: number[];
  setValue: (values: number[]) => void;
}

function RangeSliderComponent({
  min,
  max,
  step,
  isSingle,
  value,
  setValue,
}: Props) {
  return (
    <Container>
      <RangeSlider
        className="single-thumb"
        min={min}
        max={max}
        step={step}
        thumbsDisabled={[isSingle, false]}
        rangeSlideDisabled={isSingle}
        value={value}
        onInput={setValue}
      />
    </Container>
  );
}

export default observer(RangeSliderComponent);
