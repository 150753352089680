export const motion = {
  default: { type: "spring", stiffness: 400, damping: 30, mass: 1 },
  chat: { type: "spring", stiffness: 500, damping: 20, mass: 1 },
  openChat: {
    show: {
      opacity: 1,
      x: 0,
      y: 0,
    },
    hide: {
      opacity: 0,
      x: 20,
      y: 0
    }
  },
}