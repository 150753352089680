import React from "react";

export default function LayoutSecond({ ...props }: React.HTMLAttributes<SVGElement>) {
	return (
		<svg {...props} width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect width="22" height="5" rx="2" fill="white" />
			<rect y="8" width="22" height="5" rx="2" fill="white" />
			<rect y="16" width="22" height="5" rx="2" fill="white" />
		</svg>
	)
}
