// import { SocketProvider } from 'API/SocketProvider'
// import _, { remove } from 'lodash'
import { Trade, TradeInventory } from "interfaces/Trade";
import { makeAutoObservable } from "mobx";
// import { IInventoryWeaponCard } from 'models/InventoryWeaponCard'
// import { mySteamId } from 'utils/IsYou'
// import { ModalType } from './Trade'

// export type TradeModalType = "none" | "error" | "cancel" | "success"

class TradeState {
  constructor() {
    makeAutoObservable(this);
  }

  private _defaultConfirmData = {
    avatar: "",
    myInventory: [],
    name: "",
    receiver: "",
    status: null,
    time: "",
    uuid: "",
  };

  public isConfirmed = false;
  public confirmData: TradeInventory = { ...this._defaultConfirmData };
  public timeRemining = 0;
  public statusCansel = false;
  public uuid = [""];

  private showModal = false;

  tradeData: Trade = {
    myItems: [],
    receivedItems: [],
    myInventory: [],
    avatar: "",
    name: "loading",
    uuid: "",
    time: "",
    sender: "",
    receiver: "",
    status: null,
  };

  isSecondModal = false;
  isSecondType = "";

  setShowModal(status: boolean) {
    this.showModal = status;
  }

  setTradeData(data: Trade) {
    this.tradeData = data;

    // console.log("new Data", { data });
  }

  setUUID(id: string) {
    this.uuid.push(id);
  }

  setStatusCansel(status: boolean) {
    this.statusCansel = status;
    // console.log(this.statusCansel);
  }

  setSecondModal(status: boolean) {
    this.isSecondModal = status;
  }

  setSecondType(type: string) {
    this.isSecondType = type;
    // console.log(this.isSecondType);
  }

  setTimeRemining(sec: number) {
    this.timeRemining = sec;
  }

  setConfirmed(status: boolean, data: TradeInventory | null) {
    this.isConfirmed = status;
    if (data) {
      // Проверить входящую инфу !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

      this.confirmData = data;
    } else {
      this.confirmData = { ...this._defaultConfirmData };
    }
  }

  get isShowModal() {
    return this.showModal;
  }
}

export default new TradeState();
