import { useId } from "react";
import { Container, Label, ToggleBlock } from "./Styles";

interface Props {
  label: string;
  value?: any;
  checked?: boolean;
  onChange?: (value: any) => any;
  callback?: (value: any) => any;
  onClick?: any;
  params?: {
    position?: "left" | "right";
    alignSelf?: string;
  };
}

export default function Toggle({
  label,
  value,
  onChange,
  callback,
  checked,
  onClick,
  params = { position: "right" },
}: Props) {
  const id = useId();

  function handleToggle(value: boolean) {
    onChange && onChange(value);
    callback && callback(value);
  }

  return (
    <Container style={{ alignSelf: params.alignSelf && params.alignSelf }}>
      <Label htmlFor={id}>
        {params?.position === "left" && (
          <>
            <input
              type="checkbox"
              checked={checked}
              id={id}
              value={value}
              onClick={onClick}
              // on-value={checked}
              // alt-label-position={checked}
              onChange={(event) => handleToggle(event.target.checked)}
            />
            <ToggleBlock id={id} />
          </>
        )}
        {label}
        {params?.position === "right" && (
          <>
            <input
              type="checkbox"
              id={id}
              onClick={onClick}
              checked={checked}
              value={value}
              // on-value={checked}
              // alt-label-position={checked}
              onChange={(event) => handleToggle(event.target.checked)}
            />
            <ToggleBlock id={id} />
          </>
        )}
      </Label>
    </Container>
  );
}
