

export default function MessageDots({ onClick }) {
	return (
		<svg width="3" height="14" viewBox="0 0 3 14" onClick={onClick} fill="none" xmlns="http://www.w3.org/2000/svg">
			<circle cx="1.5" cy="2" r="1.5" fill="white" fillOpacity="0.1" />
			<circle cx="1.5" cy="7" r="1.5" fill="white" fillOpacity="0.1" />
			<circle cx="1.5" cy="12" r="1.5" fill="white" fillOpacity="0.1" />
		</svg>
	)
}
