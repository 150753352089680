import { TinyIcons } from "assets/tiny-icons";

import { calculatePercent } from "utils/calculatePercent";
import { Wrapper } from "./Styles";

interface Props {
  players?: number;
  max_players: number;
}

export default function ServerPlayers({ players, max_players }: Props) {
  return (
    <Wrapper percent={calculatePercent(players, max_players)}>
      <TinyIcons.Players />
      <div>
        <span className="highlighted">{players}</span>/{max_players}
      </div>
    </Wrapper>
  );
}
