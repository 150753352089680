import styled from "styled-components";

export const Container = styled.div`
	width: 100%;
	border-radius: 2.5rem;
	background: transparent;
	${({ theme }) => theme.mixins.defaultShadow};
	padding: 3rem;
	display: flex;
	flex-direction: column;
	gap: 3rem;
	height: fit-content;
`

export const Title = styled.h1`
	width: 100%;
	text-align: center;
	${({ theme }) => theme.fonts.GothamBold24};
	color: ${({ theme }) => theme.colors.white.white100};
`