import Bans from "./Bans";
import Home from "./Home";
import Info from "./Info";
import LayoutSixLines from "./LayoutSixLines";
import LayoutThreeLines from "./LayoutThreeLines";
import MenuCircles from "./MenuCircles";
import Rating from "./Rating";
import Shop from "./Shop";
import Support from "./Support";
import Trade from "./Trade";
import Work from "./Work";
import Steam from "./Steam";
import VK from "./VK";
import Discord from "./Discord";
import Pocket from "./Pocket";
import Notification from "./Notification";
import Buy from "./Buy";
import Sell from "./Sell";
import OnSale from "./OnSale";
import BansLayout from "./BansLayout";
import Mutes from "./Mutes";
import Applications from "./Applications";
import Wallet from "./Wallet";
import Reload from "./Reload";
import Close from "./Close";
import ArrowBack from "./ArrowBack";
import RemoveFile from "./RemoveFile";
import Swap from "./Swap";
import Check from "./Check";
import Cancle from "./Cancle";
import MarketHistory from "./MarketHistory";
import Used from "./Used";
import Settings from "./Settings";
import Lock from "./Lock";
import StatTrack from "./StatTrack";
import Add from "./Add";
import dropDownArrow from "./dropDownArrow";
import Case from "./Case";
import LifeCheck from "./LifeCheck";
import ArrowRightCase from "./ArrowRightCase";
import ArrowLeftCase from "./ArrowLeftCase";
import Sold from "./Sold";
import Delete from "./Delete";
import Contract from "./Contract";
import Roulette from "./Roulette";
import ViShop from "./ViShop";
import Cart from "./Cart";
import Telegram from "./Telegram";
import Youtube from "./Youtube";
import Banc from "./Banc";
import IconHref from "./IconHref";
import Cross from "./Cross";
import Kills from "./Kills";
import Death from "./Death";
import Time from "./Time";
import Ellipse from "./Ellipse";
import BattlPass from "./BattlPass";
import EarnedBP from "./EarnedBP";
import Quechen from "./Quechen";
import Detach from "./Detach";
import Clans from "./Clans";
import Brelok from "./Brelok";

export const Icons = {
  Brelok,
  Clans,
  Detach,
  Quechen,
  EarnedBP,
  BattlPass,
  Ellipse,
  Time,
  Death,
  Kills,
  Cross,
  IconHref,
  Banc,
  Youtube,
  Telegram,
  Cart,
  ViShop,
  Roulette,
  Sold,
  LifeCheck,
  Bans,
  Home,
  Info,
  LayoutSixLines,
  LayoutThreeLines,
  MenuCircles,
  Rating,
  Shop,
  Support,
  Trade,
  Work,
  Steam,
  VK,
  Discord,
  Pocket,
  Notification,
  Buy,
  Sell,
  OnSale,
  BansLayout,
  Mutes,
  Applications,
  Wallet,
  Reload,
  Close,
  ArrowBack,
  RemoveFile,
  Swap,
  Check,
  Cancle,
  MarketHistory,
  Used,
  Settings,
  Lock,
  StatTrack,
  Add,
  dropDownArrow,
  Case,
  ArrowRightCase,
  ArrowLeftCase,
  Delete,
  Contract,
};
