import styled from "styled-components";

export const WrappEarn = styled.div`
  display: flex;
  justify-content: center;
`;

export const BlockEarn = styled.div`
  min-width: 21.5rem;
  max-width: 21.5rem;
  display: flex;
  align-items: center;
`;

export const Test = styled.div`
  display: none;
  background-image: url("static/images/BgLevelComplite.png");
`;

export const TextClip = styled.span<{ earn: boolean }>`
  background-image: ${(props) =>
    props.earn
      ? `url("static/images/BGLevelComplite.png")`
      : `url("static/images/BgLevelNoComplite.png")`};
  width: 4.4rem;
  height: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;

  ${(props) => props.theme.fonts.GothamBold20}
`;

export const LineBlue = styled.div<{ colorBlue: boolean }>`
  width: 79.6%;
  height: 2px;
  background-color: ${(props) =>
    props.colorBlue ? `#50adf0` : props.theme.colors.white.white10};
`;
/* Star 4 */
