import { AdminServise } from "API/AdminServise";
import { Icons } from "assets/icons";
import { TinyIcons } from "assets/tiny-icons";
import Avatar from "components/Avatar";
import Layout from "components/Layout";
import { Modals } from "components/Modal";
import SubHeader from "components/SubHeader";
import UIKIT from "components/UI";
import Button from "components/UI/Button";
import Input from "components/UI/Input";
import { useActions } from "hooks/useActions";
import { useAppSelector } from "hooks/useAppSelector";
import { AdminList, Privileges } from "interfaces/AdminPanel";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  BlockWrapUser,
  DefaulFlexConteiner,
  SpanDefal,
  SpanTitle,
  Wr,
  WrapBlockAddAdmin,
  WrapBlockChange,
  WrapInfoW,
  WrapInput,
  WrapItemServer,
  WrapperAdminServer,
  WrapperMenuProfile,
  WrapSearch,
  WrapServerAndAdmin,
  WrapUser,
} from "./styled";

export default function AdminPages() {
  const { fetchServers } = useActions();
  const { servers } = useAppSelector((state) => state.servers);
  const [admins, setAdmins] = useState<Array<AdminList>>([]);
  const [activeServer, setActiveServer] = useState<number[]>([]);
  const [filterServer, setFilterServer] = useState<string[]>([""]);
  const [filterName, setFilterName] = useState<string>("");
  const [editAdmin, setEditAdmin] = useState<boolean>(false);
  const [steamIDAdminNew, setsteamIDAdminNew] = useState<string>();
  const [newAdminServer, setNewAdminServer] = useState<string>("MIRAGE #1");
  const [newAdminPrivelegies, setNewAdminPrivelegies] =
    useState<string>("Создатель");
  const [tarife, setTarife] = useState<number>(7);
  const [privelegies, setPrivelegies] = useState<Privileges>({
    success: true,
    data: [
      {
        id: 1,
        name: "Создатель",
        flags: "",
        immunity: 100,
      },
    ],
  });

  //-----modal
  const [modal, setModal] = useState<boolean>(false);
  const [adminModal, setAdminModal] = useState<AdminList>();

  //-----modal

  const Tarife = [7, 30, 90, 180, 365, 1000000];
  const { isAuth, user } = useAppSelector((state) => state.user);

  useEffect(() => {
    fetchServers();
    async function getCategory() {
      setAdmins(await AdminServise.getAdmins());
    }
    async function getPrivil() {
      setPrivelegies(await AdminServise.getprivileges());
    }
    getCategory();
    getPrivil();
    // console.log("admins", admins);
  }, []);

  useEffect(() => {
    admins.forEach((item) => {
      if (
        item.user.steamid64 === filterName ||
        item.user.name.toLowerCase() === filterName.toLowerCase()
      ) {
        setFilterServer((prevfilterServer) => [
          ...prevfilterServer,
          item.server.server_name,
        ]);
        if (filterServer.includes("")) {
          setFilterServer((current) =>
            current.filter((filterServer) => {
              return filterServer !== "";
            })
          );
        }
        setActiveServer((prevActiveServer) => [
          ...prevActiveServer,
          item.server.server_id,
        ]);
      }
      if (filterName == "") {
        setFilterServer([""]);
        setActiveServer([]);
      }
    });
  }, [filterName]);

  function addNewAdmin() {
    // console.log(servers);
    servers.forEach((item) => {
      if (item.server_name == newAdminServer) {
        privelegies.data.forEach((i) => {
          if (i.name == newAdminPrivelegies) {
            let dateTarif = new Date();
            const offsetMinutes = new Date().getTimezoneOffset();
            dateTarif.setMinutes(dateTarif.getMinutes() + offsetMinutes);
            dateTarif.setDate(dateTarif.getDate() + tarife);
            AdminServise.addNewAdmin(
              steamIDAdminNew || "",
              i.id,
              Number(item.id),
              dateTarif
            );
          }
        });
      }
    });
  }
  const navigate = useNavigate();

  if (editAdmin == false) {
    return (
      <Layout headerElement={<div />} leftElement={<div />}>
        <WrapperAdminServer>
          <WrapSearch>
            <Wr>
              <Input
                type="search"
                placeholder="Steam ID или ник админа"
                params={{ width: "35rem" }}
                onChange={(e) => setFilterName(e.target.value)}
              />
            </Wr>
            {user?.group?.flags.includes("h") ? (
              <Button
                variant="default"
                color="blue"
                height="6rem"
                params={{ width: "35rem" }}
                onClick={() => setEditAdmin(true)}
              >
                Управление админами
              </Button>
            ) : null}
          </WrapSearch>
          <WrapServerAndAdmin>
            {servers.map((item) => {
              if (
                filterServer.includes("") ||
                filterServer.includes(item.server_name || "")
              ) {
                return (
                  <WrapItemServer
                    onClick={() =>
                      activeServer.includes(Number(item.id))
                        ? setActiveServer((current) =>
                            current.filter((activeServer) => {
                              return activeServer !== Number(item.id);
                            })
                          )
                        : setActiveServer([...activeServer, Number(item.id)])
                    }
                  >
                    <WrapperMenuProfile
                      Active={
                        activeServer.includes(Number(item.id)) ? true : false
                      }
                    >
                      <SpanTitle>{item.server_name}</SpanTitle>
                      <TinyIcons.Arrow />
                    </WrapperMenuProfile>
                    <BlockWrapUser
                      Active={
                        activeServer.includes(Number(item.id)) ? false : true
                      }
                    >
                      <WrapInfoW>
                        <SpanDefal>№ Пользователь</SpanDefal>
                        <SpanDefal>Steam ID</SpanDefal>
                        <SpanDefal>Услуги</SpanDefal>
                        <SpanDefal>Дата окончания</SpanDefal>
                      </WrapInfoW>
                      {admins.map((items, index) => {
                        if (
                          items.server.server_name == item.server_name &&
                          (filterName == "" ||
                            items.user.steamid64.includes(filterName) ||
                            items.user.name
                              .toLowerCase()
                              .includes(filterName.toLowerCase()))
                        ) {
                          return (
                            <WrapUser
                              onClick={(e) => e.stopPropagation()}
                              nonActive={items.paused}
                            >
                              <DefaulFlexConteiner
                                onClick={() =>
                                  navigate(`/profile/${items.user.steamid64}`)
                                }
                              >
                                <SpanDefal width={true}>{index + 1}</SpanDefal>
                                <Avatar src={items.user.avatar} size="small" />
                                <SpanDefal width={true}>
                                  {items.user.name}
                                </SpanDefal>
                              </DefaulFlexConteiner>
                              <SpanDefal>{items.user.steamid64}</SpanDefal>
                              <SpanDefal>{items.user.group.name}</SpanDefal>
                              <SpanDefal>
                                {items.user.group.expiration.slice(0, 10)}
                              </SpanDefal>
                            </WrapUser>
                          );
                        }
                      })}
                    </BlockWrapUser>
                  </WrapItemServer>
                );
              }
            })}
          </WrapServerAndAdmin>
        </WrapperAdminServer>
      </Layout>
    );
  } else {
    return (
      <Layout
        leftElement={
          <Icons.ArrowBack
            onClick={() => setEditAdmin(false)}
            style={{ cursor: "pointer" }}
          />
        }
        headerElement={<SubHeader>Управление админами</SubHeader>}
      >
        <WrapperAdminServer>
          <WrapBlockAddAdmin>
            <SpanTitle>Выдать права</SpanTitle>

            <WrapInput>
              <WrapBlockChange>
                <UIKIT.Select
                  label="Выберете сервер"
                  items={servers.map((item) => {
                    return item.server_name;
                  })}
                  onChange={setNewAdminServer}
                  params={{ width: "100%" }}
                />
              </WrapBlockChange>
              <WrapBlockChange>
                <UIKIT.Select
                  label="Услуга"
                  items={privelegies.data.map((item) => {
                    return item.name;
                  })}
                  onChange={setNewAdminPrivelegies}
                  params={{ width: "100%" }}
                />
              </WrapBlockChange>
              <WrapBlockChange>
                <UIKIT.Input
                  label="Введите STEAM ID"
                  placeholder="STEAM ID"
                  type="search"
                  params={{ width: "100%" }}
                  onChange={(e) => setsteamIDAdminNew(e.target.value)}
                />
              </WrapBlockChange>
              <WrapBlockChange>
                <UIKIT.Select
                  label="Выберете тариф"
                  items={Tarife}
                  onChange={setTarife}
                  params={{ width: "100%" }}
                />
              </WrapBlockChange>
            </WrapInput>
            <Button
              variant="default"
              color="blue"
              height="5rem"
              params={{ width: "100%" }}
              onClick={() => addNewAdmin()}
            >
              Выдать
            </Button>
          </WrapBlockAddAdmin>

          <WrapItemServer>
            <WrapInfoW>
              <SpanDefal>№ Пользователь</SpanDefal>
              <SpanDefal>Steam ID</SpanDefal>
              <SpanDefal>Услуги</SpanDefal>
              <SpanDefal>Дата окончания</SpanDefal>
            </WrapInfoW>
            {admins.map((items, index) => {
              if (newAdminServer.includes(items.server.server_name || "")) {
                return (
                  <WrapUser
                    onClick={() => {
                      setModal(true);
                      setAdminModal(items);
                    }}
                    cursor={true}
                    nonActive={items.paused}
                  >
                    <DefaulFlexConteiner>
                      <SpanDefal width={true}>{index + 1}</SpanDefal>
                      <Avatar src={items.user.avatar} size="small" />
                      <SpanDefal width={true}>{items.user.name}</SpanDefal>
                    </DefaulFlexConteiner>
                    <SpanDefal>{items.user.steamid64}</SpanDefal>
                    <SpanDefal>{items.user.group.name}</SpanDefal>
                    <SpanDefal>
                      {items.user.group.expiration.slice(0, 10)}
                    </SpanDefal>
                  </WrapUser>
                );
              }
            })}
          </WrapItemServer>
        </WrapperAdminServer>
        <Modals.AdminUserChange
          isActive={modal}
          setActive={setModal}
          admin={adminModal}
          setAdmin={setAdmins}
          fullAdmins={admins || []}
        />
      </Layout>
    );
  }
}
