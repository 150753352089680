import { parseAllServers } from "utils/parseAllServers";
import { IServer } from "../../models/Server";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import API from "API";

interface Categories {
  label: string;
  value: string;
}

interface ServersState {
  servers: IServer[];
  isLoading: boolean;
  categories: Categories[];
}

const initialState: ServersState = {
  servers: [],
  isLoading: false,
  categories: [],
};

export const fetchServers = createAsyncThunk(
  "servers/fetchServers",
  async () => {
    const response = await API.ServersService.getMonitoring();
    const servers = response.data.map((item: any) => {
      const name = item.name;
      const servers = item.servers.map(
        (item: any) => ({
          type: name,
          ...item,
        })
        // console.log(item)
      );
      return servers;
    });
    return parseAllServers(servers);
  }
);

export const serversSlice = createSlice({
  name: "servers",
  initialState,
  reducers: {
    setServers: () => {},
  },
  extraReducers: (builder) => {
    builder.addCase(fetchServers.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchServers.fulfilled, (state, action) => {
      state.servers = action.payload;
      state.isLoading = false;
      const categories = [
        ...new Set(action.payload.map((server) => server.type)),
      ].map((item) => ({ label: item, value: item.toLowerCase() }));
      const items = [
        { label: "Все", value: "all" },
        ...categories,
      ] as Categories[];
      state.categories = items;
    });
  },
});

export const { setServers } = serversSlice.actions;
export const serversActions = serversSlice.actions;
export default serversSlice.reducer;
