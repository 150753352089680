import { Col, Label, Value } from "./Styles";

interface Props {
  items: (number | string)[] | string[] | number[];
}

export default function InformationColumn({ items }: Props) {
  return (
    <Col>
      <Label>{items[0]}</Label>
      <Value>{items[1]}</Value>
    </Col>
  );
}
