import { TinyIcons } from "assets/tiny-icons";
import Detailed from "components/Templates/Detailed";
import ModalWrapper from "components/Templates/ModalWrapper";
import Buttons from "components/UI/Button";
import { useActions } from "hooks/useActions";
import ShopState from "./ShopState";
import { Container, CounText, Item, Title, WrapCount, Wrape } from "./Styles";
// import { okOperation } from ".,/../../../public/static/images/OkOperation.svg";
// import { Bottom } from "../TradeSelectorSkins/Styles";

// interface Props extends IModal {}

interface Props {
  isActive: boolean;
  setActive: React.SetStateAction<any>;
}
interface ResultCredit {
  data: {
    balance: number;
    virtual_balance: number;
  };
  success: boolean;
}

export default function Shop({ isActive, setActive }: Props) {
  const { setBalance } = useActions();

  return (
    <>
      <ModalWrapper
        setActive={setActive}
        isActive={isActive}
        headContent=""
        params={{ width: "30rem", height: "fit-content" }}
        display="flex"
      >
        <Container>
          {/* <Item textAling={"right"}>Покупка ключа</Item> */}
          <Detailed>
            {/* <PlayerItem>Покупка ключа</PlayerItem> */}

            {/* <Title>{type === 'ban' ? ViolationTitlesEnum.BAN : ViolationTitlesEnum.MUTE}</Title> */}
            {/* <Detailed> */}
            <Item textAling={"left"}>Покупка кредитов</Item>
            <WrapCount>
              {ShopState.count} &nbsp; <TinyIcons.Credit />
            </WrapCount>

            <Title>
              <CounText> К оплате </CounText>
              <Wrape>
                {ShopState.price}&nbsp; <TinyIcons.Rub />
              </Wrape>
            </Title>
            {/* </Detailed> */}
            <Buttons
              variant="default"
              height="50px"
              color="blue"
              fullWidth={false}
              params={{ width: "100%", padding: "0px" }}
              onClick={async () => {
                const respon: ResultCredit = await ShopState.postBuy();
                if (respon.success == true) {
                  setBalance({
                    real: respon.data.balance,
                    virtual: respon.data.virtual_balance,
                  });
                }
                ShopState.setShowModal(false);
              }}
            >
              Купить
            </Buttons>
          </Detailed>
        </Container>
      </ModalWrapper>
    </>
  );
}

//     <Container>
//       <Detailed>
//         <Avatar src={user?.user?.avatar} size="small-6" />
//         <Detailed>
//           <PlayerItem>{user?.user?.name}</PlayerItem>
//           <Row gap="3rem" justifyContent="center" alignItems="center">
//             {/* <Title>{type === 'ban' ? ViolationTitlesEnum.BAN : ViolationTitlesEnum.MUTE}</Title> */}
//             <Item>
//               Указание действующей электронной почты необходимо для
//               получения информации о важных обновлениях и для получения
//               электронных чеков
//             </Item>
//           </Row>
//         </Detailed>
//       </Detailed>
//       <Item>Почта</Item>
//       <Input
//         params={{
//           width: "100%",
//           labelPosition: "center",
//         }}
//         placeholder="Ваша почта"
//         type="text"
//         value={message1}
//         onChange={(e) => setMessage(e.target.value)}
//       />
//       <Buttons
//         variant="picker"
//         height="50px"
//         color="green"
//         fullWidth={false}
//         params={{ width: "130px", padding: "0px" }}
//         onClick={sendMessage}
//       >
//         Отправить
//       </Buttons>
// </Container>

// {ver === false && user.data ? (
//         <ModalWrapper
//           setActive={setActive}
//           isActive={isActive}
//           headContent=""
//           params={{ width: "80rem", height: "fit-content" }}
//           display="none"
//         >
//           {responOK !== true ? (
//             <Container>
//               <Detailed>
//                 <Avatar src={user?.user?.avatar} size="small-6" />
//                 <Detailed>
//                   <PlayerItem>{user?.user?.name}</PlayerItem>
//                   <Row gap="3rem" justifyContent="center" alignItems="center">
//                     {/* <Title>{type === 'ban' ? ViolationTitlesEnum.BAN : ViolationTitlesEnum.MUTE}</Title> */}
//                     <Item>
//                       Указание действующей электронной почты необходимо для
//                       получения информации о важных обновлениях и для получения
//                       электронных чеков
//                     </Item>
//                   </Row>
//                 </Detailed>
//               </Detailed>
//               <Item>Почта</Item>
//               <Input
//                 params={{
//                   width: "100%",
//                   labelPosition: "center",
//                 }}
//                 placeholder="Ваша почта"
//                 type="text"
//                 value={message1}
//                 onChange={(e) => setMessage(e.target.value)}
//               />
//               <Buttons
//                 variant="picker"
//                 height="50px"
//                 color="green"
//                 fullWidth={false}
//                 params={{ width: "130px", padding: "0px" }}
//                 onClick={sendMessage}
//               >
//                 Отправить
//               </Buttons>
//             </Container>
//           ) : (
//             <Detailed>
//               <Title>Письмо отправлено на адрес:</Title>
//               <PlayerItem>{message1}</PlayerItem>
//               <Image src="/static/images/OkOperation.png" alt="okOper" />
//               <Detailed>
//                 <Buttons
//                   variant="picker"
//                   color="blue"
//                   fullWidth={false}
//                   height="50px"
//                   onClick={sendMessage}
//                   params={{ width: "250px" }}
//                 >
//                   Отправить еще раз
//                 </Buttons>
//                 <Buttons
//                   variant="picker"
//                   color="red"
//                   fullWidth={false}
//                   height="50px"
//                   onClick={(e) => setResponOK(false)}
//                   params={{ width: "250px" }}
//                 >
//                   Изменить почту
//                 </Buttons>
//               </Detailed>
//             </Detailed>
//           )}
//         </ModalWrapper>
//       ) : null}
