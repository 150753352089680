import { createSlice } from "@reduxjs/toolkit";
import { ApplicationsState } from "models/Punishments";
import { TicketStatuses } from "models/Ticket";

export const initialState: ApplicationsState = {
  data: [
    {
      id: 1,
      user_name: "Den4is187",
      created_at: "16.11.2022, 17:25",
      server_id: "Freezing-Blood | MIRAGE #3 | 128tick | AntiCheat",
      steamid: "STEAM_1:0:566407427",
      expired_at: "24ч",
      reason: "Auto B-hop",
      admin: "CONSOLE",
      end_timestamp: "dfsdfdsf",
      server_ip: "37.230.137.204:7777",
      user_ip: "192.168.0.1",
      player_steamid64: "777777777777",
      status: TicketStatuses.OPEN,
      type: "ban",
    },
    {
      id: 2,
      user_name: "Valeriy",
      created_at: "16.11.2022, 17:25",
      server_id: "Freezing-Blood | MIRAGE #3 | 128tick | AntiCheat",
      steamid: "STEAM_1:0:566407427",
      expired_at: "24ч",
      reason: "Auto B-hop",
      admin: "CONSOLE",
      end_timestamp: "dfsdfdsf",
      server_ip: "37.230.137.204:7777",
      user_ip: "192.168.0.1",
      player_steamid64: "777777777777",
      status: TicketStatuses.PENDING,
      type: "voice_mute",
    },
    {
      id: 3,
      user_name: "Ugabuga",
      created_at: "16.11.2022, 17:25",
      server_id: "Freezing-Blood | MIRAGE #3 | 128tick | AntiCheat",
      steamid: "STEAM_1:0:566407427",
      expired_at: "24ч",
      reason: "Auto B-hop",
      admin: "CONSOLE",
      end_timestamp: "dfsdfdsf",
      server_ip: "37.230.137.204:7777",
      user_ip: "192.168.0.1",
      player_steamid64: "777777777777",
      status: TicketStatuses.CLOSED,
      type: "ban",
    },
  ],
};

const name = "applications";

export const applicationsSlice = createSlice({
  name,
  initialState,
  reducers: {
    setPunishments: () => {},
  },
});

export const PrivatePunishmentsActions = applicationsSlice.actions;
export default applicationsSlice.reducer;
