export default function Death() {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_2557_58297)">
        <path
          d="M10.6042 3.33301C14.2858 3.33301 17.2708 6.13134 17.2708 9.58301C17.2708 11.1672 16.6417 12.6138 15.6042 13.7163V15.833C15.6042 16.054 15.5164 16.266 15.3601 16.4223C15.2038 16.5785 14.9918 16.6663 14.7708 16.6663H6.4375C6.21649 16.6663 6.00452 16.5785 5.84824 16.4223C5.69196 16.266 5.60417 16.054 5.60417 15.833V13.7163C4.56667 12.6147 3.9375 11.168 3.9375 9.58301C3.9375 6.13134 6.9225 3.33301 10.6042 3.33301Z"
          stroke="white"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M8.9375 14.167V16.667"
          stroke="white"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M12.2656 14.167V16.667"
          stroke="white"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M7.26562 9.16634C7.26562 9.38735 7.35342 9.59932 7.5097 9.7556C7.66598 9.91188 7.87794 9.99967 8.09896 9.99967C8.31997 9.99967 8.53193 9.91188 8.68821 9.7556C8.84449 9.59932 8.93229 9.38735 8.93229 9.16634C8.93229 8.94533 8.84449 8.73337 8.68821 8.57709C8.53193 8.42081 8.31997 8.33301 8.09896 8.33301C7.87794 8.33301 7.66598 8.42081 7.5097 8.57709C7.35342 8.73337 7.26562 8.94533 7.26562 9.16634Z"
          stroke="white"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M12.2656 9.16634C12.2656 9.38735 12.3534 9.59932 12.5097 9.7556C12.666 9.91188 12.8779 9.99967 13.099 9.99967C13.32 9.99967 13.5319 9.91188 13.6882 9.7556C13.8445 9.59932 13.9323 9.38735 13.9323 9.16634C13.9323 8.94533 13.8445 8.73337 13.6882 8.57709C13.5319 8.42081 13.32 8.33301 13.099 8.33301C12.8779 8.33301 12.666 8.42081 12.5097 8.57709C12.3534 8.73337 12.2656 8.94533 12.2656 9.16634Z"
          stroke="white"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2557_58297">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(0.601562)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
