export default function Kills() {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_2557_58291)">
        <path
          d="M17.4661 3.33301V7.49967L9.96615 13.333L6.63281 16.6663L4.13281 14.1663L7.46615 10.833L13.2995 3.33301H17.4661Z"
          stroke="white"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M6.21094 9.58301L11.2109 14.583"
          stroke="white"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2557_58291">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(0.796875)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
