import { endpoints, url } from "API";
import { TinyIcons } from "assets/tiny-icons";
import Image from "components/Image";
import LabelAvatar from "components/Labels/LabelAvatar";
import Counter from "components/UI/Counter";
import { useAppSelector } from "hooks/useAppSelector";
import { IWeaponCard } from "models/WeaponCard";
import { useTheme } from "styled-components";
import { BlockDiv, Container, Info, Name, Price, Sdf, SubName } from "./Styles";

export default function WeaponCard({
  src,
  skin,
  weapon,
  price,
  owner,
  avatar,
  rarity,
  disablePrice,
  disableAvatar,
  counter,
  nickname,
  ...props
}: IWeaponCard) {
  const Theme = useTheme();

  // console.log(owner);

  return (
    <Container rarity={rarity} {...props}>
      <BlockDiv rarity={rarity}>&nbsp;</BlockDiv>

      <Counter text={counter} />

      <Image width="150" height="116" src={src} alt="Gun" />
      <Info>
        <Name>
          {weapon}
          <SubName rarity={rarity}>{skin}</SubName>
        </Name>
        {!disablePrice && (
          <Sdf>
            <Price>{price}</Price>
            <TinyIcons.Credit />
          </Sdf>
        )}
        {!disableAvatar && (
          <LabelAvatar
            src={avatar}
            params={{
              font: Theme.fonts.GothamChatRegular12,
              avatarSize: "super-small",
              isAvatarBorder: false,
            }}
          >
            {nickname}
          </LabelAvatar>
        )}
      </Info>
    </Container>
  );
}
