import styled from "styled-components";
import { LazyLoadImage } from "react-lazy-load-image-component";

interface ImageProps {
  key?: string;
  src: string;
  alt: string;
  height?: string;
  width?: string;
  caption?: string;
  maxWidth?: string;
  minWidth?: string;
}

export const LazyImage = styled(LazyLoadImage)<ImageProps>`
  max-width: ${(props) => props.maxWidth};
  min-width: ${(props) => props.minWidth};
  @media (max-width: 1250px) {
    align-self: center;
  }
`;
