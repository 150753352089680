import styled from "styled-components";

export const WrapperBattlePassMain = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  @media (max-width: 1250px) {
    padding: 1rem;
  }
`;

export const WrappDefaultFlex = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  height: 100%;
  @media (max-width: 768px) {
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
`;
export const WrappDefaultFlex2 = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  height: 100%;
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 1rem;
  }
`;

export const BattlPassName = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  @media (max-width: 768px) {
    justify-content: center;
    align-items: center;
    text-align: center;
  }
`;

export const WrapFlexSpaceBeet = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 2rem;
  @media (max-width: 1250px) {
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
    width: 100%;
  }
`;
export const WrapFlexSpaceBeet2 = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 2rem;
  flex-direction: row;
`;

export const SmallText70 = styled.span`
  ${(props) => props.theme.fonts.GothamMedium16};
  color: ${(props) => props.theme.colors.white.white70};
`;

export const SmallText100 = styled.span`
  ${(props) => props.theme.fonts.GothamMedium16};
  color: ${(props) => props.theme.colors.white.white100};
`;

export const BigText = styled.span`
  ${(props) => props.theme.fonts.GothamBold44};
  color: ${(props) => props.theme.colors.white.white100};
`;

export const TimeBattlPassRemeining = styled.span`
  ${(props) => props.theme.fonts.GothamBold16};
  color: ${(props) => props.theme.colors.white.white100};
  padding: 1rem;
  background: ${(props) => props.theme.gradients.shadow};
  border-radius: 1rem;
  max-width: 6rem;
  min-width: 6rem;
  text-align: center;
`;

export const BlockTopTool = styled.div`
  display: flex;
  flex-direction: column;
  background: ${(props) => props.theme.gradients.shadow};
  border-radius: 1.2rem;
  padding: 2rem;
  min-width: 40rem;
  gap: 2rem;
  height: max-content;
  /* width: 100%; */
  @media (max-width: 768px) {
    min-width: 32rem;
    padding: 1rem;
  }
  @media (max-width: 500px) {
    max-width: 93vw;
  }
`;

export const BlockTask = styled.div`
  display: flex;
  flex-direction: column;
  background: ${(props) => props.theme.gradients.shadow};
  border-radius: 1.2rem;
  padding: 2rem;
  min-width: 40rem;
  gap: 2rem;
  width: 100%;
  @media (max-width: 34rem) {
    min-width: 32rem;
    padding: 1rem;
  }
  @media (max-width: 500px) {
    max-width: 93vw;
  }
`;

export const MediumText100 = styled.span`
  ${(props) => props.theme.fonts.GothamBold24};
  color: ${(props) => props.theme.colors.white.white100};
`;

export const PlayerBlockTOp = styled.div<{ pad?: boolean }>`
  padding: ${(props) => (props.pad ? "0 0 0 1rem" : "1rem")};
  display: flex;
  background: ${(props) => props.theme.gradients.shadow};
  border-radius: 1.2rem;
  justify-content: space-between;
  /* padding: 2re; */
  width: 100%;
  height: 6rem;
  align-items: center;
  @media (max-width: 768px) {
    flex-direction: column;
    height: fit-content;
    padding: 1.5rem 1rem;
    gap: 2rem;
  }
`;

export const WrapBlockPlayer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const XpPrizeTask = styled.span`
  /* padding: 1.6rem; */
  border-radius: 0 1.2rem 1.2rem 0;
  background: ${(props) => props.theme.gradients.shadow};
  display: flex;
  align-items: center;
  ${(props) => props.theme.fonts.GothamMedium20}
  gap:0.5rem;
  width: 11rem;
  justify-content: center;
  height: 100%;
`;

// export const UserTasks = styled.div`
//   padding: 0 0 0 1rem;
//   display: flex;
//   justify-content: space-around;
// `;

export const BattlPassWrapperPrize = styled.div`
  display: flex;
  padding: 2.7rem;
  flex-direction: column;
  gap: 1rem;
  background: ${(props) => props.theme.gradients.shadow};
  border-radius: 2rem 2rem 0 0;
  /* max-widtwh: 85vw; */
  position: relative;
  max-width: 87vw;
  /* width: 100%; */
  /* align-items: center; */
  /* justify-content: center; */
  overflow-y: auto;
  overflow-x: auto;
  /* background-color: blue; */
  &::-webkit-scrollbar {
    width: 0.2rem;
    height: 5px;
    border: 0.1rem solid transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.colors.white.white20} !important;
    border-radius: 0.5rem !important;
  }
  &::-webkit-scrollbar-track {
    background: ${(props) => props.theme.colors.white.white10} !important;
  }
  @media (max-width: 1850px) {
    max-width: 85vw;
  }

  @media (min-width: 1250px) and (max-width: 1500px) {
    max-width: 81vw;
  }
  @media (max-width: 1250px) {
    max-width: 97vw;
  }
  @media (max-width: 768px) {
    max-width: 95vw;
  }
`;

export const BattlPassPrizeLine = styled.div`
  display: flex;
  /* justify-content: center; */
  gap: 1.5rem;
  /* width: 100%; */
  /* padding: 1rem; */
  /* width: 50vw; */
  /* min-width: 125vw; */
  /* min-width: 100%; */
  /* width: 100%; */
  width: max-content;
  align-items: center;
`;

export const BlockPrize = styled.div<{ noupBG?: boolean; prem?: boolean }>`
  display: flex;
  flex-direction: column;
  /* gap: 1rem; */
  background-image: ${(props) =>
    props.noupBG
      ? null
      : props.prem == false
      ? props.theme.gradients.standartPrizeBattlPass
      : `url("/static/images/backGroundPrize.png"),linear-gradient(180deg, rgba(255, 179, 92, 0.2) 0%, rgba(255, 255, 255, 0.04) 100%)`};

  background-blend-mode: normal, normal, overlay;
  /* backg */
  background-size: contain;
  background-repeat: no-repeat;
  min-width: 20rem;
  max-width: 20rem;
  min-height: 25rem;
  max-height: 25rem;
  justify-content: center;
  align-items: center;
  border-radius: 2rem;
  padding: 1rem 1rem 2rem 1rem;
  /* gap: rem; */
  justify-content: space-between;
  /* padding: 5rem; */
`;

// url("/static/images/backGroundPrize.png"),
// linear-gradient(
//   180deg,
//   rgba(108, 194, 255, 0.2) 0%,
//   rgba(255, 255, 255, 0.04) 100%
// );

/* background-blend-mode: overlay;
  /* background: ${(props) =>
    props.noupBG
      ? null
      : props.prem
      ? props.theme.gradients.player
      : props.theme.gradients.standartPrizeBattlPass}; */

export const Verticaltext = styled.span<{ orange: boolean }>`
  ${(props) => props.theme.fonts.GothamBold16}
  /* width: max-content; */
  width: 2rem;
  writing-mode: vertical-rl;
  transform: rotate(180deg);
  display: flex;
  gap: 2rem;
  align-items: center;
  color: ${(props) =>
    props.orange ? "#FAB700" : props.theme.colors.white.white100};
  svg {
    transform: rotate(90deg);
    fill: #fab700;
  }
`;

export const BlockEarn = styled.span`
  padding: 1rem;
  background: ${(props) => props.theme.gradients.shadow};
  border-radius: 1rem;
  display: flex;
  align-items: center;
  /* align-items: center; */
  /* align-self: center; */
  /* width: max-content;
  height: max-content; */
`;
