import { IInventoryWeaponCard, IItemSend } from "../models/InventoryWeaponCard";

export interface ITrade {
  avatar: string;
  name: string;
  receiver: string;
  receiver_items: IInventoryWeaponCard[];
  sender: string;
  sender_items: IInventoryWeaponCard[];
  time: string;
  uuid: string;
  myInventory: IInventoryWeaponCard[];
  id: number;
  confirmed: EConfirmList;
  status?: number;
}

export interface ITradeUpdate {
  receiverItems: IInventoryWeaponCard[];
  senderItems: IInventoryWeaponCard[];
}

// export type TConfirmList = 'default' | 'one' | 'two' | 'all'
export enum EConfirmList {
  partnerAply = -1,
  default = 0,
  confirm = 1,
  all = 2,

  againConfrimed = 3,
}

// NEW
export enum TradeStatus {
  NoConfirmed,
  SenderAgree,
  ReceiverAgree,
  BothAgree,
  SenderApplied,
  ReceiverApplied,
  BothApplied,
  Canceled,
  NotStarted,
  null,
}

export interface TradeInventory {
  avatar: string;
  myInventory: IItemSend[];
  name: string;
  sender?: string;
  receiver?: string;
  status: TradeStatus | null;
  time: string;
  date?: string;
  uuid: string;

  id?: number;
}

export interface Trade extends TradeInventory {
  myItems: IItemSend[];
  receivedItems: IItemSend[];
}

export interface TradeUpdate {
  myInventory: IItemSend[];
  myItems: IItemSend[];
  receivedItems: IItemSend[];
}
