export default function ArrowRightCase({ onClick = () => {} }) {
  return (
    <svg
      width="29"
      height="24"
      viewBox="0 0 29 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 12L17 3.33975L17 20.6603L2 12Z"
        stroke="url(#paint0_linear_99_23041)"
        stroke-width="2"
      />
      <path
        d="M11.9255 12L23.25 5.11767L23.25 18.8823L11.9255 12Z"
        stroke="url(#paint1_linear_99_23041)"
        stroke-width="2"
      />
      <defs>
        <linearGradient
          id="paint0_linear_99_23041"
          x1="-1.19217e-06"
          y1="12"
          x2="15.4286"
          y2="12"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#1E91E4" />
          <stop offset="1" stop-color="#1E91E4" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_99_23041"
          x1="10"
          y1="12"
          x2="22.2143"
          y2="12"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#1E91E4" />
          <stop offset="1" stop-color="#1E91E4" stop-opacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
}
