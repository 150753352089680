import { observer } from "mobx-react-lite";
import { useState } from "react";

interface Props {
  isActive: boolean;
  setActive: (status: boolean) => void;
}

const TradeSelectorSkins = ({ isActive, setActive }: Props) => {
  const [amount, setAmount] = useState(0);

  return <>Когдато тут был компонент</>;
};

export default observer(TradeSelectorSkins);
