import Row from "components/Row";
import Inventoryfilters from "components/Templates/Inventoryfilters";
import { useAppSelector } from "hooks/useAppSelector";
import { useState } from "react";
import { useParams } from "react-router-dom";

interface props {
  modal: (status: boolean) => void;
  type: (type: EModalType) => void;
}

export enum EModalType {
  fromSite = 0,
  fromServer = 1,
}

export default function Header({ modal, type }: props) {
  const { user } = useAppSelector((state) => state.user);
  const { steamid64 = "" } = useParams();

  const selectStage = (selectType: EModalType) => {
    modal(true);

    type(selectType);
  };

  if (steamid64 !== user?.user?.steamid64) {
    return <></>;
  }

  return (
    <Row>
      <Inventoryfilters />
    </Row>
  );
}
