import { useAppSelector } from "hooks/useAppSelector";
import { MenuActions, menuItem } from "interfaces/ChatMenu";
import { IMessage } from "models/Message";
import { useState } from "react";
import {
  Container,
  Menu,
  MenuItem,
  Message,
  MessageWrapper,
  Nickname,
  Text,
  Timestamp,
  Wrapper,
} from "./Styles";

export default function ChatItem({
  nickname,
  text,
  createdAt,
  steamid64,
  isLast,
}: IMessage) {
  const [isMenu, setIsMenu] = useState<boolean>(false);
  const { user } = useAppSelector((state) => state.user.user);
  const ISYOU = user.steamid64 == steamid64 ? true : false;
  const menuItems: menuItem[] = [
    { label: "Присоединиться", action: MenuActions.PLAY },
    { label: "Сообщение", action: MenuActions.MESSAGE },
    { label: "Обмен", action: MenuActions.TRADE },
    { label: "Скопировать", action: MenuActions.COPY },
    { label: "Пожаловаться", action: MenuActions.REPORT },
  ];

  function setMenu() {
    setIsMenu(!isMenu);
  }

  function onItemMenuClick(action: MenuActions) {
    setIsMenu(false);
    //console.log(action);
  }

  function times(datest: string) {
    let date = new Date(datest);
    date.setHours(date.getHours() - 3);
    // const offsetMinutes = new Date().getTimezoneOffset();
    // date.setMinutes(date.getMinutes() + offsetMinutes);
    return `${date.getHours()}:${date.getMinutes()}`;
  }

  return (
    <Container ISYOU={ISYOU}>
      {/* {ISYOU && (
        <Avatar size="super-small" alt="avatar" src={avatar} isBorder={false} />
      )} */}
      <Wrapper>
        <MessageWrapper isMenu={isMenu} ISYOU={ISYOU}>
          <Menu className={isMenu ? "active" : ""} isLast={isLast || false}>
            {menuItems.map(({ action, label }, index) => (
              <MenuItem key={index} onClick={() => onItemMenuClick(action)}>
                {label}
              </MenuItem>
            ))}
          </Menu>
          {/* <NanoIcons.MessageDots onClick={setMenu} /> */}
          <Message ISYOU={ISYOU}>
            <Nickname ISYOU={ISYOU} to={`/profile/${steamid64}`}>
              {nickname}
            </Nickname>
            <Text>{text}</Text>
            <Timestamp>{times(createdAt)}</Timestamp>
          </Message>
        </MessageWrapper>
      </Wrapper>
      {/* {ISYOU && (
        <Avatar size="super-small" alt="avatar" src={avatar} isBorder={false} />
      )} */}
    </Container>
  );
}
