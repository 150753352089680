import styled from "styled-components";

export const WrapperModal = styled.div`
  display: flex;
  gap: 2rem;
  align-items: center;
  justify-content: center;
  margin-top: 3rem;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;
