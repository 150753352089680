import Portal from "components/Portal";
import React from "react";
import { ModalContent, StyledModal } from "./Styles";

interface Props {
  children: React.ReactNode;
  isActive: boolean;
  setActive: React.Dispatch<React.SetStateAction<boolean>>;
  style?: React.CSSProperties;
  modalStyle?: React.CSSProperties;
  onScroll?: (e: React.UIEvent<HTMLDivElement>) => void; // Обновленный тип параметра
}

export default function Modal({
  children,
  isActive,
  setActive,
  style,
  modalStyle,
  onScroll,
}: Props) {
  return (
    <Portal className="modal-portal">
      <StyledModal
        className={isActive ? "active" : ""}
        onClick={() => setActive(false)}
        onScroll={onScroll}
        style={modalStyle}
      >
        <ModalContent
          style={style}
          className={isActive ? "active" : ""}
          onClick={(e) => e.stopPropagation()}
        >
          {children}
        </ModalContent>
      </StyledModal>
    </Portal>
  );
}
