
import { calculatePercent } from 'utils/calculatePercent'
import { Bar, Container, CurrentLevel, Exp, NextLevel, Wrapper } from './Styles'

interface Props {
	exp?: number;
	nextExp?: number;
	level?: number;
	nextLevel?: number;
}

export default function Progress({ exp, nextExp, level, nextLevel }: Props) {
	return (
		<Container>
			<CurrentLevel>{level}</CurrentLevel>
			<Wrapper>
				<Bar progress={calculatePercent(exp, nextExp)} />
			</Wrapper>
			<NextLevel>{nextLevel}</NextLevel>
			<Exp>({exp}/{nextExp})</Exp>
		</Container>
	)
}
