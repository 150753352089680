import { calculatePercent } from "utils/calculatePercent";
import { Container, Progress, Text } from "./Styles";

interface Props {
	players?: number;
	max_players?: number;
}

export default function Players({ players, max_players }: Props) {
	return (
		<Container>
			<Text>{players}/{max_players}</Text>
			<Progress percent={calculatePercent(players, max_players)}/>
		</Container>
	)
}
