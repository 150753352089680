import Layout from "components/Layout";
import Button from "components/UI/Button";
import { useNavigate } from "react-router-dom";
import { HeaderWrap, Text, Title, Wrapper, WrapTitle } from "./Styles";

export default function Policy() {
  const navigate = useNavigate();
  return (
    <Layout
      leftElement={<div />}
      headerElement={
        <HeaderWrap>
          {/* <UIKIT.ArrowBackMoney /> */}
          {/* <SubHeader>Договор – оферта</SubHeader> */}
          <Button
            variant="picker"
            height="5rem"
            onClick={() => navigate("/rules")}
          >
            Правила
          </Button>
          <Button
            variant="picker"
            height="5rem"
            onClick={() => navigate("/rules/offer")}
          >
            Оферта
          </Button>
          <Button variant="picker" height="5rem" className="active">
            Политика конфидициальности
          </Button>
          <Button
            variant="picker"
            height="5rem"
            onClick={() => navigate("/rules/contact")}
          >
            Контакты
          </Button>
          <Button
            variant="picker"
            height="5rem"
            onClick={() => navigate("/rules/Hints")}
          >
            Подсказки
          </Button>
        </HeaderWrap>
      }
    >
      <Wrapper>
        <WrapTitle>Политика конфидициальности</WrapTitle>

        <Title>I. Определение терминов:</Title>
        <Text>
          1. Существующая на текущий момент политика конфиденциальности
          персональных данных (далее - Политика конфиденциальности) работает со
          следующими понятиями:
          <p />- "Администрация сайта". Так называют представляющих интересы
          организации специалистов, в чьи обязанности входит управление сайтом,
          то есть организация и (или) обработка поступивших на него персональных
          данных. Для выполнения этих обязанностей они должны чётко
          представлять, для чего обрабатываются сведения, какие сведения должна
          быть обработаны, какие действия (операции) должны производиться с
          полученными сведениями.
          <p />
          - "Персональные данные" - сведения, имеющие прямое или косвенное
          отношение к определённому либо определяемому физическому лицу (также
          называемому субъектом персональных данных).
          <p />
          - "Обработка персональных данных" - любая операция (действие) либо
          совокупность таковых, которые Администрация производит с персональными
          данными. Их могут собирать, записывать, систематизировать,
          накапливать, хранить, уточнять (при необходимости обновлять или
          изменять), извлекать, использовать, передавать (распространять,
          предоставлять, открывать к ним доступ), обезличивать, блокировать,
          удалять и даже уничтожать. Данные операции (действия) могут
          выполняться как автоматически, так и вручную.
          <p />
          - "Конфиденциальность персональных данных" - обязательное требование,
          предъявляемое к Оператору или иному работающему с данными Пользователя
          должностному лицу, хранить полученные сведения в тайне, не посвящая в
          них посторонних, если предоставивший персональные данные Пользователь
          не изъявил своё согласие, а также отсутствует законное основание для
          разглашения.
          <p />
          - "Пользователь сайта" (далее - Пользователь) - человек, посетивший
          сайт, а также пользующийся его программами и продуктами.
          <p />
          - "Cookies" - короткий фрагмент данных, пересылаемый веб-браузером или
          веб-клиентом веб-серверу в HTTP-запросе, всякий раз, когда
          Пользователь пытается открыть страницу сайта. Фрагмент хранится на
          компьютере Пользователя.
          <p />
          - "IP-адрес" - уникальный сетевой адрес узла в компьютерной сети,
          построенной по протоколу TCP/IP.
          <p />
        </Text>

        <Title>II. Общие положения:</Title>
        <Text>
          1. Просмотр сайта, а также использование его программ и продуктов
          подразумевают автоматическое согласие с принятой там Политикой
          конфиденциальности, подразумевающей предоставление Пользователем
          персональных данных на обработку.
        </Text>
        <Text>
          2. Если Пользователь не принимает существующую Политику
          конфиденциальности, Пользователь должен покинуть сайт.
        </Text>
        <Text>
          3. Имеющаяся Политика конфиденциальности распространяется только на
          сайт. Если по ссылкам, размещённым на сайте последнего, Пользователь
          зайдёт на ресурсы третьих лиц, сайт за его действия ответственности не
          несёт.
        </Text>
        <Text>
          4. Проверка достоверности персональных данных, которые решил сообщить
          принявший Политику конфиденциальности Пользователь, не входит в
          обязанности Администрации сайта.
        </Text>

        <Title>III. Предмет политики конфиденциальности</Title>
        <Text>
          1. Согласно проводимой в текущий период Политике конфиденциальности
          Администрация сайта обязана не разглашать персональные данные,
          сообщаемые Пользователями, регистрирующимися на сайте, а также
          обеспечивать этим данным абсолютную конфиденциальность.
        </Text>
        <Text>
          2. Чтобы сообщить персональные данные, Пользователь заполняет
          расположенные на сайте электронные формы. Персональными данными
          Пользователя, которые подлежат обработке, являются:
          <br /> - его фамилия, имя, отчество;
          <br /> - его контактный данные;
          <br /> - его электронный адрес (e-mail);
        </Text>
        <Text>
          3. Защита данных, автоматически передаваемых при просмотре рекламных
          блоков и посещении страниц с установленными на них статистическими
          скриптами системы (пикселями) осуществляется сайтом. Вот перечень этих
          данных:
          <br /> - IP-адрес;
          <br /> - сведения из cookies;
          <br /> - сведения о браузере (либо другой программе, через которую
          становится доступен показ рекламы);
          <br /> - время посещения сайта;
          <br /> - адрес страницы, на которой располагается рекламный блок;
          <br /> - реферер (адрес предыдущей страницы).
        </Text>
        <Text>
          4. Последствием отключения cookies может стать невозможность доступа к
          требующим авторизации частям сайта.
        </Text>
        <Text>
          5. Cайт собирает статистику об IP-адресах всех посетителей. Данные
          сведения нужны, чтобы выявить и решить технические проблемы и
          проконтролировать, насколько законным будет проведение финансовых
          платежей.
        </Text>
        <Text>
          6. Любые другие неоговорённые выше персональные сведения (о том, когда
          и какие покупки были сделаны, какой при этом использовался браузер,
          какая была установлена операционная система и пр.) надёжно хранятся и
          не распространяются. Исключение существующая Политика
          конфиденциальности предусматривает для случаев, описанных в п.п. 5.2 и
          5.3.
        </Text>

        <Title>IIII. Цели сбора персональной информации пользователя</Title>
        <Text>
          1. Сбор персональных данных Пользователя Администрацией сайта
          проводится ради того, чтобы:
          <br />- Идентифицировать Пользователя, который прошёл процедуру
          регистрации на сайте, чтобы приобрести товар данного сайта.
          <br />- Открыть Пользователю доступ к персонализированным ресурсам
          данного сайта.
          <br />- Установить с Пользователем обратную связь, под которой
          подразумевается, в частности, рассылка запросов и уведомлений,
          касающихся использования сайта, обработка пользовательских запросов и
          заявок, оказание прочих услуг.
          <br />- Определить местонахождение Пользователя, чтобы обеспечить
          безопасность платежей и предотвратить мошенничество.
          <br />- Подтвердить, что данные, которые предоставил Пользователь,
          полны и достоверны.
          <br />- Создать учётную запись, если Пользователь изъявил на то своё
          желание.
          <br />- Обрабатывать и получать платежи, оспаривать платёж.
          <br />- Обеспечить Пользователю максимально быстрое решение проблем,
          встречающихся при использовании сайта, за счёт эффективной клиентской
          и технической поддержки.
          <br />- Рекламировать товары сайта, если Пользователь изъявит на то
          своё согласие.
          <br />- Предоставить Пользователю доступ на сайты или сервисы сайта,
          помогая ему тем самым получать продукты, обновления и услуги.
        </Text>
        <Text>5. Способы и сроки обработки персональной информации</Text>
        <Text>
          1. Срок обработки персональных данных Пользователя ничем не ограничен.
          Процедура обработки может проводиться любым предусмотренным
          законодательством способом. В частности, с помощью информационных
          систем персональных данных, которые могут вестись автоматически либо
          без средств автоматизации.
        </Text>
        <Text>
          2. Обработанные Администрацией сайта персональные данные Пользователя
          могут передаваться третьим лицам, в число которых входят организации
          почтовой связи, операторы электросвязи. Согласие Пользователя на
          подобную передачу предусмотрено правилами политики сайта.
        </Text>
        <Text>
          3. Также обработанные Администрацией сайта персональные данные могут
          передаваться уполномоченным органов государственной власти, если это
          осуществляется на законных основаниях и в предусмотренном
          законодательством порядке.
        </Text>
        <Text>
          4. Если персональные данные будут утрачены или разглашены,
          Пользователь уведомляется об этом Администрацией сайта.
        </Text>
        <Text>
          5. Все действия Администрации сайта направлены на то, чтобы не
          допустить к персональным данным Пользователя третьих лиц (за
          исключением п.п. 5.2, 5.3). Последним эта информация не должна быть
          доступна даже случайно, дабы те не уничтожили её, не изменили и не
          блокировали, не копировали и не распространяли, а также не совершали
          прочие противозаконные действия. Для защиты пользовательских данных
          Администрация располагает комплексом организационных и технических
          мер.
        </Text>
        <Text>
          6. Если персональные данные будут утрачены либо разглашены,
          Администрация сайта совместно с Пользователем готова принять все
          возможные меры, дабы предотвратить убытки и прочие негативные
          последствия, вызванные данной ситуацией.
        </Text>
        <Title>6. Обязательства сторон</Title>
        <Text>
          1. В обязанности Пользователя входит:
          <br />- Сообщение соответствующих требованиям сайта сведений о себе.
          <br />- Обновление и дополнение предоставляемых им сведений в случае
          изменения таковых.
        </Text>
        <Text>
          2. В обязанности Администрации сайта входит: <br />- Применение
          полученных сведений исключительно в целях, обозначенных в п. 4
          существующей Политики конфиденциальности.
          <br />- Обеспечение конфиденциальности поступивших от Пользователя
          сведений. Они не должны разглашаться, если Пользователь не даст на то
          разрешение. Также Администрация не имеет права продавать, обменивать,
          публиковать либо разглашать прочими способами переданные Пользователем
          персональные данные, исключая п.п. 5.2 и 5.3 существующей Политики
          конфиденциальности.
          <br />- Принятие мер предосторожности, дабы персональные данные
          Пользователя оставались строго конфиденциальными, точно также, как
          остаются конфиденциальными такого рода сведения в современном деловом
          обороте.
          <br />- Блокировка персональных пользовательских данных с того
          момента, с которого Пользователь либо его законный представитель
          сделает соответствующий запрос. Право сделать запрос на блокировку
          также предоставляется органу, уполномоченному защищать права
          Пользователя, предоставившего Администрации сайта свои данные, на
          период проверки, в случае обнаружения недостоверности сообщённых
          персональных данных либо неправомерности действий.
        </Text>

        <Title>7. Ответственность сторон</Title>
        <Text>
          1. В случае неисполнения Администрацией сайта собственных обязательств
          и, как следствие, убытков Пользователя, понесённых из-за
          неправомерного использования предоставленной им информации,
          ответственность возлагается на неё. Об этом, в частности, утверждает
          законодательство. Исключение существующая в настоящее время Политика
          конфиденциальности делает для случаев, отражённых в п.п. 5.2, 5.3 и
          7.2.
        </Text>
        <Text>
          2. Но существует ряд случаев, когда Администрация сайта
          ответственности не несёт, если пользовательские данные утрачиваются
          или разглашаются. Это происходит тогда, когда они: - Превратились в
          достояние общественности до того, как были утрачены или разглашены.
          <br />- Были предоставлены третьими лицами до того, как их получила
          Администрация сайта. <br />- Разглашались с согласия Пользователя.
        </Text>

        <Title>8. Разрешение споров</Title>
        <Text>
          1. Если Пользователь недоволен действиями Администрации сайта и
          намерен отстаивать свои права в суде, до того как обратиться с иском,
          он в обязательном порядке должен предъявить претензию (письменно
          предложить урегулировать конфликт добровольно).
        </Text>
        <Text>
          2. Получившая претензию Администрация обязана в течение 30 календарных
          дней с даты её получения письменно уведомить Пользователя о её
          рассмотрении и принятых мерах.
        </Text>
        <Text>
          3. Если обе стороны так и не смогли договориться, спор передаётся в
          судебный орган, где его должны рассмотреть согласно действующему
          законодательству.
        </Text>
        <Text>
          4. Регулирование отношений Пользователя и Администрации сайта в
          Политике конфиденциальности проводится согласно действующему
          законодательству.
        </Text>

        <Title>9. Дополнительные условия</Title>
        <Text>
          1. Администрация сайта вправе менять существующую на текущий момент
          Политику конфиденциальности, не спрашивая согласия у Пользователя.
        </Text>
        <Text>
          2. Вступление в силу новой Политики конфиденциальности начинается
          после того, как информация о ней будет выложена на сайт сайта, если
          изменившаяся Политика не подразумевает иного варианта размещения.
        </Text>
        <Text>
          3. Все предложения, пожелания, требования или вопросы по настоящей
          Политике конфиденциальности следует сообщать путем отправки заявки в
          разделе сайта: https://freez-blood.com/support
        </Text>
        <Text>
          4. Прочитать о существующей Политике конфиденциальности можно, зайдя
          на страницу по https://freez-blood.com/privacy-policy
        </Text>
      </Wrapper>
    </Layout>
  );
}
