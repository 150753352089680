export interface Violation {
  type: "ban" | "voice_mute";
  id: string | number;
  server_id: string;
  steamid: string;
  user_ip: string;
  player_steamid64?: string;
  user_name: string;
  admin: string;
  reason: string;
  created_at: string | number;
  end_timestamp?: string;
  expired_at: string;
  server_ip?: string;
}

export enum ViolationTitles {
  ID,
  user_name = "Ник забаненного:",
  PLAYER_IP = "Ip забаненного:",
  PLAYER_STEAMID64 = "Steam id забаненного:",
  ADMIN_NICKNAME = "Ник админа:",
  REASON = "Причина:",
  created_at = "Дата бана:",
  END_TIMESTAMP = "Дата окончания:",
  TERM = "Срок:",
  SERVER_IP = "Ip сервера:",
}

export enum ViolationTitlesEnum {
  BAN = "Ban id:",
  MUTE = "Mute id:",
}

export type ViolationType = "ban" | "voice_mute";
