import { LazyImage } from "./../Image/Styles";
import styled, { css } from "styled-components";
import { AvatarVariants } from "interfaces/Avatar";

interface WrapperProps {
  size: AvatarVariants;
  isBorder?: boolean;
}

export const Wrapper = styled.div<WrapperProps>`
  border: 0.2rem solid
    ${(props) =>
      props.isBorder
        ? props.theme.colors.blue.accent
        : props.theme.stroke.stroke5};
  cursor: pointer;
  border-radius: 50%;
  overflow: hidden;
  ${(props) =>
    props.size === "small" &&
    css`
      width: 5rem;
      height: 5rem;
    `}
  ${(props) =>
    props.size === "ava" &&
    css`
      width: 10rem;
      height: 10rem;
    `}
     ${(props) =>
    props.size === "ava2" &&
    css`
      width: 14rem;
      height: 14rem;
    `}
  ${(props) =>
    props.size === "small-6" &&
    css`
      width: 6rem;
      height: 6rem;
    `}
	${(props) =>
    props.size === "small-s" &&
    css`
      width: 7rem;
      height: 5.5rem;
    `}
	${(props) =>
    props.size === "big" &&
    css`
      width: 18rem;
      height: 18rem;
    `}
	${(props) =>
    props.size === "nano" &&
    css`
      width: 2.6rem;
      height: 2.6rem;
    `}
	${(props) =>
    props.size === "super-small" &&
    css`
      width: 3.6rem;
      height: 3.6rem;
    `}
	${LazyImage} {
    overflow: hidden;
    width: 100%;
    height: 100%;
  }
`;
