
import { Controller } from 'react-hook-form'

const FormController = (Component: any) => ({ control, name, defaultValue, rules, callback = () => { }, ...props }: any) => {
	return (
		<Controller
			control={control}
			defaultValue={defaultValue}
			name={name}
			rules={rules}
			render={({
				field: { name, value, onChange, onBlur },
				fieldState: { error, isTouched },
			}) => (
				<Component
					onBlur={onBlur}
					name={name}
					value={value}
					onChange={(value: any) => {
						onChange(value)
						callback(value)
					}}
					error={error}
					isTouched={isTouched}
					{...props}
				/>
			)}
		/>
	)
}

export default FormController