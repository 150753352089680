import styled from "styled-components";

export const SliderContainer = styled.div`
  width: 40rem;
  margin: 0px 0 2rem 0rem;
  position: relative;
  height: 40px;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  @media (max-width: 430px) {
    margin: 0 0 1.5rem 0;
    height: 40px;
    gap: 1rem;
    width: 100%;
  }
`;

export const DefFlex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const DefFlexG1 = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  user-select: none;
`;

export const MediumText = styled.span`
  ${(props) => props.theme.fonts.GothamMedium20} /* margin-bottom: 2rem; */
  @media(max-width:430px) {
    ${(props) => props.theme.fonts.GothamMenuMedium14}/* margin-bottom: 2rem; */
  }
`;

export const Dot = styled.div`
  height: 6px;
  width: 6px;
  background-color: ${(props) => props.theme.colors.white.white70};
  border-radius: 50%;
`;

export const SmallText = styled.span`
  ${(props) => props.theme.fonts.GothamMedium20} /* margin-bottom: 2rem; */
  color:${(props) => props.theme.colors.white.white70};
  @media (max-width: 430px) {
    ${(props) => props.theme.fonts.GothamMenuMedium14}/* margin-bottom: 2rem; */
  }
`;
export const TrackContainer = styled.div`
  width: 100%;
  height: 10px;
  border-radius: 5px;
  position: absolute;
  top: 15px; /* Центрируем трек по высоте контейнера */
  display: flex;
  overflow: hidden;
  z-index: 1;

  margin-top: 3rem;
`;

export const TrackSegment = styled.div<{ $color: string; $width: number }>`
  background-color: ${(props) => props.$color};
  width: ${(props) => props.$width}%;
  height: 100%;
`;

export const Thumb = styled.input`
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 10px;
  position: absolute;
  top: 15px;
  /* align-items: center; */
  background: transparent;
  /* margin-right: 5rem; */
  z-index: 2;
  margin-top: 3rem;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 14px; /* Размер ползунка */
    height: 14px;
    border-radius: 50%;
    background: transparent;
    border: 4px solid white;
    cursor: pointer;
    /* transform: translateX(-25%); */
  }

  &::-moz-range-thumb {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background: transparent;
    border: 2px solid white;
    cursor: pointer;
    /* transform: translateX(-25%); */
  }
`;

export const Button = styled.button`
  margin-top: 20px;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  background-color: #007bff;
  color: white;
  font-size: 16px;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }
`;
