import { ShopService } from "API/ShopService";
import { makeAutoObservable } from "mobx";
import { toast } from "react-toastify";

class ShopState {
  constructor() {
    makeAutoObservable(this);
  }

  public id: number = 0;
  public price: number = 0;
  public isShowModal: boolean = false;
  public count: number = 0;

  async postBuy() {
    const result = await ShopService.postCredits(this.id);
    if (result) {
      toast("Успешно");
      return result;
    } else {
      toast("Недостаточно баланса");
    }
  }

  setShowModal(state: boolean) {
    this.isShowModal = state;
  }

  setId(state: number) {
    this.id = state;
  }
  setPrice(state: number) {
    // this.live.push(data);
    this.price = state;
  }
  setCount(state: number) {
    this.count = state;
  }
}

export default new ShopState();
