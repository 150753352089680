import { endpoints, url, token, headers } from "API";
import axios from "axios";

export class NotificationsService {
  static async getNotifications() {
    try {
      const response = await axios.get(url + endpoints.notifications.get, {
        withCredentials: true,
        headers: headers,
      });
      return response.data;
    } catch (error) {
      //console.log(error);
    }
  }
}
