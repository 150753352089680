import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${(props) => props.theme.gradients.shadow};
  padding: 1rem 3rem 1rem 1rem;
  border-radius: 2rem;
  @media (max-width: 900px) {
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 1rem;
  }
`;

export const WrapOneBlock = styled.div`
  display: flex;
  gap: 2rem;
  align-items: center;

  @media (max-width: 768px) {
    width: 100%;
    flex-direction: column;
    text-align: center;
    margin-bottom: 3rem;
    button {
      width: 100%;
    }
  }
`;

export const VipSpan = styled.div<{ Color: Boolean }>`
  text-wrap: nowrap;
  display: flex;
  /* font-stretch: extra-expanded; */
  justify-content: center;
  width: 100%;
  ${(props) => props.theme.fonts.GothamBold44};
  border-radius: 2rem;
  background: ${(props) =>
    props.Color == true
      ? "rgba(255, 186, 53, 0.05);"
      : "rgba(154, 187, 251, 0.05);"};
  padding: 3rem 4rem;
  color: ${(props) => (props.Color == true ? "#FFBA35" : "#9ABBFB")};
  text-align: center;
`;

export const BlockWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
`;

export const SpanAdv = styled.span`
  ${(props) => props.theme.fonts.GothamBold24}/* width: 100%; */
`;

export const OpacitySpan = styled.span`
  color: ${(props) => props.theme.colors.white.white70};
  ${(props) => props.theme.fonts.GothamMedium16}
  text-wrap: nowrap;
`;

export const WrapNoVip = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: center;
  align-self: center;
  height: 100%;
  flex-direction: column;
  gap: 2rem;
`;
