import React from "react";

export default function Reload({ ...props }: React.HTMLAttributes<SVGElement>) {
  return (
    <svg
      {...props}
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        {...props}
        d="M3.2249e-05 11.085C-0.00656641 10.1371 1.00083 9.59382 1.77947 10.0128L1.88395 10.0766L4.82915 12.0605C5.89813 12.7808 5.24377 14.4172 4.02191 14.2676L3.89324 14.2456L2.72418 13.9883C3.31839 15.6322 4.38874 17.0619 5.79876 18.0951C7.20878 19.1283 8.89459 19.7182 10.6412 19.7896C12.3879 19.8609 14.1162 19.4106 15.6058 18.4958C17.0954 17.5811 18.2789 16.2435 19.0053 14.6536C19.1301 14.3947 19.3514 14.195 19.6216 14.0973C19.8919 13.9995 20.1897 14.0113 20.4513 14.1304C20.7129 14.2494 20.9175 14.466 21.0213 14.734C21.1252 15.002 21.12 15.3 21.0069 15.5641C18.8216 20.3699 13.4228 23.0345 8.15377 21.6225C5.8291 20.9997 3.7728 19.6318 2.30004 17.7285C0.827283 15.8252 0.0193359 13.4915 3.2249e-05 11.085ZM0.99313 6.43545C3.17839 1.62972 8.57719 -1.03378 13.8462 0.377145C16.1708 0.999713 18.2271 2.36738 19.6998 4.2705C21.1726 6.17362 21.9806 8.50717 22 10.9135C22.0077 11.8625 20.9992 12.4069 20.2216 11.9868L20.116 11.923L17.1708 9.93913C16.1019 9.21882 16.7562 7.58245 17.9781 7.73201L18.1068 7.754L19.2758 8.01134C18.6816 6.36743 17.6113 4.93773 16.2012 3.90452C14.7912 2.87131 13.1054 2.2814 11.3588 2.21003C9.61214 2.13865 7.88382 2.58904 6.39418 3.50375C4.90455 4.41847 3.7211 5.75607 2.99472 7.34601C2.93706 7.48068 2.85308 7.60248 2.7477 7.70424C2.64232 7.80601 2.51766 7.8857 2.38106 7.93863C2.24445 7.99155 2.09864 8.01666 1.9522 8.01246C1.80576 8.00827 1.66163 7.97486 1.52828 7.91419C1.39493 7.85353 1.27504 7.76684 1.17566 7.6592C1.07628 7.55157 0.999408 7.42516 0.949556 7.28741C0.899704 7.14966 0.877879 7.00334 0.885362 6.85703C0.892844 6.71073 0.929485 6.5674 0.99313 6.43545Z"
        fill="white"
        fill-opacity="0.1"
      />
    </svg>
  );
}
