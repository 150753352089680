import { useState } from "react";
import { Container, Input, Item } from "./Styles";

interface Props {
  show: boolean;
  items: React.ReactElement[] | string[] | any[];
  onItemClick: ({ index }: any) => any;
}

export default function Body({ show, items, onItemClick }: Props) {
  const [useParams, SetUseParams] = useState<string>("Все");

  return (
    <Container className={show ? "active" : ""}>
      {items.map((item, index) => (
        <Item
          key={index}
          onClick={() => {
            onItemClick(index);
            SetUseParams(item);
            if (useParams == item) {
              SetUseParams("Все");
            }
          }}
        >
          <Input type="checkbox" checked={useParams == item ? true : false} />
          {item?.label ? item?.label : item}
        </Item>
      ))}
    </Container>
  );
}
