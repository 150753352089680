import MarketplaceWeapon from "./MarketplaceWeapon";
import DashboardServer from "./DashboardServer";
import SkinTransfer from "./SkinTransfer";
import Trade from "./Trade";
import History from "./History";
import TradeSelectorSkins from "./TradeSelectorSkins";
import TradeConfirm from "./TradeConfirm/TradeConfirm";
import SkinChanger from "./SkinChanger";
import Shop from "./Shop";
import { observer } from "mobx-react-lite";
import AdminUserChange from "./AdminUserChange";

import SocialBattlPass from "./SocialBattlPass/SocialBattlPass";
import CreateClansModal from "./Clans/CreateClans/CreateClans";
import ExitClan from "./Clans/ExitClan/ExitClan";
import BuyInMarket from "./Clans/BuyInMarket/BuyInMarket";
import GivePoint from "./Clans/GivePoint/GivePoint";
import BuyModalClans from "./Clans/BuyModal/BuyModal";
import BuyModal from "./BuyModal/BuyModal";
import SkinChangerModal from "./SkinChangerModal/SkinChangerModal";

export const Modals = {
  SkinChangerModal,
  BuyModalClans,
  GivePoint,
  BuyInMarket,
  ExitClan,
  CreateClansModal,
  MarketplaceWeapon,
  DashboardServer,
  SkinTransfer,
  Trade,
  History,
  TradeSelectorSkins,
  TradeConfirm,
  SkinChanger,
  BuyModal,
  Shop,
  AdminUserChange,
  SocialBattlPass,
};
