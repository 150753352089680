export default function Youtube() {
  return (
    <svg
      width="30"
      height="20"
      viewBox="0 0 30 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M28.3639 1.52514C28.8297 1.96683 29.1654 2.51651 29.3374 3.11944C30.3283 6.901 30.0994 12.8736 29.3567 16.8011C29.1846 17.4041 28.8489 17.9538 28.3831 18.3954C27.9172 18.8371 27.3375 19.1554 26.7015 19.3186C24.3736 19.9206 15.0039 19.9206 15.0039 19.9206C15.0039 19.9206 5.63405 19.9206 3.306 19.3186C2.6701 19.1554 2.09037 18.8371 1.62455 18.3954C1.15873 17.9537 0.823074 17.404 0.650999 16.8011C-0.345681 13.036 -0.0725165 7.05978 0.631663 3.13778C0.80372 2.53482 1.13937 1.98512 1.60519 1.54341C2.07101 1.10171 2.65075 0.783411 3.28666 0.620222C5.61471 0.0182222 14.9848 0 14.9848 0C14.9848 0 24.3544 0 26.6824 0.602C27.3183 0.765179 27.8981 1.08346 28.3639 1.52514ZM19.7728 9.96019L12 14.2289V5.69153L19.7728 9.96019Z"
        fill="white"
      />
    </svg>
  );
}
