import Image from "components/Image";
import styled from "styled-components";

export const Container = styled.div<{ active: boolean }>`
  /* width: 100%; */
  min-width: 25rem;
  max-width: 25rem;
  /* background: ${(props) => props.theme.gradients.shadow}; */
  height: fit-content;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  border-radius: 2.5rem;
  ${(props) =>
    props.active
      ? "background: linear-gradient(180deg, rgba(52, 255, 86, 0.05) 0%, rgba(255, 255, 255, 0.05) 100%);"
      : props.theme.mixins.defaultShadow};
  gap: 5rem;
  cursor: pointer;
  border: "red";
  @media (max-width: 400px) {
    min-width: 25rem;
    justify-content: center;
    align-items: center;
    /* width: 100%; */
  }
`;

export const Info = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  > svg {
    border-radius: 50%;
  }
`;

export const Text = styled.p`
  color: ${(props) => props.theme.colors.white.white100};
  ${(props) => props.theme.fonts.GothamRegular16};
  text-align: center;
  @media (max-width: 400px) {
    ${(props) => props.theme.fonts.GothamRegular14};
  }
`;

export const Condition = styled.p`
  ${(props) => props.theme.fonts.GothamRegular16};
  color: ${(props) => props.theme.colors.white.white100};
  text-align: center;
  padding: 1rem;
  background: ${(props) => props.theme.gradients.shadow};
  border-radius: 1rem;
`;

export const Banner = styled(Image)`
  width: 100%;
  /* height: 10rem; */
  border-radius: 2.5rem;
  object-fit: contain;
  height: 60px;
  @media (max-width: 768px) {
    height: 45px;
  }
`;
