

export default function Emoji() {
	return (
		<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g clipPath="url(#clip0_2001_16966)">
				<path d="M9 16.875C6.91142 16.875 4.90838 16.0453 3.43153 14.5685C1.95468 13.0916 1.125 11.0886 1.125 9C1.125 6.91142 1.95468 4.90838 3.43153 3.43153C4.90838 1.95468 6.91142 1.125 9 1.125C11.0886 1.125 13.0916 1.95468 14.5685 3.43153C16.0453 4.90838 16.875 6.91142 16.875 9C16.875 11.0886 16.0453 13.0916 14.5685 14.5685C13.0916 16.0453 11.0886 16.875 9 16.875ZM9 18C11.3869 18 13.6761 17.0518 15.364 15.364C17.0518 13.6761 18 11.3869 18 9C18 6.61305 17.0518 4.32387 15.364 2.63604C13.6761 0.948212 11.3869 0 9 0C6.61305 0 4.32387 0.948212 2.63604 2.63604C0.948212 4.32387 0 6.61305 0 9C0 11.3869 0.948212 13.6761 2.63604 15.364C4.32387 17.0518 6.61305 18 9 18Z" fill="white" fillOpacity="0.7" />
				<path d="M4.82066 10.7629C4.94986 10.6883 5.10339 10.6681 5.24749 10.7067C5.39158 10.7453 5.51444 10.8396 5.58904 10.9688C5.93454 11.5676 6.43172 12.0649 7.03053 12.4105C7.62934 12.7562 8.30864 12.9379 9.00004 12.9375C9.69143 12.9379 10.3707 12.7562 10.9695 12.4105C11.5684 12.0649 12.0655 11.5676 12.411 10.9688C12.4477 10.9043 12.4968 10.8477 12.5554 10.8022C12.614 10.7568 12.6811 10.7234 12.7526 10.7039C12.8242 10.6845 12.899 10.6794 12.9725 10.689C13.0461 10.6985 13.117 10.7225 13.1813 10.7596C13.2455 10.7967 13.3018 10.8462 13.3469 10.9051C13.3919 10.964 13.4249 11.0313 13.4438 11.103C13.4628 11.1747 13.4674 11.2495 13.4573 11.323C13.4473 11.3965 13.4228 11.4673 13.3853 11.5312C12.9411 12.3011 12.3019 12.9404 11.532 13.3848C10.7622 13.8292 9.88891 14.0629 9.00004 14.0625C8.11117 14.0629 7.23788 13.8292 6.46805 13.3848C5.69822 12.9404 5.05903 12.3011 4.61479 11.5312C4.5402 11.4021 4.51999 11.2485 4.55859 11.1044C4.5972 10.9603 4.69147 10.8375 4.82066 10.7629ZM7.87504 7.3125C7.87504 8.244 7.37104 9 6.75004 9C6.12904 9 5.62504 8.244 5.62504 7.3125C5.62504 6.381 6.12904 5.625 6.75004 5.625C7.37104 5.625 7.87504 6.381 7.87504 7.3125ZM12.375 7.3125C12.375 8.244 11.871 9 11.25 9C10.629 9 10.125 8.244 10.125 7.3125C10.125 6.381 10.629 5.625 11.25 5.625C11.871 5.625 12.375 6.381 12.375 7.3125Z" fill="white" fillOpacity="0.7" />
			</g>
			<defs>
				<clipPath id="clip0_2001_16966">
					<rect width="18" height="18" fill="white" />
				</clipPath>
			</defs>
		</svg>
	)
}
