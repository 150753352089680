import styled from "styled-components";

export const Col = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3rem;
  text-align: center;
`;

export const Label = styled.div`
  ${(props) => props.theme.fonts.GothamBold20};
  color: ${(props) => props.theme.colors.white.white100};
`;

export const Value = styled.div`
  ${(props) => props.theme.fonts.GothamRegular20};
  color: ${(props) => props.theme.colors.white.white100};
`;
