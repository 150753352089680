import styled from "styled-components";
export const BlockEarn = styled.span`
  padding: 1rem;
  background: ${(props) => props.theme.gradients.shadow};
  border-radius: 1rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  z-index: 1000;
  /* align-items: center; */
  /* align-self: center; */
  /* width: max-content;
  height: max-content; */
  width: max-content;
`;
export const BlockHeaderInfo = styled.div<{ active: boolean }>`
  display: ${(props) => (props.active ? "block" : "none")};
  ${(props) => props.theme.fonts.GothamMedium15}
  position: absolute;
  top: -3.8rem;
  width: 35rem;
  background: black;
  /* right: 40rem; */
  text-align: center;
  left: -14.5rem;
  padding: 0.5rem 0;
  border-radius: 1rem;
`;

export const VerticalLineE = styled.div<{ active: Boolean }>`
  /* width: 3px;
  min-height: 20rem;

  background-color: #ffc700; */

  z-index: 1000;
  position: absolute;
  top: -0.1rem;

  left: 4px;
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 0 15px 10px 15px;
  border-color: transparent transparent black transparent;
  transform: rotate(-180deg);
  display: ${(props) => (props.active ? "block" : "none")};
`;
