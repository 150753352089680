import { DropDownHead, Icon, SubTitle, Title } from "./Styles";
import { TinyIcons } from "assets/tiny-icons";
interface Props {
  params: {
    title?: string;
    icon: React.ReactElement | string;
  };
  label?: string;
  setBodyShow: React.SetStateAction<any>;
  show: boolean;
  defaultValue?: string;
  value: string;
  useSort?: boolean;
}

export default function Head({
  params,
  setBodyShow,
  show,
  label,
  defaultValue,
  value,
  useSort,
}: Props) {
  const Drop = () => {
    return defaultValue ? (
      <SubTitle>{defaultValue}</SubTitle>
    ) : (
      <SubTitle>{label ? label : "Не выбрано"}</SubTitle>
    );
  };

  const d = useSort !== undefined ? useSort : true;

  return (
    <DropDownHead
      onClick={() => setBodyShow(!show)}
      className={show && d ? "active" : ""}
    >
      <Title>{params.title}</Title>

      {value == "pric" ? (
        <>
          <TinyIcons.SortArrPrice />
        </>
      ) : null}

      {value == "rar" ? (
        <>
          <TinyIcons.SortArrPrice />
        </>
      ) : null}

      {value !== "pric" && value !== "rar" ? (
        <>
          <Drop />
          <Icon>{params.icon}</Icon>
        </>
      ) : null}
    </DropDownHead>
  );
}
