import { CirclesWithBar } from "react-loader-spinner";

interface Props {
  visible?: boolean;
  wrapperClass?: string;
  height?: string | number;
  width?: string | number;
  color?: string;
  outerCircleColor?: string;
  innerCircleColor?: string;
  barColor?: string;
  ariaLabel?: string;
}

export default function Loader({ ...params }: Props) {
  return (
    <CirclesWithBar
      height="150"
      width="150"
      color="#1E91E4"
      wrapperStyle={{}}
      wrapperClass=""
      visible={true}
      outerCircleColor=""
      innerCircleColor="#115B90"
      barColor="#72BAEE"
      ariaLabel="circles-with-bar-loading"
      {...params}
    />
  );
}
