export default function Sold() {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.2748 19.3526C13.6637 19.3526 12.0837 19.3839 10.4726 19.3526C8.58278 19.3213 7.68432 18.5707 7.18862 16.7255C6.01132 12.5973 4.77207 8.46911 3.59478 4.34089C3.28496 3.24629 2.85122 2.43316 1.48804 2.68335C0.77547 2.80845 0.0319174 2.68335 0.000935987 1.77639C-0.0300454 0.869437 0.713508 0.806888 1.42608 0.775613C3.99753 0.68179 4.64814 1.15091 5.32973 3.71541C6.13525 6.68647 6.13525 6.68647 9.17142 6.68647C14.1594 6.68647 19.1784 6.68647 24.1664 6.68647C26.0253 6.68647 26.2731 7.03049 25.7774 8.81313C25.0339 11.5027 24.3213 14.2236 23.5778 16.9132C23.0821 18.6958 22.1836 19.3526 20.3247 19.3526C18.6827 19.3526 16.9787 19.3526 15.2748 19.3526Z"
        fill="white"
      />
      <path
        d="M20.3856 25.2323C19.3323 25.2636 18.3718 24.3879 18.3718 23.3245C18.3409 22.2925 19.2703 21.323 20.3237 21.3543C21.47 21.3855 22.1206 22.0736 22.2755 23.1682C22.3684 24.2002 21.439 25.201 20.3856 25.2323Z"
        fill="white"
      />
      <path
        d="M10.596 21.354C11.6804 21.354 12.5478 22.2297 12.5478 23.3243C12.5478 24.3876 11.6184 25.2946 10.565 25.232C9.4497 25.1382 8.76811 24.5127 8.70615 23.3556C8.64419 22.261 9.54265 21.354 10.596 21.354Z"
        fill="white"
      />
    </svg>
  );
}
