
import { Header } from './Styles'

interface Props {
	children: any;
}

export default function SubHeader({ children }: Props) {
	return (
		<Header>
			{children}
		</Header>
	)
}
