import styled, { css } from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  /* height: 4.6rem; */
  width: max-content;
  gap: 0.8rem;
  border-radius: 0.8rem;
  padding: 0.4rem 1.2rem;
  cursor: pointer;
  &:hover {
    background: ${(props) => props.theme.colors.white.white10};
  }
`;

export const Img = styled.img`
  width: 3.8rem;
`;

export const Text = styled.span`
  display: block;
  text-overflow: ellipsis;
  width: max-content;
  white-space: wrap;
  overflow: hidden;
  text-wrap: wrap;
  ${(props) => props.theme.fonts.GothamBold14};
`;

export const Shadow = styled.div`
  width: 3.8rem;
  height: 3.8rem;
  background-color: #323232;
  border-radius: 0.2rem;
  filter: blur(0.2rem);
`;
