import { UserService } from "API/UserService";
import Layout from "components/Layout";
import SubHeader from "components/SubHeader";
import { useAppSelector } from "hooks/useAppSelector";
import Image from "components/Image";
import {
  ContractInventary,
  IInventoryWeaponCard,
  IItemSend,
} from "models/InventoryWeaponCard";
import { useEffect, useState } from "react";
import {
  ArrowStyle,
  BigText,
  BlockDiv,
  BlockSkins,
  FullWrapTrade,
  Info,
  ItemContr,
  MediumText,
  Name,
  StandartFlexG1,
  SubName,
} from "./styled";
import { InventoryWeaponCard } from "components/Cards";
import rarityConvector from "utils/rarityConvector";
import { TinyIcons } from "assets/tiny-icons";
import { useNavigate, useParams } from "react-router-dom";
import { WeaponCardContract } from "pages/Contract/styled";
import Button from "components/UI/Button";
import { ClansServise } from "API/ClansServise";
import { isNumber } from "lodash";
import ClansState from "../ClansState";
import { toast } from "react-toastify";

export default function ClansTrade() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [itemCont, setItemCont] = useState<(IItemSend | { inf: string })[]>([
    { inf: "1" },
    { inf: "2" },
    { inf: "3" },
    { inf: "4" },
    { inf: "5" },
  ]);
  const [InventaryTrade, setInventaryTrade] = useState<ContractInventary>();
  const [idSkin, setIdSkin] = useState<number[]>([]);
  const [rarity, setRarity] = useState<number | undefined>(undefined);

  const [page, setPage] = useState<number>(1);
  const { isAuth, user } = useAppSelector((state) => state.user);
  const windowInnerWidth = document.documentElement.clientWidth;
  const [XP, setXP] = useState<number>(0);

  useEffect(() => {
    async function StartTrade() {
      setInventaryTrade(
        await UserService.getInventory(
          user?.user?.steamid64,
          1,
          undefined,
          undefined,
          30,
          undefined,
          [99, 6]
        )
      );
    }
    StartTrade();
  }, [user]);

  useEffect(() => {
    async function UpdateTrade() {
      setInventaryTrade(
        await UserService.getInventory(
          user?.user?.steamid64,
          page,
          undefined,
          undefined,
          30,
          undefined,
          rarity == undefined ? [6, 99] : [rarity]
        )
      );
    }
    UpdateTrade();
  }, [page, rarity]);
  useEffect(() => {
    setPage(1);
  }, [rarity]);

  useEffect(() => {
    if (itemCont.filter((element) => "inf" in element).length == 5) {
      setRarity(undefined);
      setIdSkin([]);
    }
    if (rarity !== undefined && idSkin.length > 0) {
      if (rarity == 6) {
        setXP(idSkin.length * 2);
      } else if (rarity == 99) {
        setXP(idSkin.length * 8);
      }
    } else if (idSkin.length == 0) {
      setXP(0);
    }
  }, [itemCont]);

  const updateItems = (item: IItemSend) => {
    setIdSkin([...idSkin, Number(item.id)]);

    setItemCont((prevItems) => {
      // Если уже есть 5 элементов IInventoryWeaponCard, не добавляем новый
      if (prevItems.filter((element) => !(element as any).inf).length >= 5) {
        return prevItems;
      }

      // Создаем копию текущего массива элементов
      const newItems = [...prevItems];

      // Находим индекс первого элемента с типом { inf: string }
      const firstInfIndex = newItems.findIndex((element) => "inf" in element);

      // Если нашли индекс первого элемента с типом { inf: string },
      // заменяем его на новый объект типа IInventoryWeaponCard
      // if (newItems.filter((element) => "inf" in element).length == 5) {
      //   setRarity(undefined);
      // }
      if (firstInfIndex !== -1) {
        newItems[firstInfIndex] = item;
      } else {
        // Если элементы типа { inf: string } отсутствуют,
        // добавляем новый объект типа IInventoryWeaponCard в начало массива
        newItems.unshift(item);
      }

      return newItems;
    });
  };

  const removeItem = (index: number, items: IItemSend) => {
    setItemCont((prevItems) => {
      // Создаем копию текущего массива элементов
      const newItems = [...prevItems];

      // Удаляем элемент по указанному индексу
      // newItems[index] = { inf: String(index) };
      newItems.splice(index, 1);
      newItems.push({ inf: String(newItems.length + 1) });
      // // Добавляем элемент inf в конец массива

      return newItems;
    });
    setIdSkin((prevItems) =>
      prevItems.filter((item) => item !== Number(items.id))
    );
  };

  const isLastItemInventoryCard = () => {
    // Получаем последний элемент массива
    const lastItem = itemCont[itemCont.length - 1];

    // Проверяем, является ли последний элемент элементом типа IInventoryWeaponCard
    return "id" in lastItem;
  };

  return (
    <Layout
      headerElement={<div />}
      leftElement={<div />}
      page={page}
      total={InventaryTrade?.total}
      setPage={setPage}
      activePages={
        InventaryTrade?.total !== undefined && InventaryTrade.total > 0
      }
    >
      <FullWrapTrade>
        <StandartFlexG1
          style={{
            flexDirection: "column",
            justifyContent: "center",
            gap: "2rem",
          }}
          NoAling={true}
        >
          <StandartFlexG1
            style={{ cursor: "pointer" }}
            onClick={() => navigate(`/clans/${id}`)}
          >
            <ArrowStyle right={false} />
            <MediumText>Вернуться</MediumText>
          </StandartFlexG1>
          <BlockSkins style={{ alignSelf: "center" }}>
            {itemCont.map((item, index) => {
              if ("inf" in item) {
                return (
                  <ItemContr>
                    <Image
                      src="/static/images/WeaponContract.png"
                      alt="Weapon"
                      // width="120rem"
                      height="90rem"
                      maxWidth="143px"
                      minWidth="143px"
                    />
                    {index + 1}
                  </ItemContr>
                );
              } else {
                return (
                  <WeaponCardContract
                    rarity={rarityConvector(+item.rarity)}
                    isSelected={false}
                    onlyHoverPanel={false}
                    // onClick={() => {
                    //   addItemNewInv(item);
                    //   removeItem(index, item);
                    // }}
                    onClick={() => {
                      removeItem(index, item);
                    }}
                  >
                    <BlockDiv rarity={rarityConvector(+item.rarity)}>
                      &nbsp;
                    </BlockDiv>
                    <Info>
                      <Image
                        src={item.src}
                        alt={"Weapon"}
                        width={windowInnerWidth > 768 ? "180rem" : "150rem"}
                        height={windowInnerWidth > 768 ? "150rem" : "120rem"}
                      />
                      <Name>
                        <SubName rarity={rarityConvector(+item.rarity)}>
                          &nbsp;{item.skin || item.weapon}
                        </SubName>
                      </Name>
                    </Info>
                  </WeaponCardContract>
                );
              }
            })}
          </BlockSkins>
          <StandartFlexG1 style={{ alignSelf: "center" }}>
            <StandartFlexG1 style={{ flexDirection: "column" }}>
              <MediumText style={{ opacity: "60%" }}>Кол-во очков</MediumText>
              <StandartFlexG1 style={{ alignItems: "center" }}>
                <TinyIcons.XpClans />
                <BigText> {XP}</BigText>
              </StandartFlexG1>
            </StandartFlexG1>
            <Button
              variant="default"
              height="5rem"
              color="blue"
              disabled={idSkin.length < 5}
              onClick={async () => {
                const result = await ClansServise.CreateContract(
                  idSkin,
                  rarity || -1
                );
                if (isNumber(result)) {
                  if (ClansState !== undefined && ClansState.SepClan?.members) {
                    let copyClan = { ...ClansState.SepClan }; // Копируем объект
                    const member = copyClan.members.find(
                      (item) => item.user.steamid64 == user?.user?.steamid64
                    );
                    if (member) {
                      member.xp = result; // Присваиваем xp, если пользователь найден
                    }
                    ClansState.SetSepClans(copyClan); // Не забудьте сохранить обновленные данные
                    toast.success("Успешно");
                    navigate(`/clans/${id}`);
                  } else {
                    toast.success("Успешно");
                    navigate(`/clans/${id}`);
                  }
                } else {
                  toast.error("Что-то пошло не так");
                }
              }}
            >
              Обменять на очки
            </Button>
          </StandartFlexG1>
        </StandartFlexG1>
        <StandartFlexG1 style={{ flexDirection: "column", gap: "2rem" }}>
          {InventaryTrade && InventaryTrade.inventory.length == 0 ? null : (
            <MediumText>Ваши скины</MediumText>
          )}

          <StandartFlexG1
            style={
              InventaryTrade !== undefined &&
              InventaryTrade.inventory.length == 0
                ? { justifyContent: "center" }
                : { flexWrap: "wrap" }
            }
          >
            {InventaryTrade && InventaryTrade.inventory.length !== 0 ? (
              InventaryTrade?.inventory?.map((items, index) => {
                if (!idSkin.includes(items.id)) {
                  return (
                    <InventoryWeaponCard
                      key={index}
                      src={items.src}
                      rarity={rarityConvector(+items.rarity)}
                      weapon={items.weapon}
                      skin={items.skin}
                      cl={false}
                      onClick={() => {
                        if (!isLastItemInventoryCard()) {
                          if (rarity !== undefined && rarity == items.rarity) {
                            updateItems(items);
                          } else if (rarity == undefined) {
                            updateItems(items);
                            setRarity(items.rarity);
                          }
                        }
                      }}
                    />
                  );
                }
              })
            ) : (
              <StandartFlexG1 style={{ alignSelf: "center" }}>
                <BigText style={{ textAlign: "center", alignItems: "center" }}>
                  У вас нет подходящих скинов
                </BigText>
              </StandartFlexG1>
            )}
          </StandartFlexG1>
        </StandartFlexG1>
      </FullWrapTrade>
    </Layout>
  );
}
