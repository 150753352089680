import { colors } from "./colors";
import { shadows } from "./shadows";
import { stroke } from "./stroke";

export const mixins = {
  dfcenter: `
    display: flex;
    align-items: center;
    justify-content: center;
  `,
  dfspaceBetween: `
    display: flex;
    align-items: center;
    justify-content: space-between;
  `,
  ellipsis: `
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  `,
  defaultShadow: `
    ${shadows.defaultShadow};
    stroke: ${stroke.stroke5}
  `,
  defaultShadowGreen: `
    ${shadows.defaultShadowGreen};
    stroke: ${stroke.stroke5}
  `,
  blueColor: `
      transition: .15s color;
    color: ${colors.blue.accent};
    &:hover {
      color: ${colors.blue.hover};
    };
    &:active {
      color: ${colors.blue.active};
    };
  `,
  redColor: `
    transition: .15s color;
    color: ${colors.red.accent};
    &:hover {
      color: ${colors.red.hover};
    };
    &:active {
      color: ${colors.red.active};
    };
  `,
};
