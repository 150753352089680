export default function Exit() {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="20.000000pt"
      height="20.000000pt"
      viewBox="0 0 512.000000 512.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill="#000000"
        stroke="none"
      >
        <path
          d="M1045 4786 c-41 -18 -83 -69 -90 -109 -3 -18 -4 -982 -3 -2144 l3
-2112 21 -27 c11 -15 33 -37 48 -48 27 -21 28 -21 1525 -21 l1498 0 35 27 c68
52 69 60 66 543 -3 413 -4 431 -24 465 -30 52 -88 82 -147 77 -54 -5 -91 -27
-124 -77 -23 -33 -23 -37 -23 -376 l0 -344 -1280 0 -1280 0 0 1920 0 1920
1280 0 1280 0 0 -346 c0 -387 1 -394 69 -440 71 -48 169 -27 225 49 20 28 21
40 24 470 3 496 2 503 -66 555 l-35 27 -1486 2 c-1220 2 -1491 0 -1516 -11z"
        />
        <path
          d="M3930 3349 c-83 -33 -126 -142 -86 -217 8 -15 101 -112 205 -217
l191 -190 -874 -5 c-868 -5 -875 -5 -903 -26 -98 -73 -98 -195 0 -268 28 -21
35 -21 903 -26 l874 -5 -191 -190 c-104 -104 -197 -202 -205 -217 -8 -15 -14
-47 -14 -71 0 -113 126 -190 226 -139 22 12 186 168 381 364 297 298 342 348
348 380 17 91 21 85 -348 456 -188 189 -359 352 -379 363 -40 21 -88 24 -128
8z"
        />
      </g>
    </svg>
  );
}
