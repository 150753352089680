export default function Yuap() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.77389 14.0502L4.09632 8.15418C3.49018 7.18239 2.14773 6.84944 1.09787 7.4105C0.0480143 7.97155 -0.311694 9.21417 0.294443 10.1859L5.78198 18.9838C6.3707 19.9276 7.65403 20.2689 8.68923 19.7736C9.15283 19.615 9.56155 19.3113 9.82799 18.8841L19.7056 3.048C20.3117 2.07621 19.952 0.833603 18.9021 0.272545C17.8523 -0.288513 16.5098 0.0444437 15.9037 1.01622L7.77389 14.0502Z"
        fill="white"
        fill-opacity="0.1"
      />
    </svg>
  );
}
