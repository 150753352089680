import styled from "styled-components";
import { Container } from "components/Row/Styles";
import { Button } from "components/UI/Button/Styles";

export const WrapperTradeRoom = styled.div`
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  /* align-items: center; */
  gap: 2rem;
`;
export const WrapperSwap = styled.div`
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  gap: 2rem;
`;

export const Wait = styled.span`
  display: block;
  ${(props) => props.theme.fonts.GothamRegular20};
  color: ${(props) => props.theme.colors.white.white100};
  text-align: center;
  margin-bottom: 2rem;
`;
export const Header = styled.span`
  ${(props) => props.theme.fonts.GothamBold32};
  color: ${(props) => props.theme.colors.white.white100};
  text-align: center;
`;

export const WrapperColumnTrade = styled.div`
  display: flex;
  /* flex-direction: column; */
  justify-content: space-between;
  width: 100%;
  gap: 3rem;
  align-items: center;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const WrapperInventory = styled.div``;

export const Grid = styled.div`
  display: grid;
  transition: all 0.3s;
  gap: 1.5rem;
  grid-template-columns: repeat(auto-fill, minmax(19.7rem, 1fr));
  @media (max-width: 1250px) {
    display: flex;
    flex-wrap: wrap;
    gap: 3rem;
    align-items: center;
    justify-content: center;
  }
  @media (max-width: 768px) {
    gap: 1rem;
  }
`;
export const Wrapper = styled.div`
  ${Container} {
    position: relative;
    ${Button} {
      padding: 1.25rem 4.6rem;
    }
  }
  margin-bottom: 2rem;
`;
