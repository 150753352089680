import Row from "components/Row";
import { INotificationBody } from "interfaces/Notifications";

import Avatar from "components/Avatar";
import HistoryState from "components/Modal/History/HistoryState";
import UIKIT from "components/UI";
import { useEffect } from "react";
import { tradeData } from "utils/convertData";
import { Body, Container, Head, RowContainer, Time, Title } from "./Styles";

interface Props {
  data: INotificationBody;
}

export default function ProfileNotification({ data }: Props) {
  // useEffect(() => {
  //   console.log(data);
  // }, []);

  return (
    <Container>
      <Head>
        <RowContainer>
          <Row gap="1rem">
            <Avatar isBorder={false} src={data.avatar} size="small-6" />
            <Title>{data.name}</Title>
            <Time>{tradeData(data.date) || " "} </Time>
          </Row>
          <UIKIT.Button
            onClick={() => {
              HistoryState.setSelectHistory(data);
              HistoryState.setShowModal(true);
            }}
            color="blue"
            variant="default"
            height=""
          >
            Результат обмена
          </UIKIT.Button>
        </RowContainer>
        {/* <Close onClick={closeHandler} /> */}
      </Head>
      <Body>{/* {data.body} */}</Body>
    </Container>
  );
}
