import styled from "styled-components";

export const Container = styled.div`
  padding: 4rem 1.5rem 1.5rem 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2rem;
`;

export const DefWrapFlexGap1Column = styled.div`
  /* padding: 4rem 1.5rem 1.5rem 1.5rem; */
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  gap: 1rem;
`;

export const DefWrapFlexBeet = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const DefWrapFlexGap2 = styled.div`
  display: flex;
  /* justify-content: space-between; */
  gap: 1rem;
  align-items: center;
  width: 100%;
`;

export const TextBig = styled.span`
  ${(props) => props.theme.fonts.GothamBold44}
  align-items: center;
  display: flex;
`;

export const TextMedium = styled.span`
  ${(props) => props.theme.fonts.GothamBold20}
  align-items: center;
  display: flex;
`;

export const ArrowStyle = styled.div<{ right: boolean }>`
  width: 10px;
  height: 10px;
  border-top: 2px solid white !important;
  border-right: 2px solid white !important;
  transform: rotate(${(props) => (props.right ? "45deg" : "-135deg")});
`;

export const SmallText = styled.span`
  display: flex;
  align-items: center;
  ${(props) => props.theme.fonts.GothamBold16}
  align-self: center;
`;

export const SmallText70 = styled.span`
  display: flex;
  align-items: center;
  ${(props) => props.theme.fonts.GothamBold16}
  align-self: center;
  color: ${(props) => props.theme.colors.white.white70};
`;
