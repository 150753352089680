import styled, { css } from "styled-components";

export const Text = styled.span`
  ${(props) => props.theme.fonts.GothamMedium20}
`;

export const TextVip = styled.span`
  ${(props) => props.theme.fonts.GothamMedium16}
  color:#F4C857;
  text-decoration: underline;
  cursor: pointer;
`;

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

export const Weapon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40.8rem;
  height: 18.8rem;
  border-radius: 2rem;
  background: url('data:image/svg+xml, <svg width="66.9rem" height="66.9rem" viewBox="0 0 669 669" fill="none" xmlns="http://www.w3.org/2000/svg"><path opacity="0.07" d="M239.67 389.25L334.5 225L429.33 389.25H239.67Z" stroke="white" /><path opacity="0.1" d="M305.488 351.25L334.5 301L363.512 351.25H305.488Z" stroke="white" /><path opacity="0.03" d="M45.6805 501.25L334.5 1L623.32 501.25H45.6805Z" stroke="white" /><path opacity="0.05" d="M140.077 446.75L334.5 110L528.923 446.75H140.077Z" stroke="white" /></svg>'),
    rgba(255, 255, 255, 0.05);
  background-position: center;
  /* background-size: 150%; */
  /* margin-top: 1.7rem; */
  @media (max-width: 1250px) {
    width: auto;
    height: fit-content;
  }
`;

export const Gun = styled.img`
  width: 25.9rem;
  @media (max-width: 1250px) {
    width: 10rem;
  }
`;

export const Variants = styled.div`
  /* display: flex; */
  /* align-items: center; */
  /* justify-content: space-between; */
  @media (max-width: 1250px) {
    flex-direction: column;
    gap: 1rem;
  }
`;

export const Type = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 26rem;
  & > button {
    display: flex;
    align-items: center;
    gap: 0.4rem;
  }
  @media (max-width: 1250px) {
    width: auto;
  }
`;

export const Team = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.8rem;
  & > button {
    padding: 1.5rem;
  }
`;

export const Options = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const SkinRarity = styled.div`
  display: flex;
  align-items: center;
  gap: 0.4rem;
  flex-wrap: wrap;
  @media (max-width: 380px) {
    flex-direction: column;
  }
`;

export const OptionBox = styled.div`
  display: flex;
  align-items: center;
  gap: 1.2rem;
  width: 100%;
`;
