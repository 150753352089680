

export default function Mutes() {
	return (
		<svg width="18" height="22" viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M15.875 11C15.875 11.7755 15.7458 12.5221 15.5093 13.2179L14.39 12.0986C14.4632 11.737 14.5001 11.369 14.5 11V9.625C14.5 9.44266 14.5724 9.2678 14.7014 9.13887C14.8303 9.00993 15.0052 8.9375 15.1875 8.9375C15.3698 8.9375 15.5447 9.00993 15.6736 9.13887C15.8026 9.2678 15.875 9.44266 15.875 9.625V11ZM9 16.5C10.1248 16.5 11.1697 16.1631 12.0415 15.5829L13.0288 16.5715C12.0472 17.2833 10.8941 17.7213 9.6875 17.8406V20.625H13.8125C13.9948 20.625 14.1697 20.6974 14.2986 20.8264C14.4276 20.9553 14.5 21.1302 14.5 21.3125C14.5 21.4948 14.4276 21.6697 14.2986 21.7986C14.1697 21.9276 13.9948 22 13.8125 22H4.1875C4.00516 22 3.8303 21.9276 3.70136 21.7986C3.57243 21.6697 3.5 21.4948 3.5 21.3125C3.5 21.1302 3.57243 20.9553 3.70136 20.8264C3.8303 20.6974 4.00516 20.625 4.1875 20.625H8.3125V17.8406C6.61669 17.6702 5.04464 16.8759 3.90133 15.6119C2.75803 14.348 2.12498 12.7044 2.125 11V9.625C2.125 9.44266 2.19743 9.2678 2.32636 9.13887C2.4553 9.00993 2.63016 8.9375 2.8125 8.9375C2.99484 8.9375 3.1697 9.00993 3.29864 9.13887C3.42757 9.2678 3.5 9.44266 3.5 9.625V11C3.5 12.4587 4.07946 13.8576 5.11091 14.8891C6.14236 15.9205 7.54131 16.5 9 16.5ZM13.125 4.125V10.8336L5.09225 2.80088C5.40705 1.87583 6.0399 1.09252 6.87815 0.59038C7.71639 0.0882434 8.70564 -0.100129 9.66977 0.0587988C10.6339 0.217726 11.5103 0.71364 12.1431 1.45825C12.7758 2.20287 13.1238 3.14786 13.125 4.125V4.125Z" fill="white" fillOpacity="0.1" />
			<path d="M11.0432 14.5846L4.87493 8.41499V11C4.87472 11.7223 5.06417 12.432 5.42435 13.0581C5.78452 13.6842 6.30279 14.2048 6.9273 14.5677C7.55181 14.9306 8.26068 15.1232 8.98298 15.1262C9.70529 15.1292 10.4157 14.9424 11.0432 14.5846V14.5846ZM0.263184 1.86174L16.7632 18.3617L17.7367 17.3882L1.23668 0.888245L0.263184 1.86174V1.86174Z" fill="white" fillOpacity="0.1" />
		</svg>
	)
}
