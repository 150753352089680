import { INotificationBody } from "interfaces/Notifications";
import { TradeInventory } from "interfaces/Trade";
import { makeAutoObservable } from "mobx";

class HistoryState {
  constructor() {
    makeAutoObservable(this);
  }

  public live: TradeInventory[] = [];
  public historySelect: INotificationBody | undefined;
  public isShowModal: boolean = false;

  setSelectHistory(data: INotificationBody) {
    this.historySelect = data;
  }

  setShowModal(state: boolean) {
    this.isShowModal = state;
  }

  setLive(data: TradeInventory) {
    this.live = this.live.filter((item) => item.uuid !== data.uuid);

    this.live.push(data);
  }

  deleteLive(uuid: string) {
    this.live = this.live.filter((item) => item.uuid !== uuid);
  }
  deleteLiveАFull() {
    this.live = [];
  }
}

export default new HistoryState();
