import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  /* justify-content: spacebetween; */
  align-items: center;
  gap: 3rem;
`;

export const Header = styled.span`
  ${(props) => props.theme.colors.white.white100}
  ${(props) => props.theme.fonts.GothamBold44}
  text-align: center;
`;

export const WrapperVip = styled.div`
  display: flex;
  /* flex-direction: column; */
  flex-wrap: wrap;
  justify-content: center;
  gap: 3rem;
  > img {
    position: absolute;
    left: 73vw;
    top: 18rem;
    @media (max-width: 1800px) {
      display: none;
    }
  }
`;

export const WrapperVipPrice = styled.div`
  display: flex;
  /* flex-direction: column; */
  gap: 3rem;
  padding: 2rem;
`;
export const WrapPrice = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const BlockVip = styled.div`
  width: 50rem;
  display: flex;
  background: ${(props) => props.theme.gradients.shadow};
  padding: 1rem;
  border-radius: 2rem;
  justify-content: space-between;
  flex-direction: column;
  gap: 7rem;
  @media (max-width: 768px) {
    width: max-content;
  }
`;

export const NameVip = styled.div<{ Color: Boolean }>`
  display: flex;
  /* font-stretch: extra-expanded; */
  justify-content: center;
  width: 100%;
  ${(props) => props.theme.fonts.GothamBold44};
  border-radius: 2rem;
  background: ${(props) =>
    props.Color == true
      ? "rgba(255, 186, 53, 0.05);"
      : "rgba(154, 187, 251, 0.05);"};
  padding: 2rem 4rem;
  color: ${(props) => (props.Color == true ? "#FFBA35" : "#9ABBFB")};
`;

export const BlockItem = styled.div<{ Color: Boolean }>`
  display: flex;
  gap: 1rem;
  align-items: center;
  svg {
    /* fill: red; */
    stroke: ${(props) =>
      props.Color == true ? "#FFBA35" : props.theme.colors.blue.accent};
  }
`;

export const BlockVipMed = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  gap: 0.5rem;
`;

export const ItemNamePosibilities = styled.span`
  ${(props) => props.theme.fonts.GothamMedium16};
  color: ${(props) => props.theme.colors.white.white100};
`;

export const PriceVip = styled.span`
  ${(props) => props.theme.fonts.GothamBold24}
`;

export const SmallPriceVip = styled.span`
  ${(props) => props.theme.fonts.GothamMedium15};
  color: ${(props) => props.theme.colors.white.white70};
`;

export const BlockPrice = styled.div`
  display: flex;
  align-items: center;
  /* flex-direction: column; */
  /* padding: 2rem; */
  gap: 2rem;
  width: 100%;
`;
export const Blocktest = styled.div`
  display: flex;
  align-items: center;
  /* flex-direction: column; */
  /* padding: 2rem; */
  gap: 2rem;
  /* width: max-content; */
  margin-bottom: 5rem;
  flex-wrap: wrap;
  justify-content: center;
`;
