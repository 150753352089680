import { Button } from "components/UI/Button/Styles";
import styled from "styled-components";
import { Params } from "./UserSupportBlock";

export const Container = styled.div<{ params?: Params }>`
  width: 100%;
  border-radius: 2rem;
  ${(props) => props.theme.mixins.defaultShadow};
  padding: 3rem;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  ${Button} {
    /* width: fit-content; */
    width: 100%;
    padding: 1.5rem 7.5rem;
  }
  @media (max-width: 768px) {
    ${Button} {
      /* width: fit-content; */
      width: 100%;
      padding: 1.5rem 1.5rem;
    }
  }
`;

export const Title = styled.h1<{ params?: Params }>`
  width: 100%;
  text-align: center;
  ${(props) =>
    props.params?.titleFont
      ? props.params.titleFont
      : props.theme.fonts.GothamMedium32};
  color: ${(props) => props.theme.colors.white.white100};
`;

export const DefaultFlexWrap = styled.div`
  display: flex;
  gap: 5rem;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;
