import styled from "styled-components";

export const Card = styled.div``;
export const DefFlex = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const IconsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1.5rem;
`;

export const NonAuth = styled.div`
  display: flex;
  align-items: center;
  gap: 3rem;
`;
export const WrapperMenuProfile = styled.div<{ Active?: boolean }>`
  padding: 0 20px 0 20px;
  transform: rotate(${(props) => (props.Active ? "0" : "180deg")});
  transition: 0.3s;
  cursor: pointer;
  svg {
    width: 20px;
    height: 10px;
    cursor: pointer;
  }
  @media (max-width: 1250px) {
    padding: 0;
    svg {
      display: none;
    }
  }
`;
export const Menu = styled.div<{ Active: boolean }>`
  display: ${(props) => (props.Active ? "" : "none")};
  position: ${(props) => (props.Active ? "absolute" : "none")};
  top: 80px;
  right: 100px;
  padding: 1rem 2rem;
  z-index: 1000;
  /* background-color: rgba(84, 44, 44, 0.8); */
  background-color: ${(props) => props.theme.gradients.shadow};
  backdrop-filter: blur(100px);
  /* background-color: ${(props) => props.theme.gradients.shadow}; */
  border-radius: 10px;
  ${(props) => props.theme.fonts.GothamBold16};
  transition: 0.5s;
  /* padding: 10px; */
  align-items: center;

  @media (max-width: 1250px) {
    right: 0;
  }
  svg {
    opacity: 50%;
  }
  hr {
    opacity: 5%;
    margin: 5px 0;
  }
`;

export const WrapperProfile = styled.div`
  /* width: 100%; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* margin-left: 20px; */
  /* margin-left: 5px; */

  align-items: left;
  /* cursor: pointer; */
`;
export const WrapperTitle = styled.div`
  cursor: pointer;
  display: flex;
  border-radius: 10px;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  gap: 2rem;
  transition: 0.3s;
  :hover {
    transition: 0.3s;
    background-color: ${(props) => props.theme.gradients.shadow};
  }
  img {
    width: 100%;
  }
`;
export const WrapperItem = styled.div`
  ${(props) => props.theme.fonts.GothamBold16};
  padding: 2rem 2.3rem;
  text-align: center;
  align-items: center;
  display: flex;
  border-radius: 20px;
  /* justify-content: center; */
  /* margin: 0 0 0 20px; */
  svg {
    margin-right: 10px;
  }
  cursor: pointer;
  :hover {
    transition: 0.3s;
    background-color: ${(props) => props.theme.gradients.shadow};
  }
`;

export const ExitItem = styled.div`
  ${(props) => props.theme.fonts.GothamBold16};
  padding: 10px 0;
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: center;

  svg {
    margin-right: 10px;
  }
  cursor: pointer;
  :hover {
    border-radius: 5px;
    background-color: rgba(84, 44, 44, 1);
    div {
      border-radius: 5px;

      background-color: rgba(84, 44, 44, 1);
    }
  }
`;
export const Text = styled.span`
  margin-top: 10px;
  ${(props) => props.theme.fonts.GothamChatRegular12};
`;
export const Title = styled.span`
  ${(props) => props.theme.fonts.GothamBold16};
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 10rem;
  overflow: hidden;
`;
export const Name = styled.span`
  ${(props) => props.theme.fonts.GothamRegular14};
  text-overflow: ellipsis;
  white-space: wrap;
  width: 10rem;
  margin-top: 1rem;
  /* overflow: hidden; */
`;
export const Auth = styled.div`
  display: flex;
  align-items: center;
`;

export const Balance = styled.div`
  display: flex;
  margin: 0 1rem;
  /* align-items: center; */
  ${(props) => props.theme.fonts.GothamBold20}@media(max-width:1250px) {
    display: block;
  }
`;

export const Highlighted = styled.div`
  ${(props) => props.theme.fonts.GothamBold20};
  color: ${(props) => props.theme.colors.blue.accent};
`;

export const AvatarWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1.5rem;
`;
