import { Icons } from "assets/icons";
import Row from "components/Row";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import SkinChangerState from "../SkinChangerState";
import { GunContainer, GunElem, Type } from "./Styles";
// import { useState } from 'react'

type HeaderProps = {
  setCanBeUsedCT: Dispatch<SetStateAction<boolean>>;
  setCanBeUsedTT: Dispatch<SetStateAction<boolean>>;
  setAgentPick: Dispatch<SetStateAction<boolean>>;
  agentPick: boolean;
};

export default function Header({
  setCanBeUsedCT,
  setCanBeUsedTT,
  setAgentPick,
  agentPick,
}: HeaderProps) {
  const [activeWindow, setActiveWindow] = useState<string | null>(null);
  const [selectedElem, setSelectedElem] = useState<number | null>(null); // Состояние для отслеживания выбранного элемента

  const handleSetActiveWindow = (windowName: string) => {
    setActiveWindow((prevActiveWindow) =>
      prevActiveWindow === windowName ? null : windowName
    );
  };

  const handleClick = () => {
    SkinChangerState.getAgents();
    setAgentPick(true);
  };

  return (
    <Row justifyContent="start" gap="5rem">
      {SkinChangerState.categories.map((cat) => {
        // setDrop(cat.items.);
        if (cat.single) {
          return (
            <Type
              onClick={() => {
                handleClick();
              }}
            >
              {cat.name}
            </Type>
          );
        }

        return (
          <DD
            setAgentPick={setAgentPick}
            key={cat.id}
            agentPick={agentPick}
            cat={cat}
            isActive={activeWindow === cat.name}
            setActive={() => handleSetActiveWindow(cat.name)}
            selectedElem={selectedElem}
            setSelectedElem={setSelectedElem}
            setCanBeUsedCT={setCanBeUsedCT}
            setCanBeUsedTT={setCanBeUsedTT}
          />
        );
      })}
    </Row>
  );
}
function DD({
  cat,
  isActive,
  setActive,
  setCanBeUsedTT,
  setCanBeUsedCT,
  selectedElem,
  setAgentPick,
  agentPick,
  setSelectedElem,
}: {
  cat: any;
  agentPick: boolean;
  isActive: boolean;
  setAgentPick: Dispatch<SetStateAction<boolean>>;
  setActive: () => void;
  setCanBeUsedTT: Dispatch<SetStateAction<boolean>>;
  setCanBeUsedCT: Dispatch<SetStateAction<boolean>>;
  selectedElem: number | null;
  setSelectedElem: (elemId: number | null) => void;
}) {
  const [drop, setDrop] = useState(false);

  useEffect(() => {
    if (isActive) {
      setDrop(true);
    }
  }, [isActive]);

  const handleClick = () => {
    setDrop(!drop);
    if (!isActive) {
      setActive();
    }
  };
  const handleClickIT = () => {
    setAgentPick(false);
    //console.log("fsdfsdfsdfsdf false");
  };

  const handleItemClick = (elemId: number) => {
    SkinChangerState.selectCategories(elemId);
    setDrop(false);
    setSelectedElem(elemId); // Устанавливаем выбранный элемент
  };

  // //console.log(cat.name);
  return (
    <Type onClick={handleClick}>
      {cat.name}

      {!cat.single && drop && isActive ? (
        <div style={{ transform: drop ? "rotate(180deg)" : "rotate(0deg)" }}>
          <Icons.dropDownArrow />
        </div>
      ) : (
        <div>
          <Icons.dropDownArrow />
        </div>
      )}

      {!cat.single && drop && isActive && (
        <GunContainer>
          {cat.items.map((elem: any) => (
            <GunElem
              key={elem.id}
              onClick={() => {
                handleItemClick(elem.id);
                setCanBeUsedCT(elem.used_by_ct);
                setCanBeUsedTT(elem.used_by_tt);
                handleClickIT();
              }}
              isSelected={
                selectedElem == elem.id && agentPick == false ? true : false
              } // Передаем флаг выбранного элемента
            >
              {elem.class_en}
            </GunElem>
          ))}
        </GunContainer>
      )}
    </Type>
  );
}
