import { InventoryWeaponCard } from "components/Cards";
import { ItemLifeScroll } from "interfaces/Case";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import rarityConvector from "utils/rarityConvector";
import { WrapperL } from "./styled";

interface SliderLifeScrollProps {
  _openedItems: Array<ItemLifeScroll>;
}

// interface SliderLifeScrollProps extends Array<ItemLifeScroll> {}

export default function SliderLifeScroll(_openedItems: SliderLifeScrollProps) {
  const [hoverItem, setHoverItem] = useState<number>(-1);
  const navigate = useNavigate();
  return (
    <WrapperL>
      {_openedItems._openedItems.map((item, index) => {
        // console.log(item);
        // return 0;
        return (
          <InventoryWeaponCard
            weapon={""}
            skin={""}
            avatar={item.avatar}
            src={hoverItem == index ? item.case_img : item.image}
            rarity={rarityConvector(+item.rarity)}
            cl={false}
            name={item.name}
            LifeCase={true}
            hoverItem={hoverItem == index ? true : false}
            onMouseEnter={() => setHoverItem(index)}
            onMouseLeave={() => setHoverItem(-1)}
            onClick={() => navigate(`/chest/${item.case_id}`)}
          />
        );
      })}
    </WrapperL>
  );
}
