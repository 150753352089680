import _ from "lodash";
import { makeAutoObservable } from "mobx";
import { IInventoryWeaponCard } from "models/InventoryWeaponCard";

class skinSelectorState {
  skins: IInventoryWeaponCard[] = [];
  mokeSkins: any[] = [
    {
      weapon: "AK47",
      skin: "test",
      src: "",
      rarity: 1,
    },
  ];

  constructor() {
    makeAutoObservable(this);
  }

  setSkins = (skins: IInventoryWeaponCard[]) => {
    this.skins = _.cloneDeep(skins);
  };

  selectSkin = (skin: IInventoryWeaponCard, amount?: any) => {
    const skinIndex = _.findIndex(this.skins, (item) => {
      return (
        item.skinInfo?.group === skin.skinInfo?.group &&
        item.skinInfo?.item === skin.skinInfo?.item
      );
    });
    const selectedSkin = this.skins[skinIndex];

    selectedSkin.isSelected = !selectedSkin.isSelected;

    if (!selectedSkin.isSelected) {
      selectedSkin.selectedCount = 0;
    } else {
      const { amount: selectAmount = 0 } = selectedSkin.skinInfo || {};

      if (amount && amount <= selectAmount && amount > 0) {
        selectedSkin.selectedCount = parseInt(amount);
      } else {
        selectedSkin.selectedCount = 0;
        selectedSkin.isSelected = false;
      }
    }
  };
}

export default new skinSelectorState();
