import { SelectVariants } from "./../../../../interfaces/Select";
import styled, { css } from "styled-components";

interface Props {
  font?: string;
  variant?: SelectVariants;
  Clans?: boolean;
}

export const BodyBlock = styled.div<Props>`
  width: ${(props) => (props.Clans ? "90%" : "fit-content")};
  position: absolute;
  top: 4rem;
  left: 0;
  opacity: 0;
  z-index: -2;
  pointer-events: none;
  height: max-content;
  background: ${(props) =>
    props.Clans
      ? props.theme.gradients.shadow
      : props.theme.colors.white.white100};
  border-radius: 1.5rem;
  display: flex;
  flex-direction: column;
  max-height: 50rem;
  gap: ${(props) => (props.Clans ? "2.5rem" : "1.5rem")};
  padding: 1.5rem;
  backdrop-filter: blur(9px);
  ${(props) => props.theme.shadows.defaultShadow};
  transition: 0.3s;
  ${(props) =>
    props.variant === "transparent" &&
    css`
      top: 1rem;
    `}
  &.active {
    opacity: 1;
    top: 7rem;
    left: 1rem;
    z-index: 150;
    pointer-events: all;
    ${(props) =>
      props.variant === "transparent" &&
      css`
        top: 3rem;
        left: 0;
      `}
  }
`;

export const Item = styled.div<Props>`
  cursor: pointer;
  color: ${(props) =>
    props.Clans
      ? props.theme.colors.white.white100
      : props.theme.colors.black.black100};
  ${(props) =>
    props.Clans
      ? props.theme.fonts.GothamRegular24
      : props.theme.fonts.GothamRegular16}
  text-align: ${(props) => (props.Clans ? "center" : "")};

  transition: all 0.15s;
  &:hover {
    ${(props) =>
      props.Clans
        ? props.theme.fonts.GothamBold24
        : props.theme.fonts.GothamBold16}
  }
`;
