import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  padding: 2rem 3rem;
  max-height: 55vh;
  height: 100%;
  ${(props) => props.theme.mixins.defaultShadow};
  background: ${(props) => props.theme.gradients.shadow};
  border-radius: 2.5rem;
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 1.5rem;
`;

export const Clear = styled.span`
  user-select: none;
  position: absolute;
  right: 3rem;
  top: 2rem;
  ${(props) => props.theme.fonts.GothamRegular20};
  ${(props) => props.theme.mixins.redColor};
  cursor: pointer;
`;

export const Wrapper = styled.div<{ gap?: string }>`
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  /* background: ${(props) => props.theme.colors.white.white10}; */
`;

export const Period = styled.h4`
  color: ${(props) => props.theme.colors.white.white70};
  ${(props) => props.theme.fonts.GothamRegular20};
`;
