export default function Reload() {
  return (
    <svg
      width="31"
      height="31"
      viewBox="0 0 31 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.00003 17.085C2.99343 16.1371 4.00083 15.5938 4.77947 16.0128L4.88395 16.0766L7.82915 18.0605C8.89813 18.7808 8.24377 20.4172 7.02191 20.2676L6.89324 20.2456L5.72418 19.9883C6.31839 21.6322 7.38874 23.0619 8.79876 24.0951C10.2088 25.1283 11.8946 25.7182 13.6412 25.7896C15.3879 25.8609 17.1162 25.4106 18.6058 24.4958C20.0954 23.5811 21.2789 22.2435 22.0053 20.6536C22.1301 20.3947 22.3514 20.195 22.6216 20.0973C22.8919 19.9995 23.1897 20.0113 23.4513 20.1304C23.7129 20.2494 23.9175 20.466 24.0213 20.734C24.1252 21.002 24.12 21.3 24.0069 21.5641C21.8216 26.3699 16.4228 29.0345 11.1538 27.6225C8.8291 26.9997 6.7728 25.6318 5.30004 23.7285C3.82728 21.8252 3.01934 19.4915 3.00003 17.085ZM3.99313 12.4355C6.17839 7.62972 11.5772 4.96622 16.8462 6.37714C19.1708 6.99971 21.2271 8.36738 22.6998 10.2705C24.1726 12.1736 24.9806 14.5072 25 16.9135C25.0077 17.8625 23.9992 18.4069 23.2216 17.9868L23.116 17.923L20.1708 15.9391C19.1019 15.2188 19.7562 13.5824 20.9781 13.732L21.1068 13.754L22.2758 14.0113C21.6816 12.3674 20.6113 10.9377 19.2012 9.90452C17.7912 8.87131 16.1054 8.2814 14.3588 8.21003C12.6121 8.13865 10.8838 8.58904 9.39418 9.50375C7.90455 10.4185 6.7211 11.7561 5.99472 13.346C5.93706 13.4807 5.85308 13.6025 5.7477 13.7042C5.64232 13.806 5.51766 13.8857 5.38106 13.9386C5.24445 13.9916 5.09864 14.0167 4.9522 14.0125C4.80576 14.0083 4.66163 13.9749 4.52828 13.9142C4.39493 13.8535 4.27504 13.7668 4.17566 13.6592C4.07628 13.5516 3.99941 13.4252 3.94956 13.2874C3.8997 13.1497 3.87788 13.0033 3.88536 12.857C3.89284 12.7107 3.92948 12.5674 3.99313 12.4355Z"
        fill="white"
      />
      {/* <g filter="url(#filter0_d_1480_131369)">
				<rect x="16" y="3" width="12" height="12" rx="6" fill="#E32020" />
			</g> */}
      <defs>
        <filter
          id="filter0_d_1480_131369"
          x="13"
          y="0"
          width="18"
          height="18"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="1.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.890196 0 0 0 0 0.12549 0 0 0 0 0.25098 0 0 0 0.7 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1480_131369"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1480_131369"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}
