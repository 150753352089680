import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import API from 'API';
import { ChatState } from 'models/Chat';

const initialState: ChatState = {
	items: [],
	isLoading: false,
}

const name = 'chat'

export const fetchChatHistory = createAsyncThunk(
	name + '/fetchChatHistory',
	async () => {
		const response = await API.ChatService.getHistory()
		return response
	}
)

export const chatSlice = createSlice({
	name,
	initialState,
	reducers: {
		setChatMessage: (state, action) => {
			state.items = [...state.items, action.payload]
		}
	},
	extraReducers: (builder) => {
		builder.addCase(fetchChatHistory.pending, (state, action) => {
			state.isLoading = true
		})
		builder.addCase(fetchChatHistory.fulfilled, (state, action) => {
			state.items = action.payload
			state.isLoading = false
		})
	}
})

export const { setChatMessage } = chatSlice.actions
export const ChatActions = chatSlice.actions
export default chatSlice.reducer