import Chat from 'modules/Chat'
import Layout from 'components/Layout'
import SubHeader from 'components/SubHeader'
import PreviousNavigate from 'components/UI/ArrowBack'
import { useAppSelector } from 'hooks/useAppSelector'
import { appSocket } from 'App'

export default function PrivateConversation() {

	const { user, messages } = useAppSelector(state => state.privateChat)

	return (
		<Layout
			leftElement={<div />}
			headerElement={
				<SubHeader>
					<PreviousNavigate />
					{user?.user?.name}
				</SubHeader>
			}
		>
			<Chat
				params={{ width: '100%', height: '100%' }}
				items={messages}
				isActive={true}
				isPrivate={true}
				source={appSocket}
				isFileInput={true}
			/>
		</Layout>
	)
}