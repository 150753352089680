import React from "react";


export default function Plus({ ...props }: React.HTMLAttributes<SVGElement>) {
	return (
		<svg {...props} width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path fill-rule="evenodd" clip-rule="evenodd" d="M9 20C9 21.1046 9.89543 22 11 22C12.1046 22 13 21.1046 13 20V13H20C21.1046 13 22 12.1046 22 11C22 9.89543 21.1046 9 20 9H13V2C13 0.89543 12.1046 0 11 0C9.89543 0 9 0.89543 9 2V9H2C0.895431 9 0 9.89543 0 11C0 12.1046 0.89543 13 2 13H9V20Z" fill="white" fill-opacity="0.1" />
		</svg>
	)
}
