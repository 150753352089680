import styled from "styled-components";

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 5.6rem 1fr;
  gap: 0 8.05rem;
  height: 45vh;
  align-items: center;
  svg {
    width: 56px;
    height: 56px;
    path {
      fill: ${(props) => props.theme.colors.white.white100};
    }
  }
  @media (max-height: 768px) {
    height: 25vh;
  }
`;
export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3rem;
`;
export const Wait = styled.span`
  display: block;
  ${(props) => props.theme.fonts.GothamRegular20};
  color: ${(props) => props.theme.colors.white.white100};
  text-align: center;
  margin-bottom: 2rem;
`;
