import styled from "styled-components";
// import {Container} from '../../Templates/Detailed/Styles.ts'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  // flex-direction: row;
  gap: 1rem 3rem;
  > div {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    > div:first-child {
      width: 150px;
      /* background-color: red; */
      > img {
        width: 100%;
      }
      > div {
        > button {
          color: red;
        }
      }
    }

    > div:last-child {
      gap: 1rem;
      > span {
        color: white;
        font-size: 30px;
        font-weight: 500;
      }
    }
  }
  > button {
    margin-top: 30px;
  }
`;
export const Wrape = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`;
export const Title = styled.div`
  ${({ theme }) => theme.fonts.GothamMedium20};
  color: ${({ theme }) => theme.colors.white.white100};
  width: 100%;
  display: flex;
  /* justify-content: center; */
  white-space: nowrap;
  justify-content: space-between;
  align-items: center;
`;
export const CounText = styled.span`
  ${({ theme }) => theme.fonts.GothamMedium15};
  color: ${({ theme }) => theme.colors.white.white70};
`;

interface TextAlingProps {
  textAling: "left" | "right";
}
export const Item = styled.span<TextAlingProps>`
  ${({ theme }) => theme.fonts.GothamBold20};
  color: ${({ theme }) => theme.colors.white.white100};
  word-wrap: break-word;
  /* width: 100%; */
  display: block;
  text-align: ${(props) => props.textAling};
  //редачил димас
  // white-space: nowrap;
  margin-top: 30px;
  /* margin-bottom: 15px; */
  //
`;

export const Admin = styled(Item)`
  ${({ theme }) => theme.mixins.redColor};
`;

export const Red = styled(Item)`
  ${({ theme }) => theme.mixins.redColor};
`;

export const WrapCount = styled.div`
  padding: 6rem 2rem;
  width: 100%;
  /* Frame 493 */

  /* Auto layout */
  display: flex;
  /* flex-direction: column; */
  justify-content: center;
  align-items: center;

  /* Creadits_Card_Gradient */
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(30, 145, 228, 0.2) 0%,
    rgba(255, 255, 255, 0.04) 100%
  );
  border-radius: 2rem;
  ${(props) => props.theme.fonts.GothamBold20}
`;
