import styled from "styled-components";

export const SliderContainer = styled.div`
  width: 40rem;
  /* margin: 50px auto; */
  gap: 1rem;
  display: flex;
  flex-direction: column;
  @media (max-width: 430px) {
    width: 100%;
  }
`;

export const DefFlex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const DefFlexG1 = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  user-select: none;
`;

export const MediumText = styled.span`
  ${(props) => props.theme.fonts.GothamMedium20} /* margin-bottom: 2rem; */
  @media(max-width:430px) {
    ${(props) => props.theme.fonts.GothamMenuMedium14}/* margin-bottom: 2rem; */
  }
`;

export const RangeSlider = styled.input<{ percentage: number }>`
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 10px;
  background: ${(props) =>
    `linear-gradient(to right, #1E91E4 ${props.percentage}%, ${props.theme.gradients.shadow} ${props.percentage}%)`};
  border-radius: 5px;
  outline: none;
  transition: background 0.3s;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 14px; /* Размер ползунка */
    height: 14px;
    border-radius: 50%;
    background: transparent;
    border: 4px solid white;
    cursor: pointer;
    /* transform: translateX(-15%); */
  }

  &::-moz-range-thumb {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background: transparent;
    border: 2px solid white;
    cursor: pointer;
    /* transform: translateX(-15%); */
  }
`;

export const ValueDisplay = styled.div`
  text-align: center;
  font-size: 18px;
  color: #333;
`;
