export const colors = {
  black: {
    black100: '#2F0B1A',
    black70: 'rgba(47, 11, 26, 0.7)',
    black30: 'rgba(47, 11, 26, 0.3)',
  },
  green: {
    accent: '#77AC46',
    hover: '#AACF88',
    pressed: '#4A6C2C',
    selected: '#1EE43E'
  },
  menu_bg: '#192240',
  lightLime: '#1EE4D8',
  lightGreen: '#1EE485',
  orange: '#E4651E',
  ct: '#518EC5',
  t: '#CAB05C',
  black_card_bg: 'rgba(47, 11, 26, 0.8)',
  play_copy_btns: 'rgba(42, 42, 42, 0.7)',
  white: {
    white10: 'rgba(255, 255, 255, 0.1)',
    white20: 'rgba(255, 255, 255, 0.2)',
    white70: 'rgba(255, 255, 255, 0.7)',
    white100: '#fff'
  },
  btn: {
    off: '#75838D',
    pressed: '#115B90',
    hover: '#72BAEE',
    off_hover: 'rgb(136, 136, 136)',
    off_active: 'rgb(98, 98, 98)'
  },
  blue: {
    accent: '#1E91E4',
    active: '#115B90',
    hover: '#72BAEE',
  },
  red: {
    accent: '#E32020',
    hover: '#EA5870',
    active: '#AD162E',
  },
  weapon: {
    common_grade: '#FFFFFF',
    industrial_grade: '#6D96D6',
    mil_spec: '#506AF2',
    restricted: '#7F50F5',
    classified: '#C241DE',
    covert: '#D95755',
    exceedingly_rare: '#FFC700'
  },
  gradient: {
    activeSC: 'linear-gradient(180deg, rgba(42, 49, 44, 0.60) 0%, rgba(38, 113, 59, 0.60) 100%)'
  }
};