import Layout from "components/Layout";
import SubHeader from "components/SubHeader";
import UIKIT from "components/UI";
import { ImageSuccess, Title, Wrapper } from "./Styles";

export default function FailMoney() {
  return (
    <Layout
      leftElement={<div />}
      headerElement={
        <>
          <UIKIT.ArrowBackMoney />
          <SubHeader>Пополнение баланса</SubHeader>
        </>
      }
    >
      <Wrapper marginTop="450px">
        <ImageSuccess src="/static/images/Success.png" alt="Success" />
        <Title>Неудача</Title>
      </Wrapper>
    </Layout>
  );
}
