import { SupportServise } from "API/SupportServise";
import ModalWrapper from "components/Templates/ModalWrapper";
import UIKIT from "components/UI";
import { IModal } from "interfaces/Modal";
import { MyBans, TicketTypes } from "interfaces/Support";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useTheme } from "styled-components";
import {
  HeadItem,
  NonBan,
  SupperHeaderInfo,
  WrapBans,
  Wrapper,
} from "./Styles";

interface Props extends IModal {}

export default function SupportModal({ isActive, setActive, idModal }: Props) {
  const Theme = useTheme();

  // const theme = useInput("");
  const [theme, setTheme] = useState<string>("");
  const [quill, setQuill] = useState<string>("");
  const [textArea, setTextArea] = useState<string>("");
  const [discord, setDiscord] = useState<string>("");
  const [myBans, setMyBans] = useState<MyBans[]>([]);
  const [ActiveBan, setActiveBan] = useState<number>(0);

  async function NewTicket(type: TicketTypes) {
    if (type == TicketTypes.BLOCK) {
      return await SupportServise.TiketCreated(
        "Разбан",
        textArea,
        type,
        ActiveBan,
        discord
      );
    } else {
      return await SupportServise.TiketCreated(theme, quill, type);
    }
  }
  const navigate = useNavigate();
  function redirectToFullTicket(id: number | string) {
    navigate(`/tickets/${id}`);
  }

  function CheckText(id: number) {
    if (id == 0) {
      if (quill.length >= 10 && theme.length >= 10) {
        return true;
      } else {
        toast("Мин кол-во символов 10");
        return false;
      }
    } else if (id == 1) {
      if (discord.length > 2 && textArea.length >= 10) {
        return true;
      } else {
        toast("Проверьте вводимую информацию");
        return false;
      }
    }
    return true;
  }

  function formatDate(timestampString: string) {
    const months = [
      "января",
      "февраля",
      "марта",
      "апреля",
      "мая",
      "июня",
      "июля",
      "августа",
      "сентября",
      "октября",
      "ноября",
      "декабря",
    ];

    const date = new Date(timestampString);
    date.setHours(date.getHours() - 3);
    const offsetMinutes = new Date().getTimezoneOffset();
    date.setMinutes(date.getMinutes() + offsetMinutes);

    const day = date.getDate();
    const month = months[date.getMonth()];
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    return `${day} ${month}  ${hours}:${minutes}`;
  }

  useEffect(() => {
    async function getU() {
      setMyBans(await SupportServise.GetBansMy());
    }
    getU();
  }, [1]);

  useEffect(() => {
    if (myBans[0]?.id) {
      setActiveBan(myBans[0].id);
    }
  }, [myBans]);

  useEffect(() => {
    if (theme.length == 70) {
      toast("Макс кол-во символов 70");
    }
  }, [theme]);

  useEffect(() => {
    if (quill.length == 500 || textArea.length == 500) {
      toast("Макс кол-во символов 500");
    }
  }, [quill, textArea]);

  useEffect(() => {
    if (discord.length == 20) {
      toast("Макс кол-во символов 20");
    }
  }, [discord]);

  if (idModal == false) {
    return (
      <ModalWrapper
        headContent="Создать обращение"
        isActive={isActive}
        setActive={setActive}
        params={{ width: "90vw", height: "fit-content" }}
        display="flex"
      >
        <Wrapper>
          <UIKIT.Input
            label="Тема"
            value={theme}
            onChange={(e) => setTheme(e.target.value)}
            placeholder="Кратко укажите тему проблемы"
            maxLength={70}
            params={{
              headFont: Theme.fonts.GothamBold24,
              font: Theme.fonts.GothamRegular16,
              width: "100%",
              height: "7.6rem",
            }}
          />
          <UIKIT.Textarea
            value={quill}
            maxLength={500}
            onChange={(e) => setQuill(e.target.value)}
            label="Текст"
            placeholder="Подробно опишите суть проблемы"
            params={{
              headFont: Theme.fonts.GothamBold24,
              width: "100%",
              height: "30rem",
            }}
          />
          <UIKIT.Button
            variant="default"
            color="blue"
            height="5rem"
            params={{ width: "fit-content", padding: "1.5rem 9.5rem" }}
            // disabled={!theme || !quill || theme == "" || quill.length < 10}
            onClick={async () => {
              if (CheckText(0)) {
                const a = await NewTicket(TicketTypes.HELP);
                if (a) {
                  redirectToFullTicket(a);
                } else {
                  toast("Ошибка");
                }
              }
            }}
          >
            Отправить
          </UIKIT.Button>
        </Wrapper>
      </ModalWrapper>
    );
  } else {
    return (
      <ModalWrapper
        headContent="Создать заявку на разблокировку"
        isActive={isActive}
        setActive={setActive}
        params={{ width: "90vw", height: "fit-content" }}
        display="flex"
      >
        {myBans.length ? (
          <>
            <WrapBans>
              <SupperHeaderInfo>
                <HeadItem>Тип</HeadItem>
                <HeadItem>Дата</HeadItem>
                <HeadItem>Сервер</HeadItem>
                <HeadItem>Срок</HeadItem>
                <HeadItem>Причина</HeadItem>
                <HeadItem>Забанил</HeadItem>
              </SupperHeaderInfo>
              {myBans.map((item) => {
                return (
                  <SupperHeaderInfo
                    ActiveBan={item.id == ActiveBan ? true : false}
                    Active={true}
                    onClick={() => setActiveBan(item.id)}
                  >
                    <HeadItem>{item.banned.type}</HeadItem>
                    <HeadItem>{formatDate(item.date)}</HeadItem>
                    <HeadItem>{item.server.name}</HeadItem>
                    <HeadItem>{formatDate(item.expiration)}</HeadItem>
                    <HeadItem>{item.reason}</HeadItem>
                    <HeadItem>{item.admin.name}</HeadItem>
                  </SupperHeaderInfo>
                );
              })}
            </WrapBans>
            <Wrapper>
              <UIKIT.Input
                label="Дискорд"
                placeholder="Введите ник дискорда"
                value={discord}
                maxLength={20}
                onChange={(e) => setDiscord(e.target.value)}
                params={{
                  headFont: Theme.fonts.GothamBold24,
                  font: Theme.fonts.GothamRegular16,
                  width: "100%",
                  height: "7.6rem",
                }}
              />
              <UIKIT.Textarea
                label="Доказательства для разблокировки"
                placeholder="Подробно опишите доказательства, укажите ссылку на видео (если таковое имеется)"
                params={{
                  headFont: Theme.fonts.GothamBold24,
                  width: "100%",
                  height: "30rem",
                }}
                value={textArea}
                maxLength={500}
                onChange={(e) => setTextArea(e.target.value)}
              />
              <UIKIT.Button
                variant="default"
                color="blue"
                height="5rem"
                params={{ width: "fit-content", padding: "1.5rem 9.5rem" }}
                // disabled={textArea.length < 10 || discord.length < 2}
                onClick={async () => {
                  if (CheckText(1)) {
                    const a = await NewTicket(TicketTypes.BLOCK);
                    if (a) {
                      redirectToFullTicket(a);
                    } else {
                      toast("Ошибка");
                    }
                  }
                }}
              >
                Отправить
              </UIKIT.Button>
            </Wrapper>
          </>
        ) : (
          <NonBan>Активных банов нет</NonBan>
        )}
      </ModalWrapper>
    );
  }
}
