import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API from "API";
import { EConfirmList, ITrade, ITradeUpdate } from "interfaces/Trade";

interface TradeState {
  history: ITrade[];
  trade: ITrade;
  update: ITradeUpdate;
  drawModal: boolean;
  confirmStatus: EConfirmList;
  partnerApply: boolean;
  historyTradeId: number;
  historyDrawModal: boolean;
}

const tradeDefaultStruct = {
  avatar: "",
  name: "",
  receiver: "",
  receiver_items: [],
  sender: "",
  sender_items: [],
  time: "",
  uuid: "",
  myInventory: [],
  id: 0,
  confirmed: EConfirmList.default,
};

const updateDefaultStruct = {
  receiverItems: [],
  senderItems: [],
};

const initialState: TradeState = {
  history: [],
  trade: { ...tradeDefaultStruct },
  update: { ...updateDefaultStruct },
  drawModal: false,
  confirmStatus: EConfirmList.default,
  partnerApply: false,
  historyTradeId: -1,
  historyDrawModal: false,
};

export const fetchHistory = createAsyncThunk("trade/fetchHistory", async () => {
  const response = await API.TradeService.getHistory();
  //console.log("DATA TRADE HISTORY: ", response.data)
  return response.data;
});

export const tradeSlice = createSlice({
  name: "trade",
  initialState,
  reducers: {
    setShowModal: (state, action) => {
      state.drawModal = action.payload;
    },
    // setTradeConfirmed: (state, action) => {
    // 	// state.isConfirmed = action.payload
    // },
    setConfrimStatus: (state, action) => {
      state.confirmStatus = action.payload;
    },
    setPartnerApply: (state, action) => {
      state.partnerApply = action.payload;
    },
    setData: (state, action) => {
      state.trade = action.payload;
    },
    setUpdate: (state, action) => {
      state.update = action.payload;
    },
    setHistoryTradeId: (state, action) => {
      state.historyTradeId = action.payload;
    },
    setHistoryShowModal: (state, action) => {
      state.historyDrawModal = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchHistory.fulfilled, (state, action) => {
      state.history = action.payload.history;
    });
  },
});

export const {
  setShowModal,
  setData,
  setUpdate,

  setHistoryTradeId,
  setHistoryShowModal,
  setConfrimStatus,
  setPartnerApply,
} = tradeSlice.actions;
export const tradeActions = tradeSlice.actions;
export const initialStated: TradeState = initialState;
export default tradeSlice.reducer;
