import { SkinChangerService } from "API/SkinChangerService";
import {
  AgentChangeItem,
  ApplyParams,
  Brelok,
  Categories,
  Classes,
  DataInterface,
  SkinChangeItem,
  SkinChangerStatus,
  StickerRowProps,
  UsedCategoryEnum,
} from "interfaces/SkinChanger";
import { makeAutoObservable } from "mobx";
import rarityConvector, { rarityConvectorToInt } from "utils/rarityConvector";

class SkinsChangersState {
  public categories: Categories[] = [];
  public data: DataInterface = { items: [], rarities: [] };
  public openCategoryId: number = -1;
  public usedCategoryId: number = 1;
  public openSkinId: number = 72;
  public SkinsIdArray: SkinChangeItem[] = [];
  public NameSkinId: string = "Нож-бабочка";
  public agent: AgentChangeItem[] = [];
  public UseCategory: UsedCategoryEnum = UsedCategoryEnum.Skins;
  public stickers: StickerRowProps[] = [];
  public Breloks: Brelok[] = [];

  constructor() {
    makeAutoObservable(this);
    this.getCategories();
    this.getData();
    // this.getAndUpdateSkins(this.openSkinId);
  }

  public async updateCategoryUsed(EnumCategory: UsedCategoryEnum) {
    this.UseCategory = EnumCategory;
  }

  private async getCategories() {
    this.categories = await SkinChangerService.getCategories();
  }
  public async updateOpenCategoryId(id: number) {
    this.openCategoryId = id;

    if (id != -1) {
      this.usedCategoryId = id;
    }
    return;
  }
  public async updateOpenSkinId(id: number, skinName: string, single: boolean) {
    this.openSkinId = id;
    this.NameSkinId = skinName;
    if (single) {
      this.getAndUpdateSkins(id);
    }
    return;
  }
  public async getAndUpdateSkins(idSkin: number) {
    this.SkinsIdArray = await SkinChangerService.getSkins(idSkin);

    return;
  }
  public getRarity(index: number, it: boolean) {
    if (it === false) {
      const skin = this.SkinsIdArray[index];
      const rarity = skin.rarity;
      // console.log(skin);
      if (!skin.can_be_used) {
        return rarityConvector(999);
      }
      return rarityConvector(rarityConvectorToInt(rarity));
    } else {
      const agent = this.agent[index];
      const rarity = agent.rarity;

      if (!agent.can_be_used) {
        return rarityConvector(999);
      }
      return rarityConvector(rarityConvectorToInt(rarity));
    }
  }
  public getStatus(index: number, it: boolean) {
    if (it === false) {
      const skin = this.SkinsIdArray[index];

      if (skin.paint_kit_id === 1) {
        return {
          hover: true,
          status: SkinChangerStatus.nonSkin,
        };
      }

      if (skin.can_be_used && skin.used && skin.isHover) {
        return {
          hover: false,
          status: SkinChangerStatus.selectedHover,
        };
      }

      if (skin.options !== undefined && skin.options !== null) {
        if (skin.can_be_used && skin.used) {
          return {
            hover: false,
            status: SkinChangerStatus.selected,
          };
        }
      }

      if (skin.can_be_used && !skin.used) {
        return {
          hover: true,
          status: SkinChangerStatus.notSelected,
        };
      }

      if (!skin.can_be_used && !skin.used) {
        return {
          hover: true,
          status: SkinChangerStatus.dontHave,
        };
      }

      if (skin.can_be_used) {
        return null;
      }
    } else {
      const agent = this.agent[index];
      if (agent.can_be_used && agent.used) {
        return {
          hover: false,
          status: SkinChangerStatus.selected,
        };
      }
      if (agent.can_be_used && !agent.used) {
        return {
          hover: true,
          status: SkinChangerStatus.agentSelect,
        };
      }
      if (!agent.can_be_used && !agent.used) {
        return {
          hover: true,
          status: SkinChangerStatus.dontHave,
        };
      }
      if (agent.can_be_used) {
        return null;
      }
    }
  }
  public setHover(index: number | null, status: boolean, it: boolean) {
    if (it === false) {
      if (index) {
        this.SkinsIdArray[index].isHover = status;
      }
    } else {
      if (index) {
        this.agent[index].isHover = status;
      }
    }
  }
  public async getAgents() {
    this.agent = await SkinChangerService.getAgents();
  }
  private async getData() {
    this.data = await SkinChangerService.getData();
  }

  public async ApplyItems(
    itemId: number,
    classes: Classes,
    isAgent?: boolean,
    PainKitId?: number,
    stikers?: { [key: string]: string },
    wear?: number,
    pattern?: number,
    nameTag?: string,
    isStatrack?: boolean,
    keychain_id?: number
  ) {
    if (isAgent && itemId) {
      const params: ApplyParams = {
        item_id: itemId,
        paint_kit_id: PainKitId,
        classes: classes,
      };

      const result: { success: boolean } = await SkinChangerService.applyAgent(
        params
      );
      if (result) {
        return result;
      }
    } else if (itemId) {
      const params: ApplyParams = {
        item_id: itemId,
        paint_kit_id: PainKitId,
        classes: classes,
        stickers: stikers,
        wear: wear,
        pattern: pattern,
        nametag: nameTag,
        is_stattrack: isStatrack,
        keychain_id: keychain_id,
      };
      const result: { success: boolean } = await SkinChangerService.apply(
        params
      );
      if (result) {
        return result;
      }
    }
  }

  public async getStickers(filter: string) {
    const stikers = await SkinChangerService.getStickers(1, filter);
    if (stikers.data) {
      this.stickers = stikers.data;
    }
  }
  public async getBrelok() {
    const brelok = await SkinChangerService.getBrelok();
    if (brelok) {
      this.Breloks = brelok;
    }
    // if (stikers.data) {
    //   this.stickers = stikers.data;
    // }
  }
}

export default new SkinsChangersState();
