import styled from "styled-components";

export const Container = styled.div`
  padding: 4rem 1.5rem 1.5rem 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2rem;
`;

export const BigText = styled.span`
  ${(props) => props.theme.fonts.GothamBold20}
  text-align: center;
`;

export const MediumText = styled.span`
  ${(props) => props.theme.fonts.GothamMedium16}
  align-self: start;
`;

export const StandartFlex = styled.div`
  display: flex;
  align-items: center;
  gap: 2rem;
  justify-content: space-between;
  width: 100%;
`;
