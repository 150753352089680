import Layout from "components/Layout";
import SubHeader from "components/SubHeader";
import path from "path";
import { redirect } from "react-router-dom";
import {
  Container,
  Grid,
  ImageSuccess,
  Title,
  Wrapper,
  ImageBack,
} from "./Styles";
import UIKIT from "components/UI";

export default function SuccessMoney() {
  return (
    <Layout
      leftElement={<div />}
      headerElement={
        <>
          <UIKIT.ArrowBackMoney />
          <SubHeader>Пополнение баланса</SubHeader>
        </>
      }
    >
      <Wrapper marginTop="450px">
        <ImageSuccess src="/static/images/Success.png" alt="Success" />
        <Title>Успешно</Title>
      </Wrapper>
    </Layout>
  );
}
