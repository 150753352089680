import styled, { GradientKeys } from "styled-components";

export const WrapperItem = styled.div`
  width: 100%;
  padding: 20px 0px 100px 0px;
`;

export const Title = styled.span`
  text: ${(props) => props.theme.colors.white.white100};
  ${(props) => props.theme.fonts.GothamBold32};
  text-align: center;
`;

export const TitleCaseWrapper = styled.div`
  display: flex;

  justify-content: center;
  align-items: center;
  svg {
    width: 29px;
    height: 24px;
  }
`;

export const TitleSkinCase = styled.span`
  text: ${(props) => props.theme.colors.white.white100};
  ${(props) => props.theme.fonts.GothamBold24};
`;
export const WrapperItemCase = styled.div`
  /* display: flex; */
  /* align-items: center; */
  /* justify-content: center; */
  width: 100%;
  /* padding: 20px 0px 0 100px;
   */
  display: flex;
  justify-content: center;
  flex-direction: column;
  /* justify- */
  background-image: src("/public/static/images/linies.jpg");
  /* background-image: src("/public/static/images/T.png"); */
  z-index: 10;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin-top: 40px;
  align-items: center;
  hr {
    /* border-color: ${(props) => props.theme.colors.play_copy_btns};
    opacity: 50%;
    height: 1px; */
    border: none;
    height: 1px; /* Общая высота линии */
    background: linear-gradient(
      to right,
      transparent,
      ${(props) => props.theme.colors.play_copy_btns},
      transparent
    );
    align-self: stretch;

    margin-bottom: 20px;
    margin-top: 20px;
  }
`;

export const ContainerAnimationCase = styled.div`
  padding: 20px 0px;
  border-radius: 20px;
  /* width: 100%; */
  background: ${(props) => props.theme.gradients.shadow};
  position: relative;
  ::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 2px; /* Ширина линии */
    background-color: #ffc700; /* Цвет линии */
    z-index: 1000;
  }
`;

export const WrappKolCase = styled.div`
  display: flex;
  gap: 5px;
  margin-top: 20px;
  margin-bottom: 20px;
  span:first-child {
    border-radius: 30px 5px 5px 30px;
  }
  span:last-child {
    border-radius: 5px 30px 30px 5px;
  }
`;
export const CheckBoxWrapper = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 20px;
`;
export const Text = styled.span`
  color: ${(props) => props.theme.colors.white.white100};
  ${(props) => props.theme.fonts.GothamRegular16},
`;

export const WrapperItemSkin = styled.div`
  /* width: auto; */
  display: flex;
  gap: 15px;
  justify-content: center;
  margin-top: 40px;
  flex-wrap: wrap;
  flex-direction: column;
`;

export const ItemKilCase = styled.span<{ active?: Boolean }>`
  padding: 25px;
  cursor: pointer;
  border-radius: 10px;
  background: ${(props) =>
    props.active
      ? props.theme.colors.white.white100
      : props.theme.gradients.shadow};
  color: ${(props) =>
    props.active
      ? props.theme.colors.black.black100
      : props.theme.colors.white.white100};
  ${(props) => props.theme.fonts.GothamMedium20},/* gap: 20px; */
`;

export const Container = styled.div<{ rarity: GradientKeys }>`
  background: ${(props) => props.theme.gradients[props.rarity]};

  /* width: 100%; */
  /* padding: 50px; */
  border-radius: 15px;
  width: 20rem;
  height: 23rem;
  display: flex;
  flex-direction: column; /* gap: 15px; */
  /* text-align: center; */
  /* align-items: center; */
  @media (max-width: 768px) {
    width: 15rem;
    height: 18rem;
  }
`;
export const WrapperSkin = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 15px;
  justify-content: center;
  @media (max-width: 768px) {
    gap: 1rem;
  }
`;
export const SkinName = styled.span<{ rarity: GradientKeys }>`
  color: ${(props) => props.theme.colors.weapon[props.rarity]};
  ${(props) => props.theme.fonts.GothamBold16},
`;
export const NameSkin = styled.div`
  display: flex;
  /* flex-wrap: wrap; */
  flex-direction: column;
  margin: 2px 0 4px 2rem;
  /* align-items: center; */
  /* justify-content: center; */
`;
export const BlockDiv = styled.div<{ rarity: GradientKeys }>`
  /* position: relative; */
  /* top: 0; */
  font-size: 3px;
  border-radius: 0 0 5rem 5rem;
  height: 0.5rem;
  width: 5rem;
  background: ${(props) => props.theme.colors.weapon[props.rarity]};
  color: ${(props) => props.theme.colors.weapon[props.rarity]};
  align-self: center;
`;

export const NameSkinResult = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 20px 0 40px 0;
  justify-content: center;
`;
export const WeaponName = styled.span`
  color: ${(props) => props.theme.colors.white.white100};
  ${(props) => props.theme.fonts.GothamRegular16},
`;
export const Price = styled.span`
  /* margin: 3px 0 0 0; */
  color: ${(props) => props.theme.colors.white.white100};
  ${(props) => props.theme.fonts.GothamMedium20},
`;
export const WrapPrice = styled.div`
  display: flex;
  align-items: baseline;
`;
export const BlueKP = styled.span`
  /* margin: 3px 0 0 0; */
  color: ${(props) => props.theme.colors.blue.accent};
  ${(props) => props.theme.fonts.GothamBold20},
`;
export const WrapperButton = styled.div`
  display: flex;
  width: 100%;
`;

export const NonBalance = styled.span`
  color: ${(props) => props.theme.colors.red.active};
  ${(props) => props.theme.fonts.GothamBold20},
  padding: 20px 50px;
  border-radius: 20px;
  background: #ff7c7c;
`;
export const WrapperResultItemCase = styled.div<{ rarity: GradientKeys }>`
  width: 35rem;
  height: 37rem;
  background: ${(props) => props.theme.gradients[props.rarity]};
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  /* justify-content: center; */
  align-items: center;
  background-image: src("/public/static/images/fon.png");
  @media (max-width: 768px) {
    width: 27rem;
  }
`;
export const WrapResultCases = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 20px;
  justify-content: center;
  margin-bottom: 40px;
`;
export const ButNot = styled.div`
  width: max-content;
  display: flex;
  gap: 10px;
  align-items: center;

  background: ${(props) => props.theme.colors.black.black70};
  padding: 13px 22px;
  border-radius: 15px;
`;
export const TextSold = styled.span`
  ${(props) => props.theme.fonts.GothamMedium20},
  color: ${(props) => props.theme.colors.white.white100};
`;
export const ButtonWrap = styled.div`
  display: flex;
  gap: 15px;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;
