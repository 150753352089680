import styled from "styled-components";

export const Container = styled.div`
	width: fit-content;
	input[type='checkbox'] {
		display: none;
	}
	input:checked + label::before {
		border: .15rem solid ${props => props.theme.colors.blue.accent};
	}
	input:checked + label::after {
		opacity: 1;
	}
`

export const Label = styled.label`
	display: flex;
	cursor: pointer;
	align-items: center;
	user-select: none;
	position: relative;
	gap: 1rem;
	${props => props.theme.fonts.GothamRegular20};
	color: ${props => props.theme.colors.white.white100};
	transition: .3s all;
	&::before {
		content: '';
		transition: .3s all;
		display: inline-block;
		width: 1.6rem;
		height: 1.6rem;
		background: transparent;
		border: .15rem solid ${props => props.theme.colors.white.white100};
		border-radius: .3rem;
	}
	&::after {
		position: absolute;
		content: '';
		left: .47rem;
		transition: .3s all;
		display: inline-block;
		width: .64rem;
		height: .64rem;
		border-radius: .1rem;
		opacity: 0;
		background: ${props => props.theme.colors.blue.accent};
	}
`