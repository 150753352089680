import { VipServise } from "API/VipServise";
import ModalWrapper from "components/Templates/ModalWrapper";
import Button from "components/UI/Button";
import Select from "components/UI/Select";
import { GetCatecory, Type } from "interfaces/Vip";
import { IUser } from "models/User";
import { useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { WrapperMod } from "./styled";

interface Props {
  isActive: boolean;
  setActive: React.SetStateAction<any>;
  AdminChange: GetCatecory[];
  userDate: IUser;
}

export default function ModalAdminChange({
  isActive,
  setActive,
  AdminChange,
  userDate,
}: Props) {
  const [activeName, setActiveName] = useState<string>();
  const [activePeridd, setActivePeriod] = useState<string>();
  const [ActiveVIP, setActiveVIP] = useState<GetCatecory>(
    AdminChange[0] || {
      flags: "",
      id: 0,
      immunity: 0,
      name: "",
      posibilities: [],
      prices: [],
      visible: false,
    }
  );

  const [querNAME, setQuerName] = useState<number>();
  const [querPer, setQuerPer] = useState<number>();
  ///////////////////////////////////////////////////////////////
  useMemo(() => {
    AdminChange.forEach((item) => {
      if (item.name == activeName) {
        setQuerName(item.id);
      }
    });
  }, [activeName]);

  useMemo(() => {
    ActiveVIP.prices.map((item) => {
      if (item.title == activePeridd) {
        // console.log(item.id);
        setQuerPer(item.id);
      }
    });
  }, [activePeridd]);
  //////////////////////////////////////////////////////////////

  useMemo(() => {
    if (AdminChange.length > 0) {
      setActiveVIP(AdminChange[0]);
    }
  }, [AdminChange]);

  const handleNameChange = (name: string) => {
    setActiveName(name);
    const selectedVIP = AdminChange.find((item) => item.name === name);
    if (selectedVIP) {
      setActiveVIP(selectedVIP);
    }
  };

  const handlePeriodChange = (period: string) => {
    setActivePeriod(period);
  };

  return (
    <ModalWrapper
      headContent={"Управление игроком"}
      isActive={isActive}
      setActive={setActive}
      params={{ width: "40rem", height: "fit-content" }}
      display="flex"
    >
      <WrapperMod>
        <Select
          items={AdminChange.map((item) => item.name)}
          onChange={handleNameChange}
          label="Режим"
          params={{ width: "100%" }}
          // Rating={true}
        />
        <Select
          items={
            ActiveVIP?.prices ? ActiveVIP.prices.map((item) => item.title) : []
          }
          onChange={handlePeriodChange}
          label="Период"
          params={{ width: "100%" }}
          // defaultValue={activePeridd}
        />

        <Button
          height="5rem"
          color="blueVIP"
          variant="default"
          onClick={async () => {
            const result = await VipServise.BuyGift(
              Type.Grant,
              querNAME || 0,
              querPer || 0,
              userDate?.user?.steamid64
            );
            if (result) {
              toast.success("Успешно");
              setActive(false);
              window.location.reload();
            } else {
              toast.error("Что-то пошло не так");
              setActive(false);
            }
          }}
        >
          Сохранить
        </Button>
      </WrapperMod>
    </ModalWrapper>
  );
}
