import { TicketStatuses } from "models/Ticket";
import styled, { css } from "styled-components";

export const Wrapper = styled.div`

`

export const Table = styled.div`
	margin-top: 2rem;
	width: 100%;
	padding: 3rem;
	border: .3rem solid ${props => props.theme.stroke.stroke5};
	${props => props.theme.shadows.defaultShadow};
	border-radius: 2.5rem;
`

export const Head = styled.div`
	display: grid;
	grid-template-columns: .1fr .1fr .2fr .2fr minmax(20rem, .4fr) .15fr .15fr .15fr;
	column-gap: 2rem;
`

export const HeadItem = styled.span`
	${props => props.theme.fonts.GothamBold20};
	color: ${props => props.theme.colors.white.white100};
	column-gap: 3rem;
`

export const Body = styled.div`
	margin-top: 3rem;
	display: flex;
	flex-direction: column;
	gap: 3rem;
`

export const BodyItem = styled.div<{ status?: TicketStatuses }>`
	${props => props.theme.fonts.GothamRegular20};
	color: ${props => props.theme.colors.white.white100};
`

export const BodyElement = styled.div`
	display: grid;
	grid-template-columns: .1fr .1fr .2fr .2fr minmax(20rem, .4fr) .15fr .15fr .15fr;
	column-gap: 2rem;
	cursor: pointer;
`

export const Server = styled(BodyItem)`
	${({ theme }) => theme.mixins.ellipsis}
`

export const Reason = styled(BodyItem)`
	color: ${({ theme }) => theme.colors.red.accent};
`

export const WhoBanned = styled(BodyItem)`
	color: ${({ theme }) => theme.colors.blue.accent};
`

export const Status = styled(BodyItem)`
	${({ status, theme }) => status === TicketStatuses.OPEN && css`
		color: ${theme.colors.green.accent};
	`}
	${({ status, theme }) => status === TicketStatuses.PENDING && css`
		color: ${theme.colors.blue.accent};
	`}
	${({ status, theme }) => status === TicketStatuses.CLOSED && css`
		color: ${theme.colors.red.accent};
	`}
`