import { endpoints, url } from "API";
import axios from "axios";

export class ServersService {
  static async getMonitoring() {
    try {
      const response = await axios.get(url + endpoints.monitoring.all);
      return response.data;
    } catch (error) {
      // console.log(error);
    }
  }

  static addToFavourite(id) {
    try {
      const servers =
        JSON.parse(localStorage.getItem("favourite_servers")) || [];
      if (servers.length) {
      }
    } catch (error) {
      //console.log(error);
    }
  }

  static async getPlayers() {
    try {
      const response = await axios.get(url + endpoints.monitoring.players);
      return response.data;
    } catch (error) {
      // console.log(error);
    }
  }
  static async getMods() {
    try {
      const response = await axios.get(url + endpoints.monitoring.mods);
      // console.log("asdasd", response.data);
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
}
