import styled from "styled-components";

export const WrapFullClans = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  width: 100%;
  gap: 2rem;
  margin-left: 0.1rem;
`;

export const DefaultFlexG1 = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  @media (max-width: 768px) {
    justify-content: center;
  }
`;

export const ClanBlockss = styled.div<{ isT?: boolean }>`
  display: flex;
  gap: 3rem;
  align-items: center;
  @media (max-width: 768px) {
    gap: 7rem;
    width: ${(props) => (props.isT ? "40rem" : "35rem")};
    justify-content: ${(props) => (props.isT ? "center" : "space=between")};
  }
  @media (max-width: 450px) {
    width: max-content;
    flex-direction: column;
    gap: 2rem;
  }
`;

export const ArrowStyle = styled.div<{ right: boolean }>`
  width: 10px;
  height: 10px;
  border-top: 2px solid white !important;
  border-right: 2px solid white !important;
  transform: rotate(${(props) => (props.right ? "45deg" : "-135deg")});
  opacity: 60%;
`;

export const WrapClans = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1.5rem;
  padding: 1.5rem;
  border-radius: 1.5rem;
  background: ${(props) => props.theme.gradients.shadow};
  @media (max-width: 768px) {
    padding: 0.5rem;
  }
`;
export const BlockCreateClan = styled.div`
  background: ${(props) => props.theme.gradients.shadow};
  width: max-content;
  min-width: 30rem;
  display: flex;
  flex-direction: column;
  border-radius: 1.5rem;
  padding: 2rem;
  gap: 1rem;
  @media (max-width: 768px) {
    /* flex-direction: column; */
    width: 100%;
    justify-content: space-between;
    min-width: auto;
  }
`;

export const BlockClan = styled.div`
  background: ${(props) => props.theme.gradients.shadow};
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 1.5rem 2.5rem 1.5rem 1rem;
  border-radius: 1.5rem;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    padding: 1.5rem 0.5rem;
  }
`;

export const NoMyClansBlock = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 2rem;
  width: 100%;
  @media (max-width: 768px) {
    flex-direction: column;
    width: 100%;
  }
`;

export const BigText = styled.h2`
  ${(props) => props.theme.fonts.GothamBold24}
`;
export const MediumText = styled.h3`
  ${(props) => props.theme.fonts.GothamBold16}
`;

export const DivBg = styled.div`
  background-image: url("/static/images/bgId.png");
  background-repeat: no-repeat;
  width: 3.6rem;
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const BlockMode = styled.div`
  display: flex;
  height: 4rem;
  padding: 0 1.5rem;
  width: 100%;
  background: ${(props) => props.theme.gradients.shadow};
  border-radius: 1.5rem;
`;
export const ButMode = styled.span<{ Activ: boolean }>`
  width: max-content;
  height: 4rem;
  padding: 0 1.5rem;
  background: ${(props) =>
    props.Activ ? props.theme.colors.blue.accent : null};
  border-radius: 1rem;
  ${(props) =>
    !props.Activ
      ? props.theme.fonts.GothamMedium16
      : props.theme.fonts.GothamBold16}
  text-align: center;
  cursor: pointer;
  align-content: center;
`;
