import TradeCard from "components/Cards/TradeCard/TradeCard";
import ProfileNotification from "components/ProfileNotification/ProfileNotification";
import { INotificationBody } from "interfaces/Notifications";
import { TradeInventory, TradeStatus } from "interfaces/Trade";
import { observer } from "mobx-react-lite";
import { Fragment } from "react";
import { Container, Period, Wrapper } from "./Styles";

interface Props {
  clearCallback: () => void;
  list?: List;
  type: ListType;
}

export interface List {
  [key: string]: ListInputType[];
}

export type ListInputType = INotificationBody | TradeInventory;

export enum ListType {
  natification = 0,
  trades = 1,
}

const generateBody = (items: ListInputType[]) => {
  // console.log(TradeStatus);
  // console.log(items);
  return items.map((item: ListInputType, itemKey: number) => {
    return (
      <Fragment key={itemKey}>
        {getBodyComponent(item)}
        {/* <AppLine /> */}
      </Fragment>
    );
  });
};

const getBodyComponent = (item: ListInputType) => {
  if (item.status == TradeStatus.BothApplied) {
    return <ProfileNotification data={item as INotificationBody} />;
  }

  return <TradeCard data={item as TradeInventory} />;
};

function ListInfo({ clearCallback, list }: Props) {
  return (
    <Container className="customScroll">
      {/* {JSON.stringify(list)} */}
      {/* <Clear onClick={clearCallback}>Очистить</Clear> */}
      {list &&
        Object.keys(list).map((key: String, listIndex: number) => {
          return (
            <Wrapper key={listIndex}>
              <Period>{key}</Period>
              <Wrapper>{generateBody(list[key + ""])}</Wrapper>
            </Wrapper>
          );
        })}
    </Container>
  );
}

export default observer(ListInfo);
