import ExtendDropDown from "components/ExtendDropDown";
import Image from "components/Image";
import ModalWrapper from "components/Templates/ModalWrapper";
import UIKIT from "components/UI";
import { useAppSelector } from "hooks/useAppSelector";
import { observer } from "mobx-react-lite";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import SkinChangerState from "../../../pages/Profile/Tabs/SkinChanger/SkinChangerState";
import StickerPicker from "./StickerPicker";
import StickerRow from "./StickerRow/StickerRow";
import {
  Gun,
  ModalContainer,
  OptionBox,
  Options,
  SkinRarity,
  Team,
  Text,
  TextVip,
  Type,
  Variants,
  Weapon,
} from "./Styles";

interface Props {
  isActive: boolean;
  indexItem: number;
  setActive: React.SetStateAction<any>;
  state: typeof SkinChangerState;
  setCl: Dispatch<SetStateAction<boolean>>;
  canBeUsedTT: boolean;
  canBeUsedCT: boolean;
  canPrefab: string;
}

const SkinChanger = ({
  isActive,
  setActive,
  indexItem,
  state,
  setCl,
  canBeUsedTT,
  canBeUsedCT,
  canPrefab,
}: Props) => {
  useEffect(() => {
    SkinChangerState.selectCategories(70);
    SkinChangerState.init();
    // SkinChangerState.onClick(indexItem);
    // SkinChangerState.skins.map((item, index) =>
    //   SkinChangerState.loadSkinChangerSettings(index)
    // );

    return () => {};
  }, []);

  const [applyOk, setApplyOk] = useState<boolean>(false);
  const [kolApply, setKolAplly] = useState<number>(0);

  const renderListItems = ({
    key,
    index,
    style,
  }: {
    key: any;
    index: number;
    isScrolling: any;
    isVisible: any;
    style: any;
  }) => {
    return (
      <div key={key} style={style}>
        <StickerRow onClick={state.addSticker} data={state.stickers[index]} />
      </div>
    );
  };

  const navigate = useNavigate();
  const windowInnerWidth = document.documentElement.clientWidth;

  const { user } = useAppSelector((state) => state.user);

  return (
    <ModalWrapper
      isActive={isActive}
      setActive={() => {
        setActive();

        SkinChangerState.resetSkins(indexItem);
        setApplyOk(false);
      }}
      headContent={SkinChangerState.modalSkinName}
      params={
        windowInnerWidth > 1250
          ? {
              width: "46.8rem",
              height: "fit-content",
            }
          : { width: "max-content", height: "max-content" }
      }
      display="flex"
    >
      <ModalContainer>
        <Weapon>
          <Gun src={SkinChangerState.modalSkinImage} />
        </Weapon>
        <Variants>
          <Type></Type>
          <Team>
            {canBeUsedCT && canBeUsedTT ? (
              <>
                <UIKIT.Button
                  height="5rem"
                  variant="picker"
                  onClick={() => {
                    state.updateClasses("CT");
                    setCl(true);
                  }}
                  className={
                    state.modalSettings.classes === "CT" ||
                    state.modalSettings.classes === "BOTH"
                      ? "active"
                      : ""
                  }
                >
                  <Image src="/static/images/CT.png" alt="Team" width="28px" />
                </UIKIT.Button>
                <UIKIT.Button
                  height="5rem"
                  variant="picker"
                  onClick={() => state.updateClasses("TT")}
                  className={
                    state.modalSettings.classes === "TT" ||
                    state.modalSettings.classes === "BOTH"
                      ? "active"
                      : ""
                  }
                >
                  <Image src="/static/images/T.png" alt="Team" width="28px" />
                </UIKIT.Button>
              </>
            ) : null}
            {!canBeUsedTT && canBeUsedCT ? (
              <>
                {state.updateClasses("CT")}
                <UIKIT.Button
                  height="5rem"
                  variant="picker"
                  onClick={() => {
                    state.updateClasses("CT");
                    setCl(true);
                  }}
                  className={
                    state.modalSettings.classes === "CT" ||
                    state.modalSettings.classes === "BOTH"
                      ? "active"
                      : ""
                  }
                >
                  <Image src="/static/images/CT.png" alt="Team" width="28px" />
                </UIKIT.Button>
              </>
            ) : null}
            {!canBeUsedCT && canBeUsedTT ? (
              <>
                {state.updateClasses("TT")}
                <UIKIT.Button
                  height="5rem"
                  variant="picker"
                  onClick={() => state.updateClasses("TT")}
                  className={
                    state.modalSettings.classes === "TT" ||
                    state.modalSettings.classes === "BOTH"
                      ? "active"
                      : ""
                  }
                >
                  <Image src="/static/images/T.png" alt="Team" width="28px" />
                </UIKIT.Button>
              </>
            ) : null}
          </Team>
        </Variants>
        {canPrefab !== "hands_paintable" && canPrefab !== "melee_unusual" ? (
          <Options>
            {user?.group?.flags.includes("p") ? (
              <>
                {/* {(state.selectedStickers = { ["1"]: "0" })} */}
                <Text>Стикеры</Text>
                <StickerPicker
                  stickers={state.selectedStickers}
                  onClick={state.selectStickerGroup}
                  max={state.maxStickers}
                />
                {state.isStickerSelect && state.stickers && (
                  <ExtendDropDown
                    scroll={{
                      width: 390,
                      height: 46 * 5,
                      rowCount: state.stickersCount,
                      rowHeight: 46,
                      items: state.stickers,
                      handleItems: state.getNextStickersBundle.bind(state),
                      rowRenderer: renderListItems,
                    }}
                    searchValue={state.search}
                    searchCallback={(e: any) => state.setSearch(e.target.value)}
                  />
                )}
              </>
            ) : (
              <>
                <Text>Стикеры</Text>
                <TextVip onClick={() => navigate("/vip")}>
                  Доступно в премиум
                </TextVip>
              </>
            )}
          </Options>
        ) : null}
        {windowInnerWidth > 1250 ? (
          <Options>
            <Text>Выберите качество скина</Text>
            <SkinRarity>
              {state
                .getQualitiesList()
                .map(
                  (
                    quality: { name: string; minWare: number },
                    index: number
                  ) => {
                    return (
                      <UIKIT.Button
                        height="5rem"
                        variant="picker"
                        className={
                          state.modalSettings.quality === index ? "active" : ""
                        }
                        onClick={() =>
                          state.updateModalSettigs("float", [
                            0.00001,
                            quality.minWare,
                          ])
                        }
                      >
                        {quality.name}
                      </UIKIT.Button>
                    );
                  }
                )}
            </SkinRarity>
          </Options>
        ) : null}

        <Options>
          <Text>Выберите Float</Text>

          <OptionBox>
            <UIKIT.Input
              pad={true}
              params={{
                width: "12rem",
                height: "3.5rem",
              }}
              value={state.modalSettings.float[1]}
              onChange={(v: any) =>
                state.updateModalSettigs("float", [0.00001, v.target.value])
              }
            ></UIKIT.Input>
            <UIKIT.RangeSlider
              min={0.00001}
              max={1}
              step={0.001}
              isSingle={true}
              value={state.modalSettings.float}
              setValue={(v: number[]) => state.updateModalSettigs("float", v)}
            />
          </OptionBox>
        </Options>
        <Options>
          <Text>Выберите паттерн</Text>
          <OptionBox>
            <UIKIT.Input
              params={{
                width: "12rem",
                height: "3.5rem",
              }}
              value={state.modalSettings.patterns[1]}
              onChange={(v: any) =>
                state.updateModalSettigs("patterns", [0, v.target.value])
              }
            ></UIKIT.Input>
            <UIKIT.RangeSlider
              min={0}
              max={1000}
              step={1}
              isSingle={true}
              value={state.modalSettings.patterns}
              setValue={(v: number[]) =>
                state.updateModalSettigs("patterns", v)
              }
            />
          </OptionBox>
        </Options>

        {canPrefab !== "hands_paintable" && windowInnerWidth > 768 ? (
          <Options>
            <Text>Добавьте именной ярлык</Text>
            <UIKIT.Input
              // max
              maxLength={20}
              placeholder="Название"
              // height="2rem"
              style={{ height: "4rem" }}
              width={"50rem"}
              value={state.modalSettings.nametag}
              onChange={(e) =>
                state.updateModalSettigs("nametag", e.target.value)
              }
            ></UIKIT.Input>
          </Options>
        ) : null}
        <UIKIT.Button
          height="5rem"
          variant="default"
          color="blue"
          onClick={() => {
            state.apply(false);
            setApplyOk(true);
            setKolAplly(kolApply + 1);
            setActive(false);
          }}
        >
          Применить изменения
        </UIKIT.Button>
      </ModalContainer>
    </ModalWrapper>
  );
};

export default observer(SkinChanger);
