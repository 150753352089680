import { Icons } from "assets/icons";
import { useNavigate } from "react-router-dom";

export default function PreviousNavigates() {
  const navigate = useNavigate();

  return (
    <Icons.ArrowBack className="back" onClick={() => navigate("/purse")} />
  );
}
