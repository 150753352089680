import styled from "styled-components";

export const Container = styled.div`
  padding: 4rem 1.5rem 1.5rem 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2rem;
  height: max-content;
  align-items: center;
`;
