import { InventoryWeaponCard } from "components/Cards";
import { IItemSend } from "models/InventoryWeaponCard";
import rarityConvector from "utils/rarityConvector";
import { Card, Column, Text } from "./Styles";

interface Props {
  text: string;
  data: IItemSend[];
  onClick: any;
}

const HistoryColumn = ({ text, data, onClick }: Props) => {
  const getCountNeedRenderCards = () => {
    const START_EMPTY_CARDS = 9;

    const skinsLength = data.length;

    if (skinsLength > START_EMPTY_CARDS) {
      const needRenderCount = skinsLength / 3;

      if (`${needRenderCount}`.includes(".")) {
        if (`${needRenderCount}`.split(".")[1].startsWith("3")) return 2;
        else return 1;
      } else {
        return 0;
      }
    } else {
      return START_EMPTY_CARDS - skinsLength;
    }
  };

  return (
    <div>
      <Text>{text}</Text>
      <Column className="customScroll">
        {data.map((item) => {
          return (
            <>
              {/* {JSON.stringify(item)} */}
              <InventoryWeaponCard
                weapon={item.weapon}
                skin={item.skin}
                cl={false}
                rarity={rarityConvector(+item.rarity)}
                src={item.src}
                skinInfo={item.skinInfo}
                counter={item.skinInfo?.amount + ""}
                onClick={() => {
                  onClick(item);
                }}
              />
            </>
          );
        })}

        {new Array(getCountNeedRenderCards()).fill(1).map((item) => {
          return <Card />;
        })}
      </Column>
    </div>
  );
};

export default HistoryColumn;
