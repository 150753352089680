import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import API from 'API';

interface NotificationsState {
	data: []
}

const name = 'notifications'

const initialState: NotificationsState = {
	data: []
}

export const fetchNotifications = createAsyncThunk(
	name + '/fetchNotifications',
	async () => {
		const response = await API.NotificationsService.getNotifications()
		return response
	}
)

export const notificationsSlice = createSlice({
	name,
	initialState,
	reducers: {
		setNotifications: () => {

		}
	}
})

export const { } = notificationsSlice.actions
export const notificationsActions = notificationsSlice.actions
export default notificationsSlice.reducer