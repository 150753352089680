import styled from "styled-components";

export const Header = styled.div`
  ${(props) => props.theme.fonts.GothamMedium32};
  color: ${(props) => props.theme.colors.white.white100};
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 100%;
`;

export const Wrapper = styled.div``;

export const Filters = styled.div`
  /* display: grid;
	gap: 3rem;
	grid-template-columns: 1fr 1fr 1fr 1fr; */
  display: flex;
  align-items: end;
  gap: 3rem;
  justify-content: space-between;
`;

export const Table = styled.div`
  margin-top: 2rem;
  width: 100%;
  padding: 3rem;
  border: 0.3rem solid ${(props) => props.theme.stroke.stroke5};
  ${(props) => props.theme.shadows.defaultShadow};
  border-radius: 2.5rem;
`;

export const Head = styled.div`
  /* display: grid; */
  /* grid-template-columns: 0.1fr 0.1fr 0.2fr minmax(22rem, 0.3fr) 0.1fr 0.1fr 0.1fr 0.1fr; */
  /* column-gap: 2rem; */
  display: flex;
  text-align: center;
`;

export const HeadItem = styled.span<{ act?: boolean }>`
  ${(props) => props.theme.fonts.GothamMedium16};
  color: ${(props) => props.theme.colors.white.white100};
  column-gap: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;
export const HeadItemAdmin = styled.span<{ act?: boolean }>`
  ${(props) => props.theme.fonts.GothamMedium16};
  color: ${(props) => props.theme.colors.white.white100};
  column-gap: 1rem;
  display: flex;
  align-items: center;
  width: 100%;
`;
export const UserItem = styled.span<{ act?: boolean }>`
  ${(props) => props.theme.fonts.GothamBold14};
  color: ${(props) => props.theme.colors.white.white100};
  column-gap: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100rem;
`;

export const Body = styled.div`
  margin-top: 3rem;
  display: flex;
  flex-direction: column;
  gap: 3rem;
`;

export const BodyItem = styled.div`
  ${(props) => props.theme.fonts.GothamRegular14};
  color: ${(props) => props.theme.colors.white.white100};
  width: 100%;
`;
export const UserNameItem = styled.div<{ act?: boolean }>`
  ${(props) => props.theme.fonts.GothamRegular14};
  color: ${(props) => props.theme.colors.white.white100};
  width: 100rem;
`;

export const BodyElement = styled.div`
  /* display: grid; */
  /* grid-template-columns: 0.1fr 0.1fr 0.2fr minmax(22rem, 0.3fr) 0.1fr 0.1fr 0.1fr 0.1fr; */
  /* column-gap: 2rem; */
  display: flex;
  /* width: 100%; */
  cursor: pointer;
  text-align: center;
`;

export const Server = styled(BodyItem)`
  ${(props) => props.theme.mixins.ellipsis};
  width: 100%;
  white-space: normal;
`;

export const Duration = styled(BodyItem)`
  width: 100%;
`;

export const Reason = styled(BodyItem)<{ nonBan?: boolean }>`
  color: ${(props) =>
    props.nonBan
      ? props.theme.colors.green.accent
      : props.theme.colors.red.accent};
  width: 100%;
`;
