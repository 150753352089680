import styled from "styled-components";

export const Container = styled.div`
  /* width: 100%; */
  height: fit-content;
  border-radius: 1.5rem;
  padding: 1.5rem;
  background: ${(props) => props.theme.colors.white.white100};
  position: absolute;
  left: 5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  top: 2.5rem;
  opacity: 0;
  z-index: -2;
  pointer-events: none;
  width: max-content;
  transition: 0.3s;
  &.active {
    opacity: 1;
    pointer-events: all;
    z-index: 10;
  }
`;

export const Item = styled.div`
  ${(props) => props.theme.fonts.GothamRegular16};
  width: 100%;
  cursor: pointer;
  height: fit-content;
  color: ${(props) => props.theme.colors.black.black100};
  overflow: hidden;
  transition: 0.3s;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;

  &:hover {
    color: gray;
  }
`;

export const Input = styled.input`
  margin-right: 5px;
`;
