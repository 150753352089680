import { IActionButton } from 'interfaces/ActionButton'
import { ButtonWrapper } from './Styles'

export default function ActionButton({ children, ...props }: IActionButton) {
	return (
		<ButtonWrapper {...props}>
			{children}
		</ButtonWrapper>
	)
}
