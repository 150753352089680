import styled from "styled-components";

export const DefFlexG1 = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  @media (max-width: 930px) {
    justify-content: center;
    width: 100%;
  }
`;

export const DefFlexFCJC = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;

export const DefFlexSB = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  /* flex-direction: column; */
  justify-content: space-between;
`;

export const WrapperSkinchangerFull = styled.div`
  display: flex;
  gap: 2.5rem;
  align-items: start;
  justify-content: space-between;
  @media (max-width: 930px) {
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
  }
`;

export const WrapMenuSkinsChan = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  min-width: 30rem;
  @media (max-width: 930px) {
    width: 100%;
  }
`;

export const ItemMenu = styled.div`
  border-radius: 1.2rem;
  background: ${(props) => props.theme.gradients.shadow};
  width: 100%;
  /* height: max-content; */
  display: flex;
  /* justify-content: space-between; */
  flex-direction: column;
  /* padding: 1.5rem; */
  /* cursor: pointer; */
`;

export const ItemMenuInMenu = styled.div<{ active?: boolean }>`
  /* width: ${(props) => (props.active ? "100$" : "0px")}; */
  max-height: ${(props) => (props.active ? "100rem" : "0")};

  display: flex;
  flex-direction: column;
  transition: ${(props) => (props.active ? "0.2s" : "none")};
  transition-property: max-height;
  /* gap: 1.5rem; */
  padding: ${(props) => (props.active ? "1rem 2rem 1rem 2rem" : "0")};
  margin: ${(props) => (props.active ? "0rem 0rem 2rem 2rem" : "0")};
  border-left: ${(props) => (props.active ? "1px solid #6A6A6A" : "none")};
  overflow: hidden;
`;

export const BigText = styled.h2`
  ${(props) => props.theme.fonts.GothamBold24}
  @media (max-width: 930px) {
    text-align: center;
    width: 100%;
  }
`;
export const MediumText = styled.h3`
  ${(props) => props.theme.fonts.GothamBold16}
  overflow-wrap: break-word;
  align-items: center;
`;
export const SmallText = styled.h3`
  ${(props) => props.theme.fonts.GothamBold14}
  overflow-wrap: break-word;
  align-items: center;
`;

export const WrapperMenuProfile = styled.div<{ Active?: boolean }>`
  padding: 0 20px 0 20px;
  transform: rotate(${(props) => (props.Active ? "0" : "180deg")});
  transition: 0.3s;

  svg {
    width: 15px;
    height: 10px;
    /* cursor: pointer; */
  }
`;

export const ItemInMenuMenu = styled.h3`
  ${(props) => props.theme.fonts.GothamBold14}
  overflow-wrap: break-word;
  align-items: center;
  /* padding: 0.75rem 0; */
  display: flex;
  align-items: center;
  height: 3.5rem;
  transition: 0.2s;
  :hover {
    ${(props) => props.theme.fonts.GothamChatBold12}
    transition: 0.2s;
  }
  @media (max-width: 768) {
    :hover {
      transition: 0;
      ${(props) => props.theme.fonts.GothamBold14}
    }
  }
`;

export const BlockSkins = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  gap: 2rem;
`;
