import Image from "components/Image";
import { AvatarVariants } from "interfaces/Avatar";
import { memo } from "react";
import { Wrapper } from "./Styles";

interface Props {
  src?: string;
  alt?: string;
  size: AvatarVariants;
  isBorder?: boolean;
}
function Avatar({ src, alt = "Avatar", size, isBorder = true }: Props) {
  return (
    <Wrapper size={size} isBorder={isBorder}>
      <Image src={src ? src : "/static/images/avatar.png"} alt={alt} />
    </Wrapper>
  );
}

export default memo(Avatar);
