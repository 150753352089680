import Row from "components/Row";
import Detailed from "components/Templates/Detailed";
import ModalWrapper from "components/Templates/ModalWrapper";
import { useAppSelector } from "hooks/useAppSelector";
import { IModal } from "interfaces/Modal";
import { InfoBlock } from "models/Ticket";
import { ViolationTitles, ViolationTitlesEnum } from "models/Violation";
import { useNavigate } from "react-router-dom";
import { Admin, Container, Item, Red, Title } from "./Styles";

interface Props extends IModal {
  violation?: InfoBlock;
  activeBan?: InfoBlock;
}

interface PatchData1 {
  id: string | number;
  state: number;
}

export default function BanModalSupport({
  isActive,
  setActive,
  activeBan,
}: Props) {
  const navigate = useNavigate();

  function formatDate(timestampString: string) {
    const months = [
      "января",
      "февраля",
      "марта",
      "апреля",
      "мая",
      "июня",
      "июля",
      "августа",
      "сентября",
      "октября",
      "ноября",
      "декабря",
    ];

    let date = new Date(timestampString);
    date.setHours(date.getHours() - 3);

    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    return `${day} ${month} ${year}, ${hours}:${minutes}`;
  }

  return (
    <ModalWrapper
      setActive={setActive}
      isActive={isActive}
      headContent="Подробная информация"
      params={{ width: "80vw", height: "fit-content" }}
      display="flex"
    >
      <Container>
        <Detailed>
          {activeBan?.type !== "ban" ? (
            <Row gap="3rem" justifyContent="center" margin="3rem 0 0 0">
              <Title>{ViolationTitlesEnum.MUTE}</Title>
              <Item>{activeBan?.type}</Item>
            </Row>
          ) : (
            <></>
          )}

          <Row
            gap="3rem"
            justifyContent="center"
            margin={activeBan?.type !== "ban" ? "" : "3rem 0 0 0"}
            // fullWidth={true}
          >
            <Title>{ViolationTitles.PLAYER_IP}</Title>
            <Item>{activeBan?.ip}</Item>
          </Row>

          <Row gap="3rem" justifyContent="center">
            <Title>{ViolationTitles.PLAYER_STEAMID64}</Title>
            <Item>{activeBan?.banned.steamid64}</Item>
          </Row>

          <Row gap="3rem" justifyContent="center">
            <Title>{ViolationTitles.user_name}</Title>
            <Admin
              onClick={() =>
                navigate(`/profile/${activeBan?.banned.steamid64}`)
              }
            >
              {activeBan?.banned.name}
            </Admin>
          </Row>
          <Row gap="3rem" justifyContent="center">
            <Title>{ViolationTitles.ADMIN_NICKNAME}</Title>
            <Admin
              onClick={() => navigate(`/profile/${activeBan?.admin.steamid64}`)}
            >
              {activeBan?.admin.name}
            </Admin>
          </Row>
          <Row gap="3rem" justifyContent="center">
            <Title>{ViolationTitles.REASON}</Title>
            <Red nonBan={activeBan?.state == "banned" ? false : true}>
              {activeBan?.reason}
            </Red>
          </Row>
          <Row gap="3rem" justifyContent="center">
            <Title>{ViolationTitles.created_at}</Title>
            <Item>{formatDate(activeBan?.created_at || "")}</Item>
          </Row>
          <Row gap="3rem" justifyContent="center">
            <Title>{ViolationTitles.END_TIMESTAMP}</Title>
            <Item>{formatDate(activeBan?.expired_at || "")}</Item>
          </Row>

          <Row gap="3rem" justifyContent="center">
            <Title>{ViolationTitles.SERVER_IP}</Title>
            <Red>{activeBan?.server.ip}</Red>
          </Row>
        </Detailed>
      </Container>
    </ModalWrapper>
  );
}
