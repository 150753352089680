import { ViolationType } from "models/Violation";

export const getViolationLabel = (type: ViolationType) => {
  switch (type) {
    case "ban":
      return "Бан";
    case "voice_mute":
      return "Мут";
    default:
      return "";
  }
};
