import ModalWrapper from "components/Templates/ModalWrapper";
import { BigText, Container, MediumText } from "./styled";
import Button from "components/UI/Button";
import { Icons } from "assets/icons";
import { TinyIcons } from "assets/tiny-icons";
import { useNavigate } from "react-router-dom";

interface Props {
  isActive: boolean;
  setActive: React.SetStateAction<any>;
  setMode: React.SetStateAction<any>;
  id?: number;
}

export default function GivePoint({ isActive, setActive, setMode, id }: Props) {
  const navigate = useNavigate();
  return (
    <ModalWrapper
      headContent={"Выберите способ пополнения"}
      isActive={isActive}
      setActive={setActive}
      display={"flex"}
      params={{ height: "max-content", width: "50rem" }}
    >
      <Container>
        <Button
          color="shadow"
          height="6rem"
          params={{ width: "100%" }}
          variant="default"
          style={{ justifyContent: "start" }}
          onClick={() => {
            setMode(2);
            setActive(false);
          }}
        >
          <TinyIcons.MarketCart />
          <BigText>Купить очки в магазине</BigText>
        </Button>
        <Button
          color="shadow"
          height="6rem"
          params={{ width: "100%" }}
          variant="default"
          style={{ justifyContent: "start" }}
          onClick={() => navigate(`/clans/${id}/trade`)}
        >
          <TinyIcons.Reload style={{ fillOpacity: "100%" }} />
          <BigText>Обменять скины на очки</BigText>
        </Button>
      </Container>
    </ModalWrapper>
  );
}
