import styled from "styled-components";

export const StyledModal = styled.div`
  z-index: 1000000000000;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: ${(props) => props.theme.colors.black_card_bg};
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: 0.5s;
  pointer-events: none;
  &.active {
    z-index: 1000;
    pointer-events: all;
    opacity: 1;
  }
`;

export const ModalContent = styled.div`
  z-index: 1000000;
  transform: scale(0.5);
  transition: 0.4s all;
  &.active {
    z-index: 10000;
    max-height: 90vh;
    /* width: fit-content; */
    /* height: fit-content; */
    transform: scale(1);
  }
`;
