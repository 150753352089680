import { useMemo } from 'react';
import { IServer } from '../models/Server';

export const useServers = (servers: IServer[], tab: string) => {
	const memoizedServers = useMemo(() => servers, [servers])
	if (tab?.toLocaleLowerCase() === 'все') {
		return memoizedServers
	} else {
		return memoizedServers.filter((server) => server.type === tab)
	}
}