import { IUser } from '../../models/User';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { PrivateChatState } from 'models/PrivateChat';
import API from 'API';

const initialState: PrivateChatState = {
	user: {} as IUser,
	messages: [],
	steamid64: '',
	isLoading: false,
}

const name = 'privateChat'

export const fetchPrivateChatHistory = createAsyncThunk(
	name + '/fetchPrivateChatHistory',
	async (steamid64: string) => {
		const response = await API.ChatService.getPrivateChatHistory(steamid64)
		return response.data
	}
)

export const fetchPrivateUser = createAsyncThunk(
	name + '/fetchPrivateUser',
	async (steamid64: string) => {
		const response = await API.UserService.getAnotherProfile(steamid64)
		return response.data
	}
)

export const privateChatSlice = createSlice({
	name,
	initialState,
	reducers: {
		setMessage: (state, action) => {
			state.messages = [...state.messages, action.payload]
		}
	},
	extraReducers: (builder) => {
		builder.addCase(fetchPrivateChatHistory.pending, (state, action) => {
			state.isLoading = true
		})
		builder.addCase(fetchPrivateChatHistory.fulfilled, (state, action) => {
			state.isLoading = false
			state.messages = action.payload
		})
		builder.addCase(fetchPrivateUser.pending, (state, action) => {
			state.isLoading = true
		})
		builder.addCase(fetchPrivateUser.fulfilled, (state, action) => {
			state.user = action.payload
		})
	}
})

export const { setMessage } = privateChatSlice.actions
export const PrivateChatActions = privateChatSlice.actions
export default privateChatSlice.reducer