import Avatar from "components/Avatar";
import { FriendRenderMode, IFriend } from "models/Friend";

import { Link } from "react-router-dom";
import { Container, Nickname, Head, Body } from "./Styles";
import Button from "components/UI/Button";
import { UserService } from "API/UserService";
import { mySteamId } from "utils/IsYou";

export default function FriendCard({
  avatar,
  name,
  steamid64,
  renderMode,
}: IFriend) {
  return (
    <Container>
      <Link to={`/profile/${steamid64}`}>
        <Head>
          <Avatar size="small-6" src={avatar} isBorder={false} />
          <Nickname>{name}</Nickname>
        </Head>
      </Link>

      {renderMode == FriendRenderMode.request && (
        <Body>
          <Button
            onClick={() => UserService.acceptFriend(steamid64, true)}
            height="5rem"
            variant="default"
            color="green"
          >
            Принять
          </Button>
          <Button
            onClick={() => UserService.acceptFriend(steamid64, false)}
            height="5rem"
            variant="default"
            color="red"
          >
            Отклонить
          </Button>
        </Body>
      )}

      {renderMode == FriendRenderMode.decline && (
        <Body>
          <Button
            onClick={() => UserService.cancelRequest(mySteamId(), steamid64)}
            height="5rem"
            variant="default"
            color="red"
            style={{ width: "100%" }}
          >
            Отменить
          </Button>
        </Body>
      )}
    </Container>
  );
}
