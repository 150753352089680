export enum MenuActions {
	PLAY = 'PLAY',
	MESSAGE = 'MESSAGE',
	TRADE = 'TRADE',
	COPY = 'COPY',
	REPORT = 'REPORT',
}

export interface menuItem {
	label: string;
	action: MenuActions;
}