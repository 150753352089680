import { InventoryWeaponCard } from "components/Cards";
import Layout from "components/Layout";
import ProfileLayout from "components/ProfileLayout";
import SubHeader from "components/SubHeader";
import { useEffect, useMemo, useState } from "react";
// import SkinChangerState from ''
import { toast } from "react-toastify";

import { Modals } from "components/Modal";
import { useActions } from "hooks/useActions";
import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import Header from "./Header";
import SkinChangerState, {
  AgentChangeItem,
  SkinChangeItem,
} from "./SkinChangerState";
import { Grid, Wrapper } from "./Styles";
function SkinChanger() {
  const { steamid64 } = useParams();
  const [cl, setCl] = useState<boolean>(false);
  const [canBeUsedCt, setCanBeUsedCT] = useState<boolean>(true);
  const [canBeUsedTT, setCanBeUsedTT] = useState<boolean>(true);
  const [canPrefab, setPrefab] = useState<string>("");
  const [agentPick, setAgentPick] = useState<boolean>(false);
  const [indexItem, setIndexItem] = useState<number>(-1);
  const [kolClickAgent, setKolClickAgent] = useState<number>(0);
  enum SkinQuality {
    BS = 0,
    WW = 1,
    FT = 2,
    MW = 3,
    FN = 4,
  }

  useMemo(() => {
    SkinChangerState.selectCategories(70);

    return () => {};
  }, [steamid64]);

  useEffect(() => {
    setCl(false);
  }, [cl]);

  // useEffect(() => {
  //
  // }, [agentPick]);

  function fds(i: SkinChangeItem) {
    if (i.options !== undefined && i.options !== null) {
      if (i.options.classes !== null) {
        // (i.options);
        return i.options.classes;
      }
      if (i.options.classes !== undefined) {
        // (i.options.classes);
        return i.options.classes;
      } else {
        return null;
      }
    }
  }

  function updateModalSetting() {
    if (
      SkinChangerState.skins[indexItem].options !== undefined &&
      SkinChangerState.skins[indexItem].options !== null
    ) {
      SkinChangerState.resetSkins(indexItem);
    } else {
      SkinChangerState.modalSettings = {
        pattern: 0,
        float: [0.00001, 0.00001, 0.00001],
        patterns: [0, 0],
        quality: SkinQuality.FN,
        nametag: "",
        is_stattrack: false,
        classes: null,
        wear: 0.00001,
        stickers: { "0": "0" },
      };
    }
    // ("prefab = ", canPrefab);
  }

  function applyAgent(kol: number, item: AgentChangeItem) {
    if (kol < 5) {
      SkinChangerState.apply(true, Number(item.id), item.used);
      toast("Агент выбран!");
      SkinChangerState.getAgents();
      SkinChangerState.getAgents();
      setKolClickAgent(kolClickAgent + 1);
    } else {
      toast("Не спамьте");
    }
  }

  const skinsMemo = useMemo(() => {
    return SkinChangerState.skins.map((item, index) => (
      <InventoryWeaponCard
        key={index}
        src={item.image}
        rarity={SkinChangerState.getRarity(index, false)}
        weapon={
          toJS(SkinChangerState.data).items.filter(
            (skin: any) => skin.id === SkinChangerState.categoryID
          )[0]?.class_en || ""
        }
        paint_kit_id={item.paint_kit_id}
        skin={item.description_tag_ru}
        // skind={item.options.classes}
        cl={cl}
        skind={fds(item)}
        skinChangerStatus={SkinChangerState.getStatus(index, false)}
        onClick={() => {
          if (item.can_be_used == true) {
            SkinChangerState.onClick(index);
          }

          setPrefab(item.prefab);
          updateModalSetting();
          // SkinChangerState.resetSkins(indexItem);

          // {item.paint_kit_id == 1 ? SkinChangerState.apply() : null}
          if (item.paint_kit_id === 1) {
            SkinChangerState.apply(false);
            toast("Сброшено!");
          }
          // SkinChangerState.apply(item.paint_kit_id);
        }}
        onMouseEnter={() => {
          setIndexItem(index);
          SkinChangerState.setHover(index, true, false);
          // SkinChangerState.resetSkins(indexItem);
        }}
        onMouseLeave={() => SkinChangerState.setHover(index, false, false)}
      />
    ));
  }, [SkinChangerState.skins]);

  const agentMemo = useMemo(() => {
    return SkinChangerState.agent.map((item, index) => (
      <InventoryWeaponCard
        key={index}
        src={item.image}
        rarity={SkinChangerState.getRarity(index, true)}
        weapon={""}
        paint_kit_id={1}
        skin={item.class_ru}
        // skind={item.options.classes}
        cl={cl}
        skinChangerStatus={SkinChangerState.getStatus(index, true)}
        onClick={
          item.can_be_used ? () => applyAgent(kolClickAgent, item) : undefined
        }
        // {
        //       item.can_be_used
        //         ? applyAgent(kolClickAgent, item)
        //         : undefined;
        //     }
        onMouseEnter={() => {
          SkinChangerState.setHover(index, true, true);
        }}
        onMouseLeave={() => SkinChangerState.setHover(index, false, true)}
      />
    ));
  }, [SkinChangerState.agent]);
  // console.log("SkinChangerState.skins", SkinChangerState.skins);
  return (
    <Layout
      leftElement={<div />}
      nonChat={true}
      headerElement={<SubHeader>Управление снаряжением</SubHeader>}
    >
      <Helmet>
        <title>FREEZ-BLOOD - Управление снаряжением</title>
        <meta
          name="description"
          content={`FREEZ-BLOOD - Управление снаряжением`}
        />
      </Helmet>
      <ProfileLayout gap="3rem">
        <Wrapper>
          <Header
            agentPick={agentPick}
            setCanBeUsedCT={setCanBeUsedCT}
            setCanBeUsedTT={setCanBeUsedTT}
            setAgentPick={setAgentPick}
          />
        </Wrapper>
        {agentPick === false ? (
          <Grid>{skinsMemo}</Grid>
        ) : (
          <Grid>{agentMemo}</Grid>
        )}
      </ProfileLayout>

      <Modals.SkinChanger
        isActive={SkinChangerState.isModalShow}
        setActive={(status: boolean) => {
          SkinChangerState.setModalShow(status);
          updateModalSetting();
          // SkinChangerState.resetSkins(indexItem);
        }}
        state={SkinChangerState}
        setCl={setCl}
        canBeUsedCT={canBeUsedCt}
        canBeUsedTT={canBeUsedTT}
        canPrefab={canPrefab}
        indexItem={indexItem}
      />
    </Layout>
  );
}

export default observer(SkinChanger);
