export const fonts = {
  GothamBold32: `
    font-style: normal;
    font-weight: 700;
    font-size: 3.2rem;
    line-height: 3.1rem;
  `,
  GothamBold44: `
    font-style: normal;
    font-weight: 900;
    font-size: 4.4rem;
    line-height: 3.1rem;
  `,
  GothamMedium32: `
    font-style: normal;
    font-weight: 500;
    font-size: 3.2rem;
    line-height: 3.1rem;
  `,
  GothamBold24: `
    font-style: normal;
    font-weight: 700;
    font-size: 2.4rem;
    line-height: 2.3rem;
  `,
  GothamMedium24: `
    font-style: normal;
    font-weight: 500;
    font-size: 2.4rem;
    line-height: 2.3rem;
  `,
  GothamRegular24: `
    font-style: normal;
    font-weight: 400;
    font-size: 2.4rem;
    line-height: 2.3rem;
  `,
  GothamBold20: `
    font-style: normal;
    font-weight: 700;
    font-size: 2rem;
    line-height: 1.9rem;
  `,
  GothamMedium20: `
    font-style: normal;
    font-weight: 500;
    font-size: 2rem;
    line-height: 1.9rem;
  `,
  GothamRegular20: `
    font-style: normal;
    font-weight: 400;
    font-size: 2rem;
    line-height: 1.9rem;
  `,
  GothamBold16: `
    font-style: normal;
    font-weight: 700;
    font-size: 1.6rem;
    line-height: 1.5rem;
  `,
  GothamMedium16: `
    font-style: normal;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 1.5rem;
  `,
  GothamRegular16: `
    font-style: normal;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 1.5rem;
  `,
  GothamMedium15: `
    font-style: normal;
    font-weight: 500;
    font-size: 1.5rem;
    line-height: 1.4rem;
  `,
  GothamBold14: `
    font-family: Gotham Pro;
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 600;
    line-height: 2rem;
  `,
  GothamRegular14: `
    font-style: normal;
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 1.5rem;
  `,
  GothamMenuMedium14: `
    font-style: normal;
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 1.5rem;
  `,
  GothamChatBold12: `
    font-style: normal;
    font-weight: 700;
    font-size: 1.2rem;
    line-height: 1.1rem;
    letter-spacing: 0.02em;
  `,
  GothamChatRegular12: `
    font-style: normal;
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 1.1rem;
    letter-spacing: 0.02em;
  `,
  GothamChatTimeLight12: `
    font-style: normal;
    font-weight: 300;
    font-size: 1.2rem;
    line-height: 1.1rem;
    letter-spacing: 0.02em;
  `,
};
