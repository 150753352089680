import Loader from 'components/UI/Loader'
import './styles.sass'

export default function FallbackComp() {
	return (
		<div className='fallback__wrapper'>
			<Loader />
		</div>
	)
}
