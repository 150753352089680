import { Container } from "components/Row/Styles";
import { Button } from "components/UI/Button/Styles";
import styled from "styled-components";

export const Wrapper = styled.div`
  ${Container} {
    display: flex;
    flex-wrap: wrap;
    ${Button} {
      padding: 1.25rem 4.6rem;
    }
    @media (max-width: 1250px) {
      display: flex;
      flex-direction: column;
      gap: 2rem;
    }
  }
`;

export const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  align-items: center;
  justify-content: center;
`;
