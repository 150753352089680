import ShopState from "components/Modal/Shop/ShopState";
import UIKIT from "components/UI";

import { endpoints, url } from "API";
import { TinyIcons } from "assets/tiny-icons";
import { useAppSelector } from "hooks/useAppSelector";
import { Container, CreditPrice, Text, Wrapper } from "./Styles";

interface Props {
  count: number;
  price: number;
  id: number;
}

export default function KeyCard({ count, price, id }: Props) {
  const { isAuth } = useAppSelector((state) => state.user);

  function loginHandler() {
    window.location.href = url + endpoints.auth.login;
  }
  //-_------------------------

  return (
    <Container>
      <Wrapper>
        <Text>
          <CreditPrice>{count}</CreditPrice>
        </Text>
        &nbsp;
        <TinyIcons.Credit />
      </Wrapper>
      {/* <Price>
        {price} <TinyIcons.Rub />
      </Price> */}

      {isAuth && localStorage.token !== undefined ? (
        <UIKIT.Button
          height="5rem"
          variant="default"
          color="grayy"
          onClick={() => {
            ShopState.setShowModal(true);
            ShopState.setId(id);
            ShopState.setCount(count);
            ShopState.setPrice(price);
            // setUser(UserService.getProfile());
            // console.log("sdfsdff", s. );
          }}
        >
          Купить за {price} <TinyIcons.Rub />
        </UIKIT.Button>
      ) : (
        <UIKIT.Button
          height="5rem"
          variant="default"
          color="blue"
          onClick={() => {
            loginHandler();
          }}
        >
          Авторизоваться
        </UIKIT.Button>
      )}
    </Container>
  );
}
