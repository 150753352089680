import { useEffect, useState } from "react";
import {
  Button,
  DefFlex,
  DefFlexG1,
  Dot,
  MediumText,
  SliderContainer,
  SmallText,
  Thumb,
  TrackContainer,
  TrackSegment,
} from "./styled";
import UIKIT from "components/UI";
enum FloatEnum {
  FN = "FN",
  MW = "MW",
  FT = "FT",
  WW = "WW",
  BS = "BS",
}

const ranges = [
  { min: 0.001, max: 0.07, color: "#1D8E9C", float: FloatEnum.FN },
  { min: 0.07, max: 0.15, color: "#83C006", float: FloatEnum.MW },
  { min: 0.15, max: 0.37, color: "#F4D02E", float: FloatEnum.FT },
  { min: 0.37, max: 0.44, color: "#FF8A2E", float: FloatEnum.WW },
  { min: 0.44, max: 0.999, color: "#FF4332", float: FloatEnum.BS },
];

const calculateWidth = (range: { min: number; max: number }) => {
  return ((range.max - range.min) / (1.0 - 0.001)) * 100;
};

interface Props {
  value: number;
  setValue: React.Dispatch<React.SetStateAction<number>>;
  isActive: boolean;
}

const RangeSlider = ({ value, setValue, isActive }: Props) => {
  // const [value, setValue] = useState(0.001);
  const [currentRangeIndex, setCurrentRangeIndex] = useState(0);
  // const [activeIndex, setActiveIndex] = useState(0)

  useEffect(() => {
    if (!isActive) {
      setCurrentRangeIndex(0);
    }
  }, [isActive]);

  useEffect(() => {
    ranges.forEach((item, index) => {
      if (item.max > value && item.min < value) {
        setCurrentRangeIndex(index);
      }
    });
    if (value < 0.001) {
      setValue(0.001);
    } else if (value > 0.999) {
      setValue(0.999);
    }
  }, [value]);

  const handleNextRange = () => {
    const nextIndex = (currentRangeIndex + 1) % ranges.length;
    const nextRange = ranges[nextIndex];
    setCurrentRangeIndex(nextIndex);
    setValue(nextRange.min); // Устанавливаем значение в середину диапазона
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(parseFloat(e.target.value));
  };
  console.log(value);
  return (
    <SliderContainer>
      {/* Track segments */}
      <DefFlex>
        <DefFlexG1>
          <MediumText>Выберите float </MediumText>
          <Dot />
          <SmallText onClick={handleNextRange} style={{ cursor: "pointer" }}>
            {ranges[currentRangeIndex].float}
          </SmallText>
        </DefFlexG1>

        <UIKIT.Input
          min={0.1}
          type="number"
          // pattern="\d"
          max={0.009}
          onInput={(e) => {
            const input = (e.target as HTMLInputElement).value;

            // Проверка длины значения как строки
            if (input.length <= 5) {
              setValue(Number(input)); // Преобразуем в число
            } else {
              (e.target as HTMLInputElement).value = value.toString(); // Вернуть предыдущее значение
            }
          }}
          style={{
            padding: "1rem",
            textAlign: "center",
            borderRadius: "1rem",
          }}
          params={{
            width: "12rem",
            height: "3.5rem",
          }}
          value={value}
          onChange={(e) => setValue(Number(e.target.value))}
        ></UIKIT.Input>
      </DefFlex>

      <TrackContainer>
        {ranges.map((range, index) => (
          <TrackSegment
            key={index}
            $color={range.color}
            $width={calculateWidth(range)}
          />
        ))}
      </TrackContainer>
      {/* Thumb (slider) */}
      <Thumb
        type="range"
        min="0.001"
        max="0.999"
        step="0.001"
        value={value}
        onChange={handleChange}
      />
    </SliderContainer>
  );
};

export default RangeSlider;
