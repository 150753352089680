import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  margin-bottom: 3rem;
`;

export const Title = styled.h1`
  ${(props) => props.theme.fonts.GothamMedium32};
  color: ${(props) => props.theme.colors.white.white100};
  margin: 0px 0 50px 0;
  text-align: center;
`;

export const Container = styled.div`
  background: transparent;
  ${(props) => props.theme.mixins.defaultShadow};
  border-radius: 2.5rem;
  padding: 2rem;
  /* max-width: 80rem; */
  /* width: 100%; */
  width: 78.4rem;
  height: fit-content;
  margin-bottom: 5rem;
`;

export const Grid = styled.div`
  margin-top: 3rem;
  margin-left: 1rem;
  width: 100%;
  display: grid;
  gap: 3rem;
  grid-template-columns: repeat(auto-fill, minmax(35rem, 1fr));
`;
