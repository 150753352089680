import Progress from "./Progress";
import Select from "./Select";
import ActionButton from "./ActionButton";
import Button from "./Button";
import Checkbox from "./Checkbox";
import DropDown from "./DropDown";
import Input from "./Input";
import Toggle from "./Toggle";
import Textarea from "./Textarea";
import RangeSlider from "./RangeSlider";
import ArrowBack from "./ArrowBack";
import PreviousNavigate from "./ArrowBack";
import ArrowBackMoney from "./ArrowBackMoney";

export const UIKIT = {
  ActionButton,
  Button,
  Checkbox,
  DropDown,
  Select,
  Input,
  Progress,
  Toggle,
  Textarea,
  RangeSlider,
  ArrowBack,
  PreviousNavigate,
  ArrowBackMoney,
};

export default UIKIT;
