const tradeData = (datast: string) => {
  let date = new Date(datast);
  date.setHours(date.getHours() - 3);

  const Minutes = date.getMinutes();
  const Hours = date.getHours();
  const Day = date.getDate();
  const Month = date.getMonth() + 1;
  const Year = date.getFullYear();

  if (date) {
    return `${Day < 10 ? `0${Day}` : Day}.${
      Month < 10 ? `0${Month}` : Month
    }.${Year} ${Hours < 10 ? `0${Hours}` : Hours}:${
      Minutes < 10 ? `0${Minutes}` : Minutes
    }`;
  } else {
    return ``;
  }
};

export { tradeData };
