import ReactDOM from "react-dom/client";
import App from "./App";
import MainProvider from "./components/MainProvider";
import "./assets/fonts/Fonts.css";
import "react-toastify/dist/ReactToastify.css";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <MainProvider>
    <App />
  </MainProvider>
);
