import Clock from "./Clock"
import MessageDots from "./MessageDots"
import Star from "./Star"
import ArrowLeft from "./ArrowLeft"
import Close from "./Close"
import File from "./File"

export const NanoIcons = {
	Clock,
	Star,
	MessageDots,
	ArrowLeft,
	Close,
	File
}