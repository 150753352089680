import styled from "styled-components";

interface Props {
	progress: number
}

export const Container = styled.div`
	display: flex;
	align-items: center;
	gap: 1.5rem;
	max-width: 50rem;
	width: 100%;
`

export const Wrapper = styled.div`
	height: 1.5rem;
	width: 100%;
	border-radius: 2rem;
	${props => props.theme.shadows.defaultShadow};
	border: .3rem solid ${props => props.theme.stroke.stroke5};
	position: relative;
	overflow: hidden;
`

export const Bar = styled.div<Props>`
	width: ${props => props.progress + '%'};
	transition: all .3s;
	position: absolute;
	height: 100%;
	border-radius: 1rem;
	background: ${props => props.theme.gradients.xp_progress};
`

export const CurrentLevel = styled.span`
	${props => props.theme.fonts.GothamRegular24};
	color: ${props => props.theme.colors.white.white100};
`

export const NextLevel = styled.span`
	${props => props.theme.fonts.GothamBold32};
	color: ${props => props.theme.colors.white.white100};
`

export const Exp = styled.span`
	${props => props.theme.fonts.GothamRegular20};
	color: ${props => props.theme.colors.white.white70};
`