import { Wrapper as HoverPanel } from "../../pages/Profile/Tabs/SkinChanger/HoverPanel/Styles";
import styled, { css, GradientKeys } from "styled-components";

export const WrapperContract = styled.div`
  /* max-width: max-content; */
`;

export const Hr = styled.hr`
  padding: 0;
  height: 3.5rem;
  border: none;
  background: ${(props) => props.theme.colors.white.white10};
  border-radius: 3rem;
  width: 100%;
`;

export const ContractAnumationWrapper = styled.div`
  display: flex;
  /* grid-template-columns: 3fr 1fr; */
  /* gap: 5rem; */
  justify-content: space-between;
  /* align-items: end; */
  @media (max-width: 1900px) {
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 5rem;
  }
`;
export const Grid = styled.div`
  display: grid;
  transition: all 0.3s;
  gap: 1.5rem;
  grid-template-columns: repeat(auto-fill, minmax(19.7rem, 1fr));
  margin: 3rem 0;
  @media (max-width: 768px) {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    justify-content: center;
  }
`;

export const HeaderItem = styled.span`
  ${(props) => props.theme.fonts.GothamBold32}
  ${(props) => props.theme.colors.white.white100}
`;

export const WrapNoSkin = styled.div`
  ${(props) => props.theme.fonts.GothamBold32}
  ${(props) => props.theme.colors.white.white100}
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  gap: 2rem;
`;
export const LeftItem = styled.div`
  width: 100%;
  @media (max-width: 1250px) {
    text-align-last: center;
  }
`;

export const WrapBlockItem = styled.div`
  display: flex;
  gap: 1rem;
  /* width: 100%; */
  margin-top: 3rem;
  @media (max-width: 1900px) {
    justify-content: center;
    flex-wrap: wrap;
    /* width: 100rem; */
  }
`;
export const ItemContr = styled.div<{ act?: boolean }>`
  display: flex;
  flex-direction: column;
  padding: 5rem 3rem;
  max-width: 120rem;
  height: 25rem;
  background: ${(props) => props.theme.gradients.shadow};
  color: ${(props) => props.theme.colors.white.white20};
  ${(props) => props.theme.fonts.GothamMedium24}
  align-items:center;
  gap: 3rem;
  border-radius: 3rem;
  cursor: ${(props) => (props.act ? "pointer" : "default")};
  @media (max-width: 768px) {
    padding: 3rem 1rem;
  }
`;

export const WrapInvent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 5rem;
`;

export const WeaponCardContract = styled.div<{
  onlyHoverPanel: boolean;
  rarity: GradientKeys;
  isSelected: Boolean;
  LifeCase?: boolean;
}>`
  position: relative;
  cursor: pointer;
  padding: 0 0 2rem;
  width: ${(props) => (props.LifeCase == true ? "100%" : "20.3rem")};
  height: ${(props) => (props.LifeCase == true ? "13rem" : "25rem")};
  background: ${(props) => props.theme.gradients[props.rarity]};
  border-radius: 1.5rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  gap: ${(props) => (props.LifeCase == true ? "" : "2.3rem")};
  ${(props) =>
    props.isSelected
      ? `outline: 3px solid ${props.theme.colors.green.selected}`
      : ""};

  ${(props) =>
    props.onlyHoverPanel &&
    css`
      ${HoverPanel} {
        /* opacity: 0; */
        display: none;
        pointer-events: none;
        transition: 0.3s;
      }
    `}

  &:hover {
    ${HoverPanel} {
      /* opacity: 1; */
      display: block;
      pointer-events: all;
    }
  }
  @media (max-width: 768px) {
    padding: 0 0 1rem;
    width: ${(props) => (props.LifeCase == true ? "100%" : "16.3rem")};
    height: ${(props) => (props.LifeCase == true ? "13rem" : "25rem")};
  }
`;
export const Info = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

export const Name = styled.span`
  text-align: center;
  ${(props) => props.theme.fonts.GothamRegular14};
  color: ${(props) => props.theme.colors.white.white100};
  display: flex;
  align-items: center;
  flex-direction: column;
  font-weight: 400;
  height: 3rem;
`;

export const SubName = styled.div<{ rarity: GradientKeys }>`
  display: inline;
  font-weight: bold;
  ${(props) => props.theme.fonts.GothamBold16};
  color: ${(props) => props.theme.colors.weapon[props.rarity]};
`;

export const WrapperCanvas = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 2rem;
  background: ${(props) => props.theme.gradients.shadow};
  border-radius: 3rem;
  /* width: 40rem; */
  /* height: 20rem; */
`;
export const Canvas = styled.canvas`
  /* border: "1px solid black"; */
  /* background: red; */
  border-radius: 2rem;

  background: ${(props) => props.theme.colors.white.white10};
  cursor: crosshair;

  /* width: 50rem; */
  /* height: 30rem; */
`;

export const ButClearCanvas = styled.div`
  background: ${(props) => props.theme.colors.white.white10};
  padding: 1rem;
  /* height: max-content; */
  align-content: center;
  border-radius: 1.5rem;
  cursor: pointer;
`;
export const FlexDef = styled.div<{ act?: boolean }>`
  display: flex;
  gap: 2rem;
  @media (max-width: 768px) {
    flex-direction: ${(props) => (props.act ? "row" : "column")};
  }
`;

export const WrapNewSkin = styled.div`
  width: 100%;
  align-content: center;
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  /* max-width: 60rem; */
  gap: 2rem;
  padding: 3rem;
`;
export const WeaponWrap = styled.span`
  ${(props) => props.theme.colors.white.white100}
  ${(props) => props.theme.fonts.GothamMedium15}
`;

export const WrapSkins = styled.div<{ rarity: GradientKeys }>`
  align-content: center;
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  border-radius: 3rem;
  padding: 0rem 3rem 3rem 3rem;
  gap: 2rem;
  background: ${(props) => props.theme.gradients[props.rarity]};
`;
