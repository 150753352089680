import styled from "styled-components";

export const Template = styled.div`
  border-radius: 2.5rem;
  position: relative;
  /* width: 100%; */
  margin: 0px 7rem 0px 1rem;
  height: 13rem;
  padding: 3rem;
  /* margin-left: 5.4rem; */
  border: 0.3rem solid ${(props) => props.theme.stroke.stroke5};
  ${(props) => props.theme.shadows.defaultShadow};
  ${(props) => props.theme.mixins.dfspaceBetween};
  @media (max-width: 1250px) {
    height: auto;
    gap: 3rem;
    flex-direction: column;
    /* width: max-content; */
    /* width: calc(100% - ${(props) =>
      props.theme.consts.navBarWidth} + 3rem); */
    margin-left: 0rem;
    width: 90%;
    align-self: center;
    margin: 0;
  }
`;
