interface Props extends React.HTMLAttributes<HTMLOrSVGElement> {

}

export default function ArrowBack({ ...props }: Props) {
	return (
		<svg {...props} width="3.2rem" height="2.2rem" viewBox="0 0 32 22" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M10.4669 21.5875C11.0675 22.1581 12.0169 22.1337 12.5875 21.5332C13.1581 20.9326 13.1337 19.9832 12.5331 19.4126L5.25669 12.4999H30.5C31.3284 12.4999 32 11.8284 32 11C32 10.1716 31.3284 9.50005 30.5 9.50005H5.2567L12.5331 2.58739C13.1337 2.01682 13.1581 1.06741 12.5875 0.466839C12.0169 -0.133736 11.0675 -0.15806 10.4669 0.41251L0.466852 9.91256C0.168771 10.1957 0 10.5889 0 11C0 11.4111 0.168771 11.8043 0.466852 12.0874L10.4669 21.5875Z" fill="white" />
		</svg>
	)
}
