import { IActionButton } from "interfaces/ActionButton";
import styled from "styled-components";

export const ButtonWrapper = styled.button<IActionButton>`
	width: 2.2rem;
	height: 2.2rem;
	border-radius: 2rem;
	background: ${props => props.theme.colors.play_copy_btns};
	cursor: pointer;
	* {
		transition: all .3s;
	}

	&:hover {
		svg > * {
			fill-opacity: 1;
		}
	}
`