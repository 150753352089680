import { ICheckbox } from "interfaces/Checkbox";
import { useId } from "react";
import { Container, Label } from "./Styles";

export default function Checkbox({
  children,
  label,
  checked,
  ...props
}: ICheckbox) {
  const id = useId();

  return (
    <Container>
      <input type="checkbox" id={id} {...props} checked={checked} />
      <Label htmlFor={id}>{children ? children : label}</Label>
    </Container>
  );
}
