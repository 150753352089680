import { VipServise } from "API/VipServise";
import { Icons } from "assets/icons";
import { TinyIcons } from "assets/tiny-icons";
import Layout from "components/Layout";
import VipModal from "components/Modal/VipModal/VipModal";
import Button from "components/UI/Button";
import { useAppSelector } from "hooks/useAppSelector";
import { GetCatecory, Type } from "interfaces/Vip";
// import ArroyVip from '../../../public/static/images/arrouVip.png'
import Image from "components/Image";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  BlockItem,
  BlockPrice,
  Blocktest,
  BlockVip,
  BlockVipMed,
  Header,
  ItemNamePosibilities,
  NameVip,
  PriceVip,
  SmallPriceVip,
  Wrapper,
  WrapperVip,
  WrapperVipPrice,
  WrapPrice,
} from "./styled";

import { endpoints, url } from "API";
import { Helmet } from "react-helmet";

export default function VipShop() {
  const [VipCategory, setVipCategory] = useState<GetCatecory[]>([]);
  const [activeVip, setActiveVip] = useState<GetCatecory>();
  const [activeModal, setActiveModal] = useState<boolean>(false);
  const { isAuth, user } = useAppSelector((state) => state.user);
  const navigate = useNavigate();
  useEffect(() => {
    async function StartPage() {
      setVipCategory(await VipServise.GetShop());
    }

    StartPage();
    // console.log(VipCategory);
  }, []);

  function loginHandler() {
    window.location.href = url + endpoints.auth.login;
  }
  return (
    <Layout headerElement={<div />} leftElement={<div />}>
      <Helmet>
        <title>FREEZ-BLOOD - PREMIUM/VIP</title>
        <meta name="description" content={`FREEZ-BLOOD - PREMIUM/VIP`} />
      </Helmet>
      <Wrapper>
        <Header>Подписка на все случаи!</Header>
        <WrapperVip>
          {VipCategory &&
            VipCategory.map((item, index) => {
              if (index < 2) {
                return (
                  <BlockVip>
                    <div>
                      <NameVip Color={index === 0 ? false : true}>
                        {item.name}
                      </NameVip>
                      <BlockVipMed>
                        {item.posibilities &&
                          item.posibilities.map((items) => {
                            return (
                              <BlockItem Color={index === 0 ? false : true}>
                                <TinyIcons.Okey />
                                <ItemNamePosibilities>
                                  {items.possibility}
                                </ItemNamePosibilities>
                              </BlockItem>
                            );
                          })}
                      </BlockVipMed>
                    </div>
                    <WrapperVipPrice>
                      {item.prices.map((pr) => {
                        if (pr.name == "1 month") {
                          return (
                            <BlockPrice>
                              <WrapPrice>
                                {/* <PriceVip>{pr.name}</PriceVip> */}
                                <PriceVip>{pr.price} руб </PriceVip>
                                <SmallPriceVip>в месяц</SmallPriceVip>
                              </WrapPrice>
                              {user?.group?.name == item.name ? (
                                <Button
                                  variant="default"
                                  height="7rem"
                                  color={
                                    user?.group?.name == "VIP"
                                      ? "blueVIP"
                                      : "orangeVIP"
                                  }
                                  params={{ width: "70%" }}
                                  onClick={() => {
                                    setActiveVip(item);
                                    setActiveModal(true);
                                  }}
                                >
                                  <Icons.Cart />
                                  Продлить
                                </Button>
                              ) : isAuth && localStorage.getItem("token") ? (
                                <Button
                                  variant="default"
                                  height="7rem"
                                  color={
                                    item.name == "VIP" ? "blueVIP" : "orangeVIP"
                                  }
                                  params={{ width: "70%" }}
                                  onClick={() => {
                                    setActiveVip(item);
                                    setActiveModal(true);
                                  }}
                                >
                                  <Icons.Cart />
                                  Купить
                                </Button>
                              ) : (
                                <Button
                                  height="5rem"
                                  variant="default"
                                  color="blue"
                                  params={{ width: "70%" }}
                                  onClick={() => {
                                    loginHandler();
                                  }}
                                >
                                  Авторизоваться
                                </Button>
                              )}
                            </BlockPrice>
                          );
                        } else {
                          return null;
                        }
                      })}
                    </WrapperVipPrice>
                  </BlockVip>
                );
              } else return null;
            })}
          <Image
            src="/static/images/arrouVip.png"
            alt="Team"
            width="280px"
            height="100px"
          />
        </WrapperVip>

        {!user?.group?.vipTest ? (
          <Blocktest>
            <WrapPrice>
              <PriceVip>VIP Test</PriceVip>
              <SmallPriceVip>2 дня</SmallPriceVip>
            </WrapPrice>
            {isAuth && localStorage.getItem("token") ? (
              <Button
                variant="default"
                height="6rem"
                color="blueVIP"
                // params={{ width: "50%" }}
                onClick={async () => {
                  const result = await VipServise.BuyGift(
                    Type.Buy,
                    VipCategory[2].id,
                    VipCategory[2].prices[0].id,
                    user.user.steamid64
                  );
                  if (result) {
                    navigate(`/profile/${user.user.steamid64}/privileges`);
                    window.location.reload();
                  } else {
                    toast.error("Непредвиденная ошибка");
                  }
                }}
              >
                Попробовать бесплатно
              </Button>
            ) : (
              <Button
                variant="default"
                height="6rem"
                color="blueVIP"
                onClick={() => {
                  loginHandler();
                }}
              >
                Авторизоваться
              </Button>
            )}
          </Blocktest>
        ) : null}
      </Wrapper>
      {activeVip && (
        <VipModal
          isActive={activeModal}
          setActive={setActiveModal}
          vip={activeVip}
        />
      )}
    </Layout>
  );
}
