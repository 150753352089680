import { TicketAndBlockStatus, TicketStatuses } from "models/Ticket";
import styled, { css } from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5rem;
  overflow: auto;
  margin-left: 1rem;
`;
export const WrapperObr = styled.div`
  width: 100%;

  gap: 2rem;
  @media (max-width: 768px) {
    overflow: auto;
    overflow-x: scroll;
    margin-left: 1rem;
  }
`;
export const Header = styled.div`
  display: flex;
  justify-content: left;
  margin-bottom: 2rem;
  ${(props) => props.theme.fonts.GothamBold32}
`;

export const NotSended = styled.h1`
  color: ${(props) => props.theme.colors.red.accent};
  ${(props) => props.theme.fonts.GothamMedium32};
  width: 100%;
  text-align: center;
`;

export const Tickets = styled.div`
  width: 100%;

  height: fit-content;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-bottom: 1rem;
  @media (max-width: 768px) {
    min-width: 100rem;
  }
`;

export const Head = styled.div`
  display: grid;
  gap: 5rem;
  grid-template-columns: 1fr 0.4fr 0.3fr;
`;

export const HeadItem = styled.span`
  ${({ theme }) => theme.fonts.GothamMedium20};
  color: ${({ theme }) => theme.colors.white.white70};
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3rem;
  cursor: pointer;
  ${({ theme }) => theme.colors.ct};
  background: ${(props) => props.theme.gradients.shadow};
  /* ${(props) => `background:${props.theme.colors.blue.accent}`} */
  border-radius: 2.5rem;
  padding: 3rem;
  /* opacity: 30%; */
`;

export const BodyRow = styled.div`
  cursor: pointer;
  display: grid;
  gap: 5rem;
  grid-template-columns: 1fr 0.4fr 0.3fr;
`;

export const BodyItem = styled.span`
  ${({ theme }) => theme.fonts.GothamRegular20};
  color: ${({ theme }) => theme.colors.white.white100};
  overflow-wrap: anywhere;
`;

export const TicketStatus = styled(BodyItem)<{ status?: TicketAndBlockStatus }>`
  ${({ theme, status }) =>
    status === TicketAndBlockStatus.CREATED &&
    css`
      color: ${theme.colors.white.white70};
    `}
  ${({ theme, status }) =>
    status === TicketAndBlockStatus.IN_PROGRESS &&
    css`
      color: ${theme.colors.blue.accent};
    `}
	${({ theme, status }) =>
    status === TicketAndBlockStatus.CLOSED &&
    css`
      color: ${theme.colors.green.accent};
    `}
	${({ theme, status }) =>
    status === TicketAndBlockStatus.DELETED &&
    css`
      color: ${theme.colors.red.accent};
    `}
    ${({ theme, status }) =>
    status === TicketAndBlockStatus.UNBANNED &&
    css`
      color: ${theme.colors.green.accent};
    `}
`;
export const SubHeader = styled.span`
  display: flex;
  justify-content: center;
  ${(props) => props.theme.fonts.GothamBold32}
`;
