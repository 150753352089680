export default function LayoutSixLines() {
	return (
		<svg width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect width="10" height="5" rx="2" fill="white" />
			<rect y="8" width="10" height="5" rx="2" fill="white" />
			<rect x="12" y="8" width="10" height="5" rx="2" fill="white" />
			<rect x="12" y="16" width="10" height="5" rx="2" fill="white" />
			<rect y="16" width="10" height="5" rx="2" fill="white" />
			<rect x="12" width="10" height="5" rx="2" fill="white" />
		</svg>
	)
}
