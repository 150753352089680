export interface BattlPassInterf {
  id: number;
  active: Boolean;
  expired: Date;
  levels: [levels];
  stats: [stats];
}

export interface BatttInfoMe {
  currentLevel: LevelInfo | null;
  nextLevel: LevelInfo;
  xp: number;
  xpToNextLevel: number;
  tasks: {
    stats: [TastkStatsInfo];
    expired: Date;
  };
}

// Основные -!-

export interface levels {
  id: number;
  level: number;
  prize: PrizeEnum;
  value: string | null;
  xp: number;
  vipPrize: PrizeEnum;
  vipValue: string;
  earned: boolean;
  earnedVip: boolean;
}

export interface stats {
  id: number;
  name: string;
  steamid64: string;
  avatar: string;
  xp: number;
}

export enum PrizeEnum {
  Vip = "vip",
  Premium = "premium",
  Skin = "skin",
  Cash = "cash",
  Nothing = "nothing",
  Credits = "credits",
}

export interface TimeObject {
  day: number;
  hours: number;
  minutes: number;
  seconds: number;
}

export enum TaskEnum {
  Kills = "kills",
  Cases = "cases",
  Socials = "socials",
  Playtime = "playtime",
  PlayTimeMode = "playtimeMode",
  Contracts = "contracts",
  KillNoscope = "killNoscope",
  Dies = "dies",
  KillHeadshot = "killHeadshot",
  SpendCredits = "spendCredits",
  KillByMode = "killByMode",
}

export interface LevelInfo {
  id: number;
  level: number;
  prize: PrizeEnum;
  value: string | null;
  xp: number;
  vipPrize: PrizeEnum | null;
  vipValue: string | null;
}

export interface TastkStatsInfo {
  id: number;
  exp: number;
  type: TaskEnum;
  target: number;
  description: string;
  stats: number;
}

export interface SocialVerify {
  vk: boolean;
  vkSubscribed: boolean;
  discord: boolean;
}
