import { mySteamId } from "utils/IsYou";

export enum socketEmitters {
  tradeRoom = "tradeRoom",
  tradeStart = "tradeStart",
  changeItem = "changeItem",
  confirmation = "confirmation",
  apply = "apply",
}

export enum socketListener {
  inventory = "inventory",
  tradeData = "tradeData",
  trade = "trade",
  update = "update",
  finish = "finish",
  confirmation = "confirmation",
  partnerConfirmed = "partnerConfirmed",
  changeTradeStatus = "changeTradeStatus",
  tradeCanceled = "tradeCanceled",
  updateBalance = "updateBalance",
  marketUpdate = "marketUpdate",
  updateInventory = "updateInventory",
  // updateBalance = "UpdateBalance",
  dropLive = "dropLive",
  server = "servers",
  blockAndPlayer = "blocksAndPlayers",
}

export enum TradeAction {
  ADD,
  DELETE,
}

export class SocketProvider {
  socket = window.socket;

  public sendTrade(receiver: string) {
    this.socket.emit(socketEmitters.tradeRoom, {
      sender: mySteamId(),
      receiver: receiver,
    });
  }

  public tradeStart(uuid: string) {
    this.socket.emit(socketEmitters.tradeStart, {
      uuid,
    });
  }

  public changeItem(uuid: string, item_id: number, action: TradeAction) {
    this.socket.emit(socketEmitters.changeItem, {
      uuid,
      item_id,
      action,
    });
  }

  public confirmationTrade(uuid: string) {
    this.socket.emit(socketEmitters.confirmation, {
      uuid,
    });
  }

  public apply(uuid: string) {
    this.socket.emit(socketEmitters.apply, {
      uuid,
    });
  }

  // public sendSkins(steamid64: string, uuid: string, items: IInventoryWeaponCard[]) {
  //   window.socket.emit('changeItem', {
  //     steamid64,
  //     uuid,
  //     items
  //   })
  // }

  // /**
  //  * @description Отправлет статус подверждения противоположнему игроку, после подтверждения двух сторон приходит ивент confirmed
  //  */
  // public acceptTrade(uuid: string, second: boolean = false) {
  //   let event = 'confirmation'

  //   if (second)
  //     event = 'secondConfirmation'

  //   window.socket.emit(event, {
  //     uuid
  //   })
  // }

  // /**
  //  * @description принимает трейд из истории
  //  */
  // public startTrade(uuid: string) {
  //   window.socket.emit('tradeStart', {
  //     uuid,
  //     status: true
  //   })
  // }

  // // public acceptedTrade(senderConfirmation: boolean, receiverConfirmation: boolean) {
  // //   window.socket.emit('confirmationTrade', {
  // //     senderConfirmation,
  // //     receiverConfirmation
  // //   })
  // // }
}
