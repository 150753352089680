import Layout from 'components/Layout'
import VacansyModal from 'components/Modal/Vacansy/Vacansy'
import UserSupportBlock from 'components/Templates/UserSupportBlock'
import { useState } from 'react'
import { NotSended, Wrapper } from './Styles'

export default function Vacansy() {

	const [modal, setModal] = useState(false)

	return (
		<Layout
			headerElement={<div />}
			leftElement={<div />}
		>
			<Wrapper>
				<UserSupportBlock
					title='Модератор на сервер'
					buttonText='Отправить заявку'
					callback={() => setModal(true)}
				/>
				<NotSended>
					Нет отправленных заявок
				</NotSended>
			</Wrapper>
			<VacansyModal
				isActive={modal}
				setActive={setModal}
			/>
		</Layout>
	)
}
