import HeaderUserCard from "components/Cards/HeaderUserCard";
import { StyledHeader } from "./styles";

export default function Header({ children }: any) {
  const windowInnerWidth = document.documentElement.clientWidth;
  return (
    <StyledHeader>
      {windowInnerWidth > 1250 ? children : <div />}

      <HeaderUserCard />
    </StyledHeader>
  );
}
