import styled from "styled-components";

export const Wrapper = styled.div`
  /* display: flex; */
  /* align-items: center; */
  /* justify-content: center; */
  width: 100%;
  padding: 20px 0px 100px 0px;
`;

export const WrapperCaseCategory = styled.div`
  /* display: flex; */
  /* align-items: center; */
  /* justify-content: center; */
  width: 100%;
  /* padding: 20px 0px 0 100px;
   */
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex-wrap: wrap;
  /* justify- */
  background-image: src("/public/static/images/linies.jpg");
  /* background-image: src("/public/static/images/T.png"); */
  z-index: 10;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin-top: 50px;
  hr {
    /* border-color: ${(props) => props.theme.colors.play_copy_btns};
    opacity: 50%;
    height: 1px; */
    border: none;
    height: 1px; /* Общая высота линии */
    background: linear-gradient(
      to right,
      transparent,
      ${(props) => props.theme.colors.play_copy_btns},
      transparent
    );
    margin: 0;
    margin-top: 20px;
  }
`;

export const WrapperCaseItem = styled.div`
  /* width: auto; */
  display: flex;
  gap: 15px;
  justify-content: center;
  margin-top: 30px;
  flex-wrap: wrap;
`;

export const ItemCase = styled.div`
  width: 21rem;
  height: 33rem;
  padding: 10px 10px 0px 10px;
  background: ${(props) => props.theme.gradients.shadow};
  /* background: linear-gradient(to left top, red, yellow 20%); */
  border-radius: 1.5rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  align-items: center;
  transition: 0.3s;
  gap: 20px;
  text-align: center;
  @media (max-width: 768px) {
    width: 15rem;
    gap: 1rem;
    height: 23rem;
  }
`;

export const TitleCaseWrapper = styled.div`
  display: flex;

  justify-content: center;
  align-items: center;
  svg {
    width: 29px;
    height: 24px;
  }
`;

export const TitleCaseName = styled.span`
  text: ${(props) => props.theme.colors.white.white100};
  ${(props) => props.theme.fonts.GothamRegular20};
`;
export const PriceKP = styled.span<{ Color?: Boolean }>`
  color: ${(props) =>
    props.Color == true
      ? props.theme.colors.blue.accent
      : props.theme.colors.white.white100};
  ${(props) => props.theme.fonts.GothamBold20};
`;

export const Title = styled.span`
  text: ${(props) => props.theme.colors.white.white100};
  ${(props) => props.theme.fonts.GothamBold24};
`;

export const WrapperLifeScroll = styled.div`
  display: block;
`;

export const Text = styled.span`
  text: ${(props) => props.theme.colors.white.white100};
  ${(props) => props.theme.fonts.GothamBold16};
`;

export const InfoSmall = styled.div`
  display: flex;
  width: max-content;
  span:last-child {
    margin-left: 5px;
  }
  align-items: center;
  margin-bottom: 15px;
`;
