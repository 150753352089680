import { TicketStatuses } from "models/Ticket";
import styled, { css } from "styled-components"

export const Wrapper = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 3rem;
`

export const Info = styled.div`
	width: 100%;
	height: fit-content;
	display: flex;
	flex-direction: column;
	gap: 3rem;
`

export const InfoItem = styled.p<{ width?: string; status?: string; }>`
		${({ theme }) => theme.fonts.GothamRegular20};
		color: ${({ theme }) => theme.colors.white.white100};
		display: block;
		width: ${({ width }) => width && '100%'};
		max-width: ${({ width }) => width && width};
`

export const Content = styled.p`
	${({ theme }) => theme.fonts.GothamRegular16};
	color: ${({ theme }) => theme.colors.white.white100};
	line-height: 125%;
`

export const FullInfo = styled.div`
	display: grid;
	gap: 3rem;
	grid-template-columns: .3fr 1fr;
`
export const Status = styled(InfoItem)`
		${({ status, theme }) => status === TicketStatuses.OPEN && css`
		color: ${theme.colors.green.accent};
	`}
	${({ status, theme }) => status === TicketStatuses.PENDING && css`
		color: ${theme.colors.blue.accent};
	`}
	${({ status, theme }) => status === TicketStatuses.CLOSED && css`
		color: ${theme.colors.red.accent};
	`}
`

export const Reason = styled(InfoItem)`
	color: ${({ theme }) => theme.colors.red.accent};
`
export const Admin = styled(InfoItem)`
	color: ${({ theme }) => theme.colors.blue.accent};
`