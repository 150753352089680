import { IDropDown } from "interfaces/DropDown";
import { useEffect, useState } from "react";
import Body from "./Body";
import Head from "./Head";
import { Wrapper } from "./Styles";

export default function DropDown({
  headParams,
  items,
  onChange,
  defaultValue = "",
  useSort,
  setUse,
  _,
}: IDropDown) {
  const [show, setShow] = useState<boolean>(false);
  const [headLabel, setHeadLabel] = useState<string>("");
  const [defVal, setDefVal] = useState<string>(defaultValue);
  const [value, setValue] = useState<string>("");

  function onItemClick(index: number) {
    setShow(false);
    if (items[1] && items[0] !== "pric" && items[0] !== "rar") {
      const label = items[index]?.label ? items[index]?.label : items[index];
      const value = items[index]?.value ? items[index]?.value : items[index];
      setHeadLabel(label);
      setDefVal("");
      onChange(value);
      setValue(value);
    }
  }

  function onclickRarOrPrice() {
    if (items[0] == "pric") {
      setValue("pric");
    }
    if (items[0] == "rar") {
      setValue("rar");
    }
  }

  useEffect(() => {
    if (value == "" || value == "Все" || setUse || setUse === undefined) {
      setHeadLabel("Все");
      setValue("Все");
    }
  }, [setUse]);

  useEffect(() => {
    onclickRarOrPrice();
  }, []);

  return (
    <Wrapper onClick={() => setShow(!show)}>
      <Head
        setBodyShow={setShow}
        show={show}
        params={headParams}
        label={headLabel}
        defaultValue={defVal}
        value={value}
        useSort={useSort}
      />

      {items[1] && items[0] !== "pric" && items[0] !== "rar" ? (
        <Body show={show} items={items} onItemClick={onItemClick} />
      ) : null}
    </Wrapper>
  );
}
