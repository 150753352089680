export default function Players() {
	return (
		<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g filter="url(#filter0_d_399_2570)">
				<path d="M5 16C5 16 4 16 4 15C4 14 5 11 10 11C15 11 16 14 16 15C16 16 15 16 15 16H5ZM10 10C10.7956 10 11.5587 9.68393 12.1213 9.12132C12.6839 8.55871 13 7.79565 13 7C13 6.20435 12.6839 5.44129 12.1213 4.87868C11.5587 4.31607 10.7956 4 10 4C9.20435 4 8.44129 4.31607 7.87868 4.87868C7.31607 5.44129 7 6.20435 7 7C7 7.79565 7.31607 8.55871 7.87868 9.12132C8.44129 9.68393 9.20435 10 10 10Z" fill="white" />
			</g>
			<defs>
				<filter id="filter0_d_399_2570" x="0" y="0" width="20" height="20" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
					<feFlood floodOpacity="0" result="BackgroundImageFix" />
					<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
					<feOffset />
					<feGaussianBlur stdDeviation="2" />
					<feComposite in2="hardAlpha" operator="out" />
					<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0" />
					<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_399_2570" />
					<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_399_2570" result="shape" />
				</filter>
			</defs>
		</svg>
	)
}
