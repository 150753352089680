import styled from "styled-components";

const circleHeight = "2.5rem";

export const ToggleBlock = styled.div`
  cursor: pointer;
  width: 5rem;
  height: ${circleHeight};
  position: relative;
  transition: 0.3s;
  background: ${(props) => props.theme.gradients.shadow};
  border-radius: 2.5rem;
  &::before {
    transition: 0.3s;
    position: absolute;
    top: 0.2rem;
    left: 0.5rem;
    height: 2rem;
    border-radius: 50%;
    width: 2rem;
    content: " ";
    background: ${(props) => props.theme.gradients.shadow};
    box-shadow: 0 0.3rem 0.8rem rgba(0, 0, 0, 0.15),
      0 0.3rem 0.1rem rgba(0, 0, 0, 0.06);
  }
`;

export const Container = styled.div`
  width: fit-content;
  height: fit-content;
  input[type="checkbox"] {
    display: none;
  }
  input:checked + div::before {
    left: calc(100% - ${circleHeight});
    background: ${(props) => props.theme.colors.white.white70};
  }
  input:checked + div {
    background: ${(props) => props.theme.colors.white.white20};
  }
`;

export const Label = styled.label`
  transition: 0.3s;
  display: flex;
  align-items: center;
  position: relative;
  gap: 1rem;
  user-select: none;
  ${(props) => props.theme.fonts.GothamRegular20};
  color: ${(props) => props.theme.colors.white.white100};
  cursor: pointer;
`;
