import { Container, Img, Text, Shadow } from "./Styles";

export interface StickerRowProps {
  id: number;
  sticker_kit: number;
  image: string;
  name?: string;
  item_name?: string;
  item_name_en?: string;
  item_name_ru: string;
}

interface Props {
  onClick: (id: string) => void;
  data: StickerRowProps;
}

export default function StickerRow({ onClick, data }: Props) {
  return (
    <Container onClick={() => onClick(data.id + "")}>
      {data?.image ? <Img src={data.image} /> : <Shadow />}
      <Text>{data?.item_name_ru || "Загрузка..."}</Text>
    </Container>
  );
}
