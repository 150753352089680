import { ListInputType } from "components/ListInfo/ListInfo";
import { Container, Time, UserInfo, ButtonContainer, Timer } from "./Styles";
import { LabelAvatar } from "components/Labels";
import { useTheme } from "styled-components";
import UIKIT from "components/UI";
import Close from "assets/nano-icons/Close";
import {
  EConfirmList,
  ITrade,
  TradeInventory,
  TradeStatus,
} from "interfaces/Trade";
import { Modals } from "components/Modal";
import { useState, useEffect } from "react";
import { tradeData } from "utils/convertData";
import { useActions } from "hooks/useActions";
import { useAppSelector } from "hooks/useAppSelector";
import { observer } from "mobx-react-lite";
import TradeState from "components/Modal/Trade/TradeState";
import { SocketProvider } from "API/SocketProvider";
import API from "API";
import HistoryState from "components/Modal/History/HistoryState";
import { mySteamId } from "utils/IsYou";
import { useNavigate } from "react-router-dom";

interface Props {
  data: TradeInventory;
}

function TradeCard({ data }: Props) {
  const navigate = useNavigate();
  const Theme = useTheme();
  const [activeModal, setActiveModal] = useState<boolean>(false);
  const { trade } = useAppSelector((state) => state.trade);

  let timer: any;

  const socketProvider = new SocketProvider();
  const accept = () => {
    socketProvider.tradeStart(data.uuid);
    // TradeState.setShowModal(true);
  };

  const cancel = async () => {
    const a = await API.TradeService.cancel(data.uuid);
    // fetchCancelTrade(trade.uuid)
    // console.log("aaaaaaaa", a);
    TradeState.setStatusCansel(a.success);
    TradeState.setUUID(data.uuid);
  };

  // useEffect(() => {
  //   if (data.date || data.time) {
  //     timer = setInterval(() => {
  //       const renaining =
  //         new Date(data.date || data.time).getTime() + 60_000 - Date.now();
  //       if (renaining < 0) {
  //         clearInterval(timer);
  //         // HistoryState.setLive([]) // Старые обмены
  //       } else {
  //         TradeState.setTimeRemining(Math.floor(renaining / 1000));
  //       }
  //     }, 1000);
  //   }

  //   return () => {
  //     clearInterval(timer);
  //   };
  // }, [trade]);

  const getButtons = () => {
    if (data.status !== TradeStatus.NotStarted) {
      return (
        <UIKIT.Button
          color="blue"
          variant="default"
          height="5rem"
          params={{ width: "25rem" }}
          onClick={() => navigate("/TradeRoom")}
        >
          Вернуться к обмену
        </UIKIT.Button>
      );
    } else if (data.receiver !== mySteamId()) {
      // console.log(data);
      return (
        <>
          {/* <Timer>Осталось: 0:{TradeState.timeRemining}</Timer> */}
          <UIKIT.Button
            color="red"
            variant="default"
            height="5rem"
            params={{ width: "25rem" }}
            onClick={cancel}
          >
            Отменить
          </UIKIT.Button>
        </>
      );
    } else {
      // console.log(data);
      return (
        <>
          {/* <Timer>Осталось: 0: ХУЙ</Timer> */}
          <UIKIT.Button
            color="blue"
            variant="default"
            height="5rem"
            params={{ width: "25rem" }}
            onClick={() => {
              accept();
              if (TradeState.tradeData.status == TradeStatus.NoConfirmed) {
                navigate("/TradeRoom");
              }
            }}
          >
            Принять обмен
          </UIKIT.Button>
          <UIKIT.Button
            color="red"
            variant="default"
            height="5rem"
            params={{ width: "25rem" }}
            onClick={cancel}
          >
            Отклонить
          </UIKIT.Button>
        </>
      );
    }
  };

  return (
    <>
      <Container>
        <UserInfo>
          <LabelAvatar
            src={data.avatar}
            params={{
              gap: "2rem",
              font: Theme.fonts.GothamRegular20,
              avatarSize: "small-6",
              isAvatarBorder: false,
            }}
          >
            {data.name}
          </LabelAvatar>
          <Time>{tradeData(data.time || data.date || "")}</Time>
        </UserInfo>
        <ButtonContainer>{getButtons()}</ButtonContainer>
      </Container>

      {/* <Modals.Trade isActive={activeModal} setActive={setActiveModal} data={data} type={ModalType.history} /> */}
    </>
  );
}
export default observer(TradeCard);
