import { makeAutoObservable } from "mobx";

class ChatState {
  constructor() {
    makeAutoObservable(this);
  }
  public onlineChat: number = 0;

  setNewOnline(kol: number) {
    this.onlineChat = kol;
  }
}

export default new ChatState();
