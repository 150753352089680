import { InventoryWeaponCard } from "components/Cards";
import { IItemSend } from "models/InventoryWeaponCard";
import rarityConvector from "utils/rarityConvector";
import { Card, Row, Text } from "./Styles";

interface Props {
  data: IItemSend[];
}

const TradeRow = ({ data }: Props) => {
  const getCountNeedRenderCards = () => {
    const START_EMPTY_CARDS = 7;

    const skinsLength = data.length;

    if (skinsLength > START_EMPTY_CARDS) {
      const needRenderCount = skinsLength / START_EMPTY_CARDS;

      if (`${needRenderCount}`.includes(".")) {
        if (`${needRenderCount}`.split(".")[1].startsWith("1")) return 6;
        if (`${needRenderCount}`.split(".")[1].startsWith("2")) return 5;
        if (`${needRenderCount}`.split(".")[1].startsWith("4")) return 4;
        if (`${needRenderCount}`.split(".")[1].startsWith("5")) return 3;
        if (`${needRenderCount}`.split(".")[1].startsWith("7")) return 2;
        if (`${needRenderCount}`.split(".")[1].startsWith("8")) return 1;
      } else {
        return 0;
      }
    } else {
      return START_EMPTY_CARDS - skinsLength;
    }
  };

  return (
    <div>
      <Text>Предложено партнёром</Text>
      <Row className="customScroll">
        {data.map((item) => {
          return (
            <InventoryWeaponCard
              weapon={item.weapon}
              skin={item.skin}
              cl={false}
              rarity={rarityConvector(+item.rarity)}
              src={item.src}
              skinInfo={item.skinInfo}
            />
          );
        })}

        {new Array(getCountNeedRenderCards()).fill(1).map((item) => {
          return <Card />;
        })}
      </Row>
    </div>
  );
};

export default TradeRow;
