import styled from "styled-components";

export const Wrapper = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 5rem;
`

export const NotSended = styled.h1`
	color: ${props => props.theme.colors.red.accent};
	${props => props.theme.fonts.GothamMedium32};
	width: 100%;
	text-align: center;
`
