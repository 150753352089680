import { Container } from "./Styles";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  children: any;
  justifyContent?: string;
  alignItems?: string;
  direction?: string;
  gap?: string;
  width?: string;
  a?: boolean;
}

export default function Column({ children, a, ...props }: Props) {
  return (
    <Container a={a} {...props}>
      {children}
    </Container>
  );
}
