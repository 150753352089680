import { urlSC, endpoints, token } from "API";
import axios from "axios";

export class AdminServise {
  static async getAdmins() {
    try {
      const headers = token ? { Authorization: "Bearer " + token } : {};
      const response = await axios.get(
        urlSC + endpoints.admin.admin + "/admins",
        {
          withCredentials: true,
          headers: headers,
        }
      );
      return response.data;
    } catch (error) {
      // console.log(error);
    }
  }
  static async getprivileges() {
    try {
      const headers = token ? { Authorization: "Bearer " + token } : {};
      const response = await axios.get(
        urlSC + endpoints.admin.admin + "/privileges",
        {
          withCredentials: true,

          headers: headers,
        }
      );
      return response.data;
    } catch (error) {
      // console.log(error);
    }
  }

  static async addNewAdmin(
    steamid64: string,
    group_id: number,
    server_id: number,
    expired_at: Date
  ) {
    try {
      const headers = token ? { Authorization: "Bearer " + token } : {};
      const response = await axios.post(
        urlSC + endpoints.admin.admin + "/privileges/grant",
        {
          steamid: steamid64,
          group_id: group_id,
          server_id: server_id,
          expired_at: expired_at,
        },
        {
          withCredentials: true,
          headers: headers,
        }
      );
      return response.data;
    } catch (error) {
      // console.log(error);
    }
  }

  static async DeleteAdmin(steamid64: string, idServer: number) {
    try {
      const headers = token ? { Authorization: "Bearer " + token } : {};
      const response = await axios.delete(
        urlSC +
          endpoints.admin.admin +
          `/privileges/take?steamid=${steamid64}&server_id=${idServer}`,
        {
          withCredentials: true,
          headers: headers,
        }
      );
      return response.data;
    } catch (error) {
      // console.log(error);
    }
  }

  static async PauseAdmin(
    steamid64: string,
    server_id: number,
    status: boolean
  ) {
    try {
      const headers = token ? { Authorization: "Bearer " + token } : {};
      const response = await axios.post(
        urlSC + endpoints.admin.admin + "/privileges/pause",
        {
          steamid64: steamid64,
          status: status,
          server_id: server_id,
        },
        {
          withCredentials: true,
          headers: headers,
        }
      );
      return response.data;
    } catch (error) {
      // console.log(error);
    }
  }

  static async ChangeParamsAdmin(
    old_steamid64: string,
    new_steamid64: string,
    server_id: number
  ) {
    try {
      const headers = token ? { Authorization: "Bearer " + token } : {};
      const response = await axios.post(
        urlSC + endpoints.admin.admin + "/update_steam",
        {
          old_steamid64: old_steamid64,
          new_steamid64: new_steamid64,
          server_id: server_id,
        },
        {
          withCredentials: true,
          headers: headers,
        }
      );
      return response.data;
    } catch (error) {
      // console.log(error);
    }
  }
}
