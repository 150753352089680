import styled from "styled-components";

interface Props {
	color?: string;
	font?: string;
}

export const Text = styled.span<Props>`
	${props => props.theme.fonts.GothamMedium15};
	color: ${props => props.color};
	${props => props.font && props.font}
`