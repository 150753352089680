import { Icons } from "../assets/icons/index";

export const asideItems = [
  { icon: <Icons.Home />, label: "Главная", link: "/" },
  { icon: <Icons.Rating />, label: "Статистика", link: "/rating" },
  { icon: <Icons.Shop />, label: "Магазин", link: "/store/credits" },
  { icon: <Icons.ViShop />, label: "PREMIUM", link: "/vip" },
  { icon: <Icons.Trade />, label: "Торговая площадка", link: "/marketplace" },
  { icon: <Icons.Case />, label: "Контейнеры", link: "/chest" },
  { icon: <Icons.Contract />, label: "Контракты", link: "/contract" },
  // { icon: <Icons.Roulette />, label: "Рулетка ", link: "/roulette" },
  { icon: <Icons.BattlPass />, label: "BattlePass", link: "/battlepass" },
  { icon: <Icons.Clans />, label: "Кланы", link: "/clans" },
  { icon: <Icons.Reload />, label: "Обмены", link: "/trade" },
  // { icon: <Icons.Bans />, label: "Баны/Муты", link: "/bans-mutes" },
  // { icon: <Icons.Work />, label: "Вакансии", link: "/vacansy" },
  { icon: <Icons.Support />, label: "Поддержка", link: "/support" },
  { icon: <Icons.Info />, label: "Информация", link: "/rules" },
];

export const footerAsideItem = [
  { label: "Правила проекта", link: "/rules" },
  { label: "Главная", link: "/" },
  { label: "Оферта проекта", link: "/rules/offer" },
  { label: "Магазин", link: "/store/credits" },
  { label: "Контакты проекта", link: "/rules/contact" },
  { label: "VIP/PREMIUM", link: "/vip" },
  { label: "Privacy Policy", link: "/rules/PrivatePolicy" }, //!!!!!!!!!!!
  { label: "Поддержка ", link: "/support" },
];
