import { Icons } from "assets/icons";
import { TinyIcons } from "assets/tiny-icons";
import Column from "components/Column";
import Layout from "components/Layout";
import LayoutIcon from "components/LayoutIcon";
import ProfileLayout from "components/ProfileLayout";
import SubHeader from "components/SubHeader";
import { useAppSelector } from "hooks/useAppSelector";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { getViolationLabel } from "utils/getViolationLabel";
import {
  Body,
  BodyElement,
  BodyItem,
  Head,
  HeadItem,
  Reason,
  Server,
  Status,
  Table,
  WhoBanned,
  Wrapper,
} from "./Styles";

export default function Applications() {
  const [mode, setMode] = useState<number>(0);
  const navigate = useNavigate();
  const { data } = useAppSelector((state) => state.applications);

  function onClickHandler(id: string | number) {
    navigate(`/applications/${id}`);
  }

  return (
    <Layout
      headerElement={<SubHeader>Заявки</SubHeader>}
      leftElement={
        <Column style={{ marginTop: "29rem" }}>
          <LayoutIcon
            className={mode === 0 ? "active" : ""}
            onClick={() => setMode(0)}
          >
            <TinyIcons.LayoutSecond />
          </LayoutIcon>
          <LayoutIcon
            className={mode === 1 ? "active" : ""}
            onClick={() => setMode(1)}
          >
            <Icons.Applications />
          </LayoutIcon>
        </Column>
      }
    >
      <ProfileLayout>
        <Wrapper>
          <Table>
            <Head>
              <HeadItem>№</HeadItem>
              <HeadItem>Тип</HeadItem>
              <HeadItem>Статус</HeadItem>
              <HeadItem>Дата</HeadItem>
              <HeadItem>Сервер</HeadItem>
              <HeadItem>Срок</HeadItem>
              <HeadItem>Причина</HeadItem>
              <HeadItem>Забанил</HeadItem>
            </Head>
            <Body>
              {data.map(
                (
                  {
                    user_name,
                    created_at,
                    server_id,
                    expired_at,
                    reason,
                    status,
                    type,
                    admin,
                    id,
                  },
                  index
                ) => (
                  <BodyElement key={index} onClick={() => onClickHandler(id)}>
                    <BodyItem>{index + 1}</BodyItem>
                    <BodyItem>{getViolationLabel(type)}</BodyItem>
                    <Status status={status}>{status}</Status>
                    <BodyItem>{created_at}</BodyItem>
                    <Server>{server_id}</Server>
                    <BodyItem>{expired_at}</BodyItem>
                    <Reason>{reason}</Reason>
                    <WhoBanned>{admin}</WhoBanned>
                  </BodyElement>
                )
              )}
            </Body>
          </Table>
        </Wrapper>
      </ProfileLayout>
    </Layout>
  );
}
