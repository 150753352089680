import { NavLink } from "react-router-dom";
import styled, { css } from "styled-components";

export const Weapons = styled.section<{ isGrid: boolean }>`
  ${(props) =>
    props.isGrid &&
    css`
      display: grid;
    `};
  grid-template-columns: repeat(auto-fit, 15.7rem);
  grid-auto-rows: max-content;
  align-items: stretch;
  gap: 1.5rem;
  align-content: flex-start;
  justify-content: center;
  @media (max-width: 1250px) {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    margin-left: 1.5rem;
    justify-content: center;
    overflow: scroll;
    /* width: max-content; */
  }
`;

export const Table = styled.div`
  width: 100%;
  padding: 3rem;
  border: 0.3rem solid ${(props) => props.theme.stroke.stroke5};
  ${(props) => props.theme.shadows.defaultShadow};
  border-radius: 2.5rem;
  @media (max-width: 1250px) {
    /* width: fit-content; */
    border: none;
    border-radius: 0;
    box-shadow: 0 0 0 0;
    /* gap: 3rem; */
  }
`;

export const Head = styled.div`
  display: grid;
  grid-template-columns: 2.2rem 9.4rem 21.5rem 17.8rem 15.3rem 22.5rem;
  justify-content: space-between;
  /* column-gap: 4rem; */

  @media (max-width: 1390px) {
    grid-template-columns: 2.2rem 9.4rem 21.5rem 10.8rem 10.3rem 10.5rem;
    gap: 3rem;
  }
`;

export const HeadItem = styled.span`
  ${(props) => props.theme.fonts.GothamBold20};
  color: ${(props) => props.theme.colors.white.white100};
  column-gap: 3rem;
`;

export const BodyItem = styled.div<{ color?: string }>`
  ${(props) => props.theme.fonts.GothamRegular20};
  color: ${(props) => props.color || props.theme.colors.white.white100};
`;

export const BodyItemItem = styled(BodyItem)<{ rarity: any }>`
  ${(props) => props.theme.mixins.ellipsis};

  color: ${(props) => props.theme.colors.weapon[props.rarity]};
`;

export const BodyElement = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 2.2rem 9.4rem 21.5rem 17.8rem 15.3rem 22.5rem;
  /* grid-template-columns: 1% 10% 14% 12% 16% 13% 15%; */
  justify-content: space-between;
  /* column-gap: 4rem; */
  cursor: pointer;
  @media (max-width: 1390px) {
    grid-template-columns: 2.2rem 9.4rem 21.5rem 10.8rem 10.3rem 10.5rem;
    gap: 3rem;
  }
  ${BodyItem}:nth-child(4) {
  }
`;

export const Body = styled.div`
  margin-top: 3rem;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  width: 100%;
`;

export const Server = styled(BodyItem)<{ rarity: any }>`
  ${(props) => props.theme.mixins.ellipsis};

  color: ${(props) => props.theme.colors.weapon[props.rarity]};
`;

export const Duration = styled(BodyItem)`
  ${(props) => props.theme.fonts.GothamRegular20}
`;

export const Reason = styled(NavLink)`
  display: flex;
  width: 22.5rem;
  ${(props) => props.theme.fonts.GothamRegular20};
  align-items: center;
  gap: 1.5rem;
  color: ${(props) => props.theme.colors.blue.accent};
`;
