export default function Ellipsis() {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_3391_51334)">
        <path
          d="M9.17188 9.99984C9.17188 10.2209 9.25967 10.4328 9.41595 10.5891C9.57223 10.7454 9.78419 10.8332 10.0052 10.8332C10.2262 10.8332 10.4382 10.7454 10.5945 10.5891C10.7507 10.4328 10.8385 10.2209 10.8385 9.99984C10.8385 9.77882 10.7507 9.56686 10.5945 9.41058C10.4382 9.2543 10.2262 9.1665 10.0052 9.1665C9.78419 9.1665 9.57223 9.2543 9.41595 9.41058C9.25967 9.56686 9.17188 9.77882 9.17188 9.99984Z"
          stroke="white"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M9.17188 15.8333C9.17188 16.0543 9.25967 16.2663 9.41595 16.4226C9.57223 16.5789 9.78419 16.6667 10.0052 16.6667C10.2262 16.6667 10.4382 16.5789 10.5945 16.4226C10.7507 16.2663 10.8385 16.0543 10.8385 15.8333C10.8385 15.6123 10.7507 15.4004 10.5945 15.2441C10.4382 15.0878 10.2262 15 10.0052 15C9.78419 15 9.57223 15.0878 9.41595 15.2441C9.25967 15.4004 9.17188 15.6123 9.17188 15.8333Z"
          stroke="white"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M9.17188 4.16683C9.17188 4.38784 9.25967 4.5998 9.41595 4.75608C9.57223 4.91237 9.78419 5.00016 10.0052 5.00016C10.2262 5.00016 10.4382 4.91237 10.5945 4.75608C10.7507 4.5998 10.8385 4.38784 10.8385 4.16683C10.8385 3.94582 10.7507 3.73385 10.5945 3.57757C10.4382 3.42129 10.2262 3.3335 10.0052 3.3335C9.78419 3.3335 9.57223 3.42129 9.41595 3.57757C9.25967 3.73385 9.17188 3.94582 9.17188 4.16683Z"
          stroke="white"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3391_51334">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
