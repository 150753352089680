import React, { useState } from "react";
import UIKIT from "components/UI";
interface ColorPickerProps {
  onChange: (color: string) => void;
}

const ColorPicker: React.FC<ColorPickerProps> = ({ onChange }) => {
  const [hue, setHue] = useState(180);
  const [selectedColor, setSelectedColor] = useState("hsl(0, 100%, 50%)");

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // console.log(event);
    const newHue = parseInt(event.target.value, 10);
    setHue(newHue);
    const newColor = `hsl(${newHue}, 100%, 50%)`;
    onChange(newColor);
    setSelectedColor(newColor);
  };

  const gradient = `linear-gradient(to bottom, 
    hsl(0, 100%, 50%) 0%, 
    hsl(60, 100%, 50%) 17%,
    hsl(120, 100%, 50%) 33%, 
    hsl(180, 100%, 50%) 50%, 
    hsl(240, 100%, 50%) 67%, 
    hsl(300, 100%, 50%) 83%, 
    hsl(360, 100%, 50%) 100%)`;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "10px",
        height: "200px",
      }}
    >
      <div
        style={{
          position: "relative",
          width: "10px",
          height: "200px",
          borderRadius: "3px",
          background: gradient,
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-start",
        }}
      >
        <input
          type="range"
          min="0"
          max="360"
          value={hue}
          onChange={handleChange}
          style={{
            minWidth: "200px",
            height: "10px",
            borderRadius: "50%",
            // border: "2px solid white",
            backgroundColor: "transparent",
            cursor: "pointer",
            transform: "rotate(90deg) translateX(90px)",
            // marginTop: `${(1 - hue / 360) * 100}%`,
            outline: "none",
            appearance: "none",
            background: "transparent",
          }}
        />
      </div>
    </div>
  );
};

export default ColorPicker;
