import { Button } from 'components/UI/Button/Styles';
import { Label } from 'components/UI/Input/Styles';
import styled, { WeaponKeys } from "styled-components"

interface Props {
	rarity: WeaponKeys;
}

export const Container = styled.div`
	position: relative;
	width: 50em;
	height: fit-content;
	padding: 3rem;
	border-radius: 2rem;
	${props => props.theme.shadows.defaultShadow};
	background: ${props => props.theme.gradients.bg};
	display: flex;
	align-items: center;
	flex-direction: column;
	gap: 3rem;
	> svg:nth-last-child(1){
		position: absolute;
		top: 3rem;
		right: 3rem;
		cursor: pointer;
	}
`

export const Text = styled.span`
	${props => props.theme.fonts.GothamBold24}
`