export default function MarketHistory() {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="white"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.47474 5.50033C4.5114 0.239623 11.238 -1.56087 16.4986 1.47478C18.5828 2.67798 20.215 4.53141 21.1451 6.75104C22.0752 8.97067 22.2519 11.4341 21.6481 13.7637C21.4094 14.6788 20.304 14.945 19.6595 14.3477L19.5672 14.252L17.0903 11.4067C16.3369 10.5411 17.0991 9.23771 18.1868 9.41589L18.3078 9.44119L19.7178 9.80965C19.4469 7.82949 18.5099 6.00056 17.0612 4.62386C15.6124 3.24715 13.7381 2.40471 11.7467 2.23516C9.75539 2.06561 7.76566 2.57907 6.10499 3.69103C4.44432 4.80298 3.21167 6.44718 2.6099 8.35304C2.00814 10.2589 2.0731 12.3128 2.79411 14.1769C3.51513 16.0409 4.84922 17.6039 6.57684 18.6087C8.30446 19.6134 10.3227 20.0001 12.2993 19.705C14.276 19.41 16.0933 18.4508 17.4522 16.9852C17.5496 16.8768 17.6676 16.7889 17.7993 16.7265C17.9311 16.6641 18.0739 16.6285 18.2195 16.6218C18.3651 16.6151 18.5105 16.6374 18.6475 16.6874C18.7844 16.7375 18.91 16.8142 19.0169 16.9132C19.1239 17.0121 19.2102 17.1314 19.2707 17.264C19.3312 17.3966 19.3647 17.5399 19.3694 17.6856C19.374 17.8313 19.3496 17.9764 19.2977 18.1126C19.2457 18.2488 19.1672 18.3733 19.0667 18.4789C15.655 22.1646 10.0348 23.1423 5.50016 20.5246C0.239616 17.489 -1.56083 10.761 1.47474 5.50033ZM10.9994 4.40045C11.2688 4.40049 11.5288 4.4994 11.7301 4.67841C11.9314 4.85743 12.06 5.1041 12.0915 5.37165L12.0992 5.50033V10.5444L15.0765 13.5217C15.2737 13.7197 15.3883 13.9853 15.3968 14.2646C15.4053 14.5439 15.3072 14.816 15.1224 15.0256C14.9376 15.2352 14.6799 15.3666 14.4018 15.3931C14.1236 15.4196 13.8458 15.3392 13.6247 15.1682L13.5213 15.077L10.2218 11.7773C10.0508 11.6062 9.94107 11.3836 9.90943 11.1438L9.89953 10.9997V5.50033C9.89953 5.20863 10.0154 4.92887 10.2217 4.7226C10.4279 4.51633 10.7077 4.40045 10.9994 4.40045Z"
        fill="white"
        fillOpacity="0.1"
      />
    </svg>
  );
}
