import { endpoints, url, urlSC } from "API";
import axios from "axios";
import LabelAvatar from "components/Labels/LabelAvatar";
import Layout from "components/Layout";
import SubHeader from "components/SubHeader";
import Select from "components/UI/Select";
import { useAppSelector } from "hooks/useAppSelector";
import { RatingInt, RatingIntMe } from "interfaces/RatingInt";
import { useEffect, useMemo, useState } from "react";
import { useTheme } from "styled-components";

import { CategoryMods } from "interfaces/CategoryMap";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import {
  Body,
  BodyItem,
  BodyRow,
  Container,
  Gradient,
  Head,
  HeadItem,
  PlaceItem,
  Wrapper,
} from "./Styles";
import { ServersService } from "API/ServersService";

export default function Ratings() {
  const [mode, setMode] = useState<number>();
  const [Rating, setIRating] = useState<Array<RatingInt>>([]);
  const [FinishCat, setFinishCat] = useState<object[]>([]);
  const [RatingMe, setIRatingMe] = useState<RatingIntMe>({});
  const theme = useTheme();
  // const { categories } = useAppSelector((state) => state.servers);
  const { user } = useAppSelector((state) => state.user);

  useEffect(() => {
    const getCategory = async () => {
      // category = await ServersService.getMonitoring();

      const category: Array<CategoryMods> = await ServersService.getMods();

      setFinishCat(category);

      // console.log("cat", cat);
    };

    const getHistory = async () => {
      try {
        const response = await axios.post(
          urlSC + endpoints.stats.top,
          { between_start: 1, between_end: 100, mode_id: mode },
          {
            withCredentials: true,
            headers: {
              Authorization: `Bearer ${localStorage.token}`,
            },
          }
        );

        setIRatingMe(response.data.myTop);
        setIRating(response.data.top);
        return response.data.data;
      } catch (error) {
        // console.log(error);
      }
    };
    getCategory();
    getHistory();
  }, []);

  useMemo(() => {
    const getHistory = async () => {
      try {
        const response = await axios.post(
          urlSC + endpoints.stats.top,
          { between_start: 1, between_end: 100, mode_id: mode },
          {
            withCredentials: true,
            headers: {
              Authorization: `Bearer ${localStorage.token}`,
            },
          }
        );

        setIRatingMe(response.data.myTop);
        setIRating(response.data.top);
        // console.log(categories);
        return response.data.data;
      } catch (error) {
        // console.log(error);
      }
    };
    getHistory();
  }, [mode]);
  // //console.log(Rating[1]);
  //console.log(urlSC + endpoints.stats.top);

  const RatingMemo = useMemo(() => {
    return Rating.map(
      (
        { avatar, name, place, kills, deaths, playtime, steamid64, points },
        index
      ) => (
        <BodyRow>
          <Gradient place={place !== null && place !== undefined ? place : 0} />
          <PlaceItem>
            {Rating[index].place !== null && Rating[index].place !== undefined
              ? Rating[index].place
              : 0}
          </PlaceItem>
          <BodyItem onClick={() => navigate(`/profile/${steamid64}`)}>
            <LabelAvatar
              src={
                avatar == "" || avatar == undefined || avatar == null
                  ? "static/images/AvatarStat.png"
                  : avatar
              }
              params={{
                avatarSize: "nano",
                font: theme.fonts.GothamRegular20,
                isAvatarBorder: false,
              }}
            >
              {name}
            </LabelAvatar>
          </BodyItem>
          <BodyItem>{kills}</BodyItem>

          <BodyItem>
            {(kills !== null &&
            kills !== undefined &&
            deaths !== undefined &&
            deaths !== null
              ? kills / deaths
              : 0
            ).toFixed(2)}
          </BodyItem>
          <BodyItem>
            {Math.round(playtime ? playtime / 60 / 60 : 0)} ч.
          </BodyItem>
          <BodyItem>{points}</BodyItem>
        </BodyRow>
      )
    );
  }, [Rating]);

  const navigate = useNavigate();
  return (
    <Layout
      leftElement={<div />}
      headerElement={<SubHeader>Топ 100 игроков</SubHeader>}
    >
      <Helmet>
        <title>FREEZ-BLOOD - Статистика</title>
        <meta name="description" content={`FREEZ-BLOOD - Статистика`} />
      </Helmet>
      <Wrapper direction="column">
        <Select
          items={FinishCat}
          onChange={setMode}
          label="Режим"
          params={{ width: "100%" }}
          Rating={true}
        />
        <Container className="customScroll">
          <Head>
            <HeadItem>Место</HeadItem>
            <HeadItem>Игрок</HeadItem>
            <HeadItem>Убийств</HeadItem>
            <HeadItem>K/D</HeadItem>
            <HeadItem>Время на сервере</HeadItem>
            <HeadItem>Очки</HeadItem>
          </Head>
          <Body>
            {user?.user?.steamid64 &&
              RatingMe !== undefined &&
              RatingMe !== null && (
                <BodyRow>
                  <Gradient place={0} />
                  <PlaceItem>
                    {RatingMe.place !== null && RatingMe.place !== undefined
                      ? RatingMe.place
                      : 0}
                  </PlaceItem>
                  <BodyItem
                    onClick={() =>
                      navigate(`/profile/${user?.user?.steamid64}`)
                    }
                  >
                    <LabelAvatar
                      src={
                        user?.user?.avatar == "" ||
                        user?.user?.avatar == undefined ||
                        user?.user?.avatar == null
                          ? "../../../build/static/images/AvatarStat.png"
                          : user?.user?.avatar
                      }
                      params={{
                        avatarSize: "nano",
                        font: theme.fonts.GothamRegular20,
                        isAvatarBorder: false,
                      }}
                    >
                      {user?.user?.name}
                    </LabelAvatar>
                  </BodyItem>
                  <BodyItem>{RatingMe.kills}</BodyItem>

                  <BodyItem>
                    {(RatingMe.kills !== undefined &&
                    RatingMe.deaths !== undefined
                      ? RatingMe.kills / RatingMe.deaths
                      : 0
                    ).toFixed(2)}
                  </BodyItem>
                  <BodyItem>
                    {Math.round(
                      RatingMe.playtime ? RatingMe.playtime / 60 / 60 : 0
                    )}{" "}
                    ч.
                  </BodyItem>
                  <BodyItem>{RatingMe.points}</BodyItem>
                </BodyRow>
              )}
            {/* {Rating[1]} */}
            {Rating !== null && Rating !== undefined && RatingMemo}
          </Body>
        </Container>
      </Wrapper>
    </Layout>
  );
}
