export default function Used() {
  return (
    <svg width="3.3rem" height="3.2rem" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_207_25523)">
        <path d="M4.5 16C4.5 17.5759 4.81039 19.1363 5.41345 20.5922C6.0165 22.0481 6.90042 23.371 8.01472 24.4853C9.12902 25.5996 10.4519 26.4835 11.9078 27.0866C13.3637 27.6896 14.9241 28 16.5 28C18.0759 28 19.6363 27.6896 21.0922 27.0866C22.5481 26.4835 23.871 25.5996 24.9853 24.4853C26.0996 23.371 26.9835 22.0481 27.5866 20.5922C28.1896 19.1363 28.5 17.5759 28.5 16C28.5 14.4241 28.1896 12.8637 27.5866 11.4078C26.9835 9.95189 26.0996 8.62902 24.9853 7.51472C23.871 6.40042 22.5481 5.5165 21.0922 4.91345C19.6363 4.31039 18.0759 4 16.5 4C14.9241 4 13.3637 4.31039 11.9078 4.91345C10.4519 5.5165 9.12902 6.40042 8.01472 7.51472C6.90042 8.62902 6.0165 9.95189 5.41345 11.4078C4.81039 12.8637 4.5 14.4241 4.5 16Z" stroke="#4DB45E" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M12.5 15.9999L15.1667 18.6666L20.5 13.3333" stroke="#4DB45E" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_207_25523">
          <rect width="3.2rem" height="3.2rem" fill="white" transform="translate(0.5)" />
        </clipPath>
      </defs>
    </svg>

  )
}