export default function Settings() {
  return (
    <svg width="3.3rem" height="3.2rem" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_207_25508)">
        <path d="M14.2667 5.756C14.8347 3.41467 18.1653 3.41467 18.7333 5.756C18.8186 6.10773 18.9856 6.43437 19.2209 6.70933C19.4563 6.98429 19.7532 7.19981 20.0875 7.33833C20.4219 7.47685 20.7842 7.53447 21.145 7.50649C21.5059 7.47852 21.855 7.36574 22.164 7.17733C24.2213 5.924 26.5773 8.27867 25.324 10.3373C25.1359 10.6462 25.0233 10.9951 24.9954 11.3557C24.9674 11.7163 25.025 12.0784 25.1634 12.4125C25.3017 12.7467 25.517 13.0434 25.7916 13.2787C26.0663 13.514 26.3926 13.6812 26.744 13.7667C29.0853 14.3347 29.0853 17.6653 26.744 18.2333C26.3923 18.3186 26.0656 18.4856 25.7907 18.7209C25.5157 18.9563 25.3002 19.2532 25.1617 19.5875C25.0232 19.9219 24.9655 20.2842 24.9935 20.645C25.0215 21.0059 25.1343 21.355 25.3227 21.664C26.576 23.7213 24.2213 26.0773 22.1627 24.824C21.8538 24.6359 21.5049 24.5233 21.1443 24.4954C20.7837 24.4674 20.4216 24.525 20.0875 24.6634C19.7533 24.8017 19.4566 25.017 19.2213 25.2916C18.986 25.5663 18.8188 25.8926 18.7333 26.244C18.1653 28.5853 14.8347 28.5853 14.2667 26.244C14.1814 25.8923 14.0144 25.5656 13.7791 25.2907C13.5437 25.0157 13.2468 24.8002 12.9125 24.6617C12.5781 24.5232 12.2158 24.4655 11.855 24.4935C11.4941 24.5215 11.145 24.6343 10.836 24.8227C8.77867 26.076 6.42267 23.7213 7.676 21.6627C7.86414 21.3538 7.97673 21.0049 8.00465 20.6443C8.03256 20.2837 7.97499 19.9216 7.83663 19.5875C7.69827 19.2533 7.48301 18.9566 7.20836 18.7213C6.93371 18.486 6.60742 18.3188 6.256 18.2333C3.91467 17.6653 3.91467 14.3347 6.256 13.7667C6.60773 13.6814 6.93437 13.5144 7.20933 13.2791C7.48429 13.0437 7.69981 12.7468 7.83833 12.4125C7.97685 12.0781 8.03447 11.7158 8.00649 11.355C7.97852 10.9941 7.86574 10.645 7.67733 10.336C6.424 8.27867 8.77867 5.92267 10.8373 7.176C12.1707 7.98667 13.8987 7.26933 14.2667 5.756Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M12.5 16C12.5 17.0609 12.9214 18.0783 13.6716 18.8284C14.4217 19.5786 15.4391 20 16.5 20C17.5609 20 18.5783 19.5786 19.3284 18.8284C20.0786 18.0783 20.5 17.0609 20.5 16C20.5 14.9391 20.0786 13.9217 19.3284 13.1716C18.5783 12.4214 17.5609 12 16.5 12C15.4391 12 14.4217 12.4214 13.6716 13.1716C12.9214 13.9217 12.5 14.9391 12.5 16Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_207_25508">
          <rect width="3.2rem" height="3.2rem" fill="white" transform="translate(0.5)" />
        </clipPath>
      </defs>
    </svg>
  )
}