export default function Credit() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1891_42074)">
        <rect
          width="28"
          height="28"
          rx="14"
          fill="url(#paint0_radial_1891_42074)"
        />
        <rect
          x="1"
          y="1"
          width="26"
          height="26"
          rx="13"
          stroke="url(#paint1_linear_1891_42074)"
          stroke-width="2"
        />
        <rect
          x="2.5"
          y="2.5"
          width="23"
          height="23"
          rx="11.5"
          stroke="url(#paint2_linear_1891_42074)"
        />
        <rect
          x="14.207"
          y="6.80005"
          width="10.474"
          height="10.474"
          rx="2"
          transform="rotate(45 14.207 6.80005)"
          fill="#235B84"
        />
        <rect
          opacity="0.1"
          x="-7"
          y="12.1628"
          width="38.3257"
          height="6"
          transform="rotate(-30 -7 12.1628)"
          fill="white"
        />
        <rect
          opacity="0.1"
          x="-3.90039"
          y="18.9441"
          width="38.3257"
          height="2.49716"
          transform="rotate(-30 -3.90039 18.9441)"
          fill="white"
        />
      </g>
      <defs>
        <radialGradient
          id="paint0_radial_1891_42074"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(14) rotate(90) scale(28)"
        >
          <stop stop-color="#78C6FF" />
          <stop offset="1" stop-color="#1E91E4" />
        </radialGradient>
        <linearGradient
          id="paint1_linear_1891_42074"
          x1="14"
          y1="0"
          x2="14"
          y2="28"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#389FEA" />
          <stop offset="1" stop-color="#15639A" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1891_42074"
          x1="14"
          y1="2"
          x2="14"
          y2="26"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#15639A" />
          <stop offset="1" stop-color="#2696E8" />
        </linearGradient>
        <clipPath id="clip0_1891_42074">
          <rect width="28" height="28" rx="14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
