import styled from "styled-components";
import { Template } from "components/Templates/Information/Styles";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3rem;
  ${Template} {
    margin-left: 0;
    width: 100%;
  }
`;

export const History = styled.div`
  /* border-radius: 2.5rem; */
  position: relative;
  width: 100%;
  height: fit-content;
  padding: 3rem 0;
  /* border: 0.3rem solid ${(props) => props.theme.stroke.stroke5}; */
  /* ${(props) => props.theme.shadows.defaultShadow}; */
  display: flex;
  flex-direction: column;
  gap: 3rem;
  /* justify-content: center; */
  overflow-y: auto;
  overflow-x: auto;
  @media (max-width: 1500px) {
    width: 85vw;
    align-self: center;
  }
`;
export const Head = styled.div`
  min-width: 125rem;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  min-width: 125rem;
`;

export const Row = styled.div`
  display: flex;
  /* gap: 2rem; */
  justify-content: space-around;
  padding: 0 2rem;
`;

export const HeadItem = styled.div`
  width: 25rem;
  ${(props) => props.theme.fonts.GothamBold20};
  color: ${(props) => props.theme.colors.white.white70};
`;

export const BodyItem = styled.div`
  width: 25rem;

  ${(props) => props.theme.fonts.GothamMedium16};
  color: ${(props) => props.theme.colors.white.white100};
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

export const ServiceItem = styled(BodyItem)`
  color: ${(props) => props.theme.colors.blue.accent};
`;

export const RowBody = styled.div`
  display: flex;
  padding: 2rem;
  border-radius: 1.5rem;
  background-color: ${(props) => props.theme.gradients.shadow};
  /* gap: 2rem; */
  justify-content: space-around;
`;
