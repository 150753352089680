export default function TaskBP() {
  return (
    <svg
      width="28"
      height="29"
      viewBox="0 0 28 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.1641 6.33331H24.4974"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15.1641 11H20.9974"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15.1641 18H24.4974"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15.1641 22.6667H20.9974"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M3.5 6.33335C3.5 6.02393 3.62292 5.72719 3.84171 5.5084C4.0605 5.2896 4.35725 5.16669 4.66667 5.16669H9.33333C9.64275 5.16669 9.9395 5.2896 10.1583 5.5084C10.3771 5.72719 10.5 6.02393 10.5 6.33335V11C10.5 11.3094 10.3771 11.6062 10.1583 11.825C9.9395 12.0438 9.64275 12.1667 9.33333 12.1667H4.66667C4.35725 12.1667 4.0605 12.0438 3.84171 11.825C3.62292 11.6062 3.5 11.3094 3.5 11V6.33335Z"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M3.5 18C3.5 17.6906 3.62292 17.3938 3.84171 17.175C4.0605 16.9562 4.35725 16.8333 4.66667 16.8333H9.33333C9.64275 16.8333 9.9395 16.9562 10.1583 17.175C10.3771 17.3938 10.5 17.6906 10.5 18V22.6666C10.5 22.9761 10.3771 23.2728 10.1583 23.4916C9.9395 23.7104 9.64275 23.8333 9.33333 23.8333H4.66667C4.35725 23.8333 4.0605 23.7104 3.84171 23.4916C3.62292 23.2728 3.5 22.9761 3.5 22.6666V18Z"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
