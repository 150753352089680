export default function LifeCheck({ onClick = () => {} }) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_145_23678)">
        <circle cx="10" cy="10" r="10" fill="#01B956" fill-opacity="0.2" />
        <circle cx="10" cy="10" r="3" fill="#01B956" />
      </g>
      <defs>
        <clipPath id="clip0_145_23678">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
