export default function Quechen() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_3121_16981)">
        <path
          d="M6.66406 6.6665C6.66406 6.00346 6.97135 5.36758 7.51833 4.89874C8.06532 4.4299 8.80718 4.1665 9.58073 4.1665H10.4141C11.1876 4.1665 11.9295 4.4299 12.4765 4.89874C13.0234 5.36758 13.3307 6.00346 13.3307 6.6665C13.3614 7.20755 13.2155 7.74392 12.9149 8.19482C12.6143 8.64572 12.1753 8.98673 11.6641 9.1665C11.1528 9.4062 10.7139 9.86088 10.4133 10.4621C10.1127 11.0633 9.96671 11.7784 9.9974 12.4998"
          stroke="white"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M10 15.8333V15.8416"
          stroke="white"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3121_16981">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
