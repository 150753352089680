import { IWeaponCard } from "models/WeaponCard";
import React from "react";

export const MarketplaceContext = React.createContext<IWeaponCard>({
  src: "",
  skin: "",
  weapon: "",
  rarity: "common_grade",
  paint_kit_id: null,
  price: "",
  cl: false,
});
