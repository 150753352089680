import API from "API";
import { Icons } from "assets/icons";
import Column from "components/Column";
import Layout from "components/Layout/Layout";
import LayoutIcon from "components/LayoutIcon";
import ListInfo, { ListType } from "components/ListInfo/ListInfo";
import HistoryState from "components/Modal/History/HistoryState";
import TradeState from "components/Modal/Trade/TradeState";
import SubHeader from "components/SubHeader/SubHeader";
import { ITrade, TradeInventory, TradeStatus } from "interfaces/Trade";
import { observer } from "mobx-react-lite";
import { useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import { mySteamId } from "utils/IsYou";
import { Wrapper } from "./styles";

interface HistorySelector {
  history: ITrade[];
  trade: ITrade;
}

enum ModeList {
  active = 0,
  history = 1,
}

function TradePage() {
  const [mode, setMode] = useState(0);
  const [firstOpen, setFirstOpen] = useState(false);
  const [notifications, setNotifications] = useState<any>({
    Активные: [],
    Новые: [],
    Отправленные: [],
  });
  const [resp, setResp] = useState<any>();

  useEffect(() => {
    async function Get() {
      const response = await API.TradeService.getHistory();
      setFirstOpen(true);

      if (response.data.length > 0) {
        response.data.map((item: TradeInventory) => {
          if (item.status == TradeStatus.NotStarted) {
            HistoryState.setLive(item);

            // console.log("HistoryState item:", item);
          }
        });
      }
      setResp(response);
    }
    Get();
  }, []);

  const fetchData = async () => {
    // setNotifications({
    //   "Новые": HistoryState.live || [],
    //   "Завершенные": response.data.filter((item: TradeInventory) => item.status != TradeStatus.NotStarted)
    // })

    const getTabTrade = async () => {
      const activeDontShowStatues = [
        TradeStatus.NotStarted,
        TradeStatus.BothApplied,
      ];

      if (mode === ModeList.active) {
        // console.log("Mode is active");
        const updatedNotifications = {
          Активные: HistoryState.live.filter(
            (item: TradeInventory) =>
              !activeDontShowStatues.includes(item.status as TradeStatus) &&
              !TradeState.uuid.includes(item.uuid)
          ),
          Новые:
            HistoryState.live.filter(
              (item: TradeInventory) =>
                item.status == TradeStatus.NotStarted &&
                !TradeState.uuid.includes(item.uuid) &&
                item.receiver == mySteamId()
            ) || [],
          Отправленные:
            HistoryState.live.filter(
              (item: TradeInventory) =>
                item.status === TradeStatus.NotStarted &&
                !TradeState.uuid.includes(item.uuid) &&
                item.receiver !== mySteamId()
            ) || [],
        };
        // console.log(
        //   "Updated notifications for active mode:",
        //   updatedNotifications
        // );
        // Update notifications state only if there are changes
        if (
          JSON.stringify(notifications) !== JSON.stringify(updatedNotifications)
        ) {
          // console.log("Notifications have changed, updating state");
          setNotifications(updatedNotifications);
        }
      }

      //////////////////////
      if (mode === ModeList.history) {
        // console.log("Mode is history");
        const historyNotifications = {
          "История обменов": resp.data.filter(
            (item: TradeInventory) => item.status !== TradeStatus.NotStarted
          ),
        };

        // console.log(
        //   "Updated notifications for history mode:",
        //   historyNotifications
        // );

        if (
          JSON.stringify(notifications) !== JSON.stringify(historyNotifications)
        ) {
          // console.log("Notifications have changed, updating state");
          setNotifications(historyNotifications);
        }
      }
    };

    getTabTrade();
  };

  useMemo(() => {
    // console.log("mode");
    fetchData();
  }, [
    TradeState.isConfirmed,
    TradeState.isShowModal,
    mode,
    TradeState.statusCansel,

    TradeState.uuid,
    HistoryState.live,
  ]);

  return (
    <Layout
      leftElement={
        <Column gap="2rem">
          <LayoutIcon
            className={mode === ModeList.active ? "active" : ""}
            onClick={() => setMode(ModeList.active)}
          >
            <Icons.Reload />
          </LayoutIcon>
          <LayoutIcon
            className={mode === ModeList.history ? "active" : ""}
            onClick={() => setMode(ModeList.history)}
          >
            <Icons.MarketHistory />
          </LayoutIcon>
        </Column>
      }
      headerElement={<SubHeader>Обмены</SubHeader>}
    >
      <Helmet>
        <title>FREEZ-BLOOD - Обмены</title>
        <meta name="description" content={`FREEZ-BLOOD - Обмены`} />
      </Helmet>
      <Wrapper>
        <ListInfo
          clearCallback={() => {}}
          type={ListType.natification}
          list={notifications}
        />
      </Wrapper>
    </Layout>
  );
}

export default observer(TradePage);
