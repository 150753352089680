import { Icons } from "assets/icons";
import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import SkinChangerState from "pages/Profile/Tabs/SkinChanger/SkinChangerState";
import { Box, Container, Sticker } from "./Styles";

interface Props {
  max: number;
  onClick: (index: number) => void;
  stickers: { [key: string]: string };
}

interface PickerProps {
  sticker: string;
  className: any;
  onClick: (id: number) => void;
}

const Picker = ({ sticker, onClick, className }: PickerProps) => {
  const findStricker = toJS(SkinChangerState.stickers).filter(
    (stick) => stick.id === +sticker
  );

  return (
    <Container className={className} onClick={onClick}>
      {sticker ? <Sticker src={findStricker[0]?.image} /> : <Icons.Add />}
    </Container>
  );
};

const StickerPicker = ({ max, onClick, stickers }: Props) => {
  const items = [];

  for (let i = 0; i < max; i++) {
    const mockActive =
      i === SkinChangerState.stickerSelectGroup ? "active" : "";
    items.push(
      <Picker
        sticker={stickers[i]}
        onClick={() => onClick(i)}
        className={mockActive}
      />
    );
  }

  return <Box>{items}</Box>;
};

export default observer(StickerPicker);
