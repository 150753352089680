import styled from "styled-components";

export const WrapAdminChage = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 3rem;
`;

export const WrapButtosn = styled.div`
  display: flex;
  gap: 2rem;
  width: 100%;
  margin-top: 3rem;
`;
export const WrapInfoW = styled.div<{ top?: boolean }>`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: ${(props) => (props.top ? "3rem" : "3")};
`;
export const SpanDefal = styled.span<{ width?: boolean }>`
  color: ${(props) => props.theme.colors.white.white100};
  ${(props) => props.theme.fonts.GothamBold20}
  width: ${(props) => (props.width ? "max-content" : "17rem")};
`;
export const SpanDefault = styled.span<{ width?: boolean }>`
  color: ${(props) => props.theme.colors.white.white100};
  ${(props) => props.theme.fonts.GothamRegular20}
  width: ${(props) => (props.width ? "max-content" : "17rem")};
`;
export const WrapInformationOfAdmin = styled.div<{ nonActive?: boolean }>`
  padding: 2rem;
  border: 2px solid ${(props) => props.theme.colors.black.black70};
  background-color: ${(props) =>
    props.nonActive
      ? props.theme.colors.red.active
      : props.theme.gradients.shadow};
  margin-top: 3rem;
  border-radius: 2.5rem;
`;
