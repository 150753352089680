import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  background: ${(props) => props.theme.colors.white.white10};
  padding: 1rem;
  border-radius: 2rem;
  justify-content: space-between;
  @media (max-width: 800px) {
    flex-direction: column;
  }
`;
export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 2rem;
  svg {
    cursor: pointer;
    path {
      transition: 0.15s stroke-opacity;
    }
    &:hover {
      path {
        stroke-opacity: 1;
      }
    }
  }
  @media (max-width: 800px) {
    flex-direction: column;
  }
`;
export const UserInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  @media (max-width: 800px) {
    flex-direction: column;
  }
`;

export const Time = styled.span`
  ${(props) => props.theme.fonts.GothamRegular16};
  color: ${(props) => props.theme.colors.white.white70};
`;
export const Timer = styled.span`
  ${(props) => props.theme.fonts.GothamBold24};
  color: ${(props) => props.theme.colors.white.white100};
`;
