import styled from "styled-components";
import { Container as RowContainer } from "components/Row/Styles";

export const Container = styled.div`
  width: 64.6rem;
  height: 80rem;
  border-radius: 2.5rem;
  ${(props) => props.theme.shadows.defaultShadow};
  border: 0.3rem solid ${(props) => props.theme.stroke.stroke5};
  background: ${(props) => props.theme.gradients.bg};
  padding: 3rem;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  @media (max-width: 1250px) {
    width: max-content;

    padding: 1.5rem;
    gap: 1rem;
    height: max-content;
  }
`;
export const Head = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

export const Banner = styled.div`
  overflow: hidden;
  width: 100%;
  position: relative;
  height: 19.2rem;
  border-radius: 1rem 1rem 0 0;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 2rem 0 3rem 0;
  .map {
    width: 100%;
    height: 100%;
    object-fit: cover;
    top: 0;
    left: 0;
    position: absolute;
    z-index: 10;
  }
  div.bg {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 11;
    background: linear-gradient(
      360deg,
      rgba(27, 17, 18, 0.8) 28.57%,
      rgba(27, 17, 18, 0) 98.62%
    );
  }

  @media (max-width: 1250px) {
    /* width: max-content; */
    display: none;
  }
`;

export const Stats = styled.div`
  margin-top: 3.5rem;
  position: relative;
  z-index: 100;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  ${RowContainer} {
    gap: 0.6rem;
    margin-top: 5.2rem;
  }
  ${(props) => props.theme.fonts.GothamBold32};
  color: ${(props) => props.theme.colors.white.white100};
`;

export const Team = styled.div`
  z-index: 100;
  position: relative;
  img {
    width: 10rem;
    height: 10rem;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.8rem;
  ${(props) => props.theme.fonts.GothamMedium24};
  color: ${(props) => props.theme.colors.white.white100};
`;

export const ShortInfo = styled.div`
  ${(props) => props.theme.mixins.dfspaceBetween};
  @media (max-width: 1250px) {
    display: block;
    align-self: center;
  }
`;

export const ServerConnect = styled.a`
  ${(props) => props.theme.fonts.GothamRegular16};
  color: ${(props) => props.theme.colors.white.white100};
`;

export const ServerInfoHead = styled.div`
  padding: 0 1.5rem;
  width: 100%;
  ${(props) => props.theme.mixins.dfspaceBetween}
  height: 5rem;
  border-radius: 1.5rem;
  background: ${(props) => props.theme.colors.white.white10};
`;

export const Item = styled.div<{ act?: Boolean }>`
  ${(props) => props.theme.fonts.GothamRegular14};
  cursor: ${(props) => (props.act ? "pointer" : "auto")};
  color: ${(props) => props.theme.colors.white.white100};
`;

export const Body = styled.div`
  display: flex;
  max-height: 40.5rem;
  height: 100%;
  flex-direction: column;
  gap: 2rem;
`;

export const BodyItem = styled.div`
  padding-right: 1.5rem;
  ${(props) => props.theme.mixins.dfspaceBetween}
`;
