import UI from "components/UI";
import VirtualInfiniteScroll, {
  VirtualInfiniteScrollProps,
} from "components/VirtualInfiniteScroll/VirtualInfiniteScroll";
import { observer } from "mobx-react-lite";
import { Container } from "./Styles";

interface Props {
  scroll: VirtualInfiniteScrollProps;
  searchValue: string;
  searchCallback: (v: any) => void;
}

function ExtendDropDown({ scroll, searchValue, searchCallback }: Props) {
  return (
    <Container>
      <UI.Input
        placeholder="Поиск"
        params={{
          width: "100%",
          InputPlaceholderColor: "#fff",
          bradius: "1rem",
        }}
        value={searchValue}
        onChange={searchCallback}
      />
      <VirtualInfiniteScroll {...scroll} />
    </Container>
  );
}

export default observer(ExtendDropDown);
