import Avatar from "components/Avatar";
import { InventoryWeaponCard } from "components/Cards";
import { observer } from "mobx-react-lite";
import { IItemSend } from "models/InventoryWeaponCard";
import rarityConvector from "utils/rarityConvector";
import { Card, Column, Text, TextTwo, WrapperItem } from "./Styles";

interface Props {
  text: string;
  data: IItemSend[];
  onClick?: any;
  avatar: string;
  kolItem: number;
}

const TradeColumn = ({ text, data, onClick, avatar, kolItem }: Props) => {
  function declensionOfItem(count: number): string {
    const remainder10 = count % 10;
    const remainder100 = count % 100;

    if (remainder100 >= 11 && remainder100 <= 14) {
      return `${count} предметов`;
    } else if (remainder10 === 1) {
      return `${count} предмет`;
    } else if (remainder10 >= 2 && remainder10 <= 4) {
      return `${count} предмета`;
    } else {
      return `${count} предметов`;
    }
  }

  const getCountNeedRenderCards = () => {
    const START_EMPTY_CARDS = 12;

    const skinsLength = data.length;

    if (skinsLength > START_EMPTY_CARDS) {
      const needRenderCount = skinsLength / 3;

      if (`${needRenderCount}`.includes(".")) {
        if (`${needRenderCount}`.split(".")[1].startsWith("3")) return 2;
        else return 1;
      } else {
        return 0;
      }
    } else {
      return START_EMPTY_CARDS - skinsLength;
    }
  };

  return (
    <div>
      <WrapperItem>
        <Avatar src={avatar} size="super-small" />
        <Text>{text}</Text>
      </WrapperItem>
      <TextTwo>{declensionOfItem(kolItem)}</TextTwo>
      <Column className="customScroll">
        {data.map((item) => {
          return (
            <InventoryWeaponCard
              // key={index}
              src={item.src}
              rarity={rarityConvector(+item.rarity)}
              weapon={item.weapon}
              skin={item.skin}
              cl={false}
              trade={true}
              onClick={() => {
                if (onClick) {
                  onClick(item);
                }
              }}
            />
          );
        })}

        {new Array(getCountNeedRenderCards()).fill(1).map((item) => {
          return <Card />;
        })}
      </Column>
    </div>
  );
};

export default observer(TradeColumn);
