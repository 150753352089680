import { UserService } from "./UserService";
import { ServersService } from "./ServersService";
import { ShopService } from "./ShopService";
import { ChatService } from "./ChatService";
import { NotificationsService } from "./NotificationsService";
import { MarketplaceService } from "./MarketplaceService";
import { TradeService } from "./TradeService";
import { topService } from "./topService";
import axios, { AxiosResponse, InternalAxiosRequestConfig } from "axios";

export const refreshToken = localStorage.getItem("refreshToken");
export const token = localStorage.getItem("token");
export const headers = token ? { Authorization: "Bearer " + token } : {};
export let flagRef = false;

export const url = "https://api.fb-craft.ru";
export const urlSC = "https://apiv2.fb-craft.ru";
// export const url = "https://api.freez-blood.com";
// export const urlSC = "https://apiv2.freez-blood.com";
// export const url = "http://localhost:5000";
// export const urlSC = "http://localhost:5000";

export const endpoints = {
  user: {
    profile: "/profile",
    friends: "/friends",
    inventory: "/inventory",
    serverInventory: "/inventory/serverInventory",
    inventoryToServer: "/inventory/toServer",
    inventoryFromServer: "/inventory/loadFromServer",
    exit: "/logout",
    statsss: "/stats",
    discord: "/discord/link",
    vk: "/auth/vk",
    Trade: "/settings",
  },
  clans: {
    clans: "/clans",
    clan: "/clan",
    createClan: "/create",
    requestClan: "/request",
    accept: "/accept",
    update: "/update",
    updateStatusClan: "/update-clan-status",
    shop: "/shop",
    buy: "/buy",
    me: "/me",
    contract: "/contract",
  },
  auth: {
    login: "/auth/steam",
  },
  monitoring: {
    all: "/monitoring/modes",
    mods: "/monitoring/mods",
    players: "/monitoring/players",
  },
  shop: {
    get: "/shop",
    credits: "/credits",
    freeKassa: "/free-kassa",
    unit_pay: "unit-pay",
    buy: "/buy",
    gift: "/gift",
    grant: "/grant",
    cancel: "/cancel",
    admin: "/admin",
    skins: "/skins",
  },
  support: {
    create: "/feedback/ticket/create",
    getBan: "/blocks/my",
    feedback: "/feedback",
    feedTik: "/feedback/ticket",
    feedTikBlock: "/feedback/blocks",
    closeTik: "/close",
    fedBlockClose: "/feedback/blocks/closed",
    fedTickClose: "/feedback/tickets/closed",
  },
  notifications: {
    get: "/",
  },
  marketplace: {
    get: "/market",
    buy: "/market/buy",
    post: "/market/sell",
    sales: "/market/selling",
    remove: "/market/remove",
    update: "/market/update",
    history: "/market/history",
    inventory: "/market/inventory",
    filters: "/market/filters",
  },
  trade: {
    history: "/trade/history",
    cancel: "/trade/cancel",
  },
  skinchanger: {
    skins: "/skins",
    stickers: "/skins/stickers",
    data: "/skins/data",
    categories: "/skins/categories",
    apply: "/skins/apply",
    authToken: "/token",
    settings: "/option",
    stickersID: "/stickers",
    id: "/id",
  },
  stats: {
    top: "/stats/top",
  },
  admin: {
    admin: "/admin",
    blocks: "/blocks?",
    server_id: "server_id=",
    type: "&type=",
    request: "&request=test%20-reason",
  },
  contract: {
    contract: "/contracts/item",
    create: "/contracts/create",
  },
  roulette: {
    rouletteHistory: "/roulete/history",
    roulette: "/roulete",
  },
  battlPass: {
    battlPass: "/battlepass",
    me: "/me",
    prize: "/prize",
    buy: "/buy",
  },
};

const API = {
  UserService,
  ServersService,
  ShopService,
  ChatService,
  NotificationsService,
  MarketplaceService,
  TradeService,
  topService,
};

export default API;
