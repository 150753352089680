import styled, { css } from "styled-components";

interface Props {
  percent: number;
}

export const Wrapper = styled.div<Props>`
  display: flex;
  align-items: center;
  width: 17rem;
  gap: 0.6rem;
  svg {
    path {
      fill: ${(props) =>
        props.percent > 99 &&
        css`
          ${(props) => props.theme.colors.red.accent}
        `};
    }
  }
  ${(props) => props.theme.fonts.GothamMedium15};
  color: ${(props) => props.theme.colors.white.white70};
  div {
    color: ${(props) => props.theme.colors.white.white70};
  }
  .highlighted {
    color: ${(props) =>
      props.percent > 99
        ? props.theme.colors.red.accent
        : props.theme.colors.white.white100};
  }
  @media (max-width: 1250px) {
    justify-content: center;
  }
`;
