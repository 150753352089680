import { observer } from "mobx-react-lite";
import { InfiniteLoader, List } from "react-virtualized";

export interface VirtualInfiniteScrollProps {
  width: number;
  height: number;
  rowCount: number;
  rowHeight: number;
  items: any[];
  handleItems: any;
  rowRenderer: any;
}

function VirtualInfiniteScroll({
  width,
  height,
  rowCount,
  rowHeight,
  items,
  handleItems,
  rowRenderer,
}: VirtualInfiniteScrollProps) {
  function isRowLoaded({ index }: { index: number }) {
    // console.log(items[index]);
    return !!items[index];
  }

  return (
    <InfiniteLoader
      isRowLoaded={isRowLoaded}
      loadMoreRows={handleItems}
      rowCount={rowCount}
    >
      {({ onRowsRendered, registerChild }) => (
        <List
          width={width}
          height={height}
          onRowsRendered={onRowsRendered}
          ref={registerChild}
          rowCount={rowCount}
          rowHeight={rowHeight}
          rowRenderer={rowRenderer}
        ></List>
      )}
    </InfiniteLoader>
  );
}

export default observer(VirtualInfiniteScroll);
