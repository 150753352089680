import styled from "styled-components";

export const Container = styled.div`
  padding: 4rem 1.5rem 1.5rem 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
`;

export const BlockTask = styled.div<{ pad: boolean }>`
  display: flex;
  padding: ${(props) => (props.pad ? "1rem" : "0rem 0 0rem 1rem")};
  border-radius: 1.5rem;
  justify-content: space-between;
  background: ${(props) => props.theme.gradients.shadow};
  align-items: center;
  gap: 3rem;
  min-height: 6rem;
  @media (max-width: 1250px) {
    flex-direction: column;
  }
`;

export const TextBlock = styled.span`
  ${(props) => props.theme.fonts.GothamBold16}
  align-items: center;
  align-self: center;
  display: flex;
  gap: 1rem;
`;

export const BlockTaskExp = styled.div`
  height: 6rem;
  background: ${(props) => props.theme.colors.white.white20};
  width: 6rem;
  border-radius: 0rem 1.5rem 1.5rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;
