export default function Notification() {
	return (
		<svg width="29" height="32" viewBox="0 0 29 32" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M12.511 6.30054C12.511 5.74216 12.0584 5.28951 11.5 5.28951C10.9416 5.28951 10.489 5.74216 10.489 6.30054H12.511ZM10.489 6.30054V7.60052H12.511V6.30054H10.489Z" fill="white" />
			<path d="M21.2822 24.4998H1.71781C1.4027 24.4998 1.20865 24.1554 1.37191 23.8858L4.35234 18.9658C4.73512 18.3339 4.9375 17.6093 4.9375 16.8705V14.0297C4.9375 12.4107 5.58462 10.859 6.73484 9.71973L7.23116 9.22814C9.59536 6.88648 13.4046 6.88648 15.7688 9.22814L16.2652 9.71973C17.4154 10.859 18.0625 12.4107 18.0625 14.0297V16.8705C18.0625 17.6093 18.2649 18.3339 18.6477 18.9658L21.6281 23.8858C21.7914 24.1554 21.5973 24.4998 21.2822 24.4998Z" fill="white" />
			<path d="M21.6281 23.8858L22.4928 23.362H22.4928L21.6281 23.8858ZM18.6477 18.9658L17.7829 19.4897L18.6477 18.9658ZM16.2652 9.71973L16.9766 9.00141L16.9766 9.00141L16.2652 9.71973ZM15.7688 9.22814L15.0574 9.94646L15.0574 9.94646L15.7688 9.22814ZM7.23116 9.22814L6.51969 8.50981V8.50982L7.23116 9.22814ZM6.73484 9.71973L7.44631 10.438V10.438L6.73484 9.71973ZM4.35234 18.9658L3.4876 18.442L4.35234 18.9658ZM1.37191 23.8858L0.507175 23.362H0.507175L1.37191 23.8858ZM1.71781 25.5108H21.2822V23.4888H1.71781V25.5108ZM22.4928 23.362L19.5124 18.442L17.7829 19.4897L20.7633 24.4097L22.4928 23.362ZM19.0735 16.8705V14.0297H17.0515V16.8705H19.0735ZM16.9766 9.00141L16.4803 8.50982L15.0574 9.94646L15.5537 10.4381L16.9766 9.00141ZM6.51969 8.50982L6.02337 9.00141L7.44631 10.438L7.94263 9.94646L6.51969 8.50982ZM3.92647 14.0297V16.8705H5.94853V14.0297H3.92647ZM3.4876 18.442L0.507175 23.362L2.23665 24.4097L5.21708 19.4897L3.4876 18.442ZM3.92647 16.8705C3.92647 17.4246 3.77469 17.9681 3.4876 18.442L5.21708 19.4897C5.69556 18.6998 5.94853 17.794 5.94853 16.8705H3.92647ZM6.02337 9.00141C4.68144 10.3305 3.92647 12.1409 3.92647 14.0297H5.94853C5.94853 12.6806 6.48779 11.3874 7.44631 10.438L6.02337 9.00141ZM16.4803 8.50982C13.7221 5.77788 9.27792 5.77788 6.51969 8.50981L7.94263 9.94646C9.9128 7.99508 13.0872 7.99508 15.0574 9.94646L16.4803 8.50982ZM19.0735 14.0297C19.0735 12.1409 18.3186 10.3305 16.9766 9.00141L15.5537 10.438C16.5122 11.3874 17.0515 12.6806 17.0515 14.0297H19.0735ZM19.5124 18.442C19.2253 17.9681 19.0735 17.4246 19.0735 16.8705H17.0515C17.0515 17.794 17.3044 18.6998 17.7829 19.4897L19.5124 18.442ZM21.2822 25.5108C22.3851 25.5108 23.0643 24.3053 22.4928 23.362L20.7633 24.4097C20.5184 24.0054 20.8095 23.4888 21.2822 23.4888V25.5108ZM1.71781 23.4888C2.19048 23.4888 2.48155 24.0054 2.23665 24.4097L0.507175 23.362C-0.0642588 24.3053 0.614914 25.5108 1.71781 25.5108V23.4888Z" fill="white" />
			<path d="M14.125 28.3999H8.875L10.0771 29.5905C10.8651 30.371 12.1349 30.371 12.9229 29.5905L14.125 28.3999Z" fill="white" />
			<path d="M8.875 28.3999V27.3889C8.4652 27.3889 8.09592 27.6363 7.94001 28.0152C7.78409 28.3942 7.87237 28.8298 8.16353 29.1182L8.875 28.3999ZM14.125 28.3999L14.8365 29.1182C15.1276 28.8298 15.2159 28.3942 15.06 28.0152C14.9041 27.6363 14.5348 27.3889 14.125 27.3889V28.3999ZM12.9229 29.5905L12.2115 28.8722V28.8722L12.9229 29.5905ZM10.0771 29.5905L9.36558 30.3088H9.36558L10.0771 29.5905ZM8.875 29.4109H14.125V27.3889H8.875V29.4109ZM13.4135 27.6816L12.2115 28.8722L13.6344 30.3088L14.8365 29.1182L13.4135 27.6816ZM10.7885 28.8722L9.58647 27.6816L8.16353 29.1182L9.36558 30.3088L10.7885 28.8722ZM12.2115 28.8722C11.8174 29.2624 11.1826 29.2624 10.7885 28.8722L9.36558 30.3088C10.5477 31.4796 12.4523 31.4796 13.6344 30.3088L12.2115 28.8722Z" fill="white" />
			{/* <g filter="url(#filter0_d_574_5464)">
				<rect x="14" y="3" width="12" height="12" rx="6" fill="#E32040" />
			</g> */}
			<defs>
				<filter id="filter0_d_574_5464" x="11" y="0" width="18" height="18" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
					<feFlood floodOpacity="0" result="BackgroundImageFix" />
					<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
					<feOffset />
					<feGaussianBlur stdDeviation="1.5" />
					<feComposite in2="hardAlpha" operator="out" />
					<feColorMatrix type="matrix" values="0 0 0 0 0.890196 0 0 0 0 0.12549 0 0 0 0 0.25098 0 0 0 0.7 0" />
					<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_574_5464" />
					<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_574_5464" result="shape" />
				</filter>
			</defs>
		</svg>

	)
}
