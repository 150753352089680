export default function ArrowRightCase({ onClick = () => {} }) {
  return (
    <svg
      width="29"
      height="24"
      viewBox="0 0 29 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27 12L12 20.6603L12 3.33975L27 12Z"
        stroke="url(#paint0_linear_99_23042)"
        stroke-width="2"
      />
      <path
        d="M17.0745 12L5.75 18.8823L5.75 5.11767L17.0745 12Z"
        stroke="url(#paint1_linear_99_23042)"
        stroke-width="2"
      />
      <defs>
        <linearGradient
          id="paint0_linear_99_23042"
          x1="29"
          y1="12"
          x2="13.5714"
          y2="12"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#1E91E4" />
          <stop offset="1" stop-color="#1E91E4" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_99_23042"
          x1="19"
          y1="12"
          x2="6.78572"
          y2="12"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#1E91E4" />
          <stop offset="1" stop-color="#1E91E4" stop-opacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
}
