import styled from "styled-components";

export const Container = styled.div`
  background: transparent;
  ${(props) => props.theme.mixins.defaultShadow};
  padding: 1rem 1rem;
  border-radius: 2.5rem;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  width: 100%;
  height: fit-content;
`;

export const Wrapper = styled.div`
  width: 100%;
  height: 15rem;
  padding: 2rem;
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  justify-content: center;

  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(30, 145, 228, 0.2) 0%,
    rgba(255, 255, 255, 0.04) 100%
  );
  border-radius: 2rem;
`;

export const Text = styled.p`
  /* margin-top: 1.5rem; */
  text-align: center;

  ${(props) => props.theme.fonts.GothamRegular14};
  color: ${(props) => props.theme.colors.white.white100};
`;

export const CreditPrice = styled.h4`
  ${(props) => props.theme.fonts.GothamBold24};
  color: ${(props) => props.theme.colors.white.white100};
`;

export const Price = styled.span`
  display: block;
  width: 100%;
  text-align: center;
  ${(props) => props.theme.fonts.GothamRegular24};
  color: ${(props) => props.theme.colors.white.white100};
`;
