import Avatar from "components/Avatar";
import Row from "components/Row";
import { AvatarVariants } from "interfaces/Avatar";
import { Nickname } from "./Styles";

interface Props {
  src?: string;
  children: any;
  minName?: boolean;
  params: {
    color?: string;
    font?: any;
    avatarSize: AvatarVariants;
    gap?: string;
    isAvatarBorder?: boolean;
  };
}

export default function LabelAvatar({ children, src, params, minName }: Props) {
  return (
    <Row gap={params.gap || "1rem"} justifyContent="flex-start">
      <Avatar
        src={src}
        size={params?.avatarSize}
        isBorder={params.isAvatarBorder}
      />
      <Nickname color={params?.color} font={params?.font} minName={minName}>
        {children}
      </Nickname>
    </Row>
  );
}
