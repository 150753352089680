import React, { Dispatch, useEffect, useState } from "react";
import { StylesModal, LiStyles } from "./Styles";

interface Pagin {
  setPage?: React.Dispatch<React.SetStateAction<number>>;
  total?: number;
  page1?: number;
  perPage?: number;
  small?: boolean;
}

const Pagination = ({
  setPage,
  total = 25,
  page1,
  perPage = 30,
  small,
}: Pagin) => {
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (newPage: React.SetStateAction<number>) => {
    if (
      newPage !== 0 &&
      newPage !== -1 &&
      Number(newPage) < Math.ceil(total / perPage) + 1
    ) {
      setCurrentPage(newPage);
      if (setPage) {
        setPage(newPage);
      }
    }
  };
  useEffect(() => {
    generatePageNumbers();
  }, []);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const generatePageNumbers = (total: number = 25) => {
    const totalPages = total < perPage ? 1 : Math.ceil(total / perPage);
    const visiblePages = windowWidth < 800 || small ? 5 : 10; // Изменение видимых страниц в зависимости от размера окна
    const pages = [];
    let startPage;

    if (currentPage <= Math.floor(visiblePages / 2) + 2 || page1 == 1) {
      startPage = 1;
    } else if (currentPage >= totalPages - Math.floor(visiblePages / 2)) {
      startPage = totalPages - visiblePages + 2;
    } else {
      startPage = currentPage - Math.floor(visiblePages / 2); // Изменение здесь
    }

    for (let i = 0; i < visiblePages; i++) {
      if (startPage + i <= totalPages) {
        pages.push(startPage + i);
      }
    }

    return pages;
  };

  return (
    <StylesModal>
      <LiStyles
        className={`page-item ${currentPage === 1 ? "disabled" : ""}`}
        onClick={() => handlePageChange(currentPage - 1)}
        smalls={small}
      >
        &lt;
      </LiStyles>

      {generatePageNumbers(total).map((page, index, array) => (
        <React.Fragment key={page}>
          {index > 0 && page - array[index - 1] !== 1 && (
            <LiStyles className="page-item disabled" smalls={small}>
              ...
            </LiStyles>
          )}
          <LiStyles
            className={`page-item ${page === page1 ? "active" : ""}`}
            onClick={() => handlePageChange(page)}
            smalls={small}
          >
            {page}
          </LiStyles>
        </React.Fragment>
      ))}

      <LiStyles
        className={`page-item ${currentPage === total ? "disabled" : ""}`}
        onClick={() => handlePageChange(currentPage + 1)}
        smalls={small}
      >
        &gt;
      </LiStyles>
    </StylesModal>
  );
};

export default Pagination;
