import { ServersService } from "API/ServersService";
import { socketListener } from "API/SocketProvider";
import { appSocket } from "App";
import { TinyIcons } from "assets/tiny-icons";
import Column from "components/Column";
import LayoutIcon from "components/LayoutIcon";
import Servers from "components/Servers";
import FooterTemplate from "components/Templates/Information";
import FooterColumn from "components/Templates/Information/Column";
import Tabs from "components/Templates/Tabs";
import { DashBoardContext } from "context/dashboard";
import { useActions } from "hooks/useActions";
import { useAppSelector } from "hooks/useAppSelector";
import { useServers } from "hooks/useServers";
import { PlayerAndBlock } from "interfaces/CategoryMap";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Layout from "../../components/Layout";
import { FlexDef, NoneDisplay1000 } from "./styles";

export default function Dashboard() {
  const [tab, setTab] = useState(0);
  const { servers: rawServers, categories } = useAppSelector(
    (state) => state.servers
  );
  const [layoutType, setLayoutType] = useState(0);
  const { fetchServers } = useActions();
  const [players, setPlayers] = useState<PlayerAndBlock>();

  const servers = useServers(rawServers, categories?.[tab]?.label);
  const windowInnerWidth = document.documentElement.clientWidth;
  useEffect(() => {
    if (document.visibilityState === "visible") {
      let i: PlayerAndBlock;
      appSocket.on(socketListener.server, (data) => {
        i = data;
      });
      appSocket.on(
        socketListener.blockAndPlayer,
        (data: { blocks: number; users: number }) => {
          i.blocks = data.blocks;
          i.users = data.users;
          setPlayers(i);
        }
      );
    } else {
      appSocket.off(socketListener.server);
      appSocket.off(socketListener.blockAndPlayer);
    }
    // appSocket.on("error", (error) => toast.error(error.message));
  }, []);

  useEffect(() => {
    fetchServers();
    async function getInf() {
      setPlayers(await ServersService.getPlayers());
    }
    getInf();
  }, [1]);

  return (
    <DashBoardContext.Provider value={{ tab, setTab }}>
      <Helmet>
        <title>Сервера КС 2 (CS2) - FREEZ-BLOOD</title>
        <meta
          name="description"
          content="FREEZ-BLOOD —  проект Counter Strike 2 (CS2) серверов. Множество режимов: PUBLIC, AIM, AWP, FPS, ARENA"
        />
      </Helmet>
      <Layout
        NoneGridConteiner={true}
        headerElement={
          // windowInnerWidth > 1415 ? (
          //   <Tabs
          //     type="default"
          //     items={categories.map((item) => {
          //       // console.log(item.label);
          //       return item.label;
          //     })}
          //     context={DashBoardContext}
          //   />
          // ) : (
          <div />
          // )
        }
        leftElement={
          // <Column gap="2rem">
          //   <LayoutIcon
          //     className={layoutType === 0 ? "active" : ""}
          //     onClick={() => setLayoutType(0)}
          //   >
          //     <TinyIcons.LayoutFirst />
          //   </LayoutIcon>
          //   <LayoutIcon
          //     className={layoutType === 1 ? "active" : ""}
          //     onClick={() => setLayoutType(1)}
          //   >
          //     <TinyIcons.LayoutSecond />
          //   </LayoutIcon>
          // </Column>
          <div />
        }
        footerElement={
          <FooterTemplate>
            <FooterColumn items={["Текущий онлайн", players?.total || "0"]} />
            <FooterColumn
              items={["Игроков на проекте", players?.users || "0"]}
            />

            <FooterColumn
              items={["Блокировок за день", players?.blocks || "0"]}
            />
          </FooterTemplate>
        }
      >
        <FlexDef>
          <Tabs
            type="NewDizign"
            items={categories.map((item) => {
              // console.log(item.label);
              return item.label;
            })}
            style={{
              justifyContent: "center",

              flexWrap: "wrap",
            }}
            context={DashBoardContext}
          >
            <NoneDisplay1000>
              <LayoutIcon
                className="active"
                onClick={() =>
                  layoutType == 0 ? setLayoutType(1) : setLayoutType(0)
                }
              >
                {layoutType == 0 ? (
                  <TinyIcons.LayoutFirst />
                ) : (
                  <TinyIcons.LayoutSecond />
                )}
              </LayoutIcon>
            </NoneDisplay1000>
          </Tabs>
        </FlexDef>
        <Servers servers={servers} layout={layoutType} players={players} />
      </Layout>
    </DashBoardContext.Provider>
  );
}
