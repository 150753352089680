import styled from "styled-components";

export const WrapFullSettings = styled.div`
  display: flex;
  gap: 1rem;
  flex-direction: column;
  width: 100%;
`;

export const WrapBlock = styled.div`
  display: flex;
  flex-direction: column;
  padding: 4rem 2rem;
  background: ${(props) => props.theme.gradients.shadow};
  border-radius: 2rem;
  gap: 2rem;
  min-height: 5rem;
  width: 100%;
  @media (max-width: 768px) {
    padding: 2rem 1rem;
    width: 97%;
    button {
      width: 100%;
    }
  }
`;

export const DefWrap = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  gap: 1rem;
  align-items: center;
  @media (max-width: 768px) {
    justify-content: center;
  }
`;

export const TextBig = styled.span`
  ${(props) => props.theme.fonts.GothamBold32}
`;

export const TextNormal = styled.span`
  ${(props) => props.theme.fonts.GothamBold16}
`;

export const WrapButtons = styled.span<{ active: boolean }>`
  background: ${(props) =>
    props.active ? (props) => props.theme.colors.white.white10 : null};
  border: ${(props) =>
    props.active ? "" : `2px ${props.theme.colors.white.white10} dashed`};
  padding: 1rem;
  border-radius: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 20rem;
  min-height: 5rem;
  cursor: pointer;
`;
