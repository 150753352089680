import { CaseServise as CaseService } from "API/CaseService";
import { TinyIcons } from "assets/tiny-icons";
import Image from "components/Image";
import { LabelAvatar } from "components/Labels";
import UIKIT from "components/UI";
import { MarketplaceContext } from "context/marketplace";
import { useActions } from "hooks/useActions";
import { useAppSelector } from "hooks/useAppSelector";
import { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import { filterMarketInventory } from "redux/slices/user.slice";
import { useTheme } from "styled-components";
import rarityConvector, { priceRarityConvector } from "utils/rarityConvector";
import Modal from "../Modal";
import {
  Bottom,
  BuyMode,
  Container,
  Fsdf,
  Info,
  Name,
  OnSellMode,
  SellMode,
  SubName,
  Text,
} from "./Styles";

interface Props {
  isActive: boolean;
  setActive: React.SetStateAction<any>;
  mode: number;
  marketInventory?: any[];
  modalStyle?: any;
  priceUpdate?: () => void;
  compare?: {
    set: any;
    get: any;
    all: any;
    btn: any;
  };
}

export default function MarketplaceWeaponMOdal({
  isActive,
  setActive,
  mode,
  compare,
}: Props) {
  const currentWeapon = useContext(MarketplaceContext);

  const Theme = useTheme();
  const dispatch = useDispatch();
  const {
    fetchPutItem,
    fetchRemoveItem,
    fetchBuyItems,
    fetchUpdateItem,
    setBalance,
  } = useActions();

  const { user } = useAppSelector((state) => state.user);

  const isAvatar = () => {
    if (mode === 100 || mode === 1 || mode === 2) {
      return false;
    }

    return true;
  };

  const isPrice = () => {
    if (mode === 2 || mode === 100) {
      return false;
    }

    return true;
  };

  const [sellSkinPrice, setSellSkinPrice] = useState("");
  const [error, setError] = useState<boolean | undefined>(undefined);

  const updateSellingPrice = (price: string) => {
    if (/[^0-9]/.test(price)) return;

    setSellSkinPrice(price);
  };

  const buyItem = (id = "0") => {
    fetchBuyItems(id);
    setActive(false);
  };
  const updateItem = (obj: any) => {
    fetchUpdateItem(obj);
    setActive(false);
  };
  const removeItem = (id = "0") => {
    fetchRemoveItem(id);
    setActive(false);
  };

  useEffect(() => {
    setSellSkinPrice("");
  }, [isActive]);
  const windowInnerWidth = document.documentElement.clientWidth;

  return (
    <Modal isActive={isActive} setActive={setActive}>
      <Container>
        <Image
          src={currentWeapon.src}
          alt="Gun"
          width={windowInnerWidth < 1250 ? "300px" : ""}
        />
        <Bottom>
          <Info>
            <Name>
              {currentWeapon.weapon + " |"}
              <SubName rarity={rarityConvector(+currentWeapon.rarity)}>
                &nbsp;{currentWeapon.skin}
              </SubName>
            </Name>
          </Info>
          {isAvatar() && (
            <NavLink to={`/profile/${currentWeapon.steamid}`}>
              <LabelAvatar
                minName={true}
                src={currentWeapon.avatar}
                params={{
                  color: Theme.colors.white.white70,
                  avatarSize: "small",
                }}
              >
                {currentWeapon.nickname}
              </LabelAvatar>
            </NavLink>
          )}
          {mode === 0 && (
            <BuyMode>
              <Fsdf>
                <Text>{currentWeapon.price}</Text>
                <TinyIcons.Credit />
              </Fsdf>

              <UIKIT.Button
                height="5rem"
                variant="default"
                color="blue"
                onClick={() => {
                  buyItem(currentWeapon.id);
                  if (
                    user.balance.virtual - (Number(currentWeapon.price) || 0) >=
                    0
                  ) {
                    setBalance({
                      real: user.balance.real,
                      virtual:
                        user.balance.virtual -
                        (Number(currentWeapon.price) || 0),
                    });
                  } else {
                    toast.error("Недостаточно баланса");
                  }
                }}
              >
                Купить
              </UIKIT.Button>
            </BuyMode>
          )}
          {mode === 1 && (
            <SellMode>
              <UIKIT.Input
                label="Цена"
                params={{
                  labelPosition: "center",
                  labelColor: Theme.colors.white.white100,
                  inputColor: Theme.colors.white.white100,
                }}
                onChange={(e) => {
                  updateSellingPrice(e.target.value);
                  if (
                    Number(sellSkinPrice) > 1000 &&
                    Number(sellSkinPrice) < 2000000
                  ) {
                    setError(undefined);
                  }
                }}
                value={sellSkinPrice}
              />
              <UIKIT.Button
                height="5rem"
                variant="default"
                color="red"
                onClick={() => {
                  const { item = 0, group = 0 } = currentWeapon.skinInfo || {};
                  const struct = {
                    steamid: currentWeapon.steamid,
                    item_id: currentWeapon.id,
                    price: sellSkinPrice,
                  };
                  if (
                    Number(sellSkinPrice) >= 1000 &&
                    Number(sellSkinPrice) <= 2000000
                  ) {
                    fetchPutItem(struct);

                    setActive(false);
                    setSellSkinPrice("");
                    setError(false);
                  } else {
                    toast.error("Мин:1000 Макс:2000000");
                    setError(true);
                  }
                }}
              >
                Выставить на продажу
              </UIKIT.Button>
              <UIKIT.Button
                variant="picker"
                height={"52"}
                color="blue"
                params={{ width: "250px" }}
                onClick={async () => {
                  const result: { success: boolean } =
                    await CaseService.SoldItem([currentWeapon.id || 0]);
                  // console.log(result.success);
                  if (result) {
                    setBalance({
                      real: user.balance.real,
                      virtual:
                        user.balance.virtual +
                        priceRarityConvector(+currentWeapon.rarity),
                    });
                    setActive(false);
                    // priceUpdate && priceUpdate();
                    // console.log(marketInventory);
                    // marketInventory = [];
                    dispatch(filterMarketInventory(Number(currentWeapon.id)));
                    toast.success("Успешная продажа");
                  } else {
                    toast.error("Ошибка");
                  }
                }}
              >
                Моментально за {priceRarityConvector(+currentWeapon.rarity)}{" "}
                {currentWeapon.price}
                &nbsp; <TinyIcons.Credit />
              </UIKIT.Button>
            </SellMode>
          )}
          {mode === 2 && (
            <OnSellMode>
              <UIKIT.Input
                label="Цена"
                params={{
                  labelPosition: "center",
                  labelColor: Theme.colors.white.white100,
                  inputColor: Theme.colors.white.white100,
                }}
                onChange={(e) => {
                  updateSellingPrice(e.target.value);
                }}
                value={sellSkinPrice}
              />
              <UIKIT.Button
                height="5rem"
                variant="default"
                color="red"
                onClick={() => {
                  updateItem({
                    id: currentWeapon.id || 0,
                    price: sellSkinPrice,
                  });
                }}
              >
                Изменить цену
              </UIKIT.Button>
              <UIKIT.Button
                height="5rem"
                variant="picker"
                color="red"
                onClick={() => {
                  removeItem(currentWeapon.id);
                  setSellSkinPrice("");
                }}
              >
                Снять с продажи
              </UIKIT.Button>
            </OnSellMode>
          )}
          {mode === 100 && (
            <SellMode>
              <UIKIT.Input
                label="Колличество"
                compare
                compareText={compare?.all}
                compareSet={compare?.set}
                compareGet={compare?.get}
                params={{
                  labelPosition: "center",
                  labelColor: Theme.colors.black.black100,
                  inputColor: Theme.colors.black.black100,
                }}
              />
              <UIKIT.Button
                height="5rem"
                variant="default"
                color="red"
                onClick={() => {
                  compare?.btn(currentWeapon, true);
                }}
              >
                Подтвердить
              </UIKIT.Button>
            </SellMode>
          )}
        </Bottom>
      </Container>
    </Modal>
  );
}
