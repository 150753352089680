export default function Close({ ...props }: React.HTMLAttributes<SVGElement>) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.7784 4.05025C17.5594 3.2692 17.5594 2.00287 16.7784 1.22182C15.9973 0.440771 14.731 0.44077 13.9499 1.22182L9.00019 6.17157L4.05044 1.22182C3.2694 0.44077 2.00307 0.44077 1.22202 1.22182C0.440968 2.00287 0.440968 3.2692 1.22202 4.05025L6.17176 8.99999L1.22202 13.9497C0.440969 14.7308 0.440969 15.9971 1.22202 16.7782C2.00307 17.5592 3.2694 17.5592 4.05044 16.7782L9.00019 11.8284L13.9499 16.7782C14.731 17.5592 15.9973 17.5592 16.7784 16.7782C17.5594 15.9971 17.5594 14.7308 16.7784 13.9497L11.8286 8.99999L16.7784 4.05025Z"
        fill="white"
        fillOpacity="0.1"
        {...props}
      />
    </svg>
  );
}
