export default function Arrow({ ...props }: React.HTMLAttributes<SVGElement>) {
  return (
    <svg
      width="10"
      height="5"
      viewBox="0 0 10 5"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.35355 4.14645C9.54882 4.34171 9.54882 4.65829 9.35355 4.85355C9.15829 5.04882 8.84171 5.04882 8.64645 4.85355L9.35355 4.14645ZM5 0.5L4.64645 0.146446C4.74021 0.0526778 4.86739 -2.96195e-07 5 -3.01992e-07C5.13261 -3.07788e-07 5.25978 0.0526778 5.35355 0.146446L5 0.5ZM1.35355 4.85355C1.15829 5.04882 0.841709 5.04882 0.646446 4.85355C0.451184 4.65829 0.451184 4.34171 0.646446 4.14645L1.35355 4.85355ZM8.64645 4.85355L4.64645 0.853553L5.35355 0.146446L9.35355 4.14645L8.64645 4.85355ZM5.35355 0.853553L1.35355 4.85355L0.646446 4.14645L4.64645 0.146446L5.35355 0.853553Z"
        fill="white"
        {...props}
      />
    </svg>
  );
}
