import styled from "styled-components";

export const Card = styled.div`
  height: 14rem;
  cursor: pointer;
  padding: 2rem;
  position: relative;
  border-radius: 2.5rem;
  overflow: hidden;
  border: 0.3rem solid ${(props) => props.theme.stroke.stroke5};
  :hover img {
    opacity: 100%;
    transition: 0.3s;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    top: 0;
    left: 0;
    position: absolute;
    z-index: 10;
    opacity: 60%;
    transition: 0.3s;
  }

  .bg {
    width: 100%;

    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 11;
    background: ${(props) => props.theme.gradients.card_fade};
  }
`;

export const Container = styled.div`
  height: 100%;
  cursor: pointer;
  display: flex;
  width: fit-content;
  justify-content: flex-end;
  gap: 1rem;
  flex-direction: column;
  z-index: 100;
  position: relative;
`;

export const Map = styled.span`
  ${(props) => props.theme.fonts.GothamBold24};
  color: ${(props) => props.theme.colors.white.white100};
  cursor: pointer;
`;

export const Type = styled.div`
  ${(props) => props.theme.mixins.dfcenter};
  justify-content: flex-start;
  ${(props) => props.theme.fonts.GothamRegular20};
  color: ${(props) => props.theme.colors.white.white100};
  gap: 0.5rem;
`;

export const Online = styled.div`
  display: flex;
  align-items: center;
  gap: 0.6rem;
  ${(props) => props.theme.fonts.GothamMedium15};
  color: ${(props) => props.theme.colors.white.white70};
  div {
    color: ${(props) => props.theme.colors.white.white70};
  }
  .highlighted {
    color: ${(props) => props.theme.colors.white.white100};
  }
`;
