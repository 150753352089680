import { VipServise } from "API/VipServise";
import ModalWrapper from "components/Templates/ModalWrapper";
import Button from "components/UI/Button";
import { useAppSelector } from "hooks/useAppSelector";
import { toast } from "react-toastify";
import { WrapperModal } from "./styled";

interface Props {
  isActive: boolean;
  setActive: React.SetStateAction<any>;
}

export default function СancellationModal({ isActive, setActive }: Props) {
  const { user } = useAppSelector((state) => state.user);
  return (
    <ModalWrapper
      headContent={`Аннулировать подписку?`}
      isActive={isActive}
      setActive={setActive}
      params={{ width: "max-content", height: "fit-content" }}
      display="flex"
    >
      <WrapperModal>
        <Button
          color="grayy"
          height="5rem"
          variant="default"
          onClick={async () => {
            const result = await VipServise.Сancellation(user?.user?.steamid64);
            if (result) {
              setActive(false);
              toast.success("Успешно");
              window.location.reload();
            } else {
              setActive(false);
              toast.success("Ошибка");
            }
          }}
        >
          Аннулировать
        </Button>
        <Button
          color="blue"
          height="5rem"
          variant="default"
          onClick={() => setActive(false)}
        >
          Отменить
        </Button>
      </WrapperModal>
    </ModalWrapper>
  );
}
