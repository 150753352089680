import Layout from "components/Layout";
import SubHeader from "components/SubHeader";
import PreviousNavigate from "components/UI/ArrowBack";
import { useAppSelector } from "hooks/useAppSelector";
import { useParams } from "react-router-dom";
import { Admin, Content, Info, Reason, Status, Wrapper } from "./Styles";
import WithTitle from "components/Templates/WithTitle";
import Column from "components/Column";
import Row from "components/Row";
import { InfoItem, PlayerItem } from "pages/Ticket/Styles";
import Button from "components/UI/Button";
import Chat from "modules/Chat";
import { io } from "socket.io-client";
import { useMemo } from "react";

const source = io("");

export default function Application() {
  const { id } = useParams();
  const { data } = useAppSelector((state) => state.applications);
  const dataObject = useMemo(() => {
    // console.log("lox");
    return data.find((item) => Number(item.id) === Number(id));
  }, [data]);

  return (
    <Layout
      leftElement={<div />}
      headerElement={
        <SubHeader>
          <PreviousNavigate />
          Заявка #{id}
        </SubHeader>
      }
    >
      <Wrapper>
        <Info>
          <WithTitle title="Информация">
            <Column
              gap="2rem"
              justifyContent="normal"
              alignItems="normal"
              width="100%"
            >
              <Row justifyContent="flex-start" alignItems="flex-start">
                <InfoItem width="18.9rem">Ник:</InfoItem>
                <InfoItem width="100%">{dataObject?.user_name}</InfoItem>
              </Row>
              <Row justifyContent="flex-start" alignItems="flex-start">
                <InfoItem width="18.9rem">Причина:</InfoItem>
                <Reason width="100%">{dataObject?.reason}</Reason>
              </Row>
              <Row justifyContent="flex-start" alignItems="flex-start">
                <InfoItem width="18.9rem">Статус:</InfoItem>
                <Status status={dataObject?.status}>
                  {dataObject?.status}
                </Status>
              </Row>
              <Row justifyContent="flex-start" alignItems="flex-start">
                <InfoItem width="18.9rem">Сервер:</InfoItem>
                <InfoItem width="100%">{dataObject?.server_id}</InfoItem>
              </Row>
              <Row justifyContent="flex-start" alignItems="flex-start">
                <InfoItem width="18.9rem">Игрок:</InfoItem>
                <PlayerItem width="100%">{dataObject?.user_name}</PlayerItem>
              </Row>
              <Row justifyContent="flex-start" alignItems="flex-start">
                <InfoItem width="18.9rem">Дата создания:</InfoItem>
                <InfoItem width="100%">{dataObject?.created_at}</InfoItem>
              </Row>
              <Row justifyContent="flex-start" alignItems="flex-start">
                <InfoItem width="18.9rem">Рассмотрел:</InfoItem>
                <Admin width="100%">{dataObject?.admin}</Admin>
              </Row>
              <Row gap="3rem">
                <Button
                  variant="default"
                  color="blue"
                  height="5rem"
                  params={{ padding: "1.5rem 10.5rem" }}
                >
                  Удалить
                </Button>
              </Row>
            </Column>
          </WithTitle>
          <WithTitle title="Комментарий">
            <Content>
              Режим, в котором задача каждой команды убить команду противника, с
              помощью выданного заранее или купленного игроком оружия.
              Уничтожение и Основные карты, на которые ориентируется сервер. В
              даном режиме можно играть как угодно не нарушая основных правил
              сервера. Главная задача данного режима пройти карту до конца,
              чтобы получить кредиты, на которые можно купить все что угодно в
              магазине !lk и не только. На данной карте необходимо быстрее
              другой команды пройти весь Бхоп до конца, чтобы получить доступ к
              оружию на верхнем спавне. Игрок, который не смог пройти Бхоп до
              конца появится со стороны своей команды на нижнем спавне с
              оружием, которое он выбил на определенном уровне прохождения.
              Любой игрок на верхнем спавне имеет право убивать любого
              противника, не включать фонарик, не играть с противником в
              какие-либо "игры"! Разрешены быстрые дуэли на ножах.
            </Content>
          </WithTitle>
        </Info>
        <Chat
          source={source}
          items={[]}
          isActive={true}
          isFileInput={true}
          params={{ width: "100%", height: "87rem" }}
        />
      </Wrapper>
    </Layout>
  );
}
