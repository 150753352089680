import ModalWrapper from "components/Templates/ModalWrapper";
import Modal from "../../Modal";
import {
  ArrowStyle,
  Container,
  DefWrapFlexBeet,
  DefWrapFlexGap1Column,
  DefWrapFlexGap2,
  SmallText,
  SmallText70,
  TextBig,
  TextMedium,
} from "./styled";
import { useEffect, useState } from "react";
import Button from "components/UI/Button";
import { TinyIcons } from "assets/tiny-icons";
import { BattlPassServise } from "API/BattlPassServise";
import { toast } from "react-toastify";
import { useActions } from "hooks/useActions";
import { useAppSelector } from "hooks/useAppSelector";
import { BatttInfoMe } from "interfaces/BattlPass";
import { ClanShopEnum, SeparateClan, Shop } from "interfaces/Clans";
import { ClansServise } from "API/ClansServise";

interface Props {
  isActive: boolean;
  setActive: React.SetStateAction<any>;
  setSeparateClan: React.SetStateAction<any>;
  SeparateClan?: SeparateClan;
  shop?: Shop[];
  // xpNextLevel?: number;
  // xp?: number;
  // setBattlPassMe: React.SetStateAction<any>;
  // BattlPassMe?: BatttInfoMe;
}

export default function BuyModalClans({
  isActive,
  setActive,
  SeparateClan,
  setSeparateClan,
  shop,
}: // xpNextLevel,
// xp,
// setBattlPassMe,
// BattlPassMe,
Props) {
  const [valueXp, setValueXp] = useState<number>(50);
  const [valueRub, setValueRub] = useState<number>(50);
  const { setBalance } = useActions();
  const { user } = useAppSelector((state) => state.user);

  useEffect(() => {
    setValueRub(50);
    setValueXp(50);
  }, [isActive]);
  return (
    <ModalWrapper
      isActive={isActive}
      setActive={setActive}
      headContent={"Покупка очков"}
      display="block"
      params={{ width: "50rem", height: "max-content" }}
    >
      <Container>
        <DefWrapFlexBeet>
          {valueXp !== 50 ? (
            <Button
              variant="default"
              height="5rem"
              color="grayy"
              style={{ width: "5rem" }}
              onClick={() => {
                setValueRub(valueRub - 50);
                setValueXp(valueXp - 50);
              }}
            >
              <ArrowStyle right={false} />
            </Button>
          ) : (
            <div style={{ width: "5rem" }} />
          )}

          <TextBig style={{ display: "flex", gap: "2rem" }}>
            {valueXp}
            <TinyIcons.XpClans style={{ width: "36px", height: "36px" }} />
          </TextBig>
          <Button
            variant="default"
            height="5rem"
            color="grayy"
            style={{ width: "5rem" }}
            onClick={() => {
              setValueRub(valueRub + 50);
              setValueXp(valueXp + 50);
            }}
          >
            <ArrowStyle right={true} />
          </Button>
        </DefWrapFlexBeet>

        <DefWrapFlexBeet style={{ gap: "1.5rem" }}>
          <DefWrapFlexGap1Column
            style={{ width: "10rem", alignItems: "center" }}
          >
            <SmallText70>К оплате</SmallText70>
            <DefWrapFlexGap2>
              <TinyIcons.Rub />
              <TextMedium>{valueRub}</TextMedium>
            </DefWrapFlexGap2>
          </DefWrapFlexGap1Column>
          <Button
            variant="default"
            height="5rem"
            color="blue"
            style={{ width: "80%" }}
            onClick={async () => {
              const result = await ClansServise.BuyImMarket(
                shop?.find((item) => item.type == ClanShopEnum.Points)?.id ||
                  -1,
                valueXp,
                ClanShopEnum.Points
              );
              if (result == true) {
                setBalance({
                  real: user.balance.real - valueRub,
                  virtual: user.balance.virtual,
                });

                if (!SeparateClan) return;
                const copyClans = { ...SeparateClan };
                copyClans.xp = copyClans.xp + valueXp;
                setSeparateClan(copyClans);
                toast.success("Успешно");
                setActive(false);

                // const battlPassMeCopy = { ...BattlPassMe };
                // battlPassMeCopy.xp = (battlPassMeCopy.xp || 0) + valueXp;
                // setBattlPassMe(battlPassMeCopy);
              } else {
                if (result == "You have not enough balance") {
                  toast.error("Недостаточно баланса");
                }
                toast.error("Ошибка");
              }
            }}
          >
            Купить
          </Button>
        </DefWrapFlexBeet>
      </Container>
    </ModalWrapper>
  );
}
