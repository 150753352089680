import { TinyIcons } from "assets/tiny-icons";
import Layout from "components/Layout";
import СancellationModal from "components/Modal/Сancellation/СancellationModal";
import ProfileLayout from "components/ProfileLayout";
import SubHeader from "components/SubHeader";
import Button from "components/UI/Button";
import { useAppSelector } from "hooks/useAppSelector";
import { useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import {
  BlockWrap,
  OpacitySpan,
  SpanAdv,
  VipSpan,
  WrapNoVip,
  WrapOneBlock,
  Wrapper,
} from "./styled";

export default function Privileges() {
  const { isAuth, user, userData } = useAppSelector((state) => state.user);
  const [isActive, setIsActive] = useState<boolean>(false);
  const navigate = useNavigate();

  function formatExpirationDate(dateString: string): string {
    // Преобразуем строку в объект Date
    const date = new Date(dateString);
    date.setHours(date.getHours() - 3);

    // Получаем день, месяц, год, часы и минуты
    const day = date.getUTCDate();
    const month = date.getUTCMonth() + 1; // Месяцы отсчитываются с 0
    const year = date.getUTCFullYear();
    const hours = date.getUTCHours();
    const minutes = date.getUTCMinutes();

    // Форматируем день, месяц, часы и минуты в двухзначный формат
    const formattedDay = day < 10 ? `0${day}` : `${day}`;
    const formattedMonth = month < 10 ? `0${month}` : `${month}`;
    const formattedHours = hours < 10 ? `0${hours}` : `${hours}`;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;

    // Возвращаем строку в нужном формате
    return `Подписка действительна до ${formattedDay}.${formattedMonth}.${year} ${formattedHours}:${formattedMinutes}`;
  }

  return (
    <Layout
      leftElement={<div />}
      headerElement={<SubHeader>Привилегии</SubHeader>}
    >
      <Helmet>
        <title>FREEZ-BLOOD - Привилегии</title>
        <meta name="description" content={`FREEZ-BLOOD - Привилегии`} />
      </Helmet>
      <ProfileLayout gap="3rem">
        {userData?.group?.name !== "Игрок" ? (
          <Wrapper>
            <WrapOneBlock>
              <VipSpan
                Color={
                  userData?.group?.name == "VIP" ||
                  userData?.group?.name == "VIP TEST"
                    ? false
                    : true
                }
              >
                {userData?.group?.name}
              </VipSpan>
              <BlockWrap>
                <SpanAdv>{userData?.group?.name}</SpanAdv>
                <OpacitySpan>
                  {formatExpirationDate(userData?.group?.expired)}
                </OpacitySpan>
              </BlockWrap>
            </WrapOneBlock>
            {userData?.user?.steamid64 == user?.user?.steamid64 ? (
              <WrapOneBlock>
                {(userData?.group?.name !== "VIP TEST" &&
                  userData?.group?.name == "VIP") ||
                userData?.group?.name == "PREMIUM" ||
                userData?.group?.name == "VIP TEST" ? (
                  <Button
                    variant="default"
                    color="blue"
                    height="5rem"
                    onClick={() => navigate("/vip")}
                  >
                    Продлить
                  </Button>
                ) : null}

                <Button
                  variant="default"
                  color="grayy"
                  height="5rem"
                  onClick={() => setIsActive(true)}
                >
                  Аннулировать
                </Button>
              </WrapOneBlock>
            ) : null}
          </Wrapper>
        ) : (
          <WrapNoVip>
            <TinyIcons.Crown />
            <SpanAdv>Нет активных подписок</SpanAdv>
            {user?.user?.steamid64 == userData?.user?.steamid64 ? (
              <Button
                color="blue"
                variant="default"
                height="5rem"
                onClick={() => navigate("/vip")}
              >
                Купить подписку
              </Button>
            ) : null}
          </WrapNoVip>
        )}
      </ProfileLayout>
      <СancellationModal isActive={isActive} setActive={setIsActive} />
    </Layout>
  );
}
