import { useEffect, useState } from "react";
import {
  DefFlex,
  DefFlexG1,
  MediumText,
  RangeSlider,
  SliderContainer,
  ValueDisplay,
} from "./styled";
import UIKIT from "components/UI";

interface Props {
  value: number;
  setValue: React.Dispatch<React.SetStateAction<number>>;
}

export default function RangeSliderPattern({ value, setValue }: Props) {
  // Calculate percentage for gradient
  const percentage = (value / 1023) * 100;

  useEffect(() => {
    if (value < 0) {
      setValue(0);
    } else if (value > 1023) {
      setValue(1023);
    }
  }, [value]);

  return (
    <SliderContainer>
      <DefFlex>
        <MediumText>Выберите паттерн</MediumText>

        <UIKIT.Input
          min={0.1}
          type="number"
          // pattern="\d"
          max={0.009}
          onInput={(e) => {
            const input = (e.target as HTMLInputElement).value;

            // Убираем ведущие нули и проверяем длину
            const sanitizedValue = input.replace(/^0+/, ""); // Удаляем ведущие нули

            if (sanitizedValue.length <= 4) {
              const numericValue = Number(sanitizedValue); // Преобразуем в число
              setValue(numericValue); // Устанавливаем значение
              (e.target as HTMLInputElement).value = numericValue.toString(); // Обновляем поле ввода
            } else {
              (e.target as HTMLInputElement).value = value.toString(); // Вернуть предыдущее значение
            }
          }}
          style={{
            padding: "1rem",
            textAlign: "center",
            borderRadius: "1rem",
          }}
          params={{
            width: "12rem",
            height: "3.5rem",
          }}
          value={value}
          onChange={(e) => setValue(Number(e.target.value))}
        ></UIKIT.Input>
      </DefFlex>

      <RangeSlider
        type="range"
        min="0"
        max="1023"
        value={value}
        onChange={(e) => setValue(Number(e.target.value))}
        percentage={percentage}
      />
    </SliderContainer>
  );
}
