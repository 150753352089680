import { Icons } from "assets/icons";
import Button from "components/UI/Button";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import Modal from "../Modal";
import TradeState from "../Trade/TradeState";
import { Container, Text } from "./Styles";

interface Props {
  isActive: boolean;
  type: any /*TradeModalType*/;
}

const TradeConfirm = ({ isActive, type }: Props) => {
  const closeHandle = () => {
    TradeState.setSecondModal(false);
  };

  return (
    <Modal
      isActive={isActive}
      setActive={() => closeHandle()}
      modalStyle={{ zIndex: 1005 }}
    >
      <Container>
        {/* {JSON.stringify(TradeState.secondModalData)} */}
        {type == "success" && (
          <>
            <Text>Обмен произведён</Text>
            <Icons.Check />
          </>
        )}
        {type == "cancel" && (
          <>
            <Text>Обмен был отменён</Text>
            <Icons.Cancle />
          </>
        )}
        {type == "error" && (
          <>
            <Text>
              Обмен был отменён! <br /> Из-за нехватки скинов у{" "}
              {/* TradeState.secondModalData.name */}
            </Text>
            <Icons.Cancle />
          </>
        )}
        <Button
          onClick={closeHandle}
          height="5rem"
          style={{ width: "25rem" }}
          variant="picker"
          color="blue"
        >
          OK
        </Button>
        <Icons.Close onClick={closeHandle} />
      </Container>
    </Modal>
  );
};

export default observer(TradeConfirm);
