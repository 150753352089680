import { TinyIcons } from "assets/tiny-icons";
import { InventoryWeaponCard } from "components/Cards";
import Row from "components/Row";
import Filters from "components/Templates/Filters";
import ModalWrapper from "components/Templates/ModalWrapper";
import UIKIT from "components/UI";
import { MarketplaceContext } from "context/marketplace";
import { useActions } from "hooks/useActions";
import { useAppSelector } from "hooks/useAppSelector";
import { observer } from "mobx-react-lite";
import { IInventoryWeaponCard } from "models/InventoryWeaponCard";
import { IWeaponCard } from "models/WeaponCard";
import { EModalType } from "pages/Profile/Tabs/Inventory/Header/Header";
import { useState } from "react";
import rarityConvector from "utils/rarityConvector";
import { Modals } from "..";
import skinSelectorState from "./skinSelectorState";
import { Center, Grid } from "./Styles";

interface Props {
  isActive: boolean;
  setActive: React.SetStateAction<any>;
  type: EModalType;
}

interface Test {
  payload: any;
}

const SkinTransferModal = ({ isActive, setActive, type }: Props) => {
  const [currentWeapon, setCurrentWeapon] = useState<IWeaponCard>({
    cl: false,
    id: "0",
    steamid: "",
    category: "",
    item: 1,
    group: 1,
    paint_kit_id: null,
    price: "1",
    src: "",
    skin: "",
    weapon: "",
    rarity: "mil_spec",
  });
  const { user } = useAppSelector((state) => state.user);
  const {
    fetchInventoryWithCallback,
    fetchServerInventory,
    fetchInventoryToServer,
    fetchInventoryFromServer,
    fetchInventory,
  } = useActions();

  const fetchSkins = async () => {
    // skinSelectorState.setSkins(inventory)
    //! Обнулиь состояние при обновлении исАктиве
    setSelectCount(0);

    if (type == EModalType.fromServer) {
      fetchServerInventory({
        steamid64: user.user.steamid64,
        callback: skinSelectorState.setSkins,
      });
    } else {
      fetchInventoryWithCallback({
        steamid64: user.user.steamid64,
        callback: skinSelectorState.setSkins,
      });
    }
  };

  // useEffect(() => {
  //   fetchSkins();
  // }, [isActive]);

  const [foo, setFoo] = useState(false);
  const [test, setTest] = useState(false);
  const [selectCount, setSelectCount] = useState(0);

  const selectSkin = (skin: IInventoryWeaponCard, fromModal: boolean) => {
    const { amount = 0 } = skin.skinInfo || {};

    if (fromModal) {
      skinSelectorState.selectSkin(skin, selectCount);
      setTest(false);
    } else {
      if (Number(amount) > 1 && !skin.isSelected) {
        setTest(true);
      } else {
        let count = Number(amount) > 1 ? 0 : 1;
        skinSelectorState.selectSkin(skin, count);
      }
    }
  };

  const getModalTitle = () => {
    switch (type) {
      case EModalType.fromServer:
        return "Перенос предметов на сайт";
      case EModalType.fromSite:
        return "Перенос предметов на сервер";

      default:
        return "Name not found";
    }
  };

  const sendSkins = async () => {
    const struct = {
      steamid64: user.user.steamid64,
      skins: skinSelectorState.skins as IWeaponCard[],
    };

    if (type == EModalType.fromSite) {
      await fetchInventoryToServer(struct);
    }

    if (type == EModalType.fromServer) {
      await fetchInventoryFromServer(struct);
    }

    await fetchSkins();
  };

  return (
    <ModalWrapper
      isActive={isActive}
      setActive={setActive}
      headContent={getModalTitle()}
      params={{
        width: "129.8rem",
        height: "fit-content",
      }}
      display="flex"
    >
      <MarketplaceContext.Provider value={currentWeapon}>
        <Center>
          <Modals.MarketplaceWeapon
            isActive={test}
            setActive={setTest}
            mode={100}
            modalStyle={{ zIndex: "10005" }}
            compare={{
              set: setSelectCount,
              get: selectCount,
              all: currentWeapon?.skinInfo?.amount || 0,
              btn: selectSkin,
            }}
          />

          <Filters>
            <UIKIT.DropDown
              onChange={setFoo}
              items={["first", "second", "dthghfghfhf"]}
              headParams={{
                title: "Класс:",
                icon: <TinyIcons.Arrow />,
              }}
            />
            <UIKIT.DropDown
              onChange={setFoo}
              items={["first", "second", "dthghfghfhf"]}
              headParams={{
                title: "Класс:",
                icon: <TinyIcons.Arrow />,
              }}
            />
            <UIKIT.DropDown
              onChange={setFoo}
              items={["first", "second", "dthghfghfhf"]}
              headParams={{
                title: "Класс:",
                icon: <TinyIcons.Arrow />,
              }}
            />
            <Row gap="3rem">
              <UIKIT.DropDown
                onChange={setFoo}
                items={["first", "second", "dthghfghfhf"]}
                headParams={{
                  title: "Класс:",
                  icon: <TinyIcons.Arrow />,
                }}
              />
              <UIKIT.DropDown
                onChange={setFoo}
                items={["first", "second", "dthghfghfhf"]}
                headParams={{
                  title: "Класс:",
                  icon: <TinyIcons.Arrow />,
                }}
              />
            </Row>
          </Filters>
          <Grid className="customScroll">
            {skinSelectorState.skins.map((item, index) => (
              <InventoryWeaponCard
                key={index}
                paint_kit_id={item.paint_kit_id}
                src={item.src}
                cl={false}
                rarity={rarityConvector(+item.rarity)}
                weapon={item.weapon}
                skin={item.skin}
                counter={`${item.selectedCount || 0}/${
                  item.skinInfo && item.skinInfo.amount
                }`}
                isSelected={item.isSelected}
                onClick={() => {
                  //skinSelectorState.selectSkin(item)
                  selectSkin(item, false);
                  setCurrentWeapon(item as IWeaponCard);
                }}
              />
            ))}
          </Grid>
          <UIKIT.Button
            color="blue"
            height="5rem"
            variant="default"
            onClick={() => {
              //skinSelectorState.test()
              // setTest(!test)
              sendSkins();
            }}
          >
            Перенести
          </UIKIT.Button>
        </Center>
      </MarketplaceContext.Provider>
    </ModalWrapper>
  );
};

export default observer(SkinTransferModal);
