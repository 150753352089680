import styled from "styled-components";

export const DropDownHead = styled.div`
  cursor: pointer;
  ${(props) => props.theme.mixins.dfcenter};
  user-select: none;
  svg {
    transition: all 0.3s;
    transform: rotate(0deg);
  }
  &.active {
    svg {
      transform: rotate(180deg);
    }
  }
`;

export const Title = styled.div`
  ${(props) => props.theme.fonts.GothamBold16};
  color: ${(props) => props.theme.colors.blue.accent};
  user-select: none;
  margin-right: 1rem;
`;

export const SubTitle = styled.div`
  ${(props) => props.theme.fonts.GothamRegular16};
  color: ${(props) => props.theme.colors.white.white100};
  user-select: none;
`;

export const Icon = styled.div`
  margin-left: 0.6rem;
  user-select: none;
`;
