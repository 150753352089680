export const getBansAndMutesTitle = (mode: number) => {
	switch (mode) {
		case 0:
			return 'Баны'
		case 1:
			return 'Муты'
		case 2:
			return 'Заявки'
		default:
			break;
	}
}