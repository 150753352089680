import styled from "styled-components";

export const WrapperL = styled.div`
  display: flex;
  width: 100%;
  gap: 10px;
  margin-top: 15px;
`;

export const Item = styled.div`
  width: max-content;
  height: max-content;
  display: block;
`;
