import AppLink from "components/AppLink";
import { Controllers } from "components/Controllers";
import ModalWrapper from "components/Templates/ModalWrapper";
import Button from "components/UI/Button";
import { useAppSelector } from "hooks/useAppSelector";
import { IModal } from "interfaces/Modal";
import { useForm } from "react-hook-form";
import { useTheme } from "styled-components";
import { Form } from "./Styles";

interface Props extends IModal {}

export interface VacansyForm {
  server: string;
  vacansy: string;
  vk: string;
  steam: string;
  discord: string;
  age: number;
  avarageOnline: string;
  isAdminExperience: boolean;
  isContribution: boolean;
  description: string;
  isRulesAndTerms: boolean;
}

export default function VacansyModal({ isActive, setActive }: Props) {
  const Theme = useTheme();

  const {
    control,
    handleSubmit,
    formState: { isValid },
    reset,
  } = useForm<VacansyForm>({
    mode: "all",
    defaultValues: {
      server: "",
      vacansy: "Модератор",
      vk: "",
      steam: "",
      discord: "",
      age: 0,
      avarageOnline: "",
      isAdminExperience: false,
      isContribution: false,
      description: "",
      isRulesAndTerms: true,
    },
  });

  async function onSubmit(data: VacansyForm) {
    reset();
  }

  const props = {
    params: {
      width: "100%",
      font: Theme.fonts.GothamMedium16,
      headFont: Theme.fonts.GothamMedium16,
    },
    control,
    rules: {
      required: true,
    },
  };

  return (
    <ModalWrapper
      headContent="Подача заявки"
      isActive={isActive}
      setActive={setActive}
      params={{ width: "120rem", height: "fit-content" }}
      display="flex"
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Controllers.Select
          {...props}
          name={"server"}
          label="Сервер"
          items={["Ugabuga", "sadasdsa"]}
        />
        <Controllers.Input
          {...props}
          name={"vk"}
          label="ВКонтакте"
          placeholder="Ссылка на ваш профиль ВКонтакте"
        />
        <Controllers.Input
          {...props}
          name={"steam"}
          label="Steam"
          placeholder="Ссылка на ваш Steam"
        />
        <Controllers.Input
          {...props}
          name={"discord"}
          label="Discord"
          placeholder="Пример #0000"
        />
        <Controllers.Input
          {...props}
          name={"age"}
          label="Возраст"
          placeholder="Укажите ваш возраст"
        />
        <Controllers.Input
          {...props}
          name={"avarageOnline"}
          label="Средний онлайн в день"
          placeholder="Укажите среднее время игры в день"
        />
        <Controllers.Select
          {...props}
          name={"isAdminExperience"}
          label="Опыт администрирования"
          items={[
            { value: true, label: "Да" },
            { value: false, label: "Нет" },
          ]}
        />
        <Controllers.Select
          {...props}
          name={"isContribution"}
          label="Готов на обязательный взнос 400р на поддержку проекта?"
          items={[
            { value: true, label: "Да" },
            { value: false, label: "Нет" },
          ]}
        />
        <Controllers.Textarea
          {...props}
          name={"description"}
          label="Почему именно ты?"
          placeholder="Ваши плюсы"
          params={{
            width: "100%",
            inputFont: Theme.fonts.GothamMedium16,
            headFont: Theme.fonts.GothamMedium16,
            height: "15rem",
          }}
          rules={{ required: true }}
        />
        <Controllers.Checkbox
          control={control}
          name={"isRulesAndTerms"}
          rules={{ required: true }}
        >
          Я ознакомлен(-а) с<AppLink href="/rules">правилами</AppLink>, а так же
          с<AppLink href="/conditions">условиями подачи заявки</AppLink> и
          согласен(-а) с ними.
        </Controllers.Checkbox>
        <Button
          height="5rem"
          color="blue"
          variant="default"
          params={{ width: "fit-content" }}
          disabled={!isValid}
        >
          Отправить
        </Button>
      </Form>
    </ModalWrapper>
  );
}
