export default function Rub() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1891_42080)">
        <rect
          width="28"
          height="28"
          rx="14"
          fill="url(#paint0_radial_1891_42080)"
        />
        <rect
          x="1"
          y="1"
          width="26"
          height="26"
          rx="13"
          stroke="url(#paint1_linear_1891_42080)"
          stroke-width="2"
        />
        <rect
          x="2.5"
          y="2.5"
          width="23"
          height="23"
          rx="11.5"
          stroke="url(#paint2_linear_1891_42080)"
        />
        <path
          d="M10.5358 22V7H14.9127C16.6789 7 17.9651 7.39216 18.7714 8.17647C19.5905 8.96078 20 10.0392 20 11.4118C20 12.2241 19.8336 12.9874 19.5009 13.7017C19.1681 14.416 18.6114 14.9902 17.8307 15.4244C17.0628 15.8585 16.0134 16.0756 14.6824 16.0756H13.4346V22H10.5358ZM9 19.7521V17.7143H16.3909V19.7521H9ZM9 16.0756V13.4706H14.6248V16.0756H9ZM14.3944 13.4706C14.9447 13.4706 15.4183 13.4076 15.815 13.2815C16.2118 13.1415 16.5189 12.9244 16.7365 12.6303C16.954 12.3361 17.0628 11.965 17.0628 11.5168C17.0628 10.8725 16.8773 10.3964 16.5061 10.0882C16.135 9.76611 15.5526 9.60504 14.7592 9.60504H13.4346V13.4706H14.3944Z"
          fill="#1B4563"
        />
        <rect
          opacity="0.1"
          x="-7"
          y="12.1628"
          width="38.3257"
          height="6"
          transform="rotate(-30 -7 12.1628)"
          fill="white"
        />
        <rect
          opacity="0.1"
          x="-3.90039"
          y="18.9441"
          width="38.3257"
          height="2.49716"
          transform="rotate(-30 -3.90039 18.9441)"
          fill="white"
        />
      </g>
      <defs>
        <radialGradient
          id="paint0_radial_1891_42080"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(14) rotate(90) scale(28)"
        >
          <stop stop-color="#78C6FF" />
          <stop offset="1" stop-color="#1E91E4" />
        </radialGradient>
        <linearGradient
          id="paint1_linear_1891_42080"
          x1="14"
          y1="0"
          x2="14"
          y2="28"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#389FEA" />
          <stop offset="1" stop-color="#15639A" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1891_42080"
          x1="14"
          y1="2"
          x2="14"
          y2="26"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#15639A" />
          <stop offset="1" stop-color="#2696E8" />
        </linearGradient>
        <clipPath id="clip0_1891_42080">
          <rect width="28" height="28" rx="14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
