import PrivilegeCard from "components/Cards/PrivilegeCard";
import { useAppSelector } from "hooks/useAppSelector";

import { Grid } from "./Styles";
import { useMemo } from "react";

export default function Privileges() {
  const { shop } = useAppSelector((state) => state.shop);

  const shopMemo = useMemo(() => {
    return shop.map((item, index) => (
      <PrivilegeCard
        name={item.name}
        features={item.features}
        prices={item.prices}
        key={index}
      />
    ));
  }, [shop]);

  return <Grid>{shopMemo}</Grid>;
}
