import { Button } from "components/UI/Button/Styles";
import styled from "styled-components";

export const Transfer = styled.div`
  ${(props) => props.theme.shadows.defaultShadow};
  border-radius: 2rem;
  position: absolute;
  padding: 3rem;
  display: flex;
  right: -4rem;
  top: 6rem;
  flex-direction: column;
  gap: 2rem;
  height: fit-content;
  max-width: 36rem;
  width: 100%;
  background: ${(props) => props.theme.colors.white.white100};
  opacity: 0;
  z-index: -2;
  pointer-events: none;
  transition: all 0.3s;
  &.active {
    opacity: 1;
    z-index: 2;
    pointer-events: all;
  }
  ${Button} {
    width: 100%;
  }
`;
export const ColumnSC = styled.div`
  ${(props) => props.theme.fonts.GothamMedium16};
  color: ${(props) => props.theme.colors.white.white70};
`;

export const Type = styled.span`
  position: relative;
  display: flex;
  align-items: center;
  gap: 1.2rem;
  cursor: pointer;
  ${(props) => props.theme.fonts.GothamMedium16};
  color: ${(props) => props.theme.colors.white.white70};
`;

export const GunContainer = styled.div`
  position: absolute;
  z-index: 10;
  display: flex;
  flex-direction: column;
  top: 15px;
  left: 0;
  background: ${(props) => props.theme.colors.white.white10};
  border-radius: 1.5rem;
  width: 21.7rem;
  backdrop-filter: blur(5rem);
  padding: 0.9rem;
`;

export const GunElem = styled.span<{ isSelected: boolean }>`
  ${(props) => props.theme.fonts.GothamMedium16};
  color: ${(props) => props.theme.colors.white.white100};
  border-radius: 1.5rem;
  width: 100%;
  padding: 1.2rem;
  border: 2px solid
    ${(props) =>
      props.isSelected ? props.theme.colors.white.white10 : "transparent"};
  background-color: ${(props) =>
    props.isSelected ? props.theme.colors.white.white10 : "transparent"};
  transition: border-color 0.3s, background-color 0.3s; // Добавляем плавное изменение цвета рамки и фона при выборе
`;
