import { asideItems } from "consts/aside";
import Image from "../Image";
import { Item, ItemOrange, Logo, StyledAside, Wrapper } from "./Styles";

export default function Aside() {
  return (
    <StyledAside className="customScroll">
      <Logo>
        <Image src="/static/images/logo.svg" alt="Logo" />
      </Logo>
      <Wrapper>
        {asideItems.map((item, index) => {
          if (item.label !== "BattlePass") {
            return (
              <Item to={item.link} key={index}>
                {item.icon}
                {item.label}
              </Item>
            );
          } else {
            return (
              <ItemOrange to={item.link} key={index}>
                {item.icon}
                {item.label}
              </ItemOrange>
            );
          }
        })}
      </Wrapper>
    </StyledAside>
  );
}
