import { SelectVariants } from "interfaces/Select";

import { BodyBlock, Item } from "./Styles";

interface Props {
  show: boolean;
  items: any[];
  onItemClick: ({ index }: any) => any;
  variant?: SelectVariants;
  font?: string;
  Clans?: boolean;
}

export default function Body({
  show,
  items,
  onItemClick,
  variant,
  font,
  Clans,
}: Props) {
  return (
    <BodyBlock
      className={show ? "customScroll active" : "customScroll"}
      variant={variant}
      Clans={Clans}
    >
      {items.map((item, index) => (
        <Item
          key={index}
          onClick={() => onItemClick(index)}
          font={font}
          Clans={Clans}
        >
          {item?.label ? item?.label : item}
        </Item>
      ))}
    </BodyBlock>
  );
}
