import { Wrapper } from "./Styles";

interface Props {
  children: any;
  params?: {
    width: string;
  };
}

export default function Filters({ children, params }: Props) {
  return <Wrapper params={params}>{children}</Wrapper>;
}
