export interface GetCatecory {
  flags: string;
  id: number;
  immunity: number;
  name: string;
  posibilities: posibilitiesMas[];
  prices: PriceArray[];
  visible: boolean;
}

interface posibilitiesMas {
  id: number;
  possibility: string;
}

interface PriceArray {
  id: number;
  duration: number;
  name: string;
  price: number;
  title: string;
  discount: number;
}

export enum Type {
  Gift = "GIFT",
  Grant = "GRANT",
  Buy = "Buy",
}
