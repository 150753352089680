import { ClansServise } from "API/ClansServise";
import Avatar from "components/Avatar";
import Layout from "components/Layout";
import {
  ClanMemberAction,
  ClanRolesEnum,
  ClansArray,
  ClanShopEnum,
  SeparateClan,
  Shop,
  TaskInClan,
} from "interfaces/Clans";
import { memo, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  ArrowStyle,
  BigText,
  BlockAddBut,
  BlockArrUser,
  BlockClan,
  BlockExpAdd,
  BlockExpGife,
  BlockFullMembers,
  BlockMembers,
  BlockModes,
  BlockTask,
  BlockTime,
  DivBg,
  FullBlock,
  MediumText,
  OneMember,
  OneMode,
  StandartFlexG1,
  WrapBlockTaskMember,
  WrapOneMarket,
  WrapOneTask,
} from "./styled";
import { TinyIcons } from "assets/tiny-icons";
import { useAppSelector } from "hooks/useAppSelector";
import Button from "components/UI/Button";
import { TaskEnum, TimeObject } from "interfaces/BattlPass";
import { CategoryMods } from "interfaces/CategoryMap";
import { ServersService } from "API/ServersService";
import UIKIT from "components/UI";
import { toast } from "react-toastify";
import { Icons } from "assets/icons";
import { Modals } from "components/Modal";
import ClansState from "../ClansState";

function ClanId() {
  const { id } = useParams();
  const [SeparateClan, setSeparateClan] = useState<SeparateClan>();
  const [shop, setShop] = useState<Shop[]>();
  const [serverMode, serServerMode] = useState<CategoryMods[]>();
  const [TaskClan, setTaskClan] = useState<TaskInClan>();
  const [ClosedClan, setClosedClan] = useState<boolean>();
  const [updateMember, setUpdateMember] = useState<string>();
  const [idMember, setIdMember] = useState<number>();
  const [Clans, setClans] = useState<ClansArray>();
  const [timeRemaining, setTimeRemaining] = useState<TimeObject>({
    day: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });
  const navigate = useNavigate();
  const [mode, setMode] = useState<number>(0);
  const { user } = useAppSelector((state) => state.user);
  useEffect(() => {
    async function StartClan() {
      if (ClansState.Clans == undefined) {
        setClans(await ClansServise.getClans());
      } else {
        setClans(ClansState.Clans);
      }

      setSeparateClan(await ClansServise.getClan(String(id)));
    }
    StartClan();
  }, [1]);
  useEffect(() => {
    async function StartClanInMyClan() {
      if (
        Clans &&
        SeparateClan &&
        Clans.myClan?.id == SeparateClan.id &&
        !serverMode &&
        !TaskClan &&
        !shop
      ) {
        serServerMode(await ServersService.getMods());
        setTaskClan(await ClansServise.getTaskClan());
      }
      if (mode == 2 && !shop) {
        setShop(await ClansServise.GetChop());
      }
    }
    if (SeparateClan) {
      ClansState.SetSepClans(SeparateClan);
    }
    StartClanInMyClan();
  }, [Clans, SeparateClan, mode]);
  useEffect(() => {
    setClosedClan(SeparateClan?.closed);
  }, [SeparateClan]);
  useMemo(() => {
    if (TaskClan?.expired) {
      let dateBattl = new Date(TaskClan?.expired);
      dateBattl.setHours(dateBattl.getHours());

      startCountdown(dateBattl, setTimeRemaining);
    }
  }, [TaskClan]);
  useEffect(() => {
    if (idMember && updateMember !== "") {
      UpdateMember(idMember, ClanMemberAction.Update);
    }
  }, [updateMember]);

  function formatDate(date: Date): string {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Месяцы начинаются с 0
    const year = date.getFullYear();

    return `${day}.${month}.${year}`;
  }

  function convertDateToTimeObject(targetDate: Date): TimeObject {
    const now = new Date();
    const diff = targetDate.getTime() - now.getTime();

    if (diff <= 0) {
      return { day: 0, hours: 0, minutes: 0, seconds: 0 };
    }

    const seconds = Math.floor((diff / 1000) % 60);
    const minutes = Math.floor((diff / 1000 / 60) % 60);
    const hours = Math.floor((diff / (1000 * 60 * 60)) % 24);
    const day = Math.floor(diff / (1000 * 60 * 60 * 24));

    return { day, hours, minutes, seconds };
  }

  function startCountdown(
    targetDate: Date,
    onTick: (time: TimeObject) => void
  ) {
    const intervalId = setInterval(() => {
      const timeRemaining = convertDateToTimeObject(targetDate);
      onTick(timeRemaining);

      // Останавливаем таймер, если время истекло
      if (
        timeRemaining.day === 0 &&
        timeRemaining.hours === 0 &&
        timeRemaining.minutes === 0 &&
        timeRemaining.seconds === 0
      ) {
        clearInterval(intervalId);
      }
    }, 1000);
  }

  function SearchTaskEnum(task: TaskEnum, target: number, desk?: number) {
    switch (task) {
      case TaskEnum.Cases:
        return `Откройте ${target} кейсов`;
      case TaskEnum.Contracts:
        return `Сделайте ${target} контрактов`;
      case TaskEnum.Dies:
        return `Умрите ${target} раз`;
      case TaskEnum.KillByMode:
        return `Сделайте ${target} убийств на ${
          desk && serverMode?.find((item) => item.value == desk)?.label
        }`;
      case TaskEnum.KillHeadshot:
        return `Сделайте ${target} убийств в голову`;
      case TaskEnum.KillNoscope:
        return `Сделайте ${target} убийств без прицела с AWP`;
      case TaskEnum.Kills:
        return `Сделайте ${target} убийств`;
      case TaskEnum.PlayTimeMode:
        return `Сыграйте ${target / 60} минут на ${
          desk && serverMode?.find((item) => item.value == desk)?.label
        }`;
      case TaskEnum.Playtime:
        return `Сыграйте ${target / 60} минут на проекте`;
      case TaskEnum.Socials:
        return `Подпишитесь на соцсети`;
      case TaskEnum.SpendCredits:
        return `Потратьте ${target} кредитов`;
    }
  }
  async function UpdateMember(userId: number, action: ClanMemberAction) {
    if (action == ClanMemberAction.Kick) {
      const result = await ClansServise.UpdateMembers(
        ClanMemberAction.Kick,
        userId,
        ClanRolesEnum.Member
      );
      if (result == true) {
        toast.success("Успешно");
        KickPlayer(userId);
      } else {
        toast.error("Что-то пошло не так");
      }
    } else if (action == ClanMemberAction.Update) {
      const role =
        updateMember == "Участник"
          ? ClanRolesEnum.Member
          : updateMember == "Заместитель"
          ? ClanRolesEnum.Helper
          : ClanRolesEnum.Requested;
      const result = await ClansServise.UpdateMembers(
        ClanMemberAction.Update,
        userId,
        role
      );
      setUpdateMember("");
      if (result == true) {
        toast.success("Успешно");
      }
    }
  }
  function KickPlayer(userid: number) {
    if (!SeparateClan) return;
    const updatedClan = { ...SeparateClan };
    updatedClan.members = updatedClan.members.filter(
      (member) => member.user.id !== userid
    );
    setSeparateClan(updatedClan);
  }

  const updateMembers = (
    UPRequest?: boolean,
    idMember?: number,
    Kick?: boolean
  ) => {
    if (!SeparateClan) return;

    const updatedClan = { ...SeparateClan }; // Копируем объект SeparateClan
    if (UPRequest && !Kick) {
      const Member = updatedClan.requested.find((item) => item.id == idMember);
      if (Member !== undefined && Member) {
        Member.role = ClanRolesEnum.Member;
        updatedClan.members.push(Member);
      }
    }
    if (UPRequest && Kick) {
      updatedClan.requested = updatedClan.requested.filter(
        (item) => item.id !== idMember
      );
    }

    setSeparateClan(updatedClan); // Устанавливаем новое состояние
  };

  function daysUntil(date: Date): string {
    const currentDate = new Date(); // Текущая дата
    const timeDiff = date.getTime() - currentDate.getTime(); // Разница во времени в миллисекундах
    const daysDiff = Math.ceil(timeDiff / (1000 * 60 * 60 * 24)); // Перевод миллисекунд в дни, округление вверх

    if (daysDiff <= 0) {
      return "Срок действия истек";
    }

    // Склонение слова "день" в зависимости от числа
    let dayString = "";
    if (daysDiff % 10 === 1 && daysDiff % 100 !== 11) {
      dayString = "день";
    } else if (
      daysDiff % 10 >= 2 &&
      daysDiff % 10 <= 4 &&
      (daysDiff % 100 < 10 || daysDiff % 100 >= 20)
    ) {
      dayString = "дня";
    } else {
      dayString = "дней";
    }

    return `Действителен еще ${daysDiff} ${dayString}`;
  }

  //Modals
  const [isActiveExitClan, setisActiveExitClan] = useState<boolean>(false);
  const [isActiveMarket, setActiveMarket] = useState<boolean>(false);
  const [ShopActiveModal, setShopActiveModal] = useState<Shop>();
  const [ActiveGivePointModal, setActiveGivePointModal] =
    useState<boolean>(false);
  const [ActiveModalBuyXP, setActiveModalBuyXP] = useState(false);

  return (
    <Layout headerElement={<div />} leftElement={<div />}>
      <FullBlock>
        {/* Кнопка вернуться */}
        <MediumText
          style={{
            display: "flex",
            gap: "1rem",
            alignItems: "center",
            cursor: "pointer",
          }}
          onClick={() => {
            navigate("/clans");
          }}
        >
          <ArrowStyle right={false} />
          Вернуться
        </MediumText>
        {/* Кнопка вернуться */}

        <BlockClan>
          <StandartFlexG1 style={{ gap: "2rem" }}>
            <Avatar size="ava2" src={SeparateClan?.avatar} />
            <StandartFlexG1
              aling={true}
              style={{
                flexDirection: "column",
                gap: "2rem",
              }}
            >
              <StandartFlexG1 Member={true}>
                <MediumText style={{ opacity: "60%" }}>
                  Создан{" "}
                  {SeparateClan?.created &&
                    formatDate(new Date(SeparateClan?.created))}{" "}
                  /
                </MediumText>

                {SeparateClan?.expired && (
                  <>
                    <MediumText
                      style={
                        Math.ceil(
                          (new Date(SeparateClan?.expired).getTime() -
                            new Date().getTime()) /
                            (1000 * 60 * 60 * 24)
                        ) <= 3
                          ? { color: "#f76d6d" }
                          : { opacity: "60%" }
                      }
                    >
                      {daysUntil(new Date(SeparateClan?.expired))}
                    </MediumText>
                  </>
                )}
              </StandartFlexG1>

              <BigText style={{ fontWeight: "normal" }}>
                {SeparateClan?.name}
              </BigText>
              <BlockMembers>
                <TinyIcons.UsersClans />
                <MediumText>
                  {
                    SeparateClan?.members.filter(
                      (item) => item.role !== ClanRolesEnum.Requested
                    ).length
                  }
                  / {SeparateClan?.capacity}
                </MediumText>
              </BlockMembers>
            </StandartFlexG1>
          </StandartFlexG1>
          {Clans &&
          SeparateClan &&
          Clans.myClan == null &&
          SeparateClan?.closed !== true &&
          SeparateClan?.members?.length < SeparateClan?.capacity ? (
            <Button
              height={"5rem"}
              color="blue"
              variant={"default"}
              style={{ borderRadius: "1.5rem" }}
              onClick={async () => {
                const result = await ClansServise.RequestToClan(
                  String(SeparateClan?.id) || ""
                );
                if (result == true) {
                  toast.success(
                    "Заявка подана, повторно заявку можно подать через 12 часов"
                  );
                  if (!Clans) return;

                  const copyClan = { ...Clans };

                  // Если myClan не существует, создаем его как пустой объект
                  if (!copyClan.myClan && SeparateClan) {
                    copyClan.myClan = {
                      avatar: "",
                      capacity: 0,
                      id: SeparateClan?.id,
                      role: ClanRolesEnum.Requested,
                      clanTag: "",
                      closed: false,
                      expired: new Date(),
                      members: 0,
                      name: SeparateClan.name,
                      xp: 0,
                      place: 0,
                    };
                  }

                  setClans(copyClan);
                } else {
                  toast.error("Что-то пошло не так");
                }
              }}
            >
              Войти в клан
            </Button>
          ) : SeparateClan &&
            SeparateClan?.members.length >= SeparateClan?.capacity &&
            Clans?.myClan == null ? (
            <Button
              height="5rem"
              color={"blue"}
              variant="default"
              disabled={true}
            >
              Клан переполнен
            </Button>
          ) : Clans &&
            Clans.myClan?.id == SeparateClan?.id &&
            Clans.myClan?.role !== ClanRolesEnum.Requested ? (
            <StandartFlexG1 Member={true}>
              <Button
                height={"5rem"}
                color="shadow"
                variant={"default"}
                style={{ borderRadius: "1.5rem" }}
                params={{ width: "100%" }}
                onClick={() => setMode(2)}
              >
                Продлить клан на 30 дней
              </Button>
              <Button
                height={"5rem"}
                color="shadow"
                variant={"default"}
                params={{ width: "100%" }}
                style={{ borderRadius: "1.5rem" }}
                onClick={() => {
                  setisActiveExitClan(true);
                }}
              >
                Покинуть клан
              </Button>
            </StandartFlexG1>
          ) : null}
        </BlockClan>
        {Clans?.myClan?.id == SeparateClan?.id &&
        Clans &&
        Clans.myClan?.role !== ClanRolesEnum.Requested ? (
          <BlockModes>
            <OneMode
              Active={mode == 0}
              onClick={() => {
                setMode(0);
              }}
            >
              Главная
            </OneMode>
            {Clans &&
            Clans.myClan?.id == SeparateClan?.id &&
            SeparateClan?.members &&
            (SeparateClan?.members.find(
              (item) => item.user.steamid64 == user?.user?.steamid64
            )?.role == ClanRolesEnum.Creator ||
              SeparateClan?.members.find(
                (item) => item.user.steamid64 == user?.user?.steamid64
              )?.role == ClanRolesEnum.Helper) ? (
              <OneMode
                Active={mode == 1}
                onClick={() => {
                  setMode(1);
                }}
              >
                Заявки
              </OneMode>
            ) : null}

            <OneMode
              Active={mode == 2}
              onClick={() => {
                setMode(2);
              }}
            >
              Магазин
            </OneMode>
            {Clans.myClan?.id == SeparateClan?.id &&
            SeparateClan?.members &&
            (SeparateClan?.members.find(
              (item) => item.user.steamid64 == user?.user?.steamid64
            )?.role == ClanRolesEnum.Creator ||
              SeparateClan?.members.find(
                (item) => item.user.steamid64 == user?.user?.steamid64
              )?.role == ClanRolesEnum.Helper) ? (
              <OneMode
                Active={mode == 3}
                onClick={() => {
                  setMode(3);
                }}
              >
                Настройки
              </OneMode>
            ) : null}
          </BlockModes>
        ) : null}

        {/* Проверка на наличие меня в клане и отрисовка заданий если есть я */}
        {mode == 0 ? (
          <WrapBlockTaskMember>
            {Clans &&
            Clans.myClan?.id == SeparateClan?.id &&
            Clans.myClan?.role !== ClanRolesEnum.Requested ? (
              <BlockTask>
                <BigText>Задания</BigText>
                <StandartFlexG1>
                  <MediumText style={{ opacity: "60%" }}>
                    До обновления
                  </MediumText>
                  <StandartFlexG1 flexDir={true} Task={true}>
                    <BlockTime>{timeRemaining.day}д</BlockTime>:
                    <BlockTime>{timeRemaining.hours}ч</BlockTime>:
                    <BlockTime>{timeRemaining.minutes}м</BlockTime>:
                    <BlockTime>{timeRemaining.seconds}с</BlockTime>
                  </StandartFlexG1>
                </StandartFlexG1>
                <StandartFlexG1
                  style={{ flexDirection: "column", gap: "0.5rem" }}
                >
                  {TaskClan?.tasks &&
                    TaskClan.tasks.map((item) => {
                      return (
                        <WrapOneTask>
                          <MediumText al={true}>
                            {SearchTaskEnum(
                              item.type,
                              item.target,
                              Number(item.description)
                            )}
                          </MediumText>
                          <StandartFlexG1>
                            <MediumText>
                              {item.type == TaskEnum.PlayTimeMode ||
                              item.type == TaskEnum.Playtime
                                ? Math.round(item.stats / 60)
                                : item.stats}
                              /
                              {item.type == TaskEnum.PlayTimeMode ||
                              item.type == TaskEnum.Playtime
                                ? item.target / 60
                                : item.target}
                            </MediumText>
                            <BlockExpAdd>
                              {item.stats >= item.target ? (
                                <MediumText>
                                  <Icons.EarnedBP />
                                </MediumText>
                              ) : (
                                <>
                                  <MediumText>+{item.exp}</MediumText>
                                  <TinyIcons.XpClans />
                                </>
                              )}
                            </BlockExpAdd>
                          </StandartFlexG1>
                        </WrapOneTask>
                      );
                    })}
                </StandartFlexG1>
              </BlockTask>
            ) : null}

            <BlockFullMembers>
              <StandartFlexG1 style={{ justifyContent: "space-between" }}>
                <StandartFlexG1 style={{ alignItems: "center", gap: "0.5rem" }}>
                  <BigText>Участники</BigText>
                  <MediumText style={{ opacity: "60%" }}>
                    (
                    {
                      SeparateClan?.members?.filter(
                        (item) => item.role !== ClanRolesEnum.Requested
                      ).length
                    }
                    )
                  </MediumText>
                </StandartFlexG1>

                <BlockExpGife>
                  <StandartFlexG1 flexDir={true}>
                    <MediumText style={{ color: "#FFC526" }}>
                      {SeparateClan?.xp}
                    </MediumText>
                    <TinyIcons.XpClans />
                  </StandartFlexG1>
                  {Clans &&
                  Clans.myClan?.role !== ClanRolesEnum.Requested &&
                  Clans?.myClan?.id == SeparateClan?.id ? (
                    <BlockAddBut onClick={() => setActiveGivePointModal(true)}>
                      <Icons.Add />
                    </BlockAddBut>
                  ) : null}
                </BlockExpGife>
              </StandartFlexG1>
              <BlockArrUser>
                {SeparateClan?.members &&
                  SeparateClan.members
                    .filter((item) => item.role !== ClanRolesEnum.Requested)
                    .map((item, position) => {
                      if (item.role !== ClanRolesEnum.Requested) {
                        return (
                          <OneMember
                            isYou={user?.user?.steamid64 == item.user.steamid64}
                          >
                            <StandartFlexG1
                              style={{ gap: "2rem", cursor: "pointer" }}
                              onClick={() =>
                                navigate(`/profile/${item.user.steamid64}`)
                              }
                              Member={true}
                            >
                              <DivBg>
                                <MediumText>{position + 1}</MediumText>
                              </DivBg>
                              <Avatar src={item.user.avatar} size="small" />
                              <MediumText>{item.user.name}</MediumText>
                            </StandartFlexG1>

                            <StandartFlexG1
                              style={{
                                gap: "2rem",
                              }}
                            >
                              <StandartFlexG1>
                                {item.role == ClanRolesEnum.Creator ? (
                                  <TinyIcons.Crown
                                    style={{
                                      fill: "#FFC526",
                                      stroke: "#FFC526",
                                    }}
                                  />
                                ) : null}

                                {Clans &&
                                Clans.myClan?.id == SeparateClan.id &&
                                Clans.myClan?.role == ClanRolesEnum.Creator ? (
                                  <StandartFlexG1>
                                    {item.role !== ClanRolesEnum.Creator ? (
                                      <StandartFlexG1
                                        style={{
                                          gap: "2rem",
                                          width: "fit-content",
                                        }}
                                      >
                                        <StandartFlexG1
                                          onClick={() =>
                                            setIdMember(item.user.id)
                                          }
                                          style={{ width: "25rem" }}
                                        >
                                          <UIKIT.Select
                                            defaultValue={
                                              item.role == ClanRolesEnum.Member
                                                ? "Участник"
                                                : "Заместитель"
                                            }
                                            onChange={setUpdateMember}
                                            items={["Участник", "Заместитель"]}
                                            headParams={{
                                              title: "Класс:",

                                              icon: <TinyIcons.Arrow />,
                                            }}
                                            Clans={true}
                                            // Rating={true}
                                          />
                                        </StandartFlexG1>
                                        <Button
                                          color="shadow"
                                          height="5rem"
                                          variant="default"
                                          style={{ borderRadius: "1.5rem" }}
                                          params={{ width: "15rem" }}
                                          onClick={() => {
                                            UpdateMember(
                                              item.user.id,
                                              ClanMemberAction.Kick
                                            );
                                          }}
                                        >
                                          <TinyIcons.Close
                                            style={{ fillOpacity: "100%" }}
                                          />
                                          Кикнуть
                                        </Button>
                                      </StandartFlexG1>
                                    ) : (
                                      <MediumText>
                                        {item.role == ClanRolesEnum.Creator
                                          ? "Основатель"
                                          : item.role == ClanRolesEnum.Helper
                                          ? "Заместитель"
                                          : "Участник"}
                                      </MediumText>
                                    )}
                                  </StandartFlexG1>
                                ) : (
                                  <MediumText
                                    style={
                                      item.role == ClanRolesEnum.Member
                                        ? { opacity: "60%" }
                                        : { opacity: "100%" }
                                    }
                                  >
                                    {item.role == ClanRolesEnum.Creator
                                      ? "Основатель"
                                      : item.role == ClanRolesEnum.Helper
                                      ? "Заместитель"
                                      : "Участник"}
                                  </MediumText>
                                )}
                              </StandartFlexG1>
                              <StandartFlexG1>
                                <TinyIcons.XpClans />
                                <MediumText style={{ color: "#FFC526" }}>
                                  {item.xp}
                                </MediumText>
                              </StandartFlexG1>
                            </StandartFlexG1>
                          </OneMember>
                        );
                      } else {
                        return null;
                      }
                    })}
              </BlockArrUser>
            </BlockFullMembers>
          </WrapBlockTaskMember>
        ) : mode == 1 ? (
          <BlockFullMembers>
            <StandartFlexG1
              style={{
                justifyContent: "space-between",
                padding: "1.5rem 1.5rem",
              }}
            >
              <StandartFlexG1>
                <BigText>Заявки на вступление</BigText>
                <MediumText style={{ opacity: "60%" }}>
                  (
                  {
                    SeparateClan?.requested.filter(
                      (item) => item.role == ClanRolesEnum.Requested
                    ).length
                  }
                  )
                </MediumText>
              </StandartFlexG1>
              {SeparateClan &&
              SeparateClan?.requested.filter(
                (item) => item.role == ClanRolesEnum.Requested
              ).length !== 0 ? (
                <StandartFlexG1
                  style={{ cursor: "pointer" }}
                  flexDir={true}
                  onClick={async () => {
                    const result = await ClansServise.AcceptAndCancelClan(
                      1,
                      0,
                      true
                    );
                    if (result == true) {
                      const copyClan = { ...SeparateClan };
                      copyClan.requested = copyClan.requested.filter((item) => {
                        return item.role !== ClanRolesEnum.Requested;
                      });
                      setSeparateClan(copyClan);
                      toast.success("Успешно");
                    } else {
                      toast.error("Что-то пошло не так, повторите попытку");
                    }
                  }}
                >
                  <TinyIcons.Close
                    style={{ fill: "white", fillOpacity: "100%" }}
                  />

                  <MediumText>Отклонить всех</MediumText>
                </StandartFlexG1>
              ) : null}
            </StandartFlexG1>
            {SeparateClan?.requested.filter(
              (item) => item.role == ClanRolesEnum.Requested
            ).length == 0 ? (
              <BigText
                style={{
                  textAlign: "center",
                  marginTop: "5rem",
                  marginBottom: "5rem",
                }}
              >
                Нет активных заявок
              </BigText>
            ) : null}
            {SeparateClan &&
              SeparateClan.requested.map((item) => {
                if (item.role == ClanRolesEnum.Requested) {
                  return (
                    <OneMember isYou={false}>
                      <StandartFlexG1
                        onClick={() =>
                          navigate(`/profile/${item.user.steamid64}`)
                        }
                      >
                        <Avatar src={item.user.avatar} size="small" />
                        <MediumText>{item.user.name}</MediumText>
                      </StandartFlexG1>
                      <StandartFlexG1>
                        <Button
                          color="shadow"
                          height="5rem"
                          variant="default"
                          style={{ borderRadius: "1.5rem" }}
                          onClick={async () => {
                            if (
                              SeparateClan.members.length <
                              SeparateClan.capacity
                            ) {
                              const result =
                                await ClansServise.AcceptAndCancelClan(
                                  item.user.id,
                                  1
                                );
                              if (result == true) {
                                updateMembers(true, item.id, false);
                                toast.success("Успешно");
                              } else {
                                toast.error("Что-то пошло не так...");
                              }
                            } else {
                              toast.error("Клан переполнен");
                            }
                          }}
                        >
                          Принять
                        </Button>
                        <Button
                          color="shadow"
                          height="5rem"
                          variant="default"
                          style={{ borderRadius: "1.5rem" }}
                          onClick={async () => {
                            const result =
                              await ClansServise.AcceptAndCancelClan(
                                item.user.id,
                                0
                              );
                            if (result == true) {
                              updateMembers(true, item.id, true);
                              toast.success("Успешно");
                            } else {
                              toast.error("Что-то пошло не так...");
                            }
                          }}
                        >
                          Отклонить
                        </Button>
                      </StandartFlexG1>
                    </OneMember>
                  );
                }
              })}
          </BlockFullMembers>
        ) : mode == 2 ? (
          <BlockFullMembers>
            <BigText>Магазин</BigText>
            {shop &&
              shop.map((item) => {
                if (
                  Clans?.myClan?.role == ClanRolesEnum.Member &&
                  (item.type == ClanShopEnum.Extend ||
                    item.type == ClanShopEnum.Points ||
                    item.type == ClanShopEnum.Slots)
                ) {
                  return (
                    <WrapOneMarket>
                      <MediumText
                        style={
                          item.type == ClanShopEnum.Points
                            ? {
                                color: "#ffc526",
                                alignItems: "center",
                                display: "flex",
                                gap: "1rem",
                              }
                            : { color: "" }
                        }
                      >
                        {item.type == ClanShopEnum.Points ? (
                          <TinyIcons.XpClans />
                        ) : null}
                        {item.type == ClanShopEnum.Slots
                          ? `Купить ${item.value} экстра-слотов`
                          : // : item.type == ClanShopEnum.Avatar
                          // ? "Смена аватарки"
                          // : item.type == ClanShopEnum.ClanTag
                          // ? "Смена клан тега"
                          item.type == ClanShopEnum.Extend
                          ? `Продлить действие клана на ${
                              item.value / 60 / 60 / 24
                            }  дней`
                          : // : item.type == ClanShopEnum.Name
                          // ? "Смена названия клана"
                          item.type == ClanShopEnum.Points
                          ? "КУПИТЬ ОЧКИ"
                          : ""}
                      </MediumText>
                      <StandartFlexG1 style={{ gap: "2rem" }}>
                        <StandartFlexG1>
                          <MediumText>{item.price}</MediumText>
                          <TinyIcons.Rub />
                        </StandartFlexG1>
                        <Button
                          height={"5rem"}
                          variant={"default"}
                          color="blue"
                          params={{ width: "20rem" }}
                          onClick={() => {
                            if (item.type == ClanShopEnum.Points) {
                              // console.log(1);
                              setActiveModalBuyXP(true);
                            } else {
                              setActiveMarket(true);
                              setShopActiveModal(item);
                            }
                          }}
                        >
                          Купить
                        </Button>
                      </StandartFlexG1>
                    </WrapOneMarket>
                  );
                } else if (
                  Clans?.myClan?.role == ClanRolesEnum.Creator ||
                  Clans?.myClan?.role == ClanRolesEnum.Helper
                ) {
                  return (
                    <WrapOneMarket>
                      <MediumText
                        style={
                          item.type == ClanShopEnum.Points
                            ? {
                                color: "#ffc526",
                                alignItems: "center",
                                display: "flex",
                                gap: "1rem",
                              }
                            : { color: "" }
                        }
                      >
                        {item.type == ClanShopEnum.Points ? (
                          <TinyIcons.XpClans />
                        ) : null}
                        {item.type == ClanShopEnum.Slots
                          ? `Купить ${item.value} экстра-слотов`
                          : item.type == ClanShopEnum.Avatar
                          ? "Смена аватарки"
                          : item.type == ClanShopEnum.ClanTag
                          ? "Смена клан тега"
                          : item.type == ClanShopEnum.Extend
                          ? `Продлить действие клана на ${
                              item.value / 60 / 60 / 24
                            }  дней`
                          : item.type == ClanShopEnum.Name
                          ? "Смена названия клана"
                          : item.type == ClanShopEnum.Points
                          ? "КУПИТЬ ОЧКИ"
                          : ""}
                      </MediumText>
                      <StandartFlexG1 style={{ gap: "2rem" }}>
                        <StandartFlexG1>
                          <MediumText>{item.price}</MediumText>
                          <TinyIcons.Rub />
                        </StandartFlexG1>
                        <Button
                          height={"5rem"}
                          variant={"default"}
                          color="blue"
                          params={{ width: "20rem" }}
                          onClick={() => {
                            if (item.type == ClanShopEnum.Points) {
                              // console.log(1);
                              setActiveModalBuyXP(true);
                            } else {
                              setActiveMarket(true);
                              setShopActiveModal(item);
                            }
                          }}
                        >
                          Купить
                        </Button>
                      </StandartFlexG1>
                    </WrapOneMarket>
                  );
                }
              })}
          </BlockFullMembers>
        ) : mode == 3 ? (
          <BlockFullMembers style={{ gap: "2rem" }}>
            <BigText>Настройка</BigText>
            <StandartFlexG1>
              <UIKIT.Toggle
                label=""
                onClick={() => setClosedClan(!ClosedClan)}
                onChange={() => {
                  SeparateClan && ClansServise.ChangeStatus(SeparateClan.id);
                }}
                checked={ClosedClan && ClosedClan}
              />

              <MediumText style={{ fontWeight: "normal" }}>
                Закрыть заявки в клан
              </MediumText>
            </StandartFlexG1>
          </BlockFullMembers>
        ) : null}
      </FullBlock>
      <Modals.ExitClan
        isActive={isActiveExitClan}
        setActive={setisActiveExitClan}
      />
      <Modals.BuyInMarket
        isActive={isActiveMarket}
        setActive={setActiveMarket}
        shopItem={ShopActiveModal}
        setSeparateClan={setSeparateClan}
        ClansSeparete={SeparateClan}
      />
      <Modals.GivePoint
        isActive={ActiveGivePointModal}
        setActive={setActiveGivePointModal}
        setMode={setMode}
        id={SeparateClan?.id}
      />
      <Modals.BuyModalClans
        isActive={ActiveModalBuyXP}
        setActive={setActiveModalBuyXP}
        setSeparateClan={setSeparateClan}
        SeparateClan={SeparateClan}
        shop={shop}
      />
    </Layout>
  );
}
export default memo(ClanId);
