import ModalWrapper from "components/Templates/ModalWrapper";
import { ClansArray, ClanShopEnum, SeparateClan, Shop } from "interfaces/Clans";
import { BigText, Container, MediumText, StandartFlex } from "./styled";
import { useEffect, useState } from "react";
import Button from "components/UI/Button";
import UIKIT from "components/UI";
import { toast } from "react-toastify";
import { TinyIcons } from "assets/tiny-icons";
import { ClansServise } from "API/ClansServise";
import { useAppSelector } from "hooks/useAppSelector";
import { useActions } from "hooks/useActions";

interface Props {
  isActive: boolean;
  setActive: React.SetStateAction<any>;
  shopItem?: Shop;
  setSeparateClan?: React.SetStateAction<any>;
  ClansSeparete?: SeparateClan;
}

export default function BuyInMarket({
  isActive,
  setActive,
  shopItem,
  setSeparateClan,
  ClansSeparete,
}: Props) {
  const [mode, setMode] = useState<boolean>(false);
  const [value, setValue] = useState<string>("");
  const { user } = useAppSelector((state) => state.user);
  const { setBalance } = useActions();
  function TextVariantMode1() {
    if (!shopItem) return;

    switch (shopItem.type) {
      case ClanShopEnum.Avatar:
        return "Вставьте ссылку на аватарку (Статичную или GIF)";
      case ClanShopEnum.ClanTag:
        return "Введите клан тег";
      case ClanShopEnum.Extend:
        return `Вы действительно хотите продлить клан на ${
          shopItem.value / 60 / 60 / 24
        } дней?`;
      case ClanShopEnum.Name:
        return "Введите название клана";
      case ClanShopEnum.Points:
        return `Вы действительно хотите купить ${shopItem.value} очков?`;
      case ClanShopEnum.Slots:
        return `Вы действительно хотите купить ${shopItem.value} слотов?`;
    }
  }
  function TextVariantMode2() {
    if (!shopItem) return;

    switch (shopItem.type) {
      case ClanShopEnum.Avatar:
        return "Вы действительно хотите поменять аватарку?";
      case ClanShopEnum.ClanTag:
        return `Вы действительно хотите поменять клан тег на "${value}"?`;

      case ClanShopEnum.Name:
        return `Вы действительно хотите поменять название клана на "${value}"?`;
    }
  }
  useEffect(() => {
    setMode(false);
    setValue("");
  }, [isActive]);

  async function RequestBuy() {
    if (!shopItem) return;
    const result = await ClansServise.BuyImMarket(
      shopItem?.id,
      value,
      shopItem?.type
    );
    if (result == true) {
      switch (shopItem.type) {
        case ClanShopEnum.Avatar: {
          if (!ClansSeparete) return;
          const copyClan = { ...ClansSeparete };
          copyClan.avatar = value;
          setSeparateClan(copyClan);
          setBalance({
            real: user?.balance?.real - shopItem.price,
            virtual: user?.balance?.virtual,
          });
          toast.success("Успешно");
          setActive(false);
          return;
        }
        case ClanShopEnum.Name: {
          if (!ClansSeparete) return;
          const copyClan = { ...ClansSeparete };
          copyClan.name = value;
          setSeparateClan(copyClan);
          setBalance({
            real: user?.balance?.real - shopItem.price,
            virtual: user?.balance?.virtual,
          });
          toast.success("Успешно");
          setActive(false);
          return;
        }
        case ClanShopEnum.ClanTag: {
          if (!ClansSeparete) return;
          const copyClan = { ...ClansSeparete };
          copyClan.clanTag = value;
          setSeparateClan(copyClan);
          setBalance({
            real: user?.balance?.real - shopItem.price,
            virtual: user?.balance?.virtual,
          });
          toast.success("Успешно");
          setActive(false);
          return;
        }
        case ClanShopEnum.Extend: {
          if (!ClansSeparete) return;
          const copyClan = { ...ClansSeparete };
          let day = new Date(copyClan.expired);
          day.setTime(day.getTime() + shopItem.value * 1000);
          copyClan.expired = day;
          setSeparateClan(copyClan);
          setBalance({
            real: user?.balance?.real - shopItem.price,
            virtual: user?.balance?.virtual,
          });
          toast.success("Успешно");
          setActive(false);
          return;
        }
        case ClanShopEnum.Points: {
          if (!ClansSeparete) return;
          const copyClan = { ...ClansSeparete };
          copyClan.xp = copyClan.xp + shopItem.value;
          setSeparateClan(copyClan);
          setBalance({
            real: user?.balance?.real - shopItem.price,
            virtual: user?.balance?.virtual,
          });
          toast.success("Успешно");
          setActive(false);
          return;
        }
        case ClanShopEnum.Slots: {
          if (!ClansSeparete) return;
          const copyClan = { ...ClansSeparete };
          copyClan.capacity = copyClan.capacity + shopItem.value;
          setSeparateClan(copyClan);
          setBalance({
            real: user?.balance?.real - shopItem.price,
            virtual: user?.balance?.virtual,
          });
          toast.success("Успешно");
          setActive(false);
          return;
        }
      }
    } else if (result == "ThrottlerException: Too Many Requests") {
      toast.error("Не так быстро...");
      setActive(false);
    } else if (result == "You have not enough balance") {
      toast.error("Недостаточно баланса");
      setActive(false);
    }
  }

  return (
    <ModalWrapper
      headContent={
        mode == true &&
        shopItem?.type !== ClanShopEnum.Extend &&
        shopItem?.type !== ClanShopEnum.Points &&
        shopItem?.type !== ClanShopEnum.Slots
          ? "Подтверждение"
          : mode == false &&
            shopItem?.type !== ClanShopEnum.Avatar &&
            shopItem?.type !== ClanShopEnum.Name &&
            shopItem?.type !== ClanShopEnum.ClanTag
          ? "Подтверждение"
          : ""
      }
      display="flex"
      isActive={isActive}
      setActive={setActive}
      params={{ height: "max-content" }}
    >
      <Container>
        {mode == false &&
        shopItem?.type !== ClanShopEnum.Avatar &&
        shopItem?.type !== ClanShopEnum.ClanTag &&
        shopItem?.type !== ClanShopEnum.Name ? (
          <StandartFlex style={{ flexDirection: "column" }}>
            <BigText>{TextVariantMode1()}</BigText>
            <StandartFlex style={{ justifyContent: "center" }}>
              <BigText>{shopItem?.price}</BigText>
              <TinyIcons.Rub />
            </StandartFlex>
          </StandartFlex>
        ) : mode == false &&
          shopItem?.type !== ClanShopEnum.Extend &&
          shopItem?.type !== ClanShopEnum.Points &&
          shopItem?.type !== ClanShopEnum.Slots ? (
          <MediumText>{TextVariantMode1()}</MediumText>
        ) : mode == true &&
          shopItem?.type !== ClanShopEnum.Extend &&
          shopItem?.type !== ClanShopEnum.Points &&
          shopItem?.type !== ClanShopEnum.Slots ? (
          <BigText>{TextVariantMode2()}</BigText>
        ) : null}
        {mode == false &&
        shopItem?.type !== ClanShopEnum.Avatar &&
        shopItem?.type !== ClanShopEnum.ClanTag &&
        shopItem?.type !== ClanShopEnum.Name ? (
          <StandartFlex>
            <Button
              variant="default"
              color="blue"
              height="5rem"
              params={{ width: "100%" }}
              onClick={() => {
                RequestBuy();
              }}
            >
              Купить
            </Button>
            <Button
              variant="default"
              color="shadow"
              height="5rem"
              params={{ width: "100%" }}
              onClick={() => setActive(false)}
            >
              Отмена
            </Button>
          </StandartFlex>
        ) : mode == false &&
          shopItem?.type !== ClanShopEnum.Extend &&
          shopItem?.type !== ClanShopEnum.Points &&
          shopItem?.type !== ClanShopEnum.Slots ? (
          <StandartFlex style={{ flexDirection: "column" }}>
            <UIKIT.Input
              params={{ width: "100%" }}
              type="text"
              placeholder={
                shopItem?.type == ClanShopEnum.Avatar
                  ? "Вставьте ссылку"
                  : shopItem?.type == ClanShopEnum.ClanTag
                  ? "Введите клан тег"
                  : shopItem?.type == ClanShopEnum.Name
                  ? "Введите название"
                  : ""
              }
              onChange={(e) => setValue(e.target.value)}
            />
            <Button
              variant="default"
              color="blue"
              height="5rem"
              params={{ width: "100%" }}
              onClick={() => {
                if (value) {
                  setMode(true);
                } else {
                  toast.error(
                    shopItem?.type == ClanShopEnum.Avatar
                      ? "Введите ссылку"
                      : "Введите название"
                  );
                }
              }}
            >
              Продолжить
            </Button>
          </StandartFlex>
        ) : mode == true &&
          shopItem?.type !== ClanShopEnum.Extend &&
          shopItem?.type !== ClanShopEnum.Points &&
          shopItem?.type !== ClanShopEnum.Slots ? (
          <StandartFlex style={{ flexDirection: "column" }}>
            <StandartFlex style={{ justifyContent: "center" }}>
              <BigText>{shopItem?.price}</BigText>
              <TinyIcons.Rub />
            </StandartFlex>
            <StandartFlex>
              <Button
                variant="default"
                color="blue"
                height="5rem"
                params={{ width: "100%" }}
                onClick={() => {
                  RequestBuy();
                }}
              >
                Купить
              </Button>
              <Button
                variant="default"
                color="shadow"
                height="5rem"
                params={{ width: "100%" }}
                onClick={() => setActive(false)}
              >
                Отмена
              </Button>
            </StandartFlex>
          </StandartFlex>
        ) : null}
      </Container>
    </ModalWrapper>
  );
}
