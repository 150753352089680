import _ from "lodash";
import { observer } from "mobx-react-lite";
import ModalWrapper from "components/Templates/ModalWrapper";
import { Container, ButtonContainer, Wait } from "./Styles";
import TradeColumn from "./components/TradeColumn";
import TradeRow from "./components/TradeRow";
import UIKIT from "components/UI";
import { Icons } from "assets/icons";
import { ITrade, ITradeUpdate, TradeStatus } from "interfaces/Trade";
import { useAppSelector } from "hooks/useAppSelector";
import { useActions } from "hooks/useActions";
import { IInventoryWeaponCard, IItemSend } from "models/InventoryWeaponCard";
import TradeState from "./TradeState";
import { SocketProvider, TradeAction } from "API/SocketProvider";
import API from "API";
import { useEffect, useState } from "react";
interface Props {
  isActive: boolean;
  setActive: React.SetStateAction<any>;
  // data: ITrade;
  // update?: ITradeUpdate;
  // type: any /*ModalType*/;
}

const TradeModal = ({ isActive, setActive }: Props) => {
  const { setShowModal } = useActions();
  const socketProvider = new SocketProvider();
  const isSender = TradeState.tradeData.sender;

  const isFirstModalState =
    TradeState.tradeData.status == TradeStatus.NoConfirmed ||
    TradeState.tradeData.status == TradeStatus.SenderAgree ||
    TradeState.tradeData.status == TradeStatus.ReceiverAgree;

  const cancel = () => {
    API.TradeService.cancel(TradeState.tradeData.uuid);
    // fetchCancelTrade(trade.uuid)
    //console.log(TradeState.tradeData.uuid);
    setShowModal(false);
  };

  const getLeftTitle = () => {
    if (!isFirstModalState) {
      return "Отдаёте";
    }

    return "Инвентарь";
  };

  const getLeftSkins = () => {
    if (!isFirstModalState) {
      return TradeState.tradeData.myItems;
    }

    return TradeState.tradeData.myInventory;
  };

  const getRightTitle = () => {
    if (!isFirstModalState) {
      return "Получаете";
    }

    return "На обмен";
  };

  const getRightSkins = () => {
    if (!isFirstModalState) {
      return TradeState.tradeData.receivedItems;
    }

    return TradeState.tradeData.myItems;
  };

  const renderRow = () => {
    if (!isFirstModalState) {
      return;
    }

    return <TradeRow data={TradeState.tradeData.receivedItems} />;
  };

  const handleLeftItem = (item: IItemSend) => {
    if (isFirstModalState) {
      socketProvider.changeItem(
        TradeState.tradeData.uuid,
        item.id,
        TradeAction.ADD
      );
    }
  };

  const handleRightItem = (item: IItemSend) => {
    if (isFirstModalState) {
      socketProvider.changeItem(
        TradeState.tradeData.uuid,
        item.id,
        TradeAction.DELETE
      );
    }
  };

  const confirmToggle = () => {
    if (!isFirstModalState) {
      return socketProvider.apply(TradeState.tradeData.uuid);
    }

    socketProvider.confirmationTrade(TradeState.tradeData.uuid);
  };

  const renderFooter = () => {
    // if (type !== ModalType.trade) {
    //   return (
    //     <ButtonContainer>
    //       <UIKIT.Button
    //         style={{ width: "30rem" }}
    //         color='blue'
    //         height='5rem'
    //         variant='default'
    //         onClick={() => {
    //           setActive(false)
    //         }}
    //       >Закрыть</UIKIT.Button>
    //     </ButtonContainer>
    //   )
    // }

    const getFooterText = () => {
      // const isConfirm = confirmStatus == EConfirmList.confirm
      // if (confirmStatus >= EConfirmList.all && isConfirm && !partnerApply)
      //   return <></>

      // if (partnerApply && !isConfirm) {
      //   return <Wait>Ваш партнер подтвердил обмен, ожидание вашего подтверждения...</Wait>
      // } else if (isConfirm && !partnerApply) {
      //   return <Wait>Ожидание партнёра...</Wait>
      // } else if (isConfirm && partnerApply) {
      //   return <></>
      // }

      // if (TradeState.tradeData.status == TradeStatus.)

      // if (TradeState.tradeData.status == TradeStatus.) {
      //   return 'Получаете'
      // }

      // if (isFirstModalState) {

      if (TradeState.tradeData.status == TradeStatus.SenderAgree) {
        if (isSender) {
          return <Wait>Ожидание партнёра...</Wait>;
        } else {
          return <Wait>Партнёр отправил предложение и ожидает вас.</Wait>;
        }
      }

      if (TradeState.tradeData.status == TradeStatus.ReceiverAgree) {
        if (isSender) {
          return <Wait>Партнёр отправил предложение и ожидает вас.</Wait>;
        } else {
          return <Wait>Ожидание партнёра...</Wait>;
        }
      }

      if (
        TradeState.tradeData.status == TradeStatus.BothAgree &&
        isFirstModalState
      ) {
        return <Wait>Предложения приняты, ожидайте...</Wait>;
      }
      if (TradeState.tradeData.status == TradeStatus.SenderApplied) {
        if (isSender) {
          return <Wait>Ожидание партнёра...</Wait>;
        } else {
          return (
            <Wait>
              Партнёр подтвердил обмен. Ожидание вашего подтверждения.
            </Wait>
          );
        }
      }

      if (TradeState.tradeData.status == TradeStatus.ReceiverApplied) {
        if (isSender) {
          return (
            <Wait>
              Партнёр подтвердил обмен. Ожидание вашего подтверждения.
            </Wait>
          );
        } else {
          return <Wait>Ожидание партнёра...</Wait>;
        }
      }

      // }

      //   if (TradeState.tradeData.sender && TradeState.tradeData.status == TradeStatus.SenderAgree) {
      //     return <Wait>Ожидание партнёра...</Wait>
      //   }

      //   if (TradeState.tradeData.status == TradeStatus.ReceiverAgree) {
      //     return <Wait>Партнёр отправил предложение и ожидает вас.</Wait>
      //   }

      //   if (TradeState.tradeData.status == TradeStatus.BothAgree) {
      //     return <Wait>Предложения приняты, ожидайте...</Wait>
      //   }
      // }

      // if (TradeState.tradeData.status == TradeStatus.SenderApplied) {
      //   return <Wait>Ожидание партнёра...</Wait>
      // }

      // if (TradeState.tradeData.status == TradeStatus.ReceiverApplied) {
      //   return <Wait>Предложения приняты, ожидайте...</Wait>
      // }

      return "";
    };

    return (
      <div>
        {getFooterText()}
        <ButtonContainer>
          <UIKIT.Button
            style={{ width: "30rem" }}
            color="blue"
            height="5rem"
            variant="default"
            onClick={confirmToggle}
            disabled={
              (TradeState.tradeData.status === TradeStatus.SenderAgree &&
                isSender !== undefined) ||
              (TradeState.tradeData.status === TradeStatus.SenderApplied &&
                isSender !== undefined) ||
              (TradeState.tradeData.status === TradeStatus.ReceiverApplied &&
                isSender === undefined) ||
              (TradeState.tradeData.status === TradeStatus.ReceiverAgree &&
                isSender === undefined)
            }
          >
            Подтвердить
          </UIKIT.Button>
          <UIKIT.Button
            style={{ width: "30rem" }}
            color="red"
            height="5rem"
            variant="default"
            onClick={cancel}
          >
            Отменить
          </UIKIT.Button>
        </ButtonContainer>
      </div>
    );
  };

  return (
    <ModalWrapper
      isActive={isActive}
      setActive={setActive}
      headContent={"Обмен с игроком"}
      params={{
        width: "145.9rem",
        height: "fit-content",
      }}
      tradeInfo={{
        name: TradeState.tradeData.name,
        avatar: TradeState.tradeData.avatar,
      }}
      display="flex"
    >
      <>
        <Container>
          <TradeColumn
            avatar={TradeState.tradeData.avatar}
            kolItem={5}
            text={getLeftTitle()}
            data={getLeftSkins()}
            onClick={handleLeftItem}
          />
          <Icons.Swap />
          <TradeColumn
            kolItem={5}
            avatar={TradeState.tradeData.avatar}
            text={getRightTitle()}
            data={getRightSkins()}
            onClick={handleRightItem}
          />
        </Container>
        {renderRow()}
        {renderFooter()}
      </>

      {/* <Modals.TradeSelectorSkins isActive={TradeState.hasSkinCountModal} setActive={TradeState.setSkinCounterModalStatus} /> */}
    </ModalWrapper>
  );
};

export default observer(TradeModal);
