import styled from "styled-components";
import { GradientKeys } from "styled-components";

export const Item = styled.div<{ rarity: GradientKeys }>`
  margin-right: 20px;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  font-size: 24px;
  color: #fff;
  z-index: -1;
  border-radius: 15px;
  min-width: 180px;
  height: 185px;
  background: ${(props) => props.theme.gradients[props.rarity]};
  gap: 15px;
  img {
    align-self: center;
  }
`;

export const BlockDiv = styled.div<{ rarity: GradientKeys }>`
  /* position: relative; */
  /* top: 0; */
  font-size: 3px;
  border-radius: 0 0 5rem 5rem;
  height: 0.5rem;
  width: 5rem;
  background: ${(props) => props.theme.colors.weapon[props.rarity]};
  color: ${(props) => props.theme.colors.weapon[props.rarity]};
  align-self: center;
`;
export const NameSkin = styled.div`
  display: flex;
  /* flex-wrap: wrap; */
  flex-direction: column;
  margin: 2px 0 4px 2rem;
`;
export const WeaponName = styled.span`
  color: ${(props) => props.theme.colors.white.white100};
  ${(props) => props.theme.fonts.GothamRegular14},
`;
export const SkinName = styled.span<{ rarity: GradientKeys }>`
  color: ${(props) => props.theme.colors.weapon[props.rarity]};
  ${(props) => props.theme.fonts.GothamBold14},
  text-wrap:wrap;
`;
