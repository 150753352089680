import {
  fetchMarketplace,
  fetchPutItem,
  fetchSalesItems,
  fetchRemoveItem,
  fetchBuyItems,
  fetchUpdateItem,
} from "./../slices/marketplace.slice";
import { fetchShop, shopActions } from "../slices/shop.slice";
import { fetchServers, serversActions } from "../slices/servers.slice";
import {
  fetchFriends,
  fetchFriendRequests,
  fetchFriendSent,
  fetchInventory,
  fetchMarketInventory,
  fetchInventoryWithCallback,
  fetchServerInventory,
  fetchLogin,
  userActions,
  fetchInventoryToServer,
  fetchInventoryFromServer,
} from "redux/slices/user.slice";
import {
  notificationsActions,
  fetchNotifications,
} from "redux/slices/notifications.slice";
import {
  fetchPrivateChatHistory,
  fetchPrivateUser,
  PrivateChatActions,
} from "redux/slices/privateChat.slice";
import { fetchChatHistory, ChatActions } from "redux/slices/chat.slice";
import { fetchHistory, tradeActions } from "redux/slices/trade.slice";

const asyncActions = {
  fetchLogin,
  fetchFriends,
  fetchFriendRequests,
  fetchFriendSent,
  fetchInventory,
  fetchMarketInventory,
  fetchInventoryWithCallback,
  fetchServerInventory,
  fetchServers,
  fetchShop,
  fetchNotifications,
  fetchPrivateChatHistory,
  fetchPrivateUser,
  fetchChatHistory,
  fetchMarketplace,
  fetchPutItem,
  fetchSalesItems,
  fetchInventoryToServer,
  fetchInventoryFromServer,
  fetchRemoveItem,
  fetchBuyItems,
  fetchUpdateItem,
  fetchHistory,
};

const ActionCreators = {
  ...userActions,
  ...serversActions,
  ...shopActions,
  ...notificationsActions,
  ...PrivateChatActions,
  ...ChatActions,
  ...asyncActions,
  ...tradeActions,
};

export default ActionCreators;
