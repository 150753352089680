import styled from "styled-components";

export const Container = styled.div`
  position: absolute;
  top: 10.3rem;
  left: 0;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  width: 40.8rem;
  padding: 0.9rem 0.8rem;
  background: ${(props) => props.theme.colors.white.white10};
  backdrop-filter: blur(5rem);
  border-radius: 1.5rem;
  z-index: 20;

  @media (max-width: 768px) {
    width: 32rem;
  }
`;

export const Stickers = styled.div`
  display: flex;
  flex-direction: column;
  text-wrap: wrap;
  /* max-height: 130px;
  overflow-y: scroll; */
`;
