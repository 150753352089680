import { configureStore } from "@reduxjs/toolkit";
import serversReducer from '../slices/servers.slice'
import userReducer from '../slices/user.slice'
import shopReducer from '../slices/shop.slice'
import notificationsReducer from '../slices/notifications.slice'
import privateChatReducer from '../slices/privateChat.slice'
import chatReducer from '../slices/chat.slice'
import applicationsReducer from '../slices/applications.slice'
import marketplaceReducer from "redux/slices/marketplace.slice"
import tradeReducer from "redux/slices/trade.slice"

export const store = configureStore({
	reducer: {
		servers: serversReducer,
		user: userReducer,
		shop: shopReducer,
		notifications: notificationsReducer,
		privateChat: privateChatReducer,
		chat: chatReducer,
		applications: applicationsReducer,
		marketplace: marketplaceReducer,
		trade: tradeReducer
	},
	middleware: getDefaultMiddelware => getDefaultMiddelware()
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch