import { Button } from "components/UI/Button/Styles";
import styled from "styled-components";

export const Grid = styled.div`
  display: grid;
  width: 100%;
  padding: 0.3rem;
  transition: all 0.3s;
  gap: 1.5rem;
  grid-template-columns: repeat(auto-fill, minmax(18.7rem, 1fr));
  max-height: 58vh;
  overflow-y: scroll;
`;
export const Center = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 3rem;

  ${Button} {
    width: 30rem;
  }
`;
