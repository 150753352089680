import _ from "lodash";
import { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import ModalWrapper from "components/Templates/ModalWrapper";
import HistoryColumn from "./components/HistoryColumn";
import UIKIT from "components/UI";
import { Icons } from "assets/icons";
import { ITrade } from "interfaces/Trade";
import { Container, ButtonContainer } from "./Styles";
import { useAppSelector } from "hooks/useAppSelector";
import { mySteamId } from "utils/IsYou";
import HistoryState from "./HistoryState";

interface Props {
  isActive: boolean;
  setActive: React.SetStateAction<any>;
}

const HistoryModal = ({ isActive, setActive }: Props) => {
  const getLeftColumnSkins = () => {
    return HistoryState.historySelect?.myItems || [];
    // if (tradeInfo?.sender !== mySteamId()) {
    //   return tradeInfo?.receiver_items || []
    // }

    // return tradeInfo?.sender_items || []
    //console.log(HistoryState.historySelect);
  };

  const getRightColumnSkins = () => {
    return HistoryState.historySelect?.receivedItems || [];
    // if (tradeInfo?.sender !== mySteamId()) {
    //   return tradeInfo?.sender_items || []
    // }

    // return tradeInfo?.receiver_items || []
    return [];
  };

  const renderFooter = () => {
    return (
      <ButtonContainer>
        <UIKIT.Button
          style={{ width: "30rem" }}
          color="blue"
          height="5rem"
          variant="default"
          onClick={() => {
            setActive(false);
          }}
        >
          Закрыть
        </UIKIT.Button>
      </ButtonContainer>
    );
  };

  return (
    <ModalWrapper
      isActive={isActive}
      setActive={setActive}
      headContent={"Результат обмена с игроком"}
      params={{
        width: "145.9rem",
        height: "fit-content",
      }}
      tradeInfo={{
        name: "",
        avatar: "",
      }}
      display="flex"
    >
      <>
        <Container>
          <HistoryColumn
            text={"Вы отдали"}
            data={getLeftColumnSkins()}
            onClick={() => {}}
          />
          <Icons.Swap />
          <HistoryColumn
            text={"Вы получили"}
            data={getRightColumnSkins()}
            onClick={() => {}}
          />
        </Container>
        {renderFooter()}
      </>
    </ModalWrapper>
  );
};

export default observer(HistoryModal);
