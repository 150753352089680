import { endpoints, headers, token } from "API";
import { url } from "API";
import axios from "axios";
import { SellItem } from "models/SellItem";
import { toast } from "react-toastify";

export class MarketplaceService {
  // //console.log("42342", page1);
  static async getMarketplace(
    page: number,
    perPage: number,
    classId?: number,
    weapon?: number,
    priceSort?: string,
    raritySort?: string
  ) {
    try {
      const response = await axios.post(
        url + endpoints.marketplace.get,
        { page, perPage, classId, weapon, priceSort, raritySort },
        {
          withCredentials: true,
          headers: headers,
        }
      );
      // //console.log("fds", response.data);
      return response.data;
    } catch (error: any) {
      console.log(error);
      if (error.response.data.message == "Not Acceptable. Reason: actibe ban") {
        toast.error("У вас активная блокировка");
      }
    }
  }
  static async putItem(item: SellItem) {
    try {
      const response = await axios.post(
        url + endpoints.marketplace.post,
        item,
        {
          withCredentials: true,
          headers: headers,
        }
      );
      return response.data;
    } catch (error: any) {
      console.log(error);
      if (error.response.data.message == "Not Acceptable. Reason: actibe ban") {
        toast.error("У вас активная блокировка");
      }
    }
  }
  static async sellingItems() {
    try {
      const response = await axios.get(url + endpoints.marketplace.sales, {
        withCredentials: true,
        headers: headers,
      });
      return response.data;
    } catch (error: any) {
      console.log(error);
      if (error.response.data.message == "Not Acceptable. Reason: actibe ban") {
        toast.error("У вас активная блокировка");
      }
    }
  }
  static async getFilters() {
    try {
      const response = await axios.get(url + endpoints.marketplace.filters, {
        withCredentials: true,
        headers: headers,
      });
      //console.log(response.data);
      return response.data;
    } catch (error: any) {
      console.log(error);
      if (error.response.data.message == "Not Acceptable. Reason: actibe ban") {
        toast.error("У вас активная блокировка");
      }
    }
  }
  static async buyItems(id: number | string) {
    try {
      const response = await axios.post(
        url + endpoints.marketplace.buy,
        { id },
        {
          withCredentials: true,
          headers: headers,
        }
      );
      return response.data;
    } catch (error: any) {
      console.log(error);
      if (error.response.data.message == "Not Acceptable. Reason: actibe ban") {
        toast.error("У вас активная блокировка");
      }
    }
  }

  static async removeItem(id: number | string) {
    try {
      const response = await axios.post(
        url + endpoints.marketplace.remove,
        { id },
        {
          withCredentials: true,
          headers: headers,
        }
      );
      return response.data;
    } catch (error: any) {
      console.log(error);
      if (error.response.data.message == "Not Acceptable. Reason: actibe ban") {
        toast.error("У вас активная блокировка");
      }
    }
  }

  static async updateItem({
    id,
    price,
  }: {
    id: number | string;
    price: string;
  }) {
    try {
      const response = await axios.post(
        url + endpoints.marketplace.update,
        { id, price },
        {
          withCredentials: true,
          headers: headers,
        }
      );
      return response.data;
    } catch (error: any) {
      console.log(error);
      if (error.response.data.message == "Not Acceptable. Reason: actibe ban") {
        toast.error("У вас активная блокировка");
      }
    }
  }
  static async getMarketplaceHistory() {
    try {
      const response = await axios.get(url + endpoints.marketplace.history, {
        withCredentials: true,
        headers: headers,
      });
      return response.data;
    } catch (error: any) {
      console.log(error);
      if (error.response.data.message == "Not Acceptable. Reason: actibe ban") {
        toast.error("У вас активная блокировка");
      }
    }
  }
  static async MarketInventory(
    page: number,
    classId?: number,
    weapon?: number,
    perPage?: number,
    raritySort?: string
  ) {
    try {
      const response = await axios.post(
        url + endpoints.marketplace.inventory,
        {
          page: page,
          perPage: perPage,
          classId: classId,
          weapon: weapon,
          raritySort: raritySort,
        },
        {
          withCredentials: true,
          headers: headers,
        }
      );
      return response.data;
    } catch (error: any) {
      console.log(error);
      if (error.response.data.message == "Not Acceptable. Reason: actibe ban") {
        toast.error("У вас активная блокировка");
      }
    }
  }
}
