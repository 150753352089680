export default function Close({ onClick = () => {} }) {
  return (
    <svg
      // max-width="20"
      // max-height="20"
      viewBox="0 0 20 20"
      onClick={onClick}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        maxWidth: "15px",
        minWidth: "10px",
        maxHeight: "15px",
        minHeight: "10px",
      }}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.56044 0.43949C1.97478 -0.146416 1.02522 -0.146416 0.439556 0.43949C-0.146111 1.0254 -0.146111 1.97534 0.439556 2.56124L7.87814 10.0029L0.445609 17.4384C-0.140059 18.0243 -0.140058 18.9743 0.445609 19.5602C1.03128 20.1461 1.98083 20.1461 2.5665 19.5602L9.99902 12.1246L17.4316 19.5602C18.0172 20.1461 18.9668 20.1461 19.5524 19.5602C20.1381 18.9743 20.1381 18.0243 19.5524 17.4384L12.1199 10.0029L19.5585 2.56124C20.1442 1.97534 20.1442 1.0254 19.5585 0.43949C18.9728 -0.146416 18.0233 -0.146416 17.4376 0.43949L9.99902 7.8811L2.56044 0.43949Z"
        fill="white"
      />
    </svg>
  );
}
