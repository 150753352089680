import styled, { GradientKeys, css } from "styled-components";
import { Wrapper as HoverPanel } from "../../../pages/Profile/Tabs/SkinChanger/HoverPanel/Styles";

export const Container = styled.div<{
  onlyHoverPanel: boolean;
  rarity: GradientKeys;
  isSelected: Boolean;
  LifeCase?: boolean;
}>`
  position: relative;
  cursor: pointer;
  /* padding: 0 0 2rem; */
  width: ${(props) => (props.LifeCase == true ? "100%" : "18.7rem")};
  height: ${(props) => (props.LifeCase == true ? "13rem" : "22rem")};
  background: ${(props) => props.theme.gradients[props.rarity]};
  border-radius: 1.5rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  gap: ${(props) => (props.LifeCase == true ? "" : "0.3rem")};
  ${(props) =>
    props.isSelected
      ? `outline: 3px solid ${props.theme.colors.green.selected}`
      : ""};

  ${(props) =>
    props.onlyHoverPanel &&
    css`
      ${HoverPanel} {
        /* opacity: 0; */
        display: none;
        pointer-events: none;
        transition: 0.3s;
      }
    `}

  &:hover {
    ${HoverPanel} {
      /* opacity: 1; */
      display: block;
      pointer-events: all;
    }
  }
  @media (max-width: 768px) {
    width: ${(props) => (props.LifeCase == true ? "100%" : "15.7rem")};
  }
`;

export const BlockDiv = styled.div<{ rarity: GradientKeys }>`
  /* position: relative; */
  /* top: 0; */
  font-size: 3px;
  border-radius: 0 0 5rem 5rem;
  height: 1rem;
  width: 5rem;
  background: ${(props) => props.theme.colors.weapon[props.rarity]};
  color: ${(props) => props.theme.colors.weapon[props.rarity]};
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  height: 100%;
  width: 100%;
  /* align-items: center; */
  justify-content: center;
`;

export const Name = styled.span`
  text-align: center;
  ${(props) => props.theme.fonts.GothamRegular14};
  color: ${(props) => props.theme.colors.white.white100};
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;

  /* margin-left: 0.5rem; */
  /* height: 3rem; */
`;
export const Names = styled.span`
  text-align: center;
  ${(props) => props.theme.fonts.GothamRegular14};
  color: ${(props) => props.theme.colors.white.white100};
  /* display: flex;
  justify-content: center;
  align-items: center; */
  font-weight: 400;
  min-width: 7rem;
  max-width: 11rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  /* margin-left: 0.5rem; */
  /* height: 3rem; */
`;
export const NameInv = styled.span`
  ${(props) => props.theme.fonts.GothamRegular14};
  color: ${(props) => props.theme.colors.white.white100};
  display: flex;
  /* justify-content: center; */
  align-items: center;
  font-weight: 400;
  /* margin-left: 0.5rem; */
  /* height: 3rem; */
`;

export const SubName = styled.div<{ rarity: GradientKeys }>`
  ${(props) => props.theme.fonts.GothamBold16};
  color: ${(props) => props.theme.colors.weapon[props.rarity]};
`;
export const InfInInv = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 2rem;
`;
