import { TicketTypes } from "interfaces/Support";

export enum TicketStatuses {
  PENDING = "На рассмотрении",
  CLOSED = "Закрыт",
  DELETED = "Удалён",
  OPEN = "Открыт",
}

export enum TicketLabels {
  THEME = "Тема:",
  AUTHOR = "Автор:",
  STATUS = "Статус:",
  CREATION_DATE = "Дата создания:",
  Nick = "Ник",
  Reason = "Причина",
  Server = "Сервер",
  Player = "Игрок",
  Discord = "Дискорд",
  Admin = "Админ",
  Closed = "Закрыл",
  date = "Дата создания",
  ForEachBan = "Рассмотрел",
}

export interface ITicket {
  id: number;
  date: string;
  status: TicketAndBlockStatus;
  support?: userSup;
  text: string;
  discord?: string;
  topic: string;
  user?: userSup;
  type: TicketTypes;
  block?: InfoBlock;
  closedBy?: {
    name: string;
  };
  message?: {
    id: number;
    created_at: string;
    cheched: true;
    text: string;
  };
}
export interface BansTicketAdmins {
  result: ITicket[];
  total: number;
}

export enum TicketAndBlockStatus {
  CREATED = "CREATED",
  IN_PROGRESS = "IN_PROGRESS",
  CLOSED = "CLOSED",
  DELETED = "DELETED",
  UNBANNED = "UNBANNED",
}

export interface userSup {
  id: number;
  name: string;
  steamid64: string;
  avatar: string;
}

export interface InfoBlock {
  id: number;
  user_name: string;
  state: string;
  type: string;
  discord?: string;
  reason: string;
  admin: {
    name: string;
    avatar: string;
    steamid64: string;
  };
  created_at: string;
  ip: string;
  expired_at: string;
  server: {
    id: number;
    name: string;
    ip: string;
  };
  banned: {
    id: number;
    avatar: string;
    name: string;
    steamid64: string;
  };
}
