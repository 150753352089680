import Layout from "components/Layout";
import Tabs from "components/Templates/Tabs";
import { StoreContext } from "context/store";
import { useActions } from "hooks/useActions";
import { useState } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import Credits from "./Credits";
import Privileges from "./Privileges";

export default function Store() {
  const params = useParams();

  const [tab, setTab] = useState(0);
  // const { fetchShop } = useActions();

  // useEffect(() => {
  //   fetchShop();
  // }, []);

  return (
    <StoreContext.Provider value={{ tab, setTab }}>
      <Layout
        leftElement={<div />}
        headerElement={
          <Tabs
            context={StoreContext}
            type="link"
            items={[
              // { label: "Услуги", href: "/store" },
              { label: "Кредиты", href: "/store/credits" },
            ]}
          />
        }
      >
        <Helmet>
          <title>FREEZ-BLOOD - Магазин</title>
          <meta name="description" content={`FREEZ-BLOOD - Магазин`} />
        </Helmet>
        {params.tab === undefined && <Privileges />}
        {params.tab === "credits" && <Credits />}
      </Layout>
    </StoreContext.Provider>
  );
}
