import { NanoIcons } from "assets/nano-icons";
import { TinyIcons } from "assets/tiny-icons";
import Row from "components/Row";
import UIKIT from "components/UI";
import Button from "components/UI/Button";
import { useAppSelector } from "hooks/useAppSelector";
import { IPrivilege } from "interfaces/Privilege";
import { useState } from "react";
import { Container, Feature, Price, PrivilegeLabel } from "./Styles";

export default function PrivilegeCard({ name, features, prices }: IPrivilege) {
  const [server, setServer] = useState(null);
  const [tarif, setTarif] = useState(null);
  const { categories } = useAppSelector((state) => state.servers);

  const currentPrice = prices.find((item) => item.name === tarif)?.price;
  const tarifs = prices.map((item) => ({
    value: item.name,
    label: item.title,
  }));

  return (
    <Container>
      <PrivilegeLabel>{name}</PrivilegeLabel>
      <Price>
        {currentPrice && currentPrice} <TinyIcons.Rub />
      </Price>
      <UIKIT.Select
        params={{ width: "100%", headHeight: "5rem" }}
        items={categories.filter(
          (item) => !item.label.toLowerCase().includes("все")
        )}
        label="Сервер"
        defaultValue="Выберете сервер"
        onChange={setServer}
      />
      <UIKIT.Select
        params={{ width: "100%", headHeight: "5rem" }}
        items={tarifs}
        label="Тариф"
        defaultValue="Выберете тариф"
        onChange={setTarif}
      />
      <Button
        height="5rem"
        color="blue"
        variant="default"
        disabled={!currentPrice}
      >
        Купить
      </Button>
      {features.map((item, index) => (
        <Row gap="1rem" key={index} justifyContent="flex-start">
          <NanoIcons.Star />
          <Feature>{item.name}</Feature>
        </Row>
      ))}
    </Container>
  );
}
