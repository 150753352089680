import React from "react";

export default function Roulette({ ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 64 64"
      width="36px"
      height="36px"
    >
      <g id="a" />
      <g id="b" />
      <g id="c" />
      <g id="d" />
      <g id="e" />
      <g id="f" />
      <g id="g" />
      <g id="h" />
      <g id="i" />
      <g id="j" />
      <g id="k" />
      <g id="l" />
      <g id="m" />
      <g id="n" />
      <g id="o" />
      <g id="p" />
      <g id="q" />
      <g id="r" />
      <g id="s">
        <path
          d="M52.55,33.65c2.74-.63,4.62-3.09,4.62-6.34s-1.88-5.71-4.62-6.34c-2.84-10.02-11.92-16.98-22.39-16.98C17.29,4,6.83,14.46,6.83,27.32c0,8.12,4.17,15.27,10.48,19.45v6.94h-.62c-1.74,0-3.15,1.41-3.15,3.15s1.41,3.15,3.15,3.15h26.23c1.74,0,3.15-1.41,3.15-3.15s-1.41-3.15-3.15-3.15h-.62v-6.56c4.87-2.99,8.6-7.71,10.23-13.49Zm2.62-6.34c0,2.65-1.65,4.5-4.01,4.5-3.38,0-6.86-3.12-8.24-4.5,1.38-1.39,4.85-4.5,8.24-4.5,2.36,0,4.01,1.85,4.01,4.5Zm-34.91,11.31l8.9-8.9v12.59c-3.4-.23-6.48-1.57-8.9-3.69Zm-5.1-10.31h12.59l-8.9,8.9c-2.12-2.42-3.46-5.5-3.69-8.9Zm24.93-12.34l-8.93,8.93V12.3c3.4,.22,6.5,1.54,8.93,3.67Zm4.57,7.28c-1.69,1.18-2.99,2.46-3.56,3.06h-8.53l8.95-8.95c1.45,1.66,2.52,3.66,3.14,5.89Zm-15.5,1.65l-8.9-8.9c2.42-2.12,5.5-3.46,8.9-3.69v12.59Zm-10.31-7.49l8.9,8.9H15.15c.23-3.4,1.57-6.48,3.69-8.9Zm12.31,12.31l8.93,8.93c-2.43,2.13-5.54,3.45-8.93,3.67v-12.6Zm10.36,7.53l-8.95-8.95h8.53c.57,.6,1.87,1.88,3.56,3.06-.62,2.23-1.7,4.23-3.14,5.89ZM8.83,27.32c0-11.75,9.56-21.32,21.32-21.32,9.35,0,17.47,6.09,20.26,14.91-1.41,.14-2.76,.62-4.01,1.28-2.29-7.29-8.95-11.98-16.25-11.98-9.43,0-17.1,7.67-17.1,17.1s7.68,17.1,17.1,17.1c7.33,0,13.98-4.74,16.25-11.98,1.24,.67,2.6,1.15,4.01,1.28-2.79,8.82-10.91,14.91-20.26,14.91-11.76,0-21.32-9.56-21.32-21.32Zm15.52,26.38v-3.83c1.86,.48,3.8,.76,5.8,.76,1.76,0,3.46-.26,5.13-.64v3.7h-10.93Zm-5.03-5.75c.97,.51,1.99,.94,3.03,1.32v4.44h-3.03v-5.75Zm24.76,8.9c0,.63-.52,1.15-1.15,1.15H16.7c-.63,0-1.15-.52-1.15-1.15s.52-1.15,1.15-1.15h26.23c.63,0,1.15,.52,1.15,1.15Zm-3.77-3.15h-3.03v-4.22c1.05-.34,2.05-.76,3.03-1.24v5.46Z"
          fill="#bababa"
        />
        <path
          d="M49.92,27.32c0,.64,.52,1.15,1.15,1.15s1.15-.52,1.15-1.15-.52-1.15-1.15-1.15-1.15,.52-1.15,1.15Z"
          fill="#bababa"
        />
        <path
          d="M30.15,9.3c.64,0,1.15-.52,1.15-1.15s-.52-1.16-1.15-1.16-1.15,.52-1.15,1.16,.52,1.15,1.15,1.15Z"
          fill="#bababa"
        />
        <path
          d="M31.31,46.57c0-.64-.52-1.16-1.15-1.16s-1.15,.52-1.15,1.16,.52,1.15,1.15,1.15,1.15-.52,1.15-1.15Z"
          fill="#bababa"
        />
        <path
          d="M12.1,27.36c0-.64-.52-1.15-1.16-1.15s-1.15,.52-1.15,1.15,.52,1.15,1.15,1.15,1.16-.52,1.16-1.15Z"
          fill="#bababa"
        />
        <path
          d="M40.76,11.3c.32-.55,.13-1.26-.42-1.58-.55-.32-1.26-.13-1.58,.42-.32,.55-.13,1.26,.42,1.58,.55,.32,1.26,.13,1.58-.42Z"
          fill="#bababa"
        />
        <path
          d="M19.55,43.42c-.32,.55-.13,1.26,.42,1.58,.55,.32,1.26,.13,1.58-.42,.32-.55,.13-1.26-.42-1.58-.55-.32-1.26-.13-1.58,.42Z"
          fill="#bababa"
        />
        <path
          d="M47.79,37.54c.32-.55,.13-1.26-.42-1.58-.55-.32-1.26-.13-1.58,.42-.32,.55-.13,1.26,.42,1.58,.55,.32,1.26,.13,1.58-.42Z"
          fill="#bababa"
        />
        <path
          d="M14.52,18.33c.32-.55,.13-1.26-.42-1.58-.55-.32-1.26-.13-1.58,.42-.32,.55-.13,1.26,.42,1.58,.55,.32,1.26,.13,1.58-.42Z"
          fill="#bababa"
        />
        <path
          d="M34.83,9.92c.62,.16,1.25-.2,1.41-.82,.16-.62-.2-1.25-.82-1.41-.62-.16-1.25,.2-1.41,.82-.17,.62,.2,1.25,.82,1.41Z"
          fill="#bababa"
        />
        <path
          d="M25.48,44.8c-.62-.16-1.25,.2-1.41,.82-.16,.61,.2,1.25,.82,1.41,.62,.16,1.25-.2,1.41-.82,.17-.62-.2-1.25-.82-1.41Z"
          fill="#bababa"
        />
        <path
          d="M11.3,23.5c.62,.16,1.25-.2,1.41-.82,.17-.62-.2-1.25-.82-1.41-.62-.16-1.25,.2-1.41,.82-.17,.62,.2,1.25,.82,1.41Z"
          fill="#bababa"
        />
        <path
          d="M44.55,14.59c.45-.45,.45-1.18,0-1.63s-1.18-.45-1.63,0c-.45,.45-.45,1.18,0,1.63,.45,.45,1.18,.45,1.63,0Z"
          fill="#bababa"
        />
        <path
          d="M15.75,40.13c-.45,.45-.45,1.18,0,1.63,.45,.45,1.18,.45,1.63,0,.45-.45,.45-1.18,0-1.63-.45-.45-1.18-.45-1.63,0Z"
          fill="#bababa"
        />
        <path
          d="M44.55,41.76c.45-.45,.45-1.18,0-1.63-.45-.45-1.18-.45-1.63,0-.45,.45-.45,1.18,0,1.63,.45,.45,1.18,.45,1.63,0Z"
          fill="#bababa"
        />
        <path
          d="M17.38,14.59c.45-.45,.45-1.18,0-1.63-.45-.45-1.18-.45-1.63,0s-.45,1.18,0,1.63,1.18,.45,1.63,0Z"
          fill="#bababa"
        />
        <path
          d="M47.37,18.75c.55-.32,.74-1.02,.42-1.58-.32-.55-1.02-.74-1.58-.42-.55,.32-.74,1.03-.42,1.58,.32,.55,1.03,.74,1.58,.42Z"
          fill="#bababa"
        />
        <path
          d="M12.94,35.96c-.55,.32-.74,1.03-.42,1.58,.32,.55,1.02,.74,1.58,.42,.55-.32,.74-1.02,.42-1.58-.32-.55-1.03-.74-1.58-.42Z"
          fill="#bababa"
        />
        <path
          d="M40.33,45c.55-.32,.74-1.02,.42-1.58-.32-.55-1.03-.74-1.58-.42-.55,.32-.74,1.03-.42,1.58,.32,.55,1.02,.74,1.58,.42Z"
          fill="#bababa"
        />
        <path
          d="M21.12,11.72c.55-.32,.74-1.02,.42-1.58-.32-.55-1.02-.74-1.58-.42-.55,.32-.74,1.03-.42,1.58,.32,.55,1.03,.74,1.58,.42Z"
          fill="#bababa"
        />
        <path
          d="M12.71,32.03c-.16-.62-.8-.98-1.41-.82-.62,.16-.98,.8-.82,1.41,.16,.62,.8,.98,1.41,.82,.62-.16,.98-.8,.82-1.41Z"
          fill="#bababa"
        />
        <path
          d="M35.42,47.03c.62-.17,.98-.8,.82-1.41-.16-.62-.8-.98-1.41-.82-.62,.16-.98,.8-.82,1.41,.16,.62,.8,.98,1.41,.82Z"
          fill="#bababa"
        />
        <path
          d="M25.48,9.92c.62-.17,.98-.8,.82-1.41-.16-.62-.8-.98-1.41-.82-.62,.16-.98,.8-.82,1.41,.16,.62,.8,.98,1.41,.82Z"
          fill="#bababa"
        />
      </g>
      <g id="t" />
      <g id="u" />
      <g id="v" />
      <g id="w" />
      <g id="x" />
      <g id="y" />
      <g id="a`" />
      <g id="aa" />
      <g id="ab" />
      <g id="ac" />
      <g id="ad" />
      <g id="ae" />
      <g id="af" />
      <g id="ag" />
      <g id="ah" />
      <g id="ai" />
      <g id="aj" />
      <g id="ak" />
      <g id="al" />
      <g id="am" />
      <g id="an" />
      <g id="ao" />
      <g id="ap" />
      <g id="aq" />
      <g id="ar" />
      <g id="as" />
      <g id="at" />
      <g id="au" />
      <g id="av" />
      <g id="aw" />
      <g id="ax" />
    </svg>
  );
}
