export const isYou = (toCompare) => {
	const yourSteamid64 = localStorage.getItem('your_steamid64')
	return yourSteamid64 === toCompare
}

export const mySteamId = () => {
	const steamId = localStorage.getItem('your_steamid64')

	if (steamId) {
		return steamId
	} else {
		return "0"
	}
}
