import Layout from 'components/Layout'
import ProfileLayout from 'components/ProfileLayout'
import SubHeader from 'components/SubHeader'

import { Container } from './Styles'

export default function Messages() {
	return (
		<Layout
			leftElement={<div />}
			headerElement={
				<SubHeader>
					Сообщения
				</SubHeader>
			}
		>
			<ProfileLayout>
				<Container>
					Messages
				</Container>
			</ProfileLayout>
		</Layout>
	)
}
