import { ClanRolesEnum, ClansArray, SeparateClan } from "interfaces/Clans";
import { makeAutoObservable } from "mobx";

class ClansState {
  public Clans: ClansArray | undefined = undefined;
  public SepClan: SeparateClan | undefined = undefined;
  constructor() {
    makeAutoObservable(this);
  }

  public SetClans(clan: ClansArray) {
    this.Clans = clan;
  }
  public SetSepClans(clan: SeparateClan) {
    this.SepClan = clan;
  }
}

export default new ClansState();
