import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  min-height: 10rem;
  flex-direction: column;
  gap: 2rem;
  border-radius: 2.5rem;
  padding: 2rem 3rem;
  ${(props) => props.theme.mixins.defaultShadow};
`;

export const Nickname = styled.span`
  ${(props) => props.theme.fonts.GothamRegular20};
  color: ${(props) => props.theme.colors.white.white100};
`;

export const Head = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 2rem;
`;

export const Body = styled.div`
  display: flex;
  align-items: center;
  gap: 1.5rem;
`;
