

export default function Buy() {
	return (
		<svg width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M17.25 20.9976C16.3587 21.0244 15.546 20.2729 15.546 19.3604C15.5198 18.4747 16.3063 17.6427 17.1976 17.6696C18.1675 17.6964 18.7181 18.2869 18.8491 19.2262C18.9278 20.1119 18.1413 20.9708 17.25 20.9976Z" fill="white" />
			<path d="M8.96642 17.6694C9.88395 17.6694 10.618 18.4209 10.618 19.3603C10.618 20.2728 9.83152 21.0511 8.94021 20.9975C7.99647 20.9169 7.41974 20.3802 7.36731 19.3871C7.31488 18.4478 8.07511 17.6694 8.96642 17.6694Z" fill="white" />
			<path fillRule="evenodd" clipRule="evenodd" d="M12.9246 15.9517C12.4692 15.9517 12.0168 15.9547 11.5653 15.9577C10.6653 15.9637 9.7691 15.9696 8.86129 15.9517C7.26217 15.9249 6.50194 15.2808 6.0825 13.6973C5.58441 11.9259 5.07322 10.1545 4.56203 8.38318C4.05084 6.61182 3.53964 4.84045 3.04156 3.06908C2.77941 2.12972 2.4124 1.43191 1.25894 1.64662C0.655992 1.75398 0.0268318 1.64662 0.000616794 0.868296C-0.0255982 0.0899688 0.603563 0.0362909 1.20651 0.009452C3.38235 -0.0710646 3.93287 0.331518 4.5096 2.53231C5.19119 5.082 5.19119 5.082 7.76026 5.082H20.4483C22.0212 5.082 22.2309 5.37722 21.8115 6.90704C21.4969 8.06111 21.1889 9.22189 20.8809 10.3827C20.5728 11.5434 20.2648 12.7042 19.9502 13.8583C19.5308 15.3881 18.7706 15.9517 17.1977 15.9517H12.9246ZM12.9998 6.35307C13.4014 6.35307 13.727 6.67868 13.727 7.08034V9.62586H16.2726C16.6742 9.62586 16.9998 9.95147 16.9998 10.3531C16.9998 10.7548 16.6742 11.0804 16.2726 11.0804H13.727V13.6258C13.727 14.0275 13.4014 14.3531 12.9998 14.3531C12.5981 14.3531 12.2725 14.0275 12.2725 13.6258V11.0804H9.7271C9.32544 11.0804 8.99982 10.7548 8.99982 10.3531C8.99982 9.95147 9.32544 9.62586 9.7271 9.62586H12.2725V7.08034C12.2725 6.67868 12.5981 6.35307 12.9998 6.35307Z" fill="white" />
		</svg>
	)
}
