

export default function Sell() {
	return (
		<svg width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M17.25 20.9976C16.3587 21.0244 15.546 20.2729 15.546 19.3604C15.5198 18.4747 16.3063 17.6427 17.1976 17.6696C18.1675 17.6964 18.7181 18.2869 18.8491 19.2262C18.9278 20.1119 18.1413 20.9708 17.25 20.9976Z" fill="white" />
			<path d="M8.96642 17.6694C9.88395 17.6694 10.618 18.4209 10.618 19.3603C10.618 20.2728 9.83152 21.0511 8.94021 20.9975C7.99647 20.9169 7.41974 20.3802 7.36731 19.3871C7.31488 18.4478 8.07511 17.6694 8.96642 17.6694Z" fill="white" />
			<path fillRule="evenodd" clipRule="evenodd" d="M11.5653 15.9577C12.0168 15.9547 12.4692 15.9517 12.9246 15.9517H17.1977C18.7706 15.9517 19.5308 15.3881 19.9502 13.8583C20.2648 12.7042 20.5728 11.5434 20.8809 10.3827C21.1889 9.22189 21.4969 8.06111 21.8115 6.90704C22.2309 5.37722 22.0212 5.082 20.4483 5.082H7.76026C5.19119 5.082 5.19119 5.082 4.5096 2.53231C3.93287 0.331518 3.38235 -0.0710646 1.20651 0.009452C0.603563 0.0362909 -0.0255982 0.0899688 0.000616794 0.868296C0.0268318 1.64662 0.655992 1.75398 1.25894 1.64662C2.4124 1.43191 2.77941 2.12972 3.04156 3.06908C3.53964 4.84045 4.05084 6.61182 4.56203 8.38318C5.07322 10.1545 5.58441 11.9259 6.0825 13.6973C6.50194 15.2808 7.26217 15.9249 8.86129 15.9517C9.7691 15.9696 10.6653 15.9637 11.5653 15.9577ZM9.99982 9.35307C9.44754 9.35307 8.99982 9.80078 8.99982 10.3531C8.99982 10.9054 9.44754 11.3531 9.99982 11.3531H15.9998C16.5521 11.3531 16.9998 10.9054 16.9998 10.3531C16.9998 9.80078 16.5521 9.35307 15.9998 9.35307H9.99982Z" fill="white" />
		</svg>
	)
}
