import { Icons } from "assets/icons";
import { BlockEarn, BlockHeaderInfo, VerticalLineE } from "./styled";
import { useEffect, useState } from "react";

export default function QuechenButt() {
  const [active, setActive] = useState<boolean>(false);

  return (
    <>
      <BlockEarn
        onMouseLeave={() => setActive(false)}
        onMouseEnter={() => setActive(true)}
      >
        <Icons.Quechen />
        <BlockHeaderInfo active={active}>
          Вам будет отправлен обмен со скином на указанный вами Трейд-Офер в
          настройках
        </BlockHeaderInfo>
        <VerticalLineE active={active}>&nbsp;</VerticalLineE>
      </BlockEarn>
    </>
  );
}
