export default function Inventory() {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="20.000000pt"
      height="20.000000pt"
      viewBox="0 0 512.000000 512.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill="#000000"
        stroke="none"
      >
        <path d="M920 5035 l0 -85 85 0 85 0 0 85 0 85 -85 0 -85 0 0 -85z" />
        <path d="M1300 5035 l0 -85 85 0 85 0 0 85 0 85 -85 0 -85 0 0 -85z" />
        <path d="M1690 5035 l0 -85 85 0 85 0 0 85 0 85 -85 0 -85 0 0 -85z" />
        <path d="M2070 5035 l0 -85 85 0 85 0 0 85 0 85 -85 0 -85 0 0 -85z" />
        <path d="M2460 5035 l0 -85 85 0 85 0 0 85 0 85 -85 0 -85 0 0 -85z" />
        <path d="M2840 5035 l0 -85 85 0 85 0 0 85 0 85 -85 0 -85 0 0 -85z" />
        <path d="M3230 5035 l0 -85 85 0 85 0 0 85 0 85 -85 0 -85 0 0 -85z" />
        <path d="M3610 5035 l0 -85 85 0 85 0 0 85 0 85 -85 0 -85 0 0 -85z" />
        <path d="M4000 5035 l0 -85 85 0 85 0 0 85 0 85 -85 0 -85 0 0 -85z" />
        <path d="M4380 5035 l0 -85 85 0 85 0 0 85 0 85 -85 0 -85 0 0 -85z" />
        <path
          d="M724 4727 c-3 -8 -4 -47 -2 -88 l3 -74 88 -3 88 -3 -3 88 -3 88 -83
3 c-63 2 -84 0 -88 -11z"
        />
        <path d="M1110 4650 l0 -90 85 0 85 0 0 90 0 90 -85 0 -85 0 0 -90z" />
        <path
          d="M1494 4727 c-3 -8 -4 -47 -2 -88 l3 -74 88 -3 88 -3 -3 88 -3 88 -83
3 c-63 2 -84 0 -88 -11z"
        />
        <path d="M1880 4650 l0 -90 85 0 85 0 0 90 0 90 -85 0 -85 0 0 -90z" />
        <path
          d="M2267 4734 c-4 -4 -7 -45 -7 -91 l0 -83 90 0 91 0 -3 88 -3 87 -80 3
c-45 1 -84 0 -88 -4z"
        />
        <path d="M2650 4650 l0 -90 85 0 85 0 0 90 0 90 -85 0 -85 0 0 -90z" />
        <path
          d="M3037 4734 c-4 -4 -7 -45 -7 -91 l0 -83 90 0 91 0 -3 88 -3 87 -80 3
c-45 1 -84 0 -88 -4z"
        />
        <path d="M3420 4650 l0 -90 85 0 85 0 0 90 0 90 -85 0 -85 0 0 -90z" />
        <path
          d="M3807 4734 c-4 -4 -7 -45 -7 -91 l0 -84 88 3 87 3 0 85 0 85 -80 3
c-45 1 -84 0 -88 -4z"
        />
        <path d="M4190 4650 l0 -90 85 0 85 0 0 90 0 90 -85 0 -85 0 0 -90z" />
        <path
          d="M4577 4734 c-4 -4 -7 -45 -7 -91 l0 -84 88 3 87 3 0 85 0 85 -80 3
c-45 1 -84 0 -88 -4z"
        />
        <path
          d="M693 4329 c-36 -14 -67 -37 -102 -75 -83 -90 -82 -90 -79 -606 l3
-443 27 -51 c33 -63 91 -116 154 -140 47 -18 111 -19 2054 -19 l2005 0 46 23
c55 27 113 83 142 137 22 39 22 48 22 515 l0 475 -30 53 c-32 59 -66 91 -129
125 l-41 22 -2010 2 c-1989 2 -2011 2 -2062 -18z m4030 -163 c18 -7 41 -23 52
-36 19 -21 20 -38 23 -443 3 -449 2 -460 -46 -497 -24 -20 -51 -20 -2013 -20
l-1987 0 -31 30 c-41 41 -43 67 -39 515 3 423 3 421 71 450 47 21 3921 22
3970 1z"
        />
        <path
          d="M960 3992 c-32 -14 -58 -38 -79 -72 -20 -33 -21 -48 -21 -262 l0
-228 85 0 85 0 0 200 0 200 1755 0 1755 0 0 85 0 85 -1782 -1 c-981 0 -1790
-3 -1798 -7z"
        />
        <path
          d="M152 3408 c4 -296 10 -326 89 -460 77 -131 201 -231 354 -285 l80
-28 990 -5 990 -5 3 -277 2 -278 -205 0 -205 0 0 -812 c0 -895 0 -883 62
-1000 95 -179 310 -284 510 -250 190 33 345 174 393 357 13 51 15 175 15 882
l0 823 -200 0 -200 0 0 365 0 365 -1028 0 c-695 0 -1048 4 -1088 11 -178 32
-330 173 -376 348 -13 52 -17 112 -17 289 l-1 222 -86 0 -86 0 4 -262z m2906
-2255 l-3 -738 -23 -50 c-13 -27 -44 -71 -69 -97 -154 -157 -415 -108 -509 94
l-29 63 -3 733 -3 732 321 0 320 0 -2 -737z"
        />
        <path
          d="M2662 621 c-128 -81 -74 -281 76 -281 141 0 208 159 108 258 -30 31
-42 36 -93 39 -46 3 -65 0 -91 -16z"
        />
      </g>
    </svg>
  );
}
