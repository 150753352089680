import React, { useEffect, useContext } from "react";
import { NavLink, useLocation } from "react-router-dom";
import UIKIT from "components/UI";
import { Wrapper } from "./Styles";

type callbackType =
  | ((value: string) => any)
  | ((setTab: (index: number) => void) => void);

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  items: { label: string; href: any }[] | string[] | any[];
  context: any;
  type: "default" | "link" | "NewDizign";
  children?: any;
  callback?: callbackType;
}

export default function Tabs({
  items,
  context,
  type,
  callback,
  children,

  ...props
}: Props) {
  const options: { tab: any; setTab: React.SetStateAction<any> } =
    useContext(context);

  const location = useLocation();

  useEffect(() => {
    // console.log(options.tab);

    if (items.length && items[0]?.label) {
      callback && callback(options.setTab);
    }
  }, [location.pathname, options.tab]);

  return (
    <Wrapper className="customScroll" {...props}>
      {items.map((item, index) =>
        type === "link" ? (
          <NavLink to={item?.href} key={index}>
            <UIKIT.Button
              onClick={() => {
                callback && callback(items[index]);
                options.setTab(index);
              }}
              key={index}
              variant="Sort"
              height="4.5rem"
              params={{ width: "100%" }}
              className={options.tab === index ? "active" : ""}
            >
              {item?.label}
            </UIKIT.Button>
          </NavLink>
        ) : (
          <Wrapper>
            <UIKIT.Button
              onClick={() => {
                callback && callback(items[index]);
                options.setTab(index);
              }}
              key={index}
              className={options.tab === index ? "active" : ""}
              variant={type == "NewDizign" ? "NewDizign" : "default"}
              height="5rem"
              // color="shadow"
            >
              {item}
            </UIKIT.Button>

            {index == items.length - 1 && children}
          </Wrapper>
        )
      )}
    </Wrapper>
  );
}
