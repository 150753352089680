import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle<{
  redUser?: Boolean;
  purpleUser?: Boolean;
  test?: Boolean;
  russia?: Boolean;
}>`
	*,
  *::after,
  *::before {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
		color: white;
  }

  html, body {
		height: 100vh;
		width: 100vw;
    font-size: 10px;
    font-family: 'Gotham Pro', 'sans-serif';
		overflow-x:hidden;
		overflow-y: hidden;
    scroll-behavior: smooth;
	

  }

	button {
    border: none;
    outline: none;
    cursor: pointer;
  }

	img, svg {
		user-select: none;
	}

  input {
    border: none;
    outline: none;
  }

  a {
    text-decoration: none;
  }

	.App {

		overflow-y: hidden;
		overflow-x: hidden;

		/* background-image:url("/public/static/images/Background.png") ; */
		background: ${(props) =>
      props.redUser
        ? props.theme.gradients.redUser
        : props.purpleUser
        ? props.theme.gradients.purpleUser
        : props.test
        ? props.theme.gradients.yellowUser
        : props.russia
        ? props.theme.gradients.RussiaUser
        : props.theme.gradients.bg} ;
		background-blend-mode: screen;
	background-size: cover;
		/* background-image: url("/public/static/images/Background.png"); */
		display: flex;
		position: relative;

	}

	.hideScroll {
		-ms-overflow-style: none;
		scrollbar-width: none;
		&::-webkit-scrollbar {
			display: none;
		}
	}

	.customScroll, .epr-body {
		overflow-y: auto;
		overflow-x: hidden;		/* scrollbar-width: thin; */
		&::-webkit-scrollbar {
			width: .4rem;
			border: .1rem solid transparent;
		}
		&::-webkit-scrollbar-thumb {
			background: ${(props) => props.theme.colors.blue.accent} !important;
			border-radius: .5rem !important;
		}
		&::-webkit-scrollbar-track {
			background: ${(props) => props.theme.colors.white.white10} !important;
		}
	}

	.toast-body {
		${(props) => props.theme.fonts.GothamBold14};
		/* background-color: red; */
		/* backdrop-filter: blur(1.2rem); */
		/* backdrop-filter: blur(1.2rem); */
		/* opacity: 2rem; */
		/* display: flex;
		gap:2rem; */
		
		
	}
	.Toastify__toast-container {
		/* background-color:red; */
		/* backdrop-filter: blur(1.2rem); */
		display: flex;
		gap:0.5rem;
		flex-direction: column;
		@media(max-width: 480px){
			margin-bottom: 2rem;
		}
		
}



/* .Toastify__progress-bar--info {
	background: green;
}
.Toastify__progress-bar--success {
	background: green;
}
.Toastify__progress-bar--warning {
	background: orange;
}
.Toastify__progress-bar--error {
	background: red;
} */

.Toastify__progress-bar-theme--dark.Toastify__progress-bar--info{
	background-color: blue;
}
.Toastify__progress-bar-theme--dark.Toastify__progress-bar--success{
	background-color: green;
}
.Toastify__progress-bar-theme--dark.Toastify__progress-bar--warning{
	background-color: orange;
}
.Toastify__progress-bar-theme--dark.Toastify__progress-bar--error {
	background-color: red;
}

.Toastify__toast.Toastify__toast-theme--dark.Toastify__toast--default {
	background-color:rgba(112, 169, 255, 0.2);
	backdrop-filter: blur(1.2rem);
	border-radius: 1rem;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
}
.Toastify__toast.Toastify__toast-theme--dark.Toastify__toast--info {
	background-color: rgba(112, 169, 255, 0.2);
	backdrop-filter: blur(1.2rem);
	border-radius: 1rem;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
}
.Toastify__toast.Toastify__toast-theme--dark.Toastify__toast--success {
	background-color: rgba(0, 255, 34, 0.2);
	backdrop-filter: blur(1.2rem);
	border-radius: 1rem;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
}
.Toastify__toast.Toastify__toast-theme--dark.Toastify__toast--warning  {
	background-color: rgba(242, 183, 65, 0.2);
	backdrop-filter: blur(1.2rem);
	border-radius: 1rem;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
}

.Toastify__toast.Toastify__toast-theme--dark.Toastify__toast--error{
	background-color: rgba(228, 30, 30, 0.2);
	backdrop-filter: blur(1.2rem);
	border-radius: 1rem;
	/* padding: 0.5rem; */
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
}

	.single-thumb .range-slider__thumb[data-lower] {
  width: 0;
	}
	.single-thumb .range-slider__range {
		border-radius: 6px;
	}
`;
