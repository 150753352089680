import Image from "components/Image";
import Counter from "components/UI/Counter";
import { IInventoryWeaponCard } from "models/InventoryWeaponCard";

import {
  BlockDiv,
  Container,
  InfInInv,
  Info,
  Name,
  NameInv,
  Names,
  SubName,
} from "./Styles";
import HoverPanel from "pages/Profile/Tabs/SkinChanger/HoverPanel/HoverPanel";
import { forwardRef } from "react";
import Avatar from "components/Avatar";

const InventoryWeaponCard = forwardRef(
  (props: IInventoryWeaponCard, ref: any) => {
    const {
      weapon,
      rarity,
      skind,
      name,
      avatar,
      hoverItem,
      src,
      skin,
      isSelected = false,
      cl,
      counter,
      notRender,
      LifeCase,
      skinChangerStatus,
      trade,
    } = props;
    {
      // console.log(skin);
    }
    if (notRender) {
      return <></>;
    }
    // (skind);
    type classes = "CT" | "TT" | "BOTH" | null | undefined;

    function fds(skinss: classes) {
      if (skinss !== null) {
        // ("Kt=", i.options.classes);
        return skinss;
      }
      if (skinss !== undefined) {
        // (i.options.classes);
        return skinss;
      } else {
        return null;
      }
    }
    const windowInnerWidth = document.documentElement.clientWidth;

    return (
      <Container
        // id={String(id)}
        // @ts-ignore
        rarity={rarity}
        isSelected={isSelected}
        onlyHoverPanel={skinChangerStatus?.hover || false}
        ref={ref}
        skind={skind}
        LifeCase={LifeCase}
        hoverItem={true}
        {...props}
      >
        <HoverPanel
          skind={fds(skind) || null}
          cl={cl}
          status={skinChangerStatus?.status}
        />
        {!trade ? <BlockDiv rarity={rarity}>&nbsp;</BlockDiv> : null}

        <Counter text={counter} />
        {windowInnerWidth > 768 ? (
          hoverItem ? (
            <Image
              width={LifeCase ? "120" : "167"}
              height={LifeCase ? "136" : "128"}
              src={src}
              alt="Gun"
            />
          ) : (
            <Image
              width={LifeCase ? "120" : "167"}
              height={LifeCase ? "106" : "126"}
              src={src}
              alt="Gun"
            />
          )
        ) : hoverItem ? (
          <Image
            width={LifeCase ? "120" : "127"}
            height={LifeCase ? "136" : "126"}
            src={src}
            alt="Gun"
          />
        ) : (
          <Image
            width={LifeCase ? "120" : "127"}
            height={LifeCase ? "106" : "126"}
            src={src}
            alt="Gun"
          />
        )}
        {trade ? (
          <InfInInv>
            <NameInv>{weapon == "" ? "" : weapon}</NameInv>
            <SubName rarity={rarity}>{skin}</SubName>
          </InfInInv>
        ) : null}

        <Info>
          {hoverItem ? (
            <Name>
              <Avatar size="nano" src={avatar} />

              <Names> &nbsp;&nbsp;&nbsp;&nbsp;{name}</Names>
            </Name>
          ) : (
            <InfInInv>
              <NameInv>{weapon == "" ? "" : weapon}</NameInv>
              <SubName rarity={rarity}>{skin}</SubName>
            </InfInInv>
          )}
        </Info>
      </Container>
    );
  }
);

export { InventoryWeaponCard };
