import { VipServise } from "API/VipServise";
import ModalWrapper from "components/Templates/ModalWrapper";
import Button from "components/UI/Button";
import { useAppSelector } from "hooks/useAppSelector";
import { GetCatecory, Type } from "interfaces/Vip";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  BlockFlex,
  BlockTime,
  NameVip,
  PriceVip,
  SmallPriceVip,
  WrapperModal,
  WrapperModalPrices,
} from "./styled";

interface Props {
  isActive: boolean;
  setActive: React.SetStateAction<any>;
  vip: GetCatecory;
}

export default function VipModal({ isActive, setActive, vip }: Props) {
  const [activeTime, setActiveTime] = useState<number>();
  const [price, setPrice] = useState<number>();
  const { isAuth, user } = useAppSelector((state) => state.user);
  const navigate = useNavigate();
  useMemo(() => {
    setActiveTime(vip.prices[2].id);
    setPrice(vip.prices[2].price);
  }, [isActive]);

  return (
    <ModalWrapper
      headContent={
        user?.group?.name == vip.name ? "Продлить подписку" : "Покупка подписки"
      }
      isActive={isActive}
      setActive={setActive}
      params={{ width: "max-content", height: "fit-content" }}
      display="flex"
    >
      <WrapperModal>
        <WrapperModalPrices>
          {vip.prices.map((item, index) => {
            return (
              <BlockTime
                active={item.id == activeTime ? true : false}
                onClick={() => {
                  setActiveTime(item.id);
                  setPrice(item.price);
                }}
              >
                {item.title}
              </BlockTime>
            );
          })}
        </WrapperModalPrices>
        <NameVip Color={vip.name === "VIP" ? false : true}>{vip.name}</NameVip>
        <BlockFlex>
          <SmallPriceVip>К оплате</SmallPriceVip>
          {vip.prices.map((item) => {
            if (item.id == activeTime) {
              return (
                <PriceVip>
                  {item.price} руб{" "}
                  {vip.prices.map((item) => {
                    if (item.id === activeTime && item.discount !== 0) {
                      return (
                        <BlockTime active={true}>-{item.discount} %</BlockTime>
                      );
                    } else {
                      return null;
                    }
                  })}
                </PriceVip>
              );
            } else {
              return null;
            }
          })}
        </BlockFlex>
        <Button
          variant="default"
          color="blue"
          height="5rem"
          onClick={async () => {
            if (user.balance.real - (price || 0) >= 0) {
              const result = await VipServise.BuyGift(
                Type.Buy,
                vip.id,
                activeTime || vip.prices[2].id,
                user?.user?.steamid64
              );
              if (result) {
                navigate(`/profile/${user.user.steamid64}/privileges`);
                window.location.reload();
              } else {
                toast.error("Непредвиденная ошибка");
                setActive(false);
              }
            } else {
              toast.error("Недостаточно баланса");
              setActive(false);
            }
          }}
        >
          Купить
        </Button>
      </WrapperModal>
    </ModalWrapper>
  );
}
