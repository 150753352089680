
import { Link } from './Styles'

interface Props extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
	font?: string;
	children?: any;
}

export default function AppLink({ font, children, ...props }: Props) {
	return (
		<Link {...props} font={font}>
			{children}
		</Link>
	)
}
