export default function Inventory() {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="20.000000pt"
      height="20.000000pt"
      viewBox="0 0 512.000000 512.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill="#000000"
        stroke="none"
      >
        <path
          d="M1930 4624 c-106 -29 -197 -99 -253 -195 -45 -76 -57 -147 -57 -330
l0 -168 -577 -3 c-522 -4 -583 -6 -633 -22 -183 -60 -320 -215 -349 -396 -15
-89 -15 -2530 -1 -2620 30 -189 178 -354 359 -402 61 -16 212 -17 2132 -17
1345 -1 2085 3 2120 9 190 35 357 212 389 410 14 90 14 2541 -1 2630 -33 206
-209 380 -415 410 -39 5 -303 10 -611 10 l-543 0 0 156 c0 105 -5 177 -16 224
-29 129 -126 241 -251 292 l-58 23 -590 2 c-496 2 -599 0 -645 -13z m1197
-338 l28 -24 3 -166 3 -166 -605 0 -606 0 0 161 0 161 29 29 29 29 546 0 545
0 28 -24z m-1967 -976 l0 -300 -89 0 c-103 0 -133 -9 -179 -53 -36 -34 -52
-80 -52 -145 l0 -42 -227 2 -228 3 0 355 c0 337 1 357 20 389 25 42 62 71 106
81 19 4 172 8 342 9 l307 1 0 -300z m2468 -2 l2 -298 -93 0 c-76 0 -100 -4
-137 -22 -63 -33 -84 -67 -90 -146 l-5 -67 -745 0 -745 0 -5 67 c-6 79 -27
113 -90 146 -37 18 -61 22 -137 22 l-93 0 0 293 c0 162 3 297 7 300 3 4 484 6
1067 5 l1061 -3 3 -297z m981 291 c59 -12 104 -57 121 -118 6 -25 9 -161 8
-373 l-3 -333 -227 -3 -228 -2 0 42 c0 65 -16 111 -52 145 -46 44 -76 53 -179
53 l-89 0 0 300 0 300 300 0 c175 0 320 -4 349 -11z m-3129 -1164 l0 -245
-160 0 -160 0 0 245 0 245 160 0 160 0 0 -245z m2480 0 l0 -245 -160 0 -160 0
0 245 0 245 160 0 160 0 0 -245z m-3120 -213 c0 -206 1 -220 23 -262 12 -25
37 -55 56 -67 30 -20 47 -23 137 -23 l104 0 0 -530 0 -530 -334 0 c-329 0
-333 0 -366 23 -19 12 -43 38 -54 57 -21 34 -21 49 -24 793 l-2 757 230 0 230
0 0 -218z m2470 -8 c0 -211 1 -229 21 -264 34 -59 68 -74 189 -80 l105 -5 3
-527 2 -528 -1070 0 -1070 0 2 528 3 527 102 5 c112 6 138 17 181 80 21 32 22
41 22 261 l0 229 755 0 755 0 0 -226z m1428 -541 c-3 -842 1 -794 -70 -845
-33 -23 -35 -23 -370 -26 l-338 -3 0 531 0 530 104 0 c90 0 107 3 137 23 19
12 44 42 57 67 21 42 22 56 22 262 l0 218 230 0 230 0 -2 -757z"
        />
      </g>
    </svg>
  );
}
