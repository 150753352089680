import { TinyIcons } from "assets/tiny-icons";
import Players from "components/Players";
import Row from "components/Row";
import ActionButton from "components/UI/ActionButton";
import { IServer } from "models/Server";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { toast } from "react-toastify";
import { Card, Ip, Map, Name } from "./Styles";

export function RowServerCard({
  server_name,
  map,
  max_players,
  players,
  ip,
  port,
  image,
  play,
  id,
  ...props
}: IServer) {
  const [playUser, setPlayUser] = useState<number>(0);
  const [copied, setCopied] = useState(false);
  const timeoutRef = useRef<number | null>(null);

  const handleCopy = useCallback(
    (text: string) => {
      navigator.clipboard
        .writeText(text)
        .then(() => {
          if (!copied) {
            setCopied(true);
            // Сбрасываем таймер, если функция вызывается повторно до его завершения
            if (timeoutRef.current) {
              clearTimeout(timeoutRef.current);
            }
            // Устанавливаем новый таймер
            timeoutRef.current = window.setTimeout(() => {
              setCopied(false);
            }, 1000);
          }
        })
        .catch((err) => {
          console.error("Failed to copy: ", err);
        });
    },
    [copied]
  );

  useEffect(() => {
    // Очищаем таймер при размонтировании компонента
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  useMemo(() => {
    let i = 0;
    play?.servers.forEach((item) => {
      if (item.server.id == Number(id)) {
        i++;
      }
    });
    setPlayUser(i);
  }, [server_name]);

  return (
    <Card {...props}>
      <Name onClick={(e) => e.stopPropagation()}>{server_name}</Name>
      <Map onClick={(e) => e.stopPropagation()}>{map}</Map>
      <Players max_players={max_players} players={playUser} />
      <Ip onClick={(e) => e.stopPropagation()}>
        {ip}:{port}
      </Ip>
      <Row onClick={(e) => e.stopPropagation()}>
        <ActionButton
          onClick={() =>
            window.location.replace(`steam://connect/${ip}:${port}`)
          }
        >
          <TinyIcons.Play />
        </ActionButton>
        <ActionButton
          onClick={() => {
            handleCopy(`connect ${ip}:${port}`);
            toast("Адрес сервера скопирован");
          }}
        >
          <TinyIcons.Copy />
        </ActionButton>
      </Row>
    </Card>
  );
}
